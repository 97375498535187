@import "src/theme/variables.scss";

@mixin uploadBorder {
  border-radius: 5px;
  border: 1px dashed $color-primary-300;
}

.uploadWrapper {
  margin-top: 1rem;
  margin-bottom: 16px;
  text-align: center;
  color: $color-primary;

  label {
    display: flex;
    cursor: pointer;
    margin-bottom: 0;
  }

  .loader {
    border-radius: 5px;
    border: 1px dashed $color-primary-300;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 18px 6px;
    height: 200px;
  }
}

.uploadBoxWrapper {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-gap: 2%;

  .uploadResult {
    height: 169px;

    img {
      height: 90px;
    }

    p {
      margin: 0;
    }
  }
}

.invalidFileTypeDropBox {
  @include uploadBorder();
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 169px;
  font-size: 1rem;
  background-color: #ffebee;
  color: $color-secondary;

  svg {
    width: 62px;
    height: 62px;
    margin-bottom: 9px;
  }
}

.incorrectFileType {
  border-color: $color-secondary;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.pleaseTryAgain {
  border-color: $color-secondary;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.uploadDropBox {
  @include uploadBorder();
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 169px;
  font-size: 1rem;
  background: url("~src/imagesV2/certificate.svg") 50% 25% no-repeat, $color-primary-50;

  &.uploadFront {
    background: url("~src/imagesV2/cert-front.svg") 50% 25% no-repeat, $color-primary-50;
  }

  &.uploadBack {
    background: url("~src/imagesV2/cert-back.svg") 50% 25% no-repeat, $color-primary-50;
  }

  &:nth-child(2) {
    margin-left: 8px;
  }

  span {
    margin-bottom: 38px;

    u {
      font-weight: bold;
    }
  }

  &.uploadError {
    border-color: $color-secondary;
    background: url("~src/imagesV2/uploadError.svg") 50% 22% no-repeat, #ffebee;
    color: $color-secondary;

    p {
      margin-bottom: 28px;
      line-height: 21px;
      text-align: center;
    }

    b::after {
      content: "\a";
      white-space: pre;
    }
  }
}

.uploadFront span,
.uploadBack span {
  display: inline-flex;
  align-items: center;
  margin-bottom: 32px;

  &::before {
    content: "";
    width: 19px;
    height: 19px;
    transform: translateY(-1px);
    background: url("~src/imagesV2/uploadIcon.svg") center/contain;
  }
}

.uploadResult {
  @include uploadBorder();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 18px 18px 6px;
  height: 200px;

  img {
    height: 110px;
    max-width: 80%;
    object-fit: scale-down;
    object-position: 50% 80%;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    line-height: 21px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.uploadDelete {
  color: #bb0f1a;
  text-decoration: underline;
  font-size: 1rem;
  line-height: 21px;
  font-weight: bold;
}

.uploadWarning {
  color: $color-secondary;
  font-size: 0.75rem;
  text-align: center;
}

.fileUploadError {
  border-color: $color-secondary;
  font-size: 1rem;
  line-height: 21px;
  text-align: left;
}

.fileRequired {
  color: $color-secondary;
  font-size: 0.75rem;
  line-height: 21px;
  text-align: left;
  padding-left: 15px;
}

.confirmationBox {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .confirmTitle {
    height: 26px;
    flex-grow: 0;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
    margin-bottom: 24px;
  }

  .confirmSubTitle {
    width: 288px;
    height: 38px;
    margin-bottom: 24px;
    flex-grow: 0;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }

  .stayOnPage {
    width: 328px;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;
    padding: 12px 32px;
    margin-bottom: 16px;
    border: none;
  }

  .discard {
    width: 328px;
    height: 46px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;
    padding: 12px 32px;
    background-color: $color-primary;
    margin-bottom: 24px;
    border: none;
  }
}

.addNewCredential {
  font-size: 24px;
  font-weight: bold;
  color: $color-primary;
}

.autoCompleteInput {
  position: relative;
  padding-bottom: 6px;
  z-index: 9999;

  .MuiAutocomplete-endAdornment {
    display: flex;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: #fff;
    padding: 0 4px;
  }

  .MuiAutocomplete-input {
    color: $color-primary !important;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    width: 140px;
    height: 20px;
  }

  fieldset {
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    pointer-events: none;

    span {
      font-size: 1px;
      display: flex;
    }
  }
}

.spinnerDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  .MuiPaper-root {
    height: 135px;
    width: 225px;
    border-radius: 30px 30px 30px 0;
    position: fixed;
    display: flex;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
  }
}

.certForm {
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(15px, -12px) scale(0.75);
    z-index: 1;
  }

  .MuiFormHelperText-root {
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: $fontfamily-primary;
    font-weight: 400;
    line-height: 1.66;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 20px) scale(1);
    pointer-events: none;
  }

  .MuiInputLabel-animated {
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }

  .MuiInputLabel-formControl {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 24px) scale(1);
  }

  .MuiInputLabel-root {
    display: block;
    transform-origin: top left;
  }

  .actionButton {
    width: 100%;
    padding: 9px;
    font-weight: bold;
    font-size: 16px;
    border: none;
  }

  .certTitle {
    text-align: center;
  }

  .MuiFormControl-root {
    width: 100%;
    position: relative;
  }

  .MuiAutocomplete-endAdornment {
    position: absolute;
    right: 0;
    top: calc(50% - 30px);
  }

  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0;
  }

  .MuiFormHelperText-root.Mui-error {
    color: $color-error;
  }

  .MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    position: relative;
  }

  .MuiAutocomplete-root .MuiAutocomplete-input {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-overflow: ellipsis;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: -webkit-fill-available;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 16.5px 0px 16.5px 14px;
  }

  .MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
    padding-right: 0;
  }

  fieldset legend {
    width: 0px;
    visibility: hidden;
    display: none;
  }

  :focus-visible {
    outline-color: #fff;
  }
}

.centerText {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}

.formControlWidth {
  .MuiIconButton-root {
    padding: 0;
  }

  label {
    background: #fff;
    padding-right: 4px;
  }
}

.dateControl {
  label {
    background: #fff;
    padding-right: 4px;
  }
}

.orText {
  font-size: 18px;
  font-weight: bold;
  color: $color-primary;
}

.certAndLic {
  position: relative;
  left: 0;
  right: 0;
  background: #fff;
  margin: 0 auto;
  top: 5%;
  padding: 60px;
  border: 1px solid #ddd;
  border-radius: 25px 25px 25px 0;
  z-index: 99999;
  width: 100vw;
  max-width: 448px;
  max-height: calc(100% - 72px);
  overflow-y: auto;
  @media screen and (min-height: 1000px) {
    top: 15%;
  }
}

.certAndLic::-webkit-scrollbar-track {
  margin: 20px 0;
}

.certOrText {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) and (min-width: 0px) {
  .certAndLic {
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 15px 15px 15px 0;
  }

  .addNewCredential {
    font-size: 16px;
  }

  .orText {
    font-size: "14px";
  }

  .spinnerDialog {
    .MuiPaper-root {
      height: 110px;
      width: 170px;
    }
  }
}
