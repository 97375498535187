$color-primary: var(--primary-900-main);
$color-primary-shade-light: var(--primary-900-light);
$color-primary-dark: var(--primary-900-dark);
$color-primary-light: var(--primary-500);

@import "headerV2.scss";
@import "myProfileSubNav.scss";
@import "onBoardingV2.scss";
@import "dashboardBannerV2.scss";
@import "recommendedJobsV2.scss";
@import "myJobsTab.scss";
@import "jobSearchV2.scss";
@import "payrollNavigation.scss";
@import "searchResult.scss";
@import "myRecruiter.scss";
@import "benefits.scss";
@import "footerV2.scss";
@import "login.scss";
@import "changePasswordV2.scss";
@import "jobDetailsV2.scss";
@import "skeleton.scss";
@import "filter.scss";
@import "src/components/common/CertificationsLicenses/CertificationsLicenses.module.scss";
@import "hotJobs.scss";
@import "src/components/common/MainContent/Home/JobDashboardAdvertisement/JobEligibilityDashboardAdvertisement.module.scss";

/* Generic Css*/
.textCenter {
  text-align: center;
}

.pageWrapper {
  margin-top: 100px;
  margin-bottom: -24px;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: $color-secondary !important;
}

.MuiRadio-colorSecondary.Mui-disabled {
  color: #c4c4c4 !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: $color-primary !important;
  color: #fff !important;
}

.MuiPickersToolbarText-toolbarBtnSelected,
h4.MuiPickersToolbarText-toolbarTxt {
  color: #fff !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: rgba(255, 255, 255, 0.54) !important;
}

.MuiTypography-colorPrimary,
.MuiPickersMonth-root:focus,
.MuiPickersMonth-monthSelected,
.MuiPickersYear-root:focus {
  color: $color-primary !important;
}

.MuiPickersDay-current {
  color: $color-primary;
}

.MuiFormLabel-root {
  font-family: $fontfamily-primary !important;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .pageWrapper {
    margin-top: 72px;
  }
}