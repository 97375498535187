@import "src/theme/variables.scss";

.jobDetailsWrap {
  background-color: #f0f1f4 !important;
}
.descriptiontab,
.descriptiontab .jobCardMain {
  cursor: default !important;
  line-height: 26px;
}
.mainJobdetailsV2 .backButtonJobdetails {
  background-color: #fff !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  overflow: hidden;
  color: #626c7d !important;
  border: none !important;
  border-radius: 20px 20px 20px 0 !important;
  padding: 6px 25px 6px 35px !important;
  margin-left: 0px !important;
}
.mainJobdetailsV2 .shareButtonJobdetails {
  color: $color-primary !important;
  border: none !important;
  margin-left: 25px !important;
  border-radius: 20px 20px 20px 0 !important;
  padding: 6px 22px !important;
  background-color: #fff !important;
}
.jobDetailsWrap .jobDetailsBanner {
  position: absolute;
  height: 450px;
  background-size: cover;
  width: 100%;
  margin-top: -3rem;
}
.mainJobdetailsV2 .mainjobdetailsbutton {
  margin-bottom: -15px;
  margin-top: 3rem;
}
.jobDetailsRow {
  position: relative;
  margin-top: 10rem;
  height: auto;
  z-index: 99;
  background: #ffffff;
  box-shadow: 0px 3px 10px #00000019;
  border-radius: 30px 30px 30px 0px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(2, auto);
  .jobDescLayoutV2 {
    grid-area: 1/5/2/9;
    padding: 40px 40px 0;
    display: grid;
    align-items: center;
    .jobDescriptionEmailbtnposition {
      display: flex;
      justify-content: center;
      .actions-button {
        width: 80%;
      }
    }
  }
  .descriptiontab {
    grid-area: 2/8/2/1;
    padding-top: 40px;
  }
  .jobDetailsInfo {
    padding: 40px 40px 0;
    grid-area: 1/5/2/1;
  }
}
.quickApplyFlagOn {
  .jobDetailsRow {
    .jobDescLayoutV2 {
      grid-area: 1/5/3/9;
      align-items: flex-start;
      .jobDescriptionEmailbtnposition {
        display: block;
        .actions-button {
          width: 100%;
        }
      }
    }
    .descriptiontab {
      border-right: 1px solid #d6d8dc;
      grid-area: 2/5/2/1;
    }
    .jobDetailsInfo {
      border-right: 1px solid #d6d8dc;
    }
  }
}
.toasterWrapper {
  .MuiSnackbar-root {
    top: 120px;
    .MuiSnackbarContent-root {
      background-color: #f8fff5;
      padding: 17px 50px 17px 17px;
      color: #138e4d;
      font-size: 16px;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      border-radius: 5px;
      justify-content: space-around;
      .MuiSnackbarContent-action {
        padding-left: 0;
        padding-right: 16px;
      }
    }
  }
}
.mainJobdetailsV2 .exclusiveLabel {
  background: $color-primary 0% 0% no-repeat padding-box !important;
  height: 26px !important;
  border: 1px solid #373d6f;
  margin-left: 0px !important;
  padding-bottom: 1px;
}
.mainJobdetailsV2 .newLabel {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #646c7b !important;
  height: 26px !important;
  padding-bottom: 1px;
  margin-right: 5px;
}
.mainJobdetailsV2 .newLabel .MuiChip-label,
.mainJobdetailsV2 .newLabel .MuiChip-clickable:hover,
.mainJobdetailsV2 .newLabel .MuiChip-clickable:focus {
  color: #626c7d !important;
}
.mainJobdetailsV2 .specialityJobdetails {
  text-align: left !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #626c7d !important;
  margin-bottom: 20px;
  letter-spacing: normal !important;
  font-family: $fontfamily-primary !important;
}
.jobDetailsState {
  color: $color-primary;
  font-size: 1.4rem;
  font-weight: bold;
  width: 430px;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
}
.jobDetailsState a {
  display: block;
  font-size: 0.875rem;
  text-decoration: underline !important;
  cursor: pointer;
}
.mainJobdetailsV2 .payrateJobDetailsV2 {
  color: $color-primary;
}
.mainJobdetailsV2 .payrateJobDetails {
  color: #626c7d !important;
  text-transform: capitalize !important;
  font-family: $fontfamily-primary !important;
  font-size: 0.9rem !important;
}
.jobDetailsColName {
  color: #626c7d !important;
  text-transform: capitalize !important;
  font-size: 0.9rem !important;
  font-family: $fontfamily-primary;
}
.quickApplyFlagOn {
  .jobDescLayoutV2 {
    .actionsButtonInnterWrapper {
      display: flex;
      flex-direction: row;
      .actionButtonWrapper {
        padding-top: 0;
        flex-direction: row;
        .jobDescriptionEmailBtn,
        .jobDescriptionFavBtn {
          .JobDescrButton {
            background-color: #fff !important;
            border: 1px solid $color-primary !important;
            color: $color-primary !important;
            font-size: 0 !important;
            width: 50px !important;
            min-width: auto;
            height: 50px;
            padding: 0 !important;
            border-radius: 50% !important;
            .MuiButton-startIcon {
              margin: 0;
            }
            .MuiSvgIcon-fontSizeSmall {
              font-size: 30px !important;
              margin: 0;
            }
          }
        }
        .jobDescriptionFavBtn {
          margin: 0 20px;
        }
        .jobDescriptionEmailBtn {
          padding-right: 0;
        }
      }
    }
  }
}
.jobDescLayoutV2 {
  .actionsButtonInnterWrapper {
    display: flex;
    flex-direction: column-reverse;
    .actionButtonWrapper {
      display: flex;
      flex-direction: row-reverse;
      padding-bottom: 20px;
      .jobDescriptionFavBtn,
      .jobDescriptionEmailBtn {
        width: 100%;
        .JobDescrButton {
          background-color: #fff !important;
          border: 1px solid $color-primary !important;
          color: $color-primary !important;
          width: 100% !important;
          min-width: auto;
          height: auto;
          border-radius: 20px 20px 20px 0 !important;
          padding: 8px;
          font-weight: bold;
          font-size: 12px !important;
          .MuiButton-startIcon {
            margin: 0;
            .recruiterIconFilled {
              font-size: 30px !important;
            }
          }
          .MuiSvgIcon-fontSizeSmall {
            font-size: 30px !important;
            margin-right: 5px;
          }
        }
      }
      .jobDescriptionEmailBtn {
        padding-right: 30px;
        .JobDescrButton {
          .MuiSvgIcon-fontSizeSmall {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.actions-button {
  padding: 0 20px;
  .fixedAjobDescApplyBtnFixed {
    width: 100%;
  }
}
.jobDescLayoutV2 .JobDescrButton:hover {
  background-color: #f5f6f8 !important;
  border: 1px solid #d5e3ffa0 !important;
}
.jobDescLayoutV2 .btnApplyforJob,
.jobDescLayoutV2 .btnApplyforJob:disabled {
  width: 100%;
  height: auto !important;
  border-radius: 20px 20px 20px 0 !important;
  font-size: 18px !important;
  line-height: initial;
  padding: 0 10px;
  min-height: 58.5px;
}
.jobDescLayoutV2 .btnApplyforJob:disabled {
  background-color: #d5f8e7 !important;
  color: #248953 !important;
  img {
    transform: scale(1.1);
  }
}
.interestdBtnIcon svg {
  fill: #646c7b;
  height: 20px;
  width: 20px;
}
.jobSharePopover {
  width: 330px !important;
  left: 8% !important;
  box-shadow: 3px 3px 13px #00000029 !important;
  opacity: 1;
  border-radius: 10px !important;
  padding: 12px 12px;
  text-align: center;
  left: 5.5rem !important;
}
.jobSharePopover .arrow {
  left: 0px !important;
  position: absolute;
  @media screen and (min-width: 768px) {
    left: -85px !important;
  }
}
.jobDetailsRow .borderlineJobdetails {
  display: none !important;
}
.jobDetailsRow .jobDetailInfoToolTip .payRateSvg {
  fill: #626c7d;
  height: 14px;
  width: 14px;
}
.jobDetailInfoToolTip .payRateSvg {
  margin-top: 2px !important;
}
.grossPayValue {
  color: $color-primary;
  font-size: 1.9rem;
  font-weight: bold;
  position: absolute;
  margin-top: -14px;
}
.JobDescriptionJobID {
  color: #707070;
  font-weight: bold;
  font-size: 1rem;
  font-family: $fontfamily-primary;
  padding: 0 40px;
}
.mapIconJobDetail {
  padding-right: 5px;
  margin-top: -4px;
  height: 20px;
}
.mainJobdetailsV2 .shareButtonActive {
  color: $color-primary !important;
  border: none !important;
  margin-left: 25px !important;
  border-radius: 20px 20px 20px 0 !important;
  padding: 6px 22px !important;
  background-color: #fff;
}
.payrateJobDetails p {
  margin-top: 11px;
  font-size: 1rem;
}
.arrowBackIcon {
  margin-top: 1px;
  left: 15px;
  position: absolute;
  transition: 0.2s ease-in;
}
.backButtonJobdetails:hover .arrowBackIcon {
  left: 9px;
  transition: 0.2s ease-in;
}
.jobDetNoPadding {
  padding: 0rem 1.5rem 1rem 1rem;
}
.jobDetailsBed {
  padding-left: 35px;
}
.jobDetailsPadding {
  padding: 0px !important;
}
.jobDetailsWrap .h2Carousel {
  margin-left: 10px !important;
}
.quickOfferTxtJD {
  color: #d7282f;
  font-size: 0.75rem;
  font-weight: 500;
  margin-left: 5px;
}
.quickOfferSubTxtJD {
  font-size: 0.75rem;
  color: #373c73;
}
.jDetailsQuickOffer {
  margin-bottom: 10px;
}
.jDQuickOfferImgMain {
  display: inline-block;
}
.quickOfferImg {
  height: 15px;
  width: 15px;
  margin-top: -2px;
}
.jobCardMain {
  .JobDescriptionRow {
    display: flex;
    .JobDescriptionTab {
      padding: 0 40px;
      width: 100%;
      color: $color-primary;
      ul {
        padding: 0 20px;
      }
      h3 {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 14px;
      }
    }
  }
}
.jobDetailsWrap {
  .descriptiontab {
    .MuiBox-root {
      padding: 24px 0 40px;
    }
  }
}

.tabemailCard {
  position: relative;
  max-width: 400px;
  height: 405px;
  .jobDesEmailCloseBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 44px;
    height: 44px;
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .jobDetailsWrap .jobDetailsBanner {
    margin-top: -4rem !important;
    background-color: $color-primary;
    height: 718px;
    background-size: cover;
  }
  .mainJobdetailsV2 .shareButtonJobdetails {
    margin-left: 20px !important;
    background-color: #fff !important;
    border-radius: 10px 10px 10px 0 !important;
  }
  .mainJobdetailsV2 .shareButtonActive {
    color: $color-primary !important;
    border: none !important;
    border-radius: 10px 10px 10px 0 !important;
    margin-left: 20px !important;
    padding: 6px 22px !important;
    background-color: #fff !important;
  }
  .mainJobdetailsV2 .mainjobdetailsbutton {
    display: inline-flex;
    margin-left: 0px;
    width: 100%;
    margin-top: 15px;
  }
  .jobDetailsRow {
    margin-top: 6rem;
    height: max-content;
    z-index: 99;
    border-radius: 10px 10px 0px 0px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto);
    .jobDetailsInfo {
      border-right: 0;
      grid-area: 1/1/1/1;
      padding: 18px 18px 10px;
      border-bottom: 1px solid #d6d8dc;
      overflow: hidden;
      .specialitydetails {
        flex-wrap: wrap;
        padding-top: 40px;
      }
    }
    .jobDescLayoutV2 {
      padding: 0;
      grid-area: 2/1/3/1;
      .actionsButtonInnterWrapper {
        flex-direction: row;
        .actionButtonWrapper {
          padding: 0;
          flex-direction: row;
          .jobDescriptionFavBtn,
          .jobDescriptionEmailBtn {
            .JobDescrButton {
              background-color: #fff !important;
              border: 1px solid $color-primary !important;
              color: $color-primary !important;
              font-size: 0 !important;
              width: 50px !important;
              min-width: auto;
              height: 50px;
              padding: 0 !important;
              border-radius: 50% !important;
              .recruiterIconFilled {
                margin-right: 0;
                font-size: 28px !important;
              }
            }
            .MuiSvgIcon-fontSizeSmall {
              margin-right: 0;
            }
          }
          .jobDescriptionEmailBtn {
            padding-right: 0;
          }
        }
      }
      .jobDescriptionEmailbtnposition {
        .actions-button {
          position: fixed;
          bottom: 0 !important;
          left: 0;
          right: 0;
          z-index: 988;
          opacity: 1;
          margin: auto;
          background-color: #fff;
          padding: 20px 20px 10px;
          width: 100%;
        }
      }
    }
    .descriptiontab .MuiAppBar-colorPrimary {
      padding: 0;
      border: 0;
      .MuiTab-textColorInherit.Mui-selected {
        padding-left: unset;
      }
    }
  }
  .quickApplyFlagOn {
    .jobDetailsRow {
      .descriptiontab {
        padding-top: 0;
        grid-area: 3/1/3/1;
        border-right: 0;
      }
      .jobDescLayoutV2 {
        grid-area: 2/1/3/1;
      }
    }
  }
  .jobCardMain .JobDescriptionRow .JobDescriptionTab {
    padding: 0 20px;
    color: $color-primary;
  }
  .jobDescLayoutV2 .jobDescriptionEmailBtn,
  .jobDescLayoutV2 .jobDescriptionFavBtn {
    display: inline-block;
    min-width: auto;
  }
  .jobDescLayoutV2 .jobDescriptionFavBtn {
    margin: 0 10px;
  }
  .jobDescLayoutV2 .btnApplyforJob,
  .jobDescLayoutV2 .btnApplyforJob:disabled {
    width: 100%;
    font-size: 16px !important;
  }
  .jobSharePopover {
    max-width: 97% !important;
    width: auto !important;
    left: 1% !important;
    right: 0px !important;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    transform: translate3d(0px, 130px, 0px) !important;
    margin-top: 20px;
  }
  .jobDetailsState {
    width: 100%;
    margin-bottom: 28px;
    font-size: 1rem;
  }
  .mainJobdetailsV2 .specialityJobdetails {
    margin-bottom: 10px !important;
  }
  .jobDetailsColName {
    font-size: 0.8rem !important;
  }
  .mainJobdetailsV2 .specialityJobdetails {
    font-size: 0.9rem !important;
    margin-top: 14px;
  }
  .grossPayValue {
    font-size: 1.4rem;
  }
  .jobDetailsRow .jobDetailInfoToolTip .payRateSvg {
    margin-left: 7px;
    margin-top: 0px;
    height: 22px;
    width: 22px;
  }
  .mainJobdetailsV2 .backButtonJobdetails {
    margin-left: 15px !important;
    border-radius: 10px 10px 10px 0 !important;
  }
  .jobDetailsBedMob {
    padding-left: 0px;
  }
  .jobDetailsBed {
    padding-left: 0px;
  }
  .jobCardMainWrapV2 .exclusiveLabel {
    padding-bottom: 0px;
  }
  .quickOfferLabel .MuiChip-label,
  .mainJobdetailsV2 .newLabel {
    padding-bottom: 0px;
  }
  .mainJobdetailsV2 .newLabel {
    margin-left: 10px !important;
  }
  .jdQuickOfferTxtMob {
    width: 88%;
    margin-left: 30px;
  }
  .quickOfferTxtJD {
    margin-left: -1px;
  }
  .quickOfferImg {
    height: 20px;
    width: 20px;
    position: absolute;
    margin-top: 8px;
  }
  .jDQuickOfferImgMob {
    width: 20%;
  }
  .JobDescriptionRow {
    flex-direction: column-reverse;
  }

  .toasterWrapper {
    .MuiSnackbar-root {
      top: 90px;
      .MuiSnackbarContent-root {
        padding: 17px;
        flex-direction: row-reverse;
        justify-content: flex-end;
        flex-wrap: nowrap;
      }
      .MuiSnackbarContent-message {
        width: calc(100% - 34px);
      }
      .MuiSnackbarContent-root .MuiSnackbarContent-action {
        width: 24px;
        height: 24px;
        margin: 0;
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .jobDetailsColName {
    font-size: 0.6rem !important;
  }
  .payrateJobDetails p {
    margin-top: 11px;
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .jobDetailsColName {
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 375px){
  .mainJobdetailsV2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 360px) {
  .quickApplyFlagOn
    .jobDescLayoutV2
    .actionsButtonInnterWrapper
    .actionButtonWrapper
    .jobDescriptionFavBtn {
    margin: 0 16px;
  }
}
