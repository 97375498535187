@import "src/theme/variables.scss";
.emailVerificationHead {
  font-size: 1.1rem;
  color: $color-primary;
  font-weight: 700;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
  line-height: 20px;
}
.emailVerificationText {
  font-size: 1rem;
  color: $color-primary;
  text-align: center;
  line-height: 20px;
}
.emailIcon {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.emailVerificationSubText {
  color: #848484;
  text-align: center;
  margin: 15px 0 20px 0;
  font-size: 0.875rem;
  .resendEmail {
    color: $color-primary-shade-light;
    display: inline-block;
    padding: 10px 0 0;
    cursor: pointer;
    font-weight: 700;
    text-transform: none;
    width: max-content;
  }
  .resendEmail:hover {
    color: #848484;
    background-color: #ffffff !important;
  }
  .resendEmailText {
    text-decoration: underline !important;
  }
}
.loaderResendBtn {
  color: $color-primary-shade-light !important;
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
}
.mailNotFoundMsg {
  color: #626c7d;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
}
.spamMsg {
  color: #626c7d;
  text-align: center;
}
.signupModalTextWrap {
  background: #fff !important;
  box-shadow: 0px 0px 6px #00000012;
  border: 1px solid #e1e1e1;
  border-radius: 40px 40px 40px 0;
  padding: 50px 40px 30px 40px;
  min-height: 300px;
  text-align: center;
  margin: 0 20px;
}
.signupModalMargin {
  height: 14vh;
  margin-top: 100px;
}
.signupLogo {
  width: 140px;
  height: 46px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .signupModalMargin {
    height: 9vh;
    margin-top: 20px;
  }
  .signupModalTextWrap {
    margin: 0;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .signupModalMargin {
    height: 0vh;
  }
  .signupModalTextWrap {
    margin: 0;
    padding: 30px 20px 20px;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .signupModalMargin {
    height: 7vh;
  }
}
