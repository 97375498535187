@import "src/theme/variables.scss";

:root {
  --bar-border-color: "#329A4E";
  --bar-circle-color: "#26743B";
  --rotate-bar: "rotate(-90deg)";
  --text-color: "";
}

.wrapper {
  overflow: hidden;
}

.outerCircle {
  width: 72px;
  height: 38px;
  background-color: #fbf0c3;
  border-radius: 52px 52px 0px 0px;
  position: relative;
  margin: 5px 5px 0 5px;
}

.barWrapper {
  position: absolute;
  z-index: 1;
  bottom: 0;
  top: -2px;
  left: 0px;
}

.bar {
  width: 72px;
  height: 38px;
  border-radius: 52px 52px 0px 0px;
  border: 5px solid var(--bar-border-color);
  border-bottom: 0;
  transform-origin: bottom;
  position: relative;
  transform: var(--rotate-bar);
}

.bar::after {
  position: absolute;
  bottom: -5px;
  right: -8px;
  height: 10px;
  width: 10px;
  background: var(--bar-circle-color);
  content: "";
  border-radius: 50%;
}

.quickApplyTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;

  img {
    margin-right: 2px;
  }
}

.text {
  position: absolute;
  left: 50%;
  top: 60%;
  z-index: 1;
  transform: translate(-50%, -50%);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.quickApplyText {
  font-size: 9px !important;
  font-weight: 700;
  color: $color-primary;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) and (min-width: 0px) {
  .noQuickApplyText {
    .outerCircle {
      width: 46px;
      height: 23px;
      background-color: #fbf0c3;
      border-radius: 27px 27px 0px 0px;
      border: none;
      margin-top: 15px;
      margin-left: 5px;
    }

    .barWrapper {
      position: absolute;
      z-index: 1;
      bottom: 0;
      top: -1px;
      left: 0px;
    }

    .bar {
      width: 46px;
      height: 23px;
      border-radius: 27px 27px 0px 0px;
      border: 4px solid var(--bar-border-color);
      border-bottom: 0;
      transform-origin: bottom;
      position: relative;
      transform: var(--rotate-bar);
    }

    .bar::after {
      position: absolute;
      bottom: -5px;
      right: -8px;
      height: 10px;
      width: 10px;
      background: var(--bar-circle-color);
      content: "";
      border-radius: 50%;
    }

    .text {
      font-size: 8px;
    }
  }
}