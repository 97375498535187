@import "src/theme/variables.scss";

.Footer {
  background-color: transparent;
  color: #fff;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  margin: 0 auto;
}

.FooterDivider {
  max-width: 1920px;
  margin: 0 auto;
  hr {
    border-color: #5e7fb159;
    margin: 16px 0 0;
  }
}

.DoormatLabel {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
  width: fit-content;
  &:hover {
    opacity: 0.6;
    color: #fff;
  }
}

.DoormatSubLink {
  color: #fff;
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 10px;
  width: fit-content;
  &:hover {
    opacity: 0.6;
    color: #fff;
  }
}

@media screen and (max-width: 1024px) {
  .Footer {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    //padding: 50px 20px 0px 20px;
    
  }
}

@media screen and (max-width: 768px) {
  .Footer {
    flex-direction: row;
  }
}


@media screen and (max-width: 576px) {
  .Footer {
    flex-direction: column;
  }

  .FooterDivider{
    padding: 0 20px;
  }
}

@media screen and (min-width: 390px) {
  .Footer {
    padding: 50px 20px 0px 20px;
  }
}
