@import "src/theme/variables.scss";
@import "DataTable.scss";

* {
  box-sizing: border-box;
}

/* Table column sizing
================================== */
.workExpStatusColumn {
  width: 14%;
  padding-right: 0 !important;
}
.workExpActionColumn {
  width: 11%;
  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    width: 16%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1600px) {
    width: 15%;
  }
}
.workExpFacilityColumn {
  width: 30%;
  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    width: 28%;
  }
}
.workExpCityColumn {
  width: 15%;
}
.workExpDateColumn {
  width: 15%;
  @media only screen and (min-width: 1024px) and (max-width: 1600px) {
    width: 13%;
  }
}
.workExpBoxMargin {
  margin: 0 0 2rem !important;
}
@media all and (max-width: 812px) {
  .workExpBoxMargin {
    margin: 0 0 2rem !important;
  }
}
.workExpVerifyRow {
  background-color: #ccffdb;
}
.workExpJustAdded {
  display: flex;
  color: #138e4d;
  font-family: $fontfamily-primary;
  font-style: italic;
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  img {
    margin-right: 5px;
  }
}
