@import "src/theme/variables.scss";
.Icon {
  width: 28.1px;
  height: 25.8px;
  color: $color-primary;
  margin-right: 10px;
  margin-top: 10px;
}
.Frame {
  width: 250px;
  height: 55px;
  margin: 66px 88px 0 73px;
}
.JobDescriptionText {
  width: 69px;
  height: 22px;
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #224486;
}
.MuiTab-wrapper {
  flex-direction: row !important;
}
