@import "src/theme/variables.scss";

#specialtyAutocomplete .MuiAutocomplete-clearIndicator,
#specialtyAutocomplete .MuiAutocomplete-endAdornment {
  display: flex;
  margin-bottom: -3px;
}

.jobSearchOverlay {
  position: absolute;
  top: 208px;
  left: 45px;
  max-width: 107%;
  background-color: #fff;
  border: 1px solid #EFEFEF;
  z-index:998;
  border-radius:12px;
  height: 323px;
  width: 485px;
  padding:2px;
  box-shadow: 0px 1px 20px 0px rgba(118, 118, 118, 0.25);
  @media (min-width: 900px) and (max-width: 1199px) {
    max-width: 108%;
    top:200px;
    z-index:998;
    border-radius:12px;
  }
  @media (min-width: 900px) and (max-width: 992px) {
    max-width: 109% !important;
  }
  @media (min-width: 769px) and (max-width: 900px) {
    max-width: 109%;
    z-index:998;
    border-radius:12px;
    left: -30px !important
  }
  @media (max-width: 768px) {
    width: calc(100% + 30px);
    top:175px;
    z-index:998;
    border-radius:6px;
    left:14px;
    height: 397px;
    margin-top:8px;
  }
}
.bannerOverlay{
  left: 45px;
  width: 553px;
  top:113px ;
  @media(min-width:386px) and (max-width:768px) {
  left: 4%;
  width: 92% !important;
  top:80px !important
  }
  @media (max-width:385px){
      top: 30.5% !important;
      width: 98% !important;
      left: 9px !important;

  }

}




.jobSearchChipLabel .MuiChip-label {
   margin: 5px;
   color:#FFFFFF !important;
   padding:5px 15px;
   border-radius: 13px;
   gap: 5px
}

.jobSearchNumberChipLabel .MuiChip-label {
  margin: 5px;
  color:#ffffff !important;
  padding:3px;
  border-radius: 13px;
  gap: 5px
}

.jobSearchGridContainer {
  width: 114%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.jobSearchSelectedListChipLabel .MuiChip-label {
  margin: 5px;
  color:$color-primary !important;
  padding:5px 15px;
  border-radius: 13px;
  gap: 5px
}

.jobSearchSelectedListChipTextBackgroundColour {
  background-color:#E8ECF3 !important;
  margin-top: 5px;
  margin-left:5px;
}

.jobSerachSelectedListHeading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
}

.jobSerachOverlayGridStyle {
  padding-top: 5px;
  padding-right: 3px;
  @media (max-width: 768px) {
    height: 59%;;
  }
}

.jobSearchSelectedListOverlay {
  background-color: #F5F5F5;
  border-radius: 8px;
  height: 318px;
  position:relative;
  left:3.5px;
  top:-5px;
  @media (max-width: 768px) {
    border-radius: 4px !important;
    height: 147px;
    margin-top: 35px;
    bottom: -2px;
    left:2px;
  }
  @media (min-width:769px) and (max-width:900px){
    left:24px !important;
    max-width: 160px !important;
    flex-basis: 43.5% !important;
  }
}



.jobSearchChipTextBackgroundColour {
  background-color:$color-primary-400 !important;
  margin-right: 5px;
  margin-bottom:2px;
}
.jobSearchChipNumberBackgroundColour {
  background-color:$color-primary !important;
}
.jobSearchLocation {
  border-right: #a0a0a0 1px solid;
  padding-left: 16px;
}
.jobSearchSpecialties {
  padding-left: 40px;
}
.jobSearchLocation,
.jobSearchSpecialties {
  width: 100% !important;
}
.jobLocationTxt {
  color: $color-primary;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-bottom: 0;
  letter-spacing: 0.6px;
  margin-bottom: 4px;
  position: relative;
  top: 3px;
}
.jobSpecialtiesTxt {
  color: $color-primary;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-bottom: 0;
  letter-spacing: 0.6px;
  margin-bottom: 4px;
}
.jobSearchSpecial{
  display: flex;
  height: max-content;
  box-shadow: 0px 3px 20px #0000001f;
  padding: 24px 26px;
  border-radius: 25px;
  background-color: #fff;
}

.jobSearchSpecialNewFeature {
  display: flex;
  height: max-content;
  box-shadow: 0px 1px 20px 0px #0000001f;
  padding: 24px 26px;
  border-radius: 25px;
  background-color: #fff;
  @media (max-width: 768px) {
  display: flex;
  height: max-content;
  box-shadow: 0px 1px 20px 0px #0000001f;
  padding: 24px 26px;
  border-radius: 25px;
  background-color: #fff;
  width:initial;
  margin-left:initial;
  }
}
.jobSearchHeading {
  font-size: 3rem;
  color: $color-primary;
  font-family: $fontfamily-primary;
  text-align: center;
  margin: 40px 0 18px 0;
}

.clearIconMobileiOS {
  @media (max-width: 768px) {
    border-radius: 50% !important;
    margin-left: -3px !important;
    margin-top: -2px !important;
    height: 30px !important;
    width: 0px !important;
    border: none;
  }
}


#crossIconiOS{
  @media (max-width: 768px) {
  margin-top: -1px !important ;
  margin-left: -12px !important;
  color: #656E7E !important;
  }
}

.clearIconMobileAndroid {
  @media (max-width: 768px) {
    border-radius: 50% !important;
    margin-left: 0px !important;
    margin-top: 2px !important;
    height: 25px !important;
    width: 25px !important;
    border: none;
  }
}


#crossIconAndroid{
  @media (max-width: 768px) {
    margin-top: -1px !important ;
    margin-left: -5px !important;
    color: #656E7E !important;
    }
}

#iconButton{
  @media (max-width: 768px) {
    background-color: #E8ECF3 !important;
}
}
.jobSearchBtnMain {
  display: flex;
  align-items: center;
}
.jobSearchBtn {
  background-color: $color-secondary !important;
  color: #fff !important;
  font-size: 1.1rem !important;
  padding: 9px 5px !important;
  border-radius: 10px 10px 10px 0 !important;
  min-width: auto;
  height: 50px;
  width: 50px;
}
.jobSearchBtn:hover {
  box-shadow: 0px 8px 8px #00000029 !important;
}
.jobSearchBtn.MuiButtonBase-root.Mui-disabled {
  background-color: #ececec !important;
  color: #707070 !important;
  border-radius: 20px 20px 20px 0 !important;
}
.jobSearchIcon {
  width: 25px !important;
  height: 25px !important;
}
.jobSearchLocation
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
  .MuiAutocomplete-input:first-child {
  padding: 10px 0 !important;
  color: #1b1c1d;
  font-size: 0.95rem;
}
.specialtyChip .MuiChip-colorPrimary {
  color: #fff !important;
  background-color: #e8ecf3 !important;
  margin: 8px 0px 0px 0px !important;
  font-size: 0.7rem !important;
  height: 25px !important;
  box-shadow: none !important;
}

.jobLocationMapIcon {
  position: absolute;
  margin-top: 15px;
  margin-left: 15px;
}
.specialitySearchIcon {
  position: absolute;
  margin-left: 24rem;
  margin-top: 15px;
}
.jobLocationMapIcon .MuiSvgIcon-root,
.specialitySearchIcon .MuiSvgIcon-root {
  fill: #88888890 !important;
  display: none;
  width: 20px !important;
  height: 20px !important;
}
.jobSearchLocation .MuiPaper-root {
  box-shadow: none !important;
  background-color: transparent;
}
.specialtiesAutocompleteDropdownSelected {
  display:flex ;
  flex-wrap:wrap;
}
.specialtiesDropdownResult {
  margin-top: -10px;
}
.zIndexSpecialtyDropdown {
  z-index: 998 !important;
}
.specialtiesDropdownSelected {
    margin-top: 4px
}
.specialtiesDropdownSelected .MuiChip-root {
  background-color: #e8ecf3 !important;
}
.specialtiesDropdownSelected .MuiChip-deleteIcon {
  color: #fff !important;
}
.specialtiesDropdownSelectedChip {
  background-color: #e8ecf3 !important;
  margin: 5px 0px !important;
  font-family: 'Nunito Sans' !important;
  color: $color-primary !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.MuiAutocomplete-noOptions{
  padding: 0px !important;
}
::-webkit-scrollbar {
  width: 5px;
}
.jobSearchSpecialties .css-1k430x0-MuiButtonBase-root-MuiChip-root {
  color: #373c73 !important;
  background-color: #e8ecf3 !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  height: 20px !important;
  border: 1px solid red !important;
}
.jobSearchSpecialties .MuiChip-label {
  color: #373c73 !important;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: $fontfamily-primary;
  line-height: initial;
}
.jobSearchSpecialties .MuiChip-deleteIcon {
  color: #fff !important;
  font-size: 0px !important;
  background: #626c7d;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobSearchSpecialties .MuiChip-root {
  background-color: #e8ecf3;
  height: 25px;
  font-family: $fontfamily-primary !important;
  margin-left: 0;
  border: 0;
  margin-right: 5px;
}
.jobSearchSpecialties .MuiSvgIcon-fontSizeSmall {
  font-size: 0.8125rem !important;
}
#specialtyAutocomplete .MuiOutlinedInput-root .MuiAutocomplete-input {
  opacity: 1;
  position: relative;
  width: 100% !important;
  border-radius: 5px;
  font-size: 0.95rem;
  font-family: $fontfamily-primary !important;
}
#specialtyAutocomplete .MuiOutlinedInput-root {
  background-color: #fff;
  padding: 0;
  box-shadow: none !important;
}
#specialtyAutocomplete .MuiInputLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0);
}
#specialtyAutocomplete .listBox {
  position: relative;
  z-index: 995;
}
.jobSearchSpecialties
  .MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #c4c4c4 !important;
}
.jobSearchSpecialties .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
#specialtyInput {
  background-color: #fff;
}
#specialtyAutocomplete .MuiAutocomplete-input:focus {
  padding: 2px 5px 1px 5px !important;
  opacity: 1;
}
#specialtyAutocomplete .MuiAutocomplete-input:not(:focus) {
  height: 0;
  padding: 0 !important;
  opacity: 0;
}
#specialtyAutocomplete .errorMsg .MuiAutocomplete-input:not(:focus) {
  height: auto;
  padding: 0 !important;
  opacity: 1;
}
.jobSearchSpecialties span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
  background-color: #102773e1;
  border-radius: 5px;
  padding: 4px 2px 2px 6px;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
}
.txtFieldClsIcon {
  margin-right: 15px !important;
}
#specialtyAutocomplete legend {
  width: 0px;
}
#specialtyAutocomplete .MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
  padding-right: 60px !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .JobSearchFormContainer input::placeholder {
    font-size: 0.8rem !important;
  }
}

.subJobBannerSection {
  max-width: 1080px;
  width: 100%;
  @media (min-width:769px){
    padding: 0px 24px 0px 24px
  }
  @media (min-width:900px) and (max-width:1200px){
    padding: 0px 50px 0px 50px;
  }
  @media (max-width:768px){
    padding: 0px 100px
  }
  @media (max-width:600px){
    padding: 0px 20px
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .jobSearchSpecial {
    display: block !important;
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
  }
  .jobSearchSpecialNewFeature {
    display: block !important;
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
  }
  .JobBannerSection .jobSearchHeading {
    font-size: 1.875rem;
  }
  .JobBannerSection {
    .jobSearchLocation,
    .jobSearchSpecialties {
      border: 0;
    }
  }
  .loactionPaperMob {
    width: 100% !important;
    margin-top: 2px;
  }
  #specialtyAutocomplete .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 0.8rem;
  }
  .jobSearchSpecialties .MuiChip-root {
    height: 27px;
    max-width: 79%;
  }
  .specialtyChip {
    position: relative;
    max-width: 100vw !important;
    margin-top: 55px;
  }
  .specialtyChip .MuiChip-label {
    white-space: normal;
    padding: 10px !important;
    text-transform: uppercase;
    font-size: 0.7rem;
  }
  .specialtyChip .MuiChip-colorPrimary {
    height: auto !important;
    margin: 8px 0px 0px 8px !important;
  }
  .jobSearchLocation .MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
    // margin-left: -25px !important;
    margin-top: 10px !important;
  }
  .jobSearchHeading {
    font-size: 1.875rem;
  }
  .jobSearchLocation,
  .jobSearchSpecialties {
    width: 100% !important;
    margin-top: 20px !important;
    margin-left: 0px !important;
    opacity: 1;
    border-radius: 16px;
    background-color: white;
    padding: 15px 20px;
    border-right: 0;
    border: 1px solid #c4c4c4;
  }
  .jobSearchInBanner {
    padding : 6px 20px;
  }
  .jobSearchBtn {
    width: 100% !important;
    height: 55px !important;
  }
  .jobSearchBtnMain .jobSearchBtn {
    margin-top: 20px;
  }
  .jobSearchLocation
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    padding: 0 !important;
    text-transform: capitalize !important;
  }
  .jobLocationMapIcon {
    display: none;
    margin: 0;
  }
  #specialtyAutocomplete .MuiOutlinedInput-root {
    min-height: auto;
    border-radius: 16px;
    padding: 0;
  }
  #specialtyAutocomplete .errorMsg .MuiAutocomplete-input:not(:focus) {
    padding: 3px 5px 7px 5px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .jobSearchBtn {
    padding: 10px 5px !important;
    font-size: 0.9rem !important;
  }
  .jobSearchIcon {
    width: 20px !important;
    height: 20px !important;
    margin-top: 3px !important;
  }
  .jobLocationMapIcon .MuiSvgIcon-root,
  .specialitySearchIcon .MuiSvgIcon-root {
    width: 15px !important;
    height: 15px !important;
  }
  .JobSearchFormContainerBanner{
    padding:0 !important
  }
  .jobSearchSpecialties .MuiChip-label {
    font-size: 12px !important;
  }
  .jobSearchSpecialties .MuiChip-root {
    height: 22px;
  }
  .jobSearchSpecialties .MuiChip-deleteIcon {
    height: 16px;
    width: 16px;
  }
  #specialtyAutocomplete .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 0.75rem;
  }
  .jobLocationMapIcon {
    margin-top: 11px;
    margin-left: 14px;
  }
  #specialtyAutocomplete .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: 0.75rem;
  }
  #specialtyAutocomplete .MuiAutocomplete-input:focus {
    padding: 6px 5px 5px 5px !important;
    opacity: 1;
  }
  #specialtyAutocomplete .errorMsg .MuiAutocomplete-input:not(:focus) {
    padding: 9px 5px 7px 5px !important;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .jobSearchBtn {
    padding: 0 !important;
  }
  .jobSearchLocation
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    padding: 0 !important;
    font-family: $fontfamily-primary !important;
    margin-top: 5px;
  }
  #specialtyAutocomplete .MuiOutlinedInput-root {
    min-height: auto;
    padding: 0;
    background-color: transparent;
  }
  .jobLocationMapIcon {
    margin-top: 11px;
    margin-left: 14px;
  }
  .jobLocationMapIcon .MuiSvgIcon-root,
  .specialitySearchIcon .MuiSvgIcon-root {
    fill: #88888890 !important;
    width: 18px !important;
    height: 18px !important;
    display: none;
  }
  #specialtyAutocomplete .MuiAutocomplete-input:focus {
    padding: 0 !important;
  }
}
