.accSettingSubHeadV2 {
  font-size: 1.4rem;
  color: $color-primary;
  font-weight: bold;
  margin-bottom: 1rem;
}
.changePswInput {
  width: 75% !important;
  margin-top: 20px !important;
  .MuiOutlinedInput-input {
    color: #888;
    font-weight: 400 !important;
    line-height: 1rem;
    padding: 16px 15px !important;
    color: $color-primary;
    font-size: 0.9rem !important;
    font-weight: 700;
  }
  .MuiInputLabel-formControl {
    font-size: 1rem !important;
    background-color: #fff;
    padding: 0 7px 0 2px !important;
  }
}
.saveButtonV2 {
  text-align: center;
  display: inline-flex;
  margin-top: 1rem;
  border-radius: 10px 10px 10px 0 !important;
  margin-bottom: 2rem;
}
.changePswStrengthV2 {
  margin: 0 auto;
  text-align: left;
  width: 75%;
  color: #707070;
  font-size: 0.7rem;
}
.changePswInput .passwordIcon {
  color: #707070 !important;
}
.accSettingNewPwdSection .password-strength-meter {
  text-align: left !important;
  width: 75%;
  margin: 0 auto !important;
}
.changPswCloseBtn {
  height: 15px;
  width: 15px;
}
.changePswWrap {
  width: 500px;
}
.changePswWrap .saveChangebtnbottom {
  float: none !important;
  background-color: $color-secondary !important;
  color: white !important;
  border: none;
}
.changePswWrap .MuiFormHelperText-root {
  color: $color-error !important;
}
.successMsgFadeOutV2 {
  color: #2fcb65;
  background: #ffffff repeat padding-box;
  box-shadow: 7px 3px 15px #00000049;
  border-radius: 5px;
  padding: 13px 22px;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  width: max-content;
  margin-left: 40vw;
  margin-top: 40vh;
  opacity: 1;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.successMsgFadeInV2 {
  color: #2fcb65;
  background: #ffffff repeat padding-box;
  box-shadow: 7px 3px 15px #00000049;
  border-radius: 5px;
  padding: 13px 22px;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  width: max-content;
  margin-left: 40vw;
  margin-top: 40vh;
  opacity: 1;
}
.changePswWrap .MuiInputBase-input:-webkit-autofill,
.changePswWrap .MuiInputBase-input:-webkit-autofill:active,
.changePswWrap .MuiInputBase-input:-webkit-autofill:focus,
.changePswWrap .MuiInputBase-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-text-fill-color: #707070 !important;
}
.changePswInput .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid $color-primary;
}
.changePswInput .MuiOutlinedInput-notchedOutline {
  border: 1px solid #0000001f;
}
.changePswInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-primary;
}
.Mui-error .changePswInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-secondary;
}
.changePswInput .MuiFormLabel-root.Mui-focused {
  color: $color-primary;
}
.changePswInput .MuiInput-underline:after {
  border-bottom: 1px solid $color-primary;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .successMsgFadeOutV2,
  .successMsgFadeInV2 {
    margin: 34vh auto;
    width: 100%;
  }
  .changePswWrap {
    width: 345px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .accSettingSubHeadPadding {
    color: $color-primary;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0rem;
    margin-top: -1rem;
  }
  .saveButtonV2Padding {
    text-align: center;
    display: inline-flex;
    margin-top: 0rem;
    border-radius: 10px 10px 10px 0 !important;
    margin-bottom: 1rem;
  }
}
