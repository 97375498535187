@import "src/theme/variables.scss";

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.17);
  border-radius: 30px 30px 30px 0;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.topBar {
  display: flex;
  justify-content: space-between;
}

.close {
  button {
    padding: 0;
  }

  svg {
    color: $color-gray;
  }
}

.cityState {
  color: #656E7E;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;

  svg {
    font-size: 15px;
    margin-right: 5px;
  }
}

.facilityName {
  color: #656E7E;
  font-size: 12px;
  font-weight: 700;
  height: 2.4em;
  line-height: 1.2em;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 25px;
}

.message {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  border-radius: 10px;
  margin-bottom: 25px;

  div:first-of-type {
    width: 100%;

    div {
      background-color: #f8f8f8;
    }
  }

  textarea {
    font-size: 12px;
  }
}

.extraInfo {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    color: #656E7E;
    font-size: 12px;
    font-weight: 600;
  }

  svg {
    font-size: 15px;
    margin-right: 2px;
  }
}

.send {
  flex-shrink: 0;

  button,
  button:hover {
    border-radius: 0px 0px 15px 0px;
    width: 100%;
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: $color-secondary;
    color: white;
    font-size: 16px;
    font-weight: 700;
  }

  button:hover {
    background-color: #f83d34;
  }

  button:disabled {
    background-color: #E1E1E1;
    color: $color-gray;
  }
}

@include breakpoint("sm") {
  .cityState {
    font-size: 20px;

    svg {
      font-size: 20px;
      color: #656E7E;
    }
  }

  .facilityName {
    color: #656E7E;
    font-size: 16px;
  }

  .extraInfo {
    div {
      font-weight: 700;
    }
  }
}