@import "src/theme/variables.scss";

.paystubNoAssignmentWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  letter-spacing: 0px;
  height: 430px;
  color: #373c73;
  font-size: 20px;
  font-family: $fontfamily-primary;
  p {
    max-width: 500px;
    text-align: center;
    margin: 0;
    padding: 40px 9px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .paystubNoAssignmentWrapper {
    height: 60vh;
    p {
      max-width: 340px;
    }
  }
}
