@import "src/theme/variables.scss";

.container {
  position: relative;
  width: 30%;
  padding-bottom: 30%;
  margin: 0 auto 20px;
}

.circle {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.circle circle {
  fill: none;
  stroke-width: 5;
}

.background {
  stroke: #ECF1F8;
}

.bar {
  stroke: #39cd7e;
  transition: stroke-dashoffset 0.5s ease-out;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: #ECF1F8;
}

@include breakpoint("lg") {
  .container {
    width: 100%;
    padding-bottom: 100%;
    margin: 0 auto;
  }
}