@import "src/theme/variables.scss";

.jobCardMain {
  cursor: pointer;
}
.jobCardMainWrapV2 .jobCardBackImg {
  position: relative;
  width: 100%;
  height: 140px;
}
.jobCardMainWrapV2 .mbCardImage {
  cursor: pointer;
  height: 140px !important;
  width: 100%;
  position: relative;
  background-size: cover !important;
  border-radius: 30px 30px 0 0 !important;
}
.jobCardMainWrapV2 .buttonEx {
  position: absolute;
  z-index: 988;
  left: 0px !important;
  top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.jobCardHeaderIcon {
  display: flex;
  align-items: flex-start;
}

svg.headerIcon,
svg.recruiterIcon,
svg.favoriteIcon {
  fill: white;
  font-size: 1.2rem;
  &:hover {
    fill: $color-secondary;
  }
}

.alice-carousel__stage-item .jobCardMainWrapV2 .hospitalNameV2 {
  line-height: 30px;
}
.alice-carousel__stage-item.__active {
  width: auto !important;
}
.alice-carousel__stage-item .jobCardMainWrapV2 .imInterestedBtn,
.alice-carousel__stage-item .jobCardMainWrapV2 .imInterestedBtn:disabled {
  padding: 17px 15px !important;
}
.jobCardMainWrapV2 .iconLabel,
.emailCardV2 .emailRecIcons .iconLabel,
.alice-carousel .emailRecIcons .iconLabel {
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: capitalize !important;
  padding-right: 12px !important;
  padding-left: 6px !important;
  color: #888888 !important;
  font-weight: 700;
}
.jobCardMainWrapV2 .imInterestedBtnrow {
  margin-right: 0px;
}
.JobCardContainer {
  padding: 0 0.5rem !important;
}
.JobCardContainer .jobCardMain {
  border-radius: 30px 30px 20px 0px !important;
  position: relative;
}
.jobCardMainWrapV2,
.emailCardV2 {
  height: 395px;
}
.emailCardV2 {
  position: relative;
}
.emailCardV2 .emailCardCloseButton {
  position: absolute !important;
  right: 7px;
  top: 7px;
}
.emailCard .emailRecIcons {
  padding-top: 24px;
}
.jobCardMainWrapV2,
.emailCardV2 {
  height: 395px;
}
.alice-carousel__stage-item .emailCardV2 .emailRecIcons {
  padding-top: 20px;
}
.mainCarouselsection .emailCard > div {
  height: 395px !important;
  border-radius: 10px 10px 0px 0px !important;
}
.mainCarouselsection .emailCard {
  width: 375px !important;
}

.jobCardMainWrapV2 img,
.emailCardV2 .emailRecIcons img,
.alice-carousel .emailRecIcons img,
.jobCardMainWrapV2 svg,
.emailCardV2 .emailRecIcons svg,
.alice-carousel .emailRecIcons svg {
  color: #888;
  height: 14px;
  width: 14px;
  margin-bottom: 0px;
  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    height: 12px;
    width: 12px;
  }
}
.jobCardMainWrapV2 .nightIcon,
.emailCardV2 .emailRecIcons .nightIcon,
.alice-carousel .emailRecIcons .nightIcon,
.tabemailCard .emailRecIcons .nightIcon {
  transform: rotate(0deg);
}
.jobCardMainWrapV2 .jobCardFooter {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mainCarousel {
  //margin-top: 6rem !important;
  border-top: 1px solid #f1ecec !important;
  height: auto !important;
  background-color: #F9FAFC !important;
  padding: 30px 0 35px 15px !important;
}
.jobmasterheading {
  font-size: 1.5rem !important;
  color: $color-primary !important;
  margin-left: 30px !important;
  margin-bottom: 30px !important;
}
.jobCardMainWrapV2 .jobCardHeader {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 15px;
}
.jobCardMainWrapV2 .hospitalNameV2 {
  width: 265px;
  margin-right: 12px;
  font-size: 1.25rem !important;
  font-weight: 800;
  color:$color-primary;
  min-height: 82px;
}
.jobFacilityName {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
img.mapIconJobCard {
  width: 16px;
  height: 11px;
  padding-right: 4px;
  margin-top: -4px;
  height: 20px;
  color: inherit;
}
.jobCardSalary b {
  font-weight: 700;
}
.alice-carousel {
  .specialtyJobCardWrap {
    padding: 0 0 10px 15px;
  }
}
.specialtyJobCardWrap {
  text-align: left;
  padding: 0 0 10px 15px;
}
.jobCardDate {
  display: flex;
  margin: 0 23px;
  font-size: 14px;
  line-height: 17px;
  margin-top: 26px;
  font-weight: 500;
  p {
    color: #656e7e;
    font-size: 12px;
    line-height: 1.25;
    margin-bottom: 2px;
  }
  div {
    flex: 1;
  }
}
.jobCardMainWrapV2 .specialtyNameJobCardV2 {
  font-size: 1.125rem;
  color: $color-primary-dark !important;
  font-family: $fontfamily-primary;
}
.jobTitleV2 {
  width: 84% !important;
  height:21px;
  line-height: 18px;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #626c7d;
  font-size: 0.9rem !important;
  font-weight: 700;
  padding-left: 15px;
  margin-bottom: 10px;
}
.jobCardMain {
  border-radius: 30px 30px 20px 0px !important;
}
.iconMarginV2 {
  margin-left: 20px !important;
  margin-right: 6px;
  &:hover {
    background-color: unset;
  }
}
.MuiIconButton-root:hover {
  background-color: unset;
}
.alice-carousel .jobCardHeaderIcon {
  padding-top: 0px;
}
.jobCardOverlayImg {
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.3) 100%
  );
  height: 140px;
  width: 100%;
  z-index: 9;
  position: absolute;
  top: 0px;
}
.jobCardMainWrapV2 .exclusiveLabel {
  background: $color-primary 0% 0% no-repeat padding-box !important;
  height: 25px !important;
  border: 1px solid #373d6f;
  margin-left: 0px !important;
  margin-right: 5px;
  padding-bottom: 0;
  margin-bottom: 0 !important;
  font-family: $fontfamily-primary !important;
}
.jobCardMainWrapV2 .newLabel {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #646c7b !important;
  margin-right: 5px;
  height: 23px !important;
  margin-bottom: 0 !important;
}
.mainCarouselsection .newLabel {
  margin-bottom: 1px !important;
}
.jobCardMainWrapV2 .newLabel .MuiChip-label {
  color: #626c7d !important;
}
.jobCardMainWrapV2 .jobCardIcons {
  padding-left: 30px;
}
.jobCardMainWrapV2 .MuiCardContent-root:last-child {
  padding: 0px;
}
.mainCarousel .emailCard .MuiCardContent-root:last-child {
  padding: 0;
}
.emailCardV2 .mainCarousel .emailCardCloseButton {
  top: 1%;
}
.interestedBtnPos {
  cursor: default !important;
  margin-top: 2px;
}
.jobCardMainWrapV2 .imInterestedBtn,
.jobCardMainWrapV2 .imInterestedBtn:disabled {
  width: 100% !important;
  font-size: 0.9rem !important;
  border-radius: 0 0 20px 0 !important;
  padding: 12px 15px !important;
  font-family: $fontfamily-primary;
  font-weight: bold !important;
}
.jobCardMainWrapV2 .withStatus:disabled {
  background-color: #e1e4f5 !important;
  color: $color-primary !important;
  span::before {
    content: none !important;
  }
  &.applied,
  &.applied__submitted {
    span::before {
      content: "" !important;
      background: url("~src/imagesV2/applyStatusIcon.svg") center right no-repeat !important;
    }
  }
  &.applied__new_offer span::before {
    content: "" !important;
    background: url("~src/imagesV2/newOfferStatusIcon.svg") center right no-repeat !important;
  }
  &.completed,
  &.ended_assignment,
  &.cancelled_assignment,
  &.application_closed {
    background-color: #88909f !important;
    color: #ffffff !important;
  }
}

.jobCardMainWrapV2 .payRateSvg {
  fill: #d1d5e1;
  height: 20px;
  width: 20px;
  margin-top: -3px;
  min-height: 27px;
}
.mainCarouselsection .payRateSvg {
  min-height: 22px;
}
.jobCardMainWrapV2 .jobCardInfoWithoutPayrateToolTip .payRateSvg {
  margin-top: -2px;
}
.emailRecHospitalNameV2 p {
  font-family: $fontfamily-primary;
  margin: 0rem 0rem 0.6rem 0rem;
  font-size: 1.25rem !important;
  padding: 0px;
  color: #626c7d !important;
  text-align: left !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 93%;
}
.emailRecHospitalNameV2 {
  padding: 31px 20px 0;
}
.emailRecHospitalNameV2 .emailTextArea {
  margin-top: 5px !important;
}
.alice-carousel .jobCardMainWrapV2 .jobCardIcon {
  margin-top: 10px;
  margin-right: 10px;
}
.alice-carousel .jobCardMainWrapV2 .exclusiveLabel {
  margin-top: 10px;
  margin-left: 10px;
  padding-bottom: 1px;
}
.alice-carousel .jobCardMainWrapV2 .interestedBtnPos {
  position: absolute;
  bottom: 0;
}
.alice-carousel .jobCardMain {
  height: 395px !important;
  border-radius: 30px 30px 20px 0px !important;
}
.recomdJobCardPadding {
  padding-left: 0px;
  padding-right: 0px;
}
.recomdJobCardPadding {
  .jobCardsFlexWrap {
    .interestedBtnPos {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
}
.recomdJobCardPadding .jobCardsFlexWrap .interestedBtnPos {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.emailCardV2 .emailRecButtonRowV2 .sendButtonPos {
  margin-top: 18px;
  text-align: left !important;
}
.emailCardV2 .emailRecIcons {
  padding: 12px 0 0 20px;
  @media screen and (min-width: 1680px) {
    padding-top: 12px;
  }
  @supports (-moz-appearance: none) {
    padding: 12px 0 0 20px;
  }
}
.jobCardMainV2 {
  border-radius: 10px 10px 0px 0px !important;
}
.sendEmailBtnV2 {
  text-align: center;
  letter-spacing: 0;
  color: #ffffff !important;
  font-weight: bold !important;
  padding: 12px 15px !important;
  font-size: 0.9rem !important;
  background: $color-secondary 0% 0% no-repeat !important;
  border-radius: 0 0 20px 0 !important;
  box-shadow: none !important;
  font-family: $fontfamily-primary !important;
  width: 100%;
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
}
.sendEmailBtnV2:hover {
  background: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
  width: 100%;
}
.sendEmailBtnV2:disabled {
  background-color: #e1e1e1 !important;
  color: #0000007f !important;
  text-align: center;
  letter-spacing: 0;
  font-weight: bold !important;
  padding: 12px 15px;
  font-size: 0.9rem;
  border-radius: 0 0 20px 0 !important;
  font-family: $fontfamily-primary !important;
  width: 100%;
}
.mainCarouselsection {
  #filled-textarea {
    margin-top: 0;
  }
  .sendEmailBtnV2 {
    padding: 17px 15px !important;
  }
  .emailSendBtnV2 {
    margin-top: 10px !important;
  }
  .sendEmailBtnV2:disabled {
    padding: 17px 15px !important;
  }
  .emailCard .emailRecIcons {
    margin-top: 12px;
    padding-left: 20px;
    padding-top: 17px;
    @supports (-moz-appearance: none) {
      padding-top: 19px;
    }
    @media screen and (min-width: 760px) {
      padding-top: 17px;
      @supports (-moz-appearance: none) {
        padding-top: 18px;
      }
    }
    @media screen and (min-width: 1024px) {
      padding-top: 14px;
    }
    @media screen and (min-width: 1280px) {
      padding-top: 26px;
      @supports (-moz-appearance: none) {
        padding-top: 26px;
      }
    }
  }
  .emailTextArea {
    margin-top: 8px !important;
  }
}
.emailCardV2 .mainCarousel .emailCardCloseButton {
  top: 1% !important;
}
.MuiFilledInput-multiline.MuiFilledInput-marginDense {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}
.alice-carousel .MuiFilledInput-multiline.MuiFilledInput-marginDense {
  padding-top: 16px !important;
  padding-bottom: 0px !important;
  @media screen and (min-width: 1680px) {
    padding-top: 7px !important;
  }
}
.tabemailCard .dayIcon,
.tabemailCard .timeIcon,
.tabemailCard .rotationIcon,
.tabemailCard .eveningIcon,
.tabemailCard .nightIcon,
.tabemailCard .weeksIcon {
  color: #888;
  height: 14px;
  width: 14px;
  margin-bottom: 4px;
}
.emailRecMinHrsMob {
  display: inline-block;
}
.emailRecIcons .weeksIcon {
  margin-right: 3px;
}
.tabemailCard .emailRecButtonRowV2 {
  margin-top: 1.5em;
}
.tabemailCard .emailSendBtnV2 {
  margin-top: -3px;
}
.tabemailCard .iconLabel {
  color: #888;
  padding-right: 10px;
  font-weight: normal;
  text-transform: capitalize;
  font-size: 0.875rem;
}

.modalHead {
  color: #ff8388;
  font-family: $fontfamily-primary;
  text-align: center;
  font-size: 2rem;
  padding: 10px 0;
  font-weight: bold;
}
.modalText {
  color: #707070;
  text-align: center;
  font-size: 0.8rem;
}
.tryAgainBtn {
  padding: 5px 20px !important;
  color: #fff !important;
  background-color: $color-primary !important;
  box-shadow: none !important;
  margin-top: 2rem !important;
  text-transform: uppercase;
  border-radius: 10px 10px 10px 0 !important;
}
.emailRecButtonRowV2 .emailRecIcons {
  padding-right: 0px;
}
.tryAgainBtn:hover {
  background-color: #4e5794 !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.loaderTryAgainBtn {
  color: #fff !important;
}

.quickOfferLabel,
.mainCarouselsection .quickOfferLabel {
  background-color: $color-secondary !important;
  border: 1px solid #c40a12 !important;
  height: 25px !important;
  margin-bottom: 0 !important;
  margin-right: 5px !important;
}
.mainCarouselsection .quickOfferLabel,
.mainCarouselsection .newLabel {
  margin-top: 10px;
}
.mainCarouselsection .newLabel {
  margin-top: 0 !important;
}
.quickOfferLabel .MuiChip-label {
  color: #fff !important;
  font-size: 0.75rem;
  padding-bottom: 1px;
}
.mainJobdetailsV2 .quickOfferLabel {
  margin-top: 0px;
  margin-left: 0px;
}
.jobDetailsWrap .mainJobdetailsV2 .quickOfferLabel {
  margin-bottom: 10px !important;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .JobCardContainer {
    padding: 22px 0px 7px 0px !important;
  }
  .JobCardContainer .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mainCarousel {
    padding: 30px 0 35px 0 !important;
  }
  .jobmasterheading {
    margin-left: 7px !important;
    margin-bottom: 0px !important;
    font-size: 1.4rem !important;
  }
  .jobCardMainWrapV2 {
    width: 100% !important;
  }
  .jobCardMainWrapV2 .jobCardMinHrsMob,
  .weeksMob {
    display: inline-block !important;
  }
  .jobCardMainWrapV2 .jobCardBackImg {
    height: 140px;
  }
  .jobCardMainWrapV2 .mbCardImage {
    height: 140px !important;
    background-size: cover !important;
  }
  .jobCardMainWrapV2 .hospitalName {
    font-size: 1.2rem !important;
    padding-top: 4.5rem !important;
  }
  .mainCarouselsection .emailCard {
    width: 100% !important;
  }
  .jobDetailsWrap .mainCarousel .sendButtonPos {
    padding: 0px 20px;
  }
  .mainCarousel .alice-carousel__stage-item .iconLabel {
    font-size: 0.7rem !important;
  }
  .mainCarouselsection .emailCardV2 .emailRecHospitalName > p {
    margin-top: 30px !important;
    font-size: 1.2rem !important;
  }
  .jobCardMainWrapV2 .iconLabel {
    font-size: 0.75rem !important;
    padding-right: 12px !important;
    padding-left: 6px !important;
  }
  .emailCardV2 .emailRecIcons .iconLabel {
    font-size: 0.75rem !important;
    padding-right: 12px !important;
    padding-left: 6px !important;
  }
  .emailRecHospitalNameV2 p {
    font-size: 1rem !important;
  }
  .jobTitleV2 {
    margin-bottom: 10px;
    width: 98% !important;
    margin-top: 0;
  }
  .jobCardMainWrapV2 .jobCardIcon {
    padding-top: 0px !important;
    float: right !important;
    margin-right: 35px !important;
    margin-top: 7px !important;
  }
  .jobCardMainWrapV2 .payRateSvg {
    height: 17px;
    width: 17px;
    margin-top: -5px;
  }
  .jobCardMainWrapV2 .imInterestedBtnrow {
    margin-right: 0px;
  }
  .jobCardMainWrapV2 .buttonEx {
    top: 15px;
    z-index: 98;
  }
  .interestedBtnPos {
    padding-left: 0px !important;
    text-align: left !important;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  .jobCardMainWrapV2 .imInterestedBtn:disabled {
    box-shadow: none !important;
  }
  .alice-carousel .interestedBtnPos {
    width: 100%;
    text-align: left !important;
  }
  .jobCardMainWrapV2 .timeIcon,
  .jobCardMainWrapV2 .dayIcon,
  .jobCardMainWrapV2 .rotationIcon,
  .jobCardMainWrapV2 .nightIcon,
  .jobCardMainWrapV2 .eveningIcon,
  .jobCardMainWrapV2 .weeksIcon {
    height: 14px;
    width: 14px;
    margin-bottom: 0px;
  }
  .tabemailCard .dayIcon,
  .tabemailCard .timeIcon,
  .tabemailCard .rotationIcon,
  .tabemailCard .eveningIcon,
  .tabemailCard .nightIcon {
    color: #888;
    height: 14px;
    width: 14px;
    margin-bottom: 5px;
  }
  .jobCardMainWrapV2 .exclusiveLabel {
    margin-top: 0;
    padding-bottom: 0px;
  }
  .jobCardMainWrapV2 .hospitalNameV2 {
    width: 265px;
  }
  .alice-carousel .jobCardMainWrapV2 .hospitalNameV2 {
    width: 240px;
  }
  .quickOfferLabel .MuiChip-label,
  .mainJobdetailsV2 .newLabel {
    padding-bottom: 0px;
  }
  .mainCarouselsection .emailCardCloseButton {
    right: 15px !important;
    top: 14px !important;
  }
  .mainCarousel .emailCardCloseButton {
    right: 7px !important;
    top: 7px !important;
  }
  .JobCardContainer .jobCardMain {
    height: 395px !important;
  }
  .alice-carousel .jobCardMainWrapV2 .interestedBtnPos {
    position: absolute;
    bottom: 0;
  }
  .emailRecButtonRowV2 {
    margin-top: 0rem;
    text-align: right !important;
  }
  .emailSendBtnV2 {
    margin-top: 14px;
    width: 100%;
  }
  .emailCard .MuiCardContent-root:last-child {
    padding: 0;
  }
  .emailCardV2 .emailRecIcons {
    padding-top: 7px;
  }
  .sendEmailBtnV2:disabled,
  .sendEmailBtnV2 {
    width: 100%;
  }
  .alice-carousel .sendEmailBtnV2:disabled,
  .alice-carousel .sendEmailBtnV2 {
    width: 100%;
  }
  .alice-carousel .emailRecIcons {
    padding: 14px 20px 20px 20px;
  }
  .alice-carousel .emailRecIcons .iconLabel {
    font-size: 0.75rem;
  }
  .alice-carousel .MuiFilledInput-multiline.MuiFilledInput-marginDense {
    padding-bottom: 0 !important;
    padding-top: 20px !important;
  }
  .jobCardMainWrapV2 .imInterestedBtnrow {
    margin-top: 0px;
  }
  .tabemailCard .emailRecIcons {
    padding-top: 10px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .tabemailCard .emailRecButtonRowV2 {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
  .iconMarginV2 {
    margin-left: 0px !important;
  }
  .skeletonMain .jobCardsFlexWrapSkeleton {
    display: block;
  }
  .mainCarousel .quickOfferLabel,
  .mainFilter .quickOfferLabel {
    margin-top: 0px;
  }
  .mainCarouselsection .newLabel,
  .jobCardMainWrapV2 .newLabel {
    margin-bottom: 1px !important;
    height: 23px !important;
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .headerIcon {
    font-size: 1rem;
  }
  .label-root {
    width: 1rem !important;
    height: 1rem !important;
  }
  .jobCardMainWrapV2 .jobCardBackImg {
    width: 100%;
    height: 120px;
  }
  .alice-carousel__stage-item.__active {
    width: auto !important;
  }
  .jobCardHeaderIcon {
    padding-left: 15px;
    margin-top: -2px;
  }
  .jobCardMainWrapV2 .iconLabel {
    padding-right: 7px !important;
    padding-left: 4px !important;
    font-size: 0.6rem !important;
  }
  .jobCardMainWrapV2 .mbCardImage {
    height: 140px !important;
  }
  .jobCardMainWrapV2 .hospitalName {
    padding-top: 4.5rem !important;
    padding-left: 10px !important;
  }

  .jobCardMainWrapV2 .imInterestedBtn,
  .jobCardMainWrapV2 .imInterestedBtn:disabled {
    font-size: 0.8rem !important;
  }
  .jobCardMainWrapV2 .specialtyNameJobCardV2 {
    font-size: 1.2rem !important;
  }
  .jobCardMainWrapV2 .jobCardHeader {
    margin-top: 20px;
  }
  .jobCardMainWrapV2 .hospitalNameV2 {
    font-size: 1rem !important;
  }
  .MuiFilledInput-multiline.MuiFilledInput-marginDense {
    padding-top: 23px !important;
    padding-bottom: 18px !important;
  }
  .mainCarouselsection .emailCard > div {
    border-radius: 10px 10px 0px 0px !important;
  }
  .mainCarouselsection .emailCard {
    width: 275px !important;
  }
  .emailCardV2 .emailRecIcons .iconLabel,
  .alice-carousel .emailRecIcons .iconLabel {
    font-size: 0.6rem;
    text-transform: capitalize !important;
    padding-left: 4px !important;
    padding-right: 7px !important;
  }
  .emailCardV2 .emailRecIcons {
    padding-top: 2px;
  }
  .emailCard .emailRecIcons {
    padding-top: 18px;
  }
  .emailCardV2 .emailRecIcons {
    padding-top: 2px;
  }
  .emailCardV2 .emailRecButtonRowV2 .sendButtonPos {
    margin-top: 10px;
  }
  .emailRecHospitalNameV2 .emailTextArea {
    margin-top: 20px !important;
  }
  .jobCardMainWrapV2 .iconLabel {
    font-size: 0.75rem;
  }
  .emailRecIcons .weeksIcon {
    margin-right: 3px;
  }
  .tabemailCard .bedIcon,
  .tabemailCard .dayIcon,
  .tabemailCard .timeIcon,
  .tabemailCard .rotationIcon,
  .tabemailCard .eveningIcon,
  .tabemailCard .nightIcon {
    color: #888;
    height: 14px;
    width: 14px;
    margin-bottom: 4px;
  }
  .alice-carousel .emailSendBtnV2 {
    margin-top: 20px;
  }
  .jobTitleV2 {
    font-size: 0.875rem !important;
  }
  .jobCardMainWrapV2 .payRateSvg {
    min-height: 24px;
  }
  .mainCarouselsection .payRateSvg {
    min-height: 18px;
  }
  .emailRecHospitalNameV2 p {
    font-size: 1rem !important;
    margin: 0.2rem 0rem 0.2rem 0rem;
  }
  .emailSendBtnV2 {
    margin-top: 8px;
  }
  .sendEmailBtnV2,
  .sendEmailBtnV2:disabled {
    font-weight: 700 !important;
    padding: 10px 15px;
    font-size: 0.8rem !important;
  }
  .alice-carousel .emailRecButtonRowV2 {
    margin-top: 0.3rem;
  }
  .tabemailCard .bedIcon,
  .tabemailCard .dayIcon,
  .tabemailCard .timeIcon,
  .tabemailCard .rotationIcon,
  .tabemailCard .eveningIcon,
  .tabemailCard .nightIcon,
  .tabemailCard .weeksIcon {
    color: #888;
    height: 14px;
    width: 14px;
  }
  .jobCardMainWrapV2 .exclusiveLabel .MuiChip-label,
  .jobCardMainWrapV2 .quickOfferLabel .MuiChip-label,
  .jobCardMainWrapV2 .newLabel .MuiChip-label {
    font-size: 0.6rem;
  }
  .jobCardMainWrapV2 .exclusiveLabel,
  .jobCardMainWrapV2 .quickOfferLabel {
    height: 21px !important;
  }
  .jobCardMainWrapV2 .newLabel {
    height: 21px !important;
    margin-left: 0px !important;
  }
  .alice-carousel .emailSendBtnV2 {
    margin-top: 9px !important;
  }
  .recomdJobCardPadding {
    .jobCardsFlexWrap {
      .emailSendBtnV2 {
        margin-top: 3px;
      }
      .sendEmailBtnV2,
      .sendEmailBtnV2:disabled {
        padding: 12px 15px !important;
      }
      .interestedBtnPos {
        margin-top: 8px;
      }
    }
  }
  .myJobsWrap {
    .emailCard.emailCardV2,
    .emailCard.emailCardV2 .jobCardMain {
      height: 395px;
    }
    .emailSendBtnV2 {
      margin-top: 0;
    }
    .sendEmailBtnV2,
    .sendEmailBtnV2:disabled {
      padding: 12px 15px;
    }
  }
  .alice-carousel {
    .jobCardMainWrapV2 {
      .interestedBtnPos {
        margin-top: 5px;
      }
    }
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .jobCardMainWrapV2 .mbCardImage {
    height: 140px !important;
  }
  .jobCardMainWrapV2 .hospitalName {
    padding-top: 6.5rem !important;
  }
  .jobCardMainWrapV2 .jobCardBackImg {
    width: 100%;
    height: 140px;
  }
  .alice-carousel .jobCardHeaderIcon {
    padding-top: 0px;
  }
  .mainCarouselsection .alice-carousel .emailSendBtnV2 {
    margin-top: 18px !important;
  }
  .emailRecHospitalNameV2 p {
    margin: 0rem 0rem 0.6rem 0rem;
  }
  .emailCardV2 .emailRecIcons {
    margin-top: 5px;
  }
  .MuiFilledInput-multiline.MuiFilledInput-marginDense {
    padding-top: 23px !important;
    padding-bottom: 20px !important;
  }
  .emailCard .emailRecIcons {
    padding-top: 16px;
  }
  .emailCardV2 .emailRecIcons .iconLabel,
  .alice-carousel .emailRecIcons .iconLabel {
    padding-right: 20px !important;
  }
  .jobCardMainWrapV2 .iconLabel,
  .emailCardV2 .emailRecIcons .iconLabel,
  .alice-carousel .emailRecIcons .iconLabel {
    font-weight: 700;
    font-size: 0.8rem;
    padding-left: 5px !important;
    padding-right: 12px !important;
  }
  .jobCardMainWrapV2 .hospitalNameV2 {
    width: 213px;
  }
  .noEligibilityMeter {
    width: 100% !important;
  }
  .mainCarouselsection .payRateSvg {
    min-height: 20px;
  }
  .emailCard .emailRecIcons {
    padding-top: 10px;
    padding-left: 20px;
  }
  .emailSendBtnV2 {
    margin-top: 13px;
    text-align: right;
  }
  .mainCarouselsection .emailCard {
    width: 350px !important;
  }
  .emailRecIcons .weeksIcon {
    margin-right: 3px;
    margin-right: 3px;
  }
  .alice-carousel .MuiFilledInput-multiline.MuiFilledInput-marginDense {
    padding-top: 8px !important;
  }
}
@media only screen and (max-device-width: 360px) {
  .jobCardHeaderIcon {
    padding-left: 0px;
    margin-left: -5px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 413px) {
  .jobCardHeaderIcon {
    padding-left: 3rem !important;
  }
}
