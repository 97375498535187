@import "src/theme/variables.scss";

.Frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 35px 24px 50px;
  border-radius: 8px;
  background-color: #f2f5ff;
  height: 230px;
}

.OvertimeFrame {
  @extend .Frame;
}

.PayBreakdownDisclaimerFrame {
  margin-left: 49px;
  max-width: 841px;
  line-height: 19.1px;
  font-size: 14px;
}

.PayBreakdownDisclaimer {
  width: 630px;
  height: 38px;
  font-family: $fontfamily-primary;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 19.1px;
  letter-spacing: normal;
  text-align: center;
  color: #626c7d;
  margin-bottom: 80px;
}

.GrossPayRateFrame {
  width: 344px;
  height: 84px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
  padding: 0;
}

.GrossPayTitle {
  width: 184px;
  height: 48px;
  flex-grow: 0;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #656e7e;
  @media only screen and (max-width: 767px) {
    /* These styles will apply only to screens up to 767px wide (typically mobile screens) */
    width: 184px;
    height: 30px;
  }
}

.GrossPayWageTitle {
  margin-left: -13px;
}

.GrossPayWageRate {
  margin-left: -18px;
}
.GrossPayAmount {
  font-size: 44px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $color-primary;
}

.GrossPayRate {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #656e7e;
  padding-left: 5px;
}

.GrossPayWageFrame {
  width: 600px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 20px;
  border-radius: 8px;
}

.GrossPayOvertimeWageFrame {
  @extend .GrossPayWageFrame;
}

.GrossPayEntryWage {
  width: -webkit-fill-available;
  height: 50px;
  flex-grow: 0;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.GrossPayEntryOvertime {
  width: -webkit-fill-available;
  height: 50px;
  flex-grow: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GrossPayEntryMealsOvertime {
  width: -webkit-fill-available;
  height: 50px;
  flex-grow: 0;
  background-color: #f7f9ff;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GrossPayEntryLodging {
  width: -webkit-fill-available;
  height: 50px;
  flex-grow: 0;
  background-color: #f7f9ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GrossPayEntryMeals {
  width: -webkit-fill-available;
  height: 50px;
  flex-grow: 0;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GrossPayEntryWeekFrame {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.GrossPayWageMaxRateFrame {
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.GrossPayWageName {
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #656e7e;
  display: inline-block;
  padding-right: 8px;
  width: 200px;
}

.GrossPayWageBaseRate {
  font-family: $fontfamily-primary;
  font-size: 14px;

  line-height: 1.4;
  letter-spacing: normal;
  color: #88909f;
}

.GrossPayWageMaxRate {
  width: 125px;
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: end;
  color: #656e7e;
}

.hrsFrequency {
  @media (max-width: 768px) {
    margin-left: 13px !important;
  }
}

.standardRate {
  @media (max-width: 768px) {
    margin-left: 7px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {
  .Frame {
    height: 555px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0px 7px 8px 8px;
    padding: 24px 0 10px;
  }

  .OvertimeFrame {
    @extend .Frame;
    height: 640px;
  }

  .GrossPayWageFrame {
    height: 277px;
    padding: 0;
    width: 95%;
    margin-left: 2.5%;
    margin-top: 15px;
  }

  .GrossPayOvertimeWageFrame {
    @extend .GrossPayWageFrame;
    height: 356px;
  }

  .PayBreakdownDisclaimer {
    height: 70px;
    width: 333px;
    display: flex;
    text-align: left;
    margin-top: 19px;
    margin-left: -37px;
  }

  .GrossPayRateFrame {
    width: 100%;
  }

  .GrossPayEntryWage {
    height: 64px;
    padding: 12px;
  }

  .GrossPayEntryOvertime {
    height: 84px;
    padding: 12px;
  }

  .GrossPayEntryMealsOvertime {
    height: 104px;
    padding: 12px;
  }

  .GrossPayEntryLodging {
    height: 84px;
    padding: 12px;
  }

  .GrossPayEntryMeals {
    height: 104px;
    padding: 12px;
  }

  .GrossPayEntryWeekFrame {
    padding: 0;
    gap: 24px;
    margin-top: 3px;
  }

  .GrossPayWageMaxRateFrame {
    padding-right: 0px;
  }

  .GrossPayWageName {
    width: 100px;
  }

  .GrossPayWageMaxRate {
    width: fit-content;
  }
  .GrossPayWageRate {
    margin-left: initial;
    width: initial;
  }

  .GrossPayWageMaxRateRight {
    margin-right: -17px;
  }
}
