.resultsWrap .filterTags {
  background-color: #f0f1f4;
  box-shadow: none !important;
  padding: 9px 10px;
}
.resultsSubWrap {
  background-color: #f0f1f4;
  padding-bottom: 3rem;
  padding-top: 5rem;
  margin-top: -1.5rem;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-top: 0;
    margin-top: 0;
  }
}
.resultsHead {
  margin-top: 10px;
}
.resultTitle {
  color: $color-primary;
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 5px;
  margin-left: 0px;
}
.resultTxt {
  color: #626c7d !important;
  font-size: 0.9rem;
  font-weight: normal;
}
.filterTagsv2 {
  margin-right: 10px !important;
  color: #626c7d !important;
  font-size: 0.9rem;
  display: inline-block;
  margin-top: 9px;
}
.resultsWrap .popularTagsText {
  font-weight: normal !important;
  display: inline-block;
  color: #626c7d !important;
}
.resultsWrap .tagsContainerOpen {
  position: absolute;
  border-radius: 5px;
  padding: 5px 25px 15px 7px;
  top: -7px;
  background-color: #fff;
  left: 17%;
  width: 84%;
  z-index: 990;
  box-shadow: 0px 3px 5px #0000002c;
}
.resultsWrap .tagsContainer {
  top: -7px;
  background-color: #f0f1f4;
  left: 17% !important;
  width: 84% !important;
  padding: 5px 25px 15px 7px;
}
.resultsWrap .filterTagChipSelected .MuiChip-label {
  color: #fff !important;
}

.resultsWrap .filterTagChipSelected {
  background-color: $color-primary !important;
  color: #fff !important;
  padding: 6px 2px !important;
  border-radius: 21px !important;
  margin: 6px 3px 6px 3px !important;
  box-shadow: none !important;
}
.resultsWrap .filterTagChip {
  background-color: #f0f1f4 !important;
  color: #888888 !important;
  border: 1px solid #c4c4c4;
  padding: 6px 2px !important;
  margin: 6px 3px 6px 3px !important;
  box-shadow: none !important;
}
.upDownArrow {
  color: $color-primary !important;
}
.pagination .MuiPaginationItem-icon {
  color: #192a59 !important;
  font-weight: bold;
}
@media screen and (min-width: 0px) and (max-width: 768px) {
  .tagsContainerOpen {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 5px 15px;
    top: 0;
    left: 0;
    z-index: 9;
  }
  .JobSearchFilterContainer {
    margin-top: 0px !important;
  }
  .filterPopupMob .MuiDialogContent-dividers {
    background-color: #fff !important;
  }
  .popularTagsPanelV2 .popularTagsText {
    font-weight: normal !important;
    display: inline-block;
    color: #626c7d !important;
    margin-bottom: 10px;
  }
  .popularTagsPanelV2 .filterTagChipSelected {
    background-color: $color-primary !important;
    color: #fff !important;
    padding: 6px 2px !important;
    border-radius: 21px !important;
    margin: 6px 3px 6px 3px !important;
  }
  .popularTagsPanelV2 .filterTagChip {
    background-color: #fff !important;
    color: #888888 !important;
    border: 1px solid #c4c4c4;
    padding: 6px 2px !important;
    margin: 6px 3px 6px 3px !important;
  }
  .popularTagsPanelV2 .filterTagChipSelected .MuiChip-label {
    color: #fff !important;
  }
  .resultTxt {
    font-size: 1rem;
  }
  .resultTitle {
    font-size: 1rem;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .resultsWrap .tagsContainer {
    left: 24% !important;
    width: 81% !important;
  }
  .resultsWrap .tagsContainerOpen {
    left: 24% !important;
    width: 81% !important;
  }
  .resultsWrap {
    margin-top: -1.5rem !important;
    .resultsSubWrap {
      .sendEmailBtnV2,
      .sendEmailBtnV2:disabled {
        padding: 12px 15px !important;
      }
      .emailSendBtnV2 {
        margin-top: -2px;
      }
      .emailCard.emailCardV2,
      .emailCard.emailCardV2 .jobCardMain {
        height: 395px;
      }
    }
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .resultsWrap .tagsContainer {
    left: 19% !important;
  }
  .resultsWrap .tagsContainerOpen {
    left: 19% !important;
  }
}
