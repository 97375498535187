@import "src/theme/variables.scss";

.main {
  background-color: #ECEDF2;
  padding: 30px 20px;
}

.sub {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  align-items: stretch;
  background-color: #ECEDF2;
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-self: center;
  max-width: 350px;
}

.cardContent {
  border-radius: 30px 30px 30px 0px;
  border: 1px solid #D6D8DC;
}

.cardTitle {
  margin-bottom: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: $color-primary;
}

.cardHeader {
  height: 110px;
  background-color: #ECEDF2;
  border-radius: 30px 30px 0 0;
}

.cardHeaderImage {
  width: 100%;
  height: 100%;
  border-radius: 30px 30px 0 0;
}

.cardBody {
  padding: 8px;
  background-color: white;
  border-radius: 0 0 30px 0;
}

.cardBodyText {
  padding: 8px;
}

.cardBodyLabel {
  font-size: 13px;
  font-weight: 700;
}

.cardBodyValue {
  color: $color-primary;
  font-family: "Nunito Sans";
  font-size: 16px;
  white-space: normal;
  word-break: break-all;
}

.email {
  text-decoration: underline;
}

@include breakpoint("sm") {
  .card {
    max-width: 340px;
  }
}

@include breakpoint("md") {
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub {
    min-width: 710px;
    max-width: 1080px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@include breakpoint("lg") {
  .sub {
    grid-template-columns: repeat(2, 1fr);
    max-width: 1080px;
  }

  .card {
    max-width: initial;
  }

  .cardContent {
    display: flex;
    border-radius: 30px 30px 30px 0;
    height: 110px;
  }

  .cardHeader {
    border-radius: 30px 0 0 30px;
    width: 40%;
    height: 100%;
  }

  .cardHeaderImage {
    border-radius: 30px 0 0 30px;
  }

  .cardBody {
    border-radius: 0 30px 30px 0;
    width: 60%;
    display: flex;
    align-items: center;
  }

  .recruiterName {
    margin-right: 8px;
  }
}

@include breakpoint("xl") {
  .sub {
    max-width: 1590px;
  }

  .recruiterName {
    margin-right: 16px;
  }
}