.snapWrapper {
  position: fixed;
  right: 0px;
  bottom: 10%;
  width: 80px;
  height: 80px;
  z-index: 9999;
  button {
    width: 80px;
    height: 80px;
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 14px;
    overflow: hidden;
    z-index: 9999;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    line-height: 100%;
    svg {
      border-radius: 50%;
      box-shadow: 2px 2px 4px rgba(#000, 0.5);
      background-color: white;
    }
    span {
      display: block;
      text-indent: -99999px;
    }
  }
}

.snapVisible {
  display: block;
}

.snapHidden {
  display: none;
}
