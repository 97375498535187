@import "src/theme/variables.scss";

.slick-dots li button:before {
  font-size: 14px;
}

.slick-dots li.slick-active button:before {
  color: $color-secondary;
}

.slick-prev,
.slick-next,
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: black;
  outline: initial;
  background: white;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

.slick-prev,
.slick-prev:hover,
.slick-prev:focus {
  left: -30px;
}

.slick-next,
.slick-next:hover,
.slick-next:focus {
  right: -30px;
}

.slick-disabled {
  display: none !important;
}

ul.slick-dots {
  bottom: -35px;
}

.slick-list>div {
  margin-left: 0;
}

@include breakpoint("md") {

  .slick-prev,
  .slick-prev:hover,
  .slick-prev:focus {
    left: -40px;
  }

  .slick-next,
  .slick-next:hover,
  .slick-next:focus {
    right: -40px;
  }
}