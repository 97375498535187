@import "src/theme/variables.scss";

.main {
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 30px 30px 30px 0;
  }
}

.onlyContent {
  &:hover {
    border-radius: 15px;
  }
}

.roundedTop {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.roundedBottom {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}