/*Header Start*/
.fixHeader {
  height: 100px !important;
  z-index: 999 !important;
}
/* Logo */
.mainLogo {
  max-width: 81% ;
  cursor: pointer;
}

/* Global Navigation */
.globalNavbar {
  padding-left: 0px !important;
}
.globalNavbar .navbar-nav .active .myActivelink,
.globalNavbar .nav-item a:hover .myActivelink {
  font-family: $fontfamily-primary;
  background-color: $color-primary !important;
}
.globalNavbar .nav-item a:hover {
  border: $color-primary !important;
}
.globalNavbar .nav-link {
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: $fontfamily-primary !important;
  margin: 0 1.5rem 0 0.5rem !important;
}
.globalNavbar .nav-item {
  min-width: 105px;
}
.globalNavbar .nav-item a {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.navbar {
  margin: 0 !important;
}
.globalNavbar .navItemHeight {
  height: 65px !important;
  padding: 12px 0px 28px 0px !important;
}
/* User Name Popover */
.userIcon {
  height: 30px;
  margin-left: 8px;
  margin-bottom: 3px;
}
.userDropdownWrap {
  position: relative;
  float: right;
}
.userDropdown {
  display: none;
  position: absolute;
  background-color: transparent;
  min-width: 220px;
  z-index: 999;
  top: 28px;
  padding: 7px 0px;
  right: -10px;
}
.userDropdownContent {
  box-shadow: 0px 1px 10px #00000029;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 1px;
}
.userDropdown a {
  font-family: $fontfamily-primary;
  color: $color-primary;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.userDropdown a:hover {
  background-color: #f2eeee;
  color: $color-primary;
  border-radius: 0px 0px 5px 5px;
}
.userDropdownWrap:hover .userDropdown {
  display: block;
}
.userWrapV2 {
  padding: 32px 0px 50px 0px;
  display: flex ;
  justify-content: flex-end;
}
.helloUserV2 {
  font-family: $fontfamily-primary;
  font-weight: bold;
  font-size: 16px;
  color: $color-primary;
  width: max-content;
  cursor: pointer;
}
.userDropdownArrowUp {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  float: right;
  margin-top: -9px;
  z-index: 999;
  margin-right: 14px;
  box-shadow: inset 0px 2px 5px 0px #00000029;
}
.userDropdownArrowUp1 {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #cbcaca40;
  float: right;
  z-index: 9;
  margin-top: -12px;
  margin-right: 12px;
  box-shadow: inset 0px 2px 5px 0px #00000029;
}
.logoutIconV2 {
  height: 2rem;
  width: 2rem;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .globalNavbar .navItemHeight {
    height: 72px !important;
  }
}

/* Mobile Devices */
@include breakpoint("xs"){
  .mainLogo {
    width: 30px;
    height: 35px ;
    margin: 18px 0px 18px 20px;
  }
  .fixHeader {
    height: 76px !important;
    box-shadow: none !important;
    border-bottom: 2px solid $color-primary;
  }
  .hamMenuV2 .MuiDrawer-paper {
    width: 80vw !important;
  }
  .hamMenuV2 .hamNavAppBar {
    margin: 10px 0px !important;
    width: 4rem !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0px 0px 0px 0px;
    height: 62px;
  }
  .hamMenuV2 .hamCloseButton {
    color: $color-primary !important;
    width: 15% !important;
  }
  .hamMenuV2 .hamMenu:hover {
    background-color: #fff !important;
    text-decoration: none;
  }
  .hamSubMenuTxt {
    padding: 15px 20px 15px 7px;
    font-weight: normal;
    font-size: 0.9rem;
    color: #1b1c1d;
    font-family: $fontfamily-primary;
  }
  .hamSubMenuBack {
    fill: $color-primary;
  }
  .hamUserNameWrap {
    padding: 15px 0px 15px 0px;
    width: 85%;
  }
  .hamMenuV2 .navbar {
    margin: 12px 0 0 0 !important;
  }
  .hamMenuV2 .hamNavLine {
    background-color: #e1e1e1 !important;
    width: 96% !important;
    margin: 0px 20px 10px 5px !important;
  }
  .hamMenuV2 .hamUserName {
    padding: 15px 0px 15px 12px;
    font-family: $fontfamily-primary;
    font-style: italic;
    color: #1b1c1d;
    font-weight: normal;
    font-size: 1rem;
  }
  .hamMenuV2 .hamMenu.active,
  .hamMenuV2 .hamMenuNavBar .nav-link .active,
  .hamMenuV2 .hamMenuNavBar .nav-link:active {
    background-color: #fff !important;
  }
  .hamMenuV2 .hamNavLabel {
    color: $color-primary !important;
    font-weight: normal !important;
    font-size: 0.9rem;
  }
  .hamMenuV2 .hamMenu.active .hamNavLabel {
    color: $color-secondary !important;
  }
  .hamMenuV2 .hamMenu.active .myprofileIcon svg {
    fill: $color-secondary !important;
  }
  .pageTitleMob {
    margin-top: 24px;
    top: 25px;
    font-size: 1.2rem;
    color: $color-secondary;
    font-weight: 700;
    font-family: $fontfamily-primary;
    display: block;
  }
  .notificationBellIcon{
    display : none;
  }
  .navbar {
    margin: 0 !important;
    justify-content: space-around !important;
  }
  .hamNavAppBar .MuiToolbar-gutters {
    padding-left: 10px !important;
    padding-right: 10px !important;
    min-height: 48px !important;
  }

  .pageWrapper {
    margin-top: 72px !important;
  } 

  @media (min-width: 600px) {
    .pageTitleMob {
      display: none;
    }
    .notificationBellIcon {
      justify-content: space-between;
      display: flex;
      align-items: center;
      height: 65px;
    }
  }
}

@include breakpoint("md"){
  .mainLogo {
    width: 30px;
    height: 35.86px;
    margin: 32px 0px 32px 30px;
  }

  .globalNavbar .nav-item {
    min-width: 0px !important;
    white-space: nowrap !important;
  }

  .fixHeader {
    border-bottom: none;
  }

  .helloUserV2 {
    margin: 0px 0px 0px 10px;
  }

  .userDropdownWrap {
    margin-right: 30px;
  }

  .globalNavbar .nav-link {
    margin: 0px 15px 0px 0px!important;
  }

  .fixHeader {
    height: 100px !important;
  }
}

@include breakpoint("lg"){
  .mainLogo {
    width: 140.67px;
    height: 36.79px;
    margin: 31.66px 0px 31.56px 60px;
  }

  .globalNavbar .nav-item {
    min-width: 0px !important;
    white-space: nowrap !important;
  }

  .helloUserV2 {
    margin: 0px 0px 0px 20px;
  }

  .userDropdownWrap {
    margin-right: 60px;
  }

  .globalNavbar .nav-link {
    margin: 0px 20px 0px 0px!important;
  }

  .navbar {
    margin: 0 !important;
    justify-content: space-around !important;
  }
}

@include breakpoint("xl"){
  .mainLogo {
    width: 140.67px;
    height: 38.9px;
    margin: 31.66px 0px 31.66px 80px;
  }

  .globalNavbar .nav-item {
    min-width: 0px !important;
  }

  .helloUserV2 {
    margin: 0px 0px 0px 20px;
  }

  .userDropdownWrap {
    margin-right: 80px;
  }

  .globalNavbar .nav-link {
    margin: 0px 35px 0px 0px!important;
  }

  .navbar {
    margin: 0 !important;
    justify-content: space-around !important;
  }
}
