@import "src/theme/variables.scss";

:export {
  colorPrimary: $color-primary;
}

.textField {
  input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.topLargeRadius {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.calendarHeader {
  background-color: $color-primary-400;
}

.calendarHeaderSelector {
  background-color: $color-primary;
  color: white;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calenderHeaderLabel {
  background-color: $color-primary-400;
  text-align: center;
  color: white;
  font-family: $fontfamily-primary;
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 6px;
}

.calendarHeaderLabelAndSwitch {
  display: flex;
}

.calendarHeaderEnabledArrow {
  color: white;
}

.calendarHeaderDisabledArrow {
  color: currentColor;
}

.calendarHeaderArrowFlipped {
  transform: rotate(180deg);
}

.calendarHeaderLabel {
  font-family: $fontfamily-primary;
}

.actionButton {
  font-family: $fontfamily-primary;
  font-weight: 700;
  font-size: 12px;
  border: 0;
  background-color: transparent;
  border-color: transparent;
}

.cancelButton {
  margin-right: 5px;
}

.calendarAdornment {
  padding: 12px;
  cursor: pointer;
}

.disabledMonthSwitcher {
  color: $color-primary;
}

.yearViewList {
  max-height: 350px;
  overflow-y: auto;
}

.yearViewList::-webkit-scrollbar {
  width: 8px;
  border-radius: 0px;
}

.yearViewList::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

.yearViewList::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 0px;
}

.yearViewItem:first-child {
  margin-top: 8px;
}

.yearViewItem:last-child {
  margin-bottom: 8px;
}

.yearViewItem {
  padding: 10px 20px;
  width: 95%;
  font-family: $fontfamily-primary;
  font-size: 16px;
  background-color: transparent;
  border-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  div {
    height: 24px;
    width: 24px;
    display: inline-flex;
    margin-right: 20px;
    color: $color-primary-dark;
  }

  span {
    color: $color-primary;
    margin-right: 10px;
  }

  span:last-child {
    color: #9ea4b0;
  }
}

.activeYearViewItem {
  background-color: #E1E0E1;
}

@media screen and (max-width: 768px) and (min-width: 0px) {
  .datePickerTextField {
    width: 100%;
  }
}