@import "src/theme/variables.scss";

.returnToSourceWrapper {
    .snackbarWrapper {
      .MuiSnackbar-root {
        top: 120px;
        z-index: 100;
  
        .MuiSnackbarContent-root {
          background-color: $color-primary-50;
          padding: 0;
          color: $color-primary;
          font-size: 15px;
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          justify-content: space-around;
          border-radius: 10px !important;
          overflow: hidden;
          box-shadow: 0px 6px 25px #00000033;
          cursor: pointer;
  
          .MuiSnackbarContent-action {
            padding-left: 0;
            padding-right: 16px;
          }

          .MuiSnackbarContent-message {
            padding: 0;
          }
        }
      }

      .contentWrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
  
      .jobDetailIcon {
        width: 25px;
        height: 23px;
        margin: 0 9px 5px 0;
      }

      .contentWrapperImage img {
        width: 118px;
        height: 82px;
      }

      .contentWrapperText {
        padding: 0 30px 0 0;
      }

      .contentWrapperIcon {
        padding: 0 0 0 30px;
      }
    }
  }
  
 
  @media screen and (min-width: 0px) and (max-width: 768px) {
    .returnToSourceWrapper {
      .snackbarWrapper {
        .MuiSnackbar-root {
          top: 90px;
        }
  
        .MuiSnackbarContent-root {
          flex-direction: column-reverse !important;
          justify-content: center;
        }
  
        .MuiSnackbarContent-action {
          width: 58%;
        }

        .contentWrapperIcon {
          padding: 0 5px 0 30px;
          display: flex;
          align-items: center;
          justify-content: space-around;
        }
      }
    }
  
  }