@import "react-alice-carousel/lib/scss/alice-carousel.scss";
@import "scss/masterV2.scss";


// Rebranding Colors Updated as Per the Table shared in WEBONE-18474
:root {
  --primary-900-main: #003366;
  --primary-900-dark: #003366;
  --primary-900-light: #003366;
  --primary-800: #083C72;
  --primary-700: #15457D;
  --primary-500: #456595;
  --primary-400: #6780A6;
  --primary-300: #91A3BF;
  --primary-200: #BCC7D9;
  --primary-100: #D4DCE8;
  --primary-50: #ECF1F8;
}

body {
  font-family: $fontfamily-primary;
  font-weight: 500;
}

p,
a,
a:hover {
  line-height: 26px;
  font-family: $fontfamily-primary;
}

div {
  font-family: $fontfamily-primary;
}

.MuiTableCell-root {
  font-family: $fontfamily-primary !important;
  letter-spacing: 0.01em;
}

.mt-5 {
  padding-top: 5px;
}

.mt-20 {
  margin-top: 20px !important;
}

.jumbotron {
  border-radius: 0px !important;
}

.textcenter {
  text-align: center;
}

.removePointerEvents {
  cursor: auto !important;
}

.capitalize {
  text-transform: capitalize;
}

.displayNoneGlobal {
  display: none;
}

.boldFont {
  font-weight: bold;
}

.loaderPage {
  background-color: #fff;
  padding: 10rem 4rem 4rem 4rem;
  text-align: center;
  height: 100vh;
}

.loaderPage img {
  width: 9rem;
}

.loaderColor {
  fill: $color-secondary !important;
  margin: 0 auto;
  height: 6.5rem !important;
  width: 6.5rem !important;
}

.loadingTxt {
  font-family: $fontfamily-primary;
  color: $color-primary;
  font-size: 1.4rem;
  font-weight: 700;
  margin: 4rem 0rem 2.5rem 0rem;
}

#incompleteProfilePage {
  margin-top: 6rem;
  height: 79vh;
}

#profileIframePage {
  margin-top: 12rem;
  height: 70vh;
}

.iframePage {
  width: 1px;
  min-width: 100%;
  border: 0px;
  overflow: auto;
  background-color: #f6f6f6;
}

.iframePhNo a {
  text-decoration: underline !important;
  color: #ffffff !important;
  margin: 1rem 0;
  font-weight: 700;
}

.iframePhNo a:hover {
  color: $color-secondary !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.fixHeader {
  position: fixed;
  background: #f0f1f4;
  width: 100vw;
  top: 0;
  z-index: 999;
  box-shadow: 0px 3px 20px #00000012;
}

.bannerPosition {
  margin-top: 104px;
}

.MuiDrawer-paper {
  width: 380px;
}

.hamNavAppBar {
  width: 4rem !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 1.5rem 0 0.8rem 0;
}

.hamUserName {
  color: $color-primary-dark;
  width: 80%;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 15px 0px 15px 12px;
  font-family: $fontfamily-primary;
}

.hamCloseButton {
  width: 20%;
  color: $color-primary-dark !important;
  padding: 0px !important;
  background-color: #fff !important;
}

.hamNavRightIcon {
  color: $color-primary-dark;
  position: absolute;
  right: 8%;
}

.hamNavLine {
  background-color: #e1e1e1 !important;
  width: 90% !important;
  margin: 0px 20px !important;
}

.hamNavIcon {
  height: 1.5rem;
  width: 1.5rem;
  color: $color-primary-dark;
}

.hamNavLabel {
  color: $color-primary-dark;
  font-size: 0.9rem;
  font-weight: bold;
  margin-left: 15px;
  font-family: $fontfamily-primary;
}

.hamNavAppBar .MuiToolbar-gutters {
  padding: 0px !important;
}

.hamMenu {
  padding: 17px 0px 17px 22px;
  cursor: pointer;
  color: $color-primary-dark !important;
  font-size: 0.9rem;
  font-weight: bold;
  font-family: $fontfamily-primary;
  text-decoration: none;
  display: flex;
}

.hamMenu.active,
.hamMenuNavBar .nav-link .active,
.hamMenuNavBar .nav-link:active {
  background-color: $color-secondary-light-50 !important;
  border-bottom: none !important;
  margin-bottom: 0px !important;
}

.hamMenu:hover {
  background-color: $color-secondary-light-50 !important;
  text-decoration: none;
}

.hamMenuNavBar .navbar-nav {
  display: block;
  width: 100%;
}

.hamMenuNavBar .nav-link {
  padding-left: 20px !important;
  margin-bottom: 0px;
}

.hamMenuNavBar {
  min-height: 60vh;
}

.hamburgerNavIcon {
  font-size: 2rem !important;
  margin-top: 5px !important;
  color: $color-primary-dark;
}

.hamburgerNavIcon:hover {
  color: $color-secondary;
}

#hamOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1200;
  cursor: default;
}

#hamOverlayClose {
  position: relative;
}

.hamNavAppBar .HelpDeskBack {
  background-color: #fff !important;
  margin-top: 40px;
  padding: 30px 0px 5px 0px;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 5px 0px #00000029 !important;
}

.MuiAutocomplete-paper > ul li:last-child {
  margin-bottom: 0px;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  padding: 12px 0px 15px 55px !important;
  font-weight: normal;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-weight: 700 !important;
  color: $color-primary;
}

::placeholder {
  color: #000000 !important;
  font-weight: normal !important;
}

.MuiAutocomplete-option {
  font-size: 0.8rem;
  border-bottom: 1px solid #f4f4f4;
  background-color: #ffffff;
  color: $color-primary-dark;
  font-weight: bold;
  padding: 10px;
  z-index: 9999;
  width: 100%;
}

.pinDropIcon {
  margin-right: 20px;
  margin-left: 10px;
  color: #a0b7e4;
}

.MuiAutocomplete-clearIndicator,
.MuiAutocomplete-endAdornment {
  display: none;
}

.MuiAvatar-colorDefault {
  color: #ffffff;
  background-color: #bdbdbd;
  text-align: right;
}

.usernameavtar {
  display: inline;
  padding-top: 1rem;
  font-weight: bold;
  color: $color-primary-dark;
}

.MuiChip-deleteIcon {
  color: $color-primary !important;
}

.MuiChip-label {
  color: $color-primary !important;
  font-weight: 500;
}

.MuiChip-clickable {
  box-shadow: 0px 0px 6px #00000029 !important;
  border-radius: 25px !important;
}

.MultiChipSelect-paper {
  height: auto !important;
  background-color: #ffffff !important;
}

.MultiChipSelect-paper-elevation1 {
  box-shadow: 0px 2px 5px 0px #00000029 !important;
}

.errorMsg input::placeholder,
.errorMsgSignUp input::placeholder {
  color: $color-error !important;
  opacity: 1 !important;
}

.errorMsg .MuiInput-underline:after {
  border-bottom: 2px solid $color-error;
}

.errorMsg .MuiInputBase-input.Mui-disabled,
.errorMsgSignUp .MuiInputBase-input.Mui-disabled {
  border-bottom: 2px solid $color-error;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid transparent;
}

.errorMsg .MuiInput-underline:after {
  border-bottom: 2px solid $color-error !important;
}

.errorMsg .MuiInput-underline:before {
  border-bottom: 2px solid $color-error !important;
}

.errorMsg .MuiInput-underline:after,
.errorMsgSignUp .MuiInput-underline:after {
  border: 1px solid $color-error;
}

.navbar {
  padding: 14px 0 0;
}

.nav-link {
  text-align: center;
  color: #ffffff !important;
  opacity: 1;
  cursor: pointer;
  margin-bottom: 2px;
}

.myActivelink {
  display: none;
}

.globalNavbar .navbar-nav .active .myActivelink,
.globalNavbar .nav-item a:hover .myActivelink {
  background-color: $color-secondary;
  border-radius: 10px;
  margin: 8px 0px 0px 0px;
  display: block;
  height: 6px;
}

.navbar-nav .active .myActivelink {
  background-color: $color-secondary;
  border-radius: 10px;
  display: block;
  height: 5px;
}

.globalNavbar .navbar-nav .nav-link.active,
.globalNavbar .navbar-nav .nav-link.active div {
  color: $color-secondary !important;
}

.globalNavbar .navItemHeight {
  height: 72px;
  padding: 17px 0px 28px 0px;
}

.payrollNav .navItemHeight {
  height: 36px;
  padding-top: 4px;
}

.payRollNavMargin .payrollNav {
  height: auto;
  position: fixed;
}

.payRollNavMargin .payrollNav .nav-link {
  padding: 0rem 0.5rem;
}

.payRollNavMargin .payrollNav .nav-link .myActivelink {
  margin-bottom: -4px;
}

.payrollNav {
  height: 53px;
}

.primarynavbar .nav-item > a,
.localNavbar .nav-item a {
  text-align: center !important;
  margin: 0rem 2rem 0rem 0rem;
  font-weight: 500;
  font-size: 1rem;
  width: max-content;
}

.globalNavbar {
  height: 60px;
  text-align: right;
}

.globalNavbar .nav-link {
  font-weight: 500 !important;
  font-size: 1rem;
  color: $color-primary-dark !important;
  text-align: center;
  margin: 0rem 0.8rem 0rem 0.8rem;
}

.globalNavbar .nav-item a:hover {
  color: $color-secondary !important;
}

.DefaultBannerJobsPg {
  background: $color-primary url("./images/SearchCrossCountry-Jobs.jpg") top center no-repeat !important;
  height: 368px;
  margin-top: 9rem;
}

.jobsBannerText {
  font-family: $fontfamily-primary;
  font-size: 3rem;
  color: #fff;
  text-align: center;
  padding-top: 4rem;
  font-weight: bold;
}

.jobsBannerSubTxt {
  font-family: $fontfamily-primary;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
}

.jobmasterheading {
  font-weight: bold;
  font-size: 2rem;
  color: $color-primary-dark;
  margin-bottom: 15px;
}

.datePick .MuiInputBase-input {
  color: $color-primary;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
}

.circularProgress {
  float: left;
  width: 50px !important;
  height: 50px !important;
  color: #0000001f !important;
  margin: 10px;
}

// Circular Progress Primary color throughout the project
.MuiCircularProgress-colorPrimary {
  color: $color-primary !important
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: rgba(255, 255, 255, 0.4) !important;
}

.closeIcon {
  float: right;
  color: #888888;
  margin-right: 8px;
  margin-top: 6px;
  font-size: 0.8rem !important;
}

.herobanner {
  width: 100%;
}

.tagsContainer {
  position: absolute;
  background: #fff;
  border-radius: 5px;
  padding: 5px 20px 5px 15px;
  top: 0;
  left: 16%;
  width: 83%;
  z-index: 9;
}

.popularTagsText {
  font-weight: bold;
  display: inline-block;
}

.upDownArrow {
  top: 3px;
  right: 0px;
  margin: 10px;
  color: #000000;
  position: absolute;
  z-index: 99;
  cursor: pointer;
}

.selectedPage .MuiSelect-icon {
  color: #000;
  right: 20px;
  top: 24px;
}

.selectedPage .MuiMenuItem-root {
  padding-left: 35px;
}

.selectedPage .MuiSelect-select {
  font-weight: 700;
  color: #000 !important;
}

.noFilterText {
  height: 200px;
  font-size: 1.5rem;
  font-weight: bold;
  color: $color-primary-dark;
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.filterTagChip .MuiChip-label {
  color: #888888;
}

.filterTagChipSelected .MuiChip-label {
  color: #000000 !important;
}

.filterTagChip {
  background-color: #e1e1e1 !important;
  color: #888888 !important;
  margin-right: 7px !important;
  cursor: pointer !important;
  font-size: 0.8rem !important;
  display: inline-block !important;
  font-weight: 700 !important;
  padding: 8px 12px !important;
  border-radius: 21px !important;
  margin: 6px 3px 6px 3px !important;
}

.filterTagChipSelected {
  background-color: #b3ccfd !important;
  color: #000000 !important;
  margin-right: 7px !important;
  cursor: pointer !important;
  font-size: 0.8rem !important;
  display: inline-block !important;
  font-weight: 700 !important;
  padding: 8px 12px !important;
  border-radius: 21px !important;
  margin: 6px 3px 6px 3px !important;
}

.filterTags .MuiChip-clickable {
  box-shadow: none !important;
  background: none;
}

.filterTags {
  display: inline-block;
  font-weight: bold;
  background-color: #fff;
  border-radius: 6px;
  width: 100%;
  box-shadow: 0px 3px 5px #0000002c;
  padding: 17px 10px 18px 10px;
}

.rotate {
  transform: rotate(90deg);
}

.listMapViewButtons {
  display: flex;
  background-color: #fff;
  width: 100px;
  padding: 3px 0px;
  border-radius: 6px;
}

.listMapViewButtons .MuiButtonGroup-root .MuiIconButton-root:hover {
  border-radius: 0 !important;
}

.listMapViewButtons .MuiButtonGroup-groupedTextHorizontal:not(:last-child) {
  border-right: 1px solid #e2e2e2;
}

.mostRecentText {
  color: #888888;
  font-weight: 700;
}

.MJfilterTags {
  display: inline-block;
  font-weight: bold;
  background-color: #fff;
  border-radius: 6px;
  width: 100%;
  box-shadow: 0px 3px 5px #0000002c;
  padding: 12px 10px 10px 31px;
}

.Mjchkboxtxt .MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  margin-top: -3px;
}

.checkboxrightline {
  padding: 0px 30px;
}

.Mjchkboxlabel {
  margin-right: 5px;
  font-weight: 700;
  color: #707070;
}

.MjchkboxAlign {
  display: inline-block;
}

.MjchkboxContainer {
  margin-left: 5%;
  display: inline-block;
}

.Mjchkboxseperator {
  color: #0000001f;
  padding: 0px 2rem;
  margin: 0px 10px;
}

.Mjchkboxtxt .MuiIconButton-root:hover {
  background-color: transparent;
}

.MjchkboxContainer .Mui-checked + label {
  font-weight: 700 !important;
  color: #000;
}

.mapHospName {
  color: $color-primary;
  font-size: 0.9rem;
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.gm-style .gm-style-iw-t::after {
  top: -1px !important;
}

.directionIcon {
  width: 20px !important;
  height: 20px !important;
  opacity: 1;
  color: #707070;
  margin-right: 5px;
}

.gm-ui-hover-effect {
  top: 0px !important;
  right: 2px !important;
}

.JobCardContainer {
  padding: 0 2rem;
}

.jobCardHolder {
  padding: 0 0.4rem;
}

.mbCardImage {
  cursor: pointer;
  height: 170px;
  background: $color-primary url("./images/JobCardBGLogo.png") center right no-repeat !important;
  background-size: 30% !important;
}

.jobCardMain {
  border-radius: 10px;
  height: 100%;
}

.tabemailCard {
  .jobCardMain {
    border-radius: 30px 30px 30px 0 !important;
  }
}

.alice-carousel__stage-item.__active {
  width: 33.3%;
}

.alice-carousel__stage-item.__active .jobCardMain {
  visibility: visible;
  opacity: 1;
  position: relative;
}

li.alice-carousel__stage-item .jobCardMain {
  visibility: hidden;
  opacity: 0;
}

.singleJobCard {
  border-radius: 10px !important;
  height: 100%;
  width: 33%;
}

.selectedPage {
  float: right;
  background: #fff;
  padding: 2px 0 2px 15px;
  margin-right: 15px;
  width: 5rem;
  margin-bottom: 9px;
  box-shadow: 0px 1px 10px #00000029;
}

.selectedPage .MuiFilledInput-root {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.getpreLabel {
  background-color: rgba(206, 0, 54, 0.8) !important;
  height: 30px !important;
  color: #fff !important;
  margin-left: 5px !important;
  margin-bottom: 10px !important;
  font-weight: 700;
  font-size: 0.75rem !important;
  cursor: auto !important;
}

.exclusiveLabel {
  background-color: rgba(230, 138, 000, 0.8) !important;
  height: 30px !important;
  color: #fff !important;
  margin-left: 5px !important;
  margin-bottom: 10px !important;
  font-weight: 700;
  font-size: 0.75rem !important;
  cursor: auto !important;
}

.newLabel {
  background: rgba(104, 174, 232, 0.8) !important;
  height: 30px !important;
  color: #fff !important;
  margin-left: 5px !important;
  margin-bottom: 10px !important;
  font-weight: 700;
  font-size: 0.75rem !important;
  cursor: auto !important;
}

.teachingFacility {
  width: 30px !important;
  height: 30px !important;
  background: rgba(25, 42, 89, 0.8);
  border-radius: 16px;
  color: #fff;
  margin-left: 5px !important;
  margin-bottom: 10px !important;
  padding: 4px;
  font-weight: 700;
  font-size: 0.75rem !important;
  z-index: 2;
  cursor: auto !important;
}

.showFacilityText {
  display: flex;
  position: absolute;
  width: 129px !important;
  height: 30px !important;
  background: rgba(25, 42, 89, 0.8);
  border-radius: 20px;
  color: #fff;
  left: 14px;
  top: 3px;
  padding-top: 6px;
  padding-left: 28px;
  font-weight: 700;
  font-size: 0.75rem !important;
  z-index: 1;
  transition: 1.5s linear;
}

.hideFacilityText {
  display: none;
}

.exclusiveLabel .MuiChip-label,
.newLabel .MuiChip-label,
.getpreLabel .MuiChip-label,
.teachingFacility .MuiChip-label {
  color: #fff !important;
}

.buttonEx {
  position: absolute;
  z-index: 988;
  left: 0px !important;
}

.JobDescrEmailButton:hover .jDrecruiterIcon {
  color: $color-secondary;
}

.recruiterIconFilled {
  fill: $color-error !important;
  margin-right: 0;
}

.favoriteIcon:hover {
  color: $color-error;
}

.MuiTooltip-tooltip {
  background-color: #f5f5f9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  margin-bottom: 3px !important;
  box-shadow: 0px 3px 10px #00000029;
}

.MuiTooltip-tooltip .emailSuccessMessage {
  color: $color-success !important;
}

.MuiTooltip-tooltip .emailFailMessage {
  color: red !important;
}

.alice-carousel__stage-item.__active .jobCardHeaderIcon {
  display: block;
}

.alice-carousel__stage-item .jobCardHeaderIcon {
  display: none;
}

.label-root {
  width: 1.2em !important;
  height: 1.2em !important;
}

.specialtyNameJobCard {
  font-family: $fontfamily-primary !important;
  font-size: 1.4rem !important;
  color: $color-primary !important;
  text-align: left !important;
  font-weight: bold !important;
  margin-top: 2px !important;
  letter-spacing: 0 !important;
  width: 90%;
  min-height: 65px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.jobDetailInfoToolTip {
  display: inline;
}

.jobDetailInfoToolTip .payRateSvg {
  margin-left: 7px;
  margin-top: -5px;
}

.jobCardInfoToolTip {
  min-height: 46px;
  width: 40px;
  display: inline;
  text-align: center;
}

.jobCardInfoWithoutPayrateToolTip {
  display: block;
}

.jobCardInfoWithoutPayrateToolTip .payRateSvg {
  margin-left: 0px;
}

.payRateSvg {
  height: 15px;
  width: 15px;
  fill: #d3d3d3;
  margin-left: 5px;
}

.jobViewsPayRate {
  height: 12px;
  width: 12px;
  fill: #BABEC6;
  margin-left: 5px;
}

.postedDayLabel {
  font-family: $fontfamily-primary !important;
  font-size: 0.8rem !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #888888;
  text-align: left;
  line-height: 24px !important;
  margin-top: -10px !important;
  cursor: pointer;
  letter-spacing: 1px;
}

button:focus {
  outline: none !important;
}

.applyButton {
  text-align: center;
  letter-spacing: 0;
  color: #ffffff !important;
  font-weight: bold !important;
  padding: 0.5rem 1.2rem !important;
  background: $color-secondary 0% 0% no-repeat !important;
  border-radius: 10px 10px 10px 0 !important;
  box-shadow: none !important;
}

.applyButton:hover {
  background: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.imInterestedBtnrow {
  margin-right: 6px;
}

.imInterestedBtn {
  text-align: center;
  letter-spacing: 0;
  color: #ffffff;
  font-weight: bold !important;
  padding: 0.5rem 0.5rem !important;
  font-size: 0.7rem !important;
  background: $color-secondary 0% 0% no-repeat !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.imInterestedBtn:hover {
  background: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.imInterestedBtn:disabled {
  background-color: #d5f8e7 !important;
  color: #248953 !important;
  text-align: center;
  letter-spacing: 0;
  font-weight: bold !important;
  padding: 0.5rem 0.5rem !important;
  font-size: 0.7rem;
  border-radius: 5px !important;
  span::before {
    content: "";
    background: url("~src/imagesV2/checkIcon.svg") center/contain;
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }
}

.iconLabel {
  font-size: 0.9rem;
  text-transform: uppercase;
  color: $color-primary;
  text-align: left;
  font-weight: bold;
  padding-left: 7px;
  padding-right: 12px;
  margin-left: -5px;
}

.nightIcon {
  transform: rotate(157deg);
  color: $color-primary;
  font-size: 1.2rem !important;
}

.dayIcon {
  color: $color-primary;
  font-size: 1.2rem !important;
}

.filterIcon {
  color: #656E7E;
  font-size: 1.2rem !important;
}

.eveningIcon {
  color: $color-primary;
  font-size: 1.2rem !important;
}

.rotationIcon {
  color: $color-primary;
  font-size: 1.2rem !important;
}

.timeIcon {
  vertical-align: middle;
  font-size: 1.2rem !important;
  color: $color-primary;
  margin-left: -3px;
}

.pagination {
  padding: 10px 0 20px 0;
  width: 100%;
  justify-content: center;
}

.MuiPaginationItem-page.Mui-selected {
  background: none !important;
  font-weight: bold !important;
  font-size: 1rem;
  text-decoration: underline;
  color: #272727 !important;
}

.MuiPaginationItem-root {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
}

.jobCardIcons {
  padding-top: 0px;
  padding-right: 0px;
}

.emailCard .MuiCardContent-root:last-child {
  padding-bottom: 2px;
}

.emailCard .MuiCardContent-root {
  padding: 0;
}

.jobCardHolder .emailCard .MuiCardContent-root:last-child {
  padding-bottom: 5px;
}

.sendButtonPos,
.interestedBtnPos {
  text-align: left;
  padding-right: 0 !important;
  cursor: default !important;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.applyButton:disabled {
  background-color: #e1e1e1 !important;
  color: #0000007f !important;
  text-align: center;
  letter-spacing: 0;
  font-weight: bold !important;
  padding: 0.5rem 1rem;
  border-radius: 10px 10px 10px 0 !important;
}

.emailTextArea {
  width: 100%;
  margin-top: 10px !important;
  background-color: #f0f1f4;
  border: 1px solid #e0e0e0 !important;
  border-radius: 10px !important;
}

.emailTextArea .MuiFilledInput-root {
  background-color: #f8f8f8 !important;
}

#filled-textarea-label {
  font-size: 0.8rem;
}

.emaildateDayLabel {
  font-size: 0.8rem !important;
  color: #0000007f;
  text-align: left;
  line-height: 24px !important;
  float: left;
}

.emailCardCloseButton {
  position: absolute !important;
  right: 3%;
  top: 2%;
}

.MuiFilledInput-multiline {
  border-radius: 10px;
}

.MuiFilledInput-root {
  background-color: #ffffff !important;
}

.selectedPage .Mui-focused {
  background-color: rgba(255, 255, 255, 1) !important;
}

.selectedPgValue {
  padding-left: 30px !important;
}

#filled-textarea {
  border-bottom-color: white;
  font-size: 0.8rem !important;
  margin-top: -10px;
  font-weight: normal;
  margin-bottom: 5px;
  height: 152px !important;
}

.emailRecIcons {
  padding-top: 10px;
  padding-left: 20px;
}

.MuiFilledInput-multiline.MuiFilledInput-marginDense {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.FavoriteIconclick {
  fill: $color-error !important;
}

.backButtonJobdetails {
  background-color: Transparent !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  overflow: hidden;
  color: #ffffff !important;
  border: 1px solid #6f7d9a !important;
  border-radius: 7px !important;
  padding: 6px 22px !important;
}

.shareButtonJobdetails {
  color: #ffffff !important;
  border: 1px solid #6f7d9a !important;
  margin-left: 25px !important;
  border-radius: 7px !important;
  padding: 6px 22px !important;
}

.backButtonJobdetails .MuiSvgIcon-fontSizeSmall,
.shareButtonJobdetails .MuiSvgIcon-fontSizeSmall,
.shareButtonActive .MuiSvgIcon-fontSizeSmall {
  font-size: 1rem !important;
}

.JobDetailShare {
  margin-left: 5px;
}

.specialityJobdetails {
  font-family: $fontfamily-primary;
  text-align: left;
  font-size: 2.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  opacity: 1;
  position: relative;
  color: #ffffff;
  margin-bottom: 20px;
}

.pJobDetails {
  color: #ffffff;
  font-size: 0.9rem;
}

.borderlineJobdetails {
  border-bottom: 1px solid $color-secondary;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -8px;
}

.payrateJobDetails {
  font-family: $fontfamily-primary;
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: bold;
}

.specialitydetails {
  font-family: $fontfamily-primary;
}

.applyJobButton {
  background-color: $color-secondary !important;
  border: none !important;
}

.buttonrightcolumn {
  margin-top: 255px;
  margin-left: 200px;
}

.addressjobdetails {
  margin-left: -15px;
}

.addressjobdetails span {
  margin-top: 2px;
}

.mainjobdetailsbutton {
  margin-bottom: -15px;
}

.jobdetailsbuttonlabel {
  font-size: 0.8rem;
  text-transform: none;
  font-weight: 700;
}

.textSecondary h6 {
  color: $color-primary-dark;
  font-weight: bold;
  font-size: 18px;
}

.descriptiontab .MuiTableCell-body {
  color: $color-primary-dark !important;
  font-weight: bold !important;
}

.descriptiontab .MuiAppBar-colorPrimary {
  color: $color-primary-dark !important;
  background-color: #fff !important;
  font-weight: bolder !important;
  box-shadow: none;
  border-bottom: none;
  border-bottom: 1px solid #d6d8dc;
  padding-left: 40px;

  .MuiTab-textColorInherit.Mui-selected {
    padding: 0;
  }
}

.signinLogo,
.genericLogo {
  background-image: url($generic-logo);
  background-repeat: no-repeat;
  background-position: center;
}

.signinLogo img,
.genericLogo img {
  width: 85%;
}
.descriptiontab .MuiTabs-indicator {
  background-color: $color-secondary !important;
  height: 5px !important;
  border-radius: 10px;
}

.descriptiontab .MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  color: $color-primary;
  font-size: 18px;
  padding-bottom: 5px;
  text-transform: capitalize;
  flex-direction: row !important;
}

.descriptiontab {
  background-color: #ffffff;
  position: relative;
  z-index: 9;
}

.JobDescrButton {
  background-color: Transparent;
  border: 1px solid #d5e3ff !important;
  color: white !important;
  margin-bottom: 10px !important;
  cursor: pointer !important;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 0.8rem !important;
  width: 53%;
  height: 40px;
  border-radius: 7px !important;
}

.JobDescrButton:hover {
  background-color: $color-primary !important;
  cursor: pointer !important;
}

.JobDescrButton:hover .jDFavoriteIcon {
  color: $color-error;
}

.btnApplyforJob {
  background: $color-secondary !important;
  color: white !important;
  margin-bottom: 10px !important;
  cursor: pointer !important;
  font-size: 0.8rem !important;
  width: 53%;
  height: 40px;
  box-shadow: none;
  font-weight: 700;
}

.notifyRecruiter {
  font-size: 12px;
}

.btnApplyforJob:hover {
  background: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.btnApplyforJob:disabled {
  background-color: #e1e1e1 !important;
  color: #0000007f !important;
  text-align: center;
  letter-spacing: 0;
  font-weight: bold !important;
  padding: 0.4rem 1.2rem !important;
  border-radius: 5px !important;
}

.tabemailCard {
  vertical-align: middle !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15vh;
  outline: none;
}

.tabemailCard .emailCardCloseButton {
  display: none;
}

.tabemailCard .MuiCardContent-root:last-child {
  padding: 0px !important;
}

.sendBtnSuccessfully {
  top: 56% !important;
  left: 80% !important;
}

.shareButtonActive {
  background-color: white !important;
  color: $color-primary-dark !important;
  cursor: default;
  border: 1px solid white !important;
  border-radius: 7px !important;
  margin-left: 25px !important;
  border-radius: 7px !important;
  padding: 6px 22px !important;
}

.MuiOutlinedInput-input {
  padding: 5px 9px !important;
}

.at-style-responsive .at-share-btn {
  margin-right: 10px !important;
  margin-left: 10px !important;
  outline: none;
}

.alice-carousel__dots-item {
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  margin-right: 18px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e0e4fb;
}

.alice-carousel__dots-item:hover,
.alice-carousel__dots-item.__active {
  background-color: $color-secondary !important;
}

.alice-carousel__dots {
  margin: 12px 3px 5px 15px !important;
  list-style: none;
  text-align: center;
}

.mainCarouselsection {
  height: auto;
  background-color: #f0f1f4;
  padding-top: 35px;
  padding-bottom: 35px;
}

.h2Carousel {
  color: $color-primary-dark;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1.6rem;
  font-weight: bold;
}

.footer {
  background-color: $color-primary;
  color: #fff;
}

.footercolMargin .MuiLink-root {
  display: block;
  padding: 6px 0px;
}

.footer .MuiLink-root {
  color: #fff;
  font-size: 0.875rem;
  width: max-content;
}

.footerSocialIcon svg:hover path {
  fill: $color-secondary;
}

.footer .MuiLink-root:hover {
  color: $color-secondary;
}

.footerEmail,
a.footerEmail:hover {
  text-decoration: underline !important;
}

.footerAddress {
  display: flex;
  margin-bottom: 25px;
  font-size: 0.75rem !important;
}

.footerSocialIcon svg:hover path {
  fill: $color-secondary;
}

.footerCopyright,
.footerCopyright:hover {
  font-size: 0.75rem !important;
  text-decoration: underline !important;
}

.addrUnderline a.MuiLink-underlineHover:hover {
  text-decoration: underline !important;
}

.footer h4 {
  padding: 30px 0px 10px 0px;
}

.custServiceNo {
  text-decoration: underline !important;
}

.custServiceNo:hover,
a.custServiceNo:hover {
  text-decoration: underline !important;
  color: $color-secondary !important;
}

.footer h6 {
  font-size: 1.1rem;
  margin-bottom: 15px;
  margin-top: 30px;
  font-family: $fontfamily-primary;
  font-weight: bold;
}

.footerFollowUs {
  padding: 6px 0px;
}

.footerBorder {
  border-bottom: 1px solid #5e7fb159;
  margin: 20px 0px 0px;
}

.footer p {
  font-size: 0.75rem;
  margin: 6px 0px 13px 0px;
}

.footerSocialIcon {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
}

.footerMobNo {
  font-size: 0.75rem !important;
}

.footercolMargin {
  margin-right: 4%;
}

.footerLinks,
.footerLinkHead {
  padding: 6px 0px;
  cursor: pointer;
  width: max-content;
}

.footerLinks:hover,
.footerLinkHead:hover {
  color: $color-secondary !important;
}

.backArrowIcon {
  color: a7a7a7 !important;
}

.loginHeight {
  height: 20vh;
}

.duplicateMsgMargin {
  margin: 5% 7% 0% 7%;
}

.duplicateLoginMsg {
  text-align: center;
  font-family: $fontfamily-primary;
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: 0px;
  color: #ffffff;
}

.loginForm {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

.signinLogo,
.genericLogo {
  background-image: url($generic-logo);
  background-repeat: no-repeat;
  background-position: center;
}

.signinLogo img,
.genericLogo img {
  width: 85%;
}

.rightsigninform {
  background: #f0f1f4;
  border-radius: 0px 10px 10px 0px;
}

.rightsigninform .MuiInputBase-input {
  color: #888;
}

.rightsigninform .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.signintextfiled,
.signuptextfiled,
.errorMsgSignUp,
.helpSignInField {
  margin-top: 30px !important;
}

.sendBtnMargin {
  margin: 15px 0px 20px !important;
}

.loginForm .MuiInputLabel-shrink,
.signUpForm .MuiInputLabel-shrink {
  color: $color-secondary !important;
}

.loginForm .MuiFormLable-root.Mui-focused,
.signUpForm .MuiFormLable-root.Mui-focused {
  color: $color-secondary !important;
}

.signInButton {
  background-color: $color-secondary !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  padding: 6px 20px !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.otpSignIn .signInButton {
  padding: 12px 20px 12px 20px !important;
  font-size: 16px;
  width: 100px;
  height: 49px;
  white-space: nowrap;
}

.otpSignIn .signInButton:disabled {
  padding: 12px 20px 12px 20px !important;
  width: 125px;
}

.signintextfiled .MuiInput-underline:after,
.helpSignInDefault .MuiInput-underline:after,
.signuptextfiled .MuiInput-underline:after {
  border-bottom: 2px solid $color-secondary !important;
}

.helpSignInEmailSent .MuiInput-underline:before {
  border-bottom: 1px solid $color-success !important;
}

.helpSignInEmailSent .MuiInput-underline:before,
.helpSignInEmailSent .MuiInput-underline:after,
.helpSignInEmailSent .MuiInput-underline:hover,
.helpSignInDefault .MuiInput-underline:after {
  border: none;
}

.secondaryBtn {
  background-color: $color-primary !important;
  border-radius: 2px !important;
  box-shadow: 0px 2px 2px #0000003d !important;
  padding: 6px 15px !important;
  color: #fff !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}

.secondaryBtn:hover {
  background-color: $color-tertiary !important;
  box-shadow: 0px 8px 8px #00000029 !important;
  border-radius: 2px !important;
}

.signInBtnAlign {
  display: inline-block;
  margin-top: 15px;
}

.signInButton:hover {
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.signInButton:disabled {
  background-color: #e1e1e1 !important;
  color: #888888 !important;
  font-weight: 700 !important;
}

.needHelpTxt {
  color: #adadad !important;
  font-weight: 700;
  margin: 25px 0px 10px 0px;
  cursor: pointer;
  text-decoration: underline !important;
  width: max-content;
}

.needHelpTxt a:hover {
  color: $color-secondary !important;
  text-decoration: underline !important;
}

.needHelpHighlight {
  color: #2e4e8c !important;
  font-weight: 700;
  cursor: pointer;
}

.attemptsLeft {
  color: #707070;
}

.erroMsgsignIn {
  color: $color-error;
  font-size: 0.8rem;
  font-weight: 500;
  margin: 5px 14px 0 14px;
}

a.loginHelpEmail,
a.loginHelpEmail:hover {
  color: $color-secondary;
  text-decoration: underline !important;
  cursor: pointer;
}

.helpSignInField .MuiFormHelperText-root {
  min-height: 21px !important;
}

.rightsigninform .helpSignInField .MuiInputBase-input.Mui-disabled,
.rightsigninform .helpSignInField .MuiInputBase-input.Mui-disabled:hover {
  -webkit-text-fill-color: #00000061 !important;
  color: #00000061 !important;
}

.rightsigninform .helpSignInField .MuiInputLabel-shrink.Mui-disabled {
  -webkit-text-fill-color: #00000061 !important;
  color: #00000061 !important;
}

.signintextfiled .MuiFormHelperText-root,
.helpSignInField .MuiFormHelperText-root.Mui-error,
.errorMsgSignUp .MuiFormHelperText-root,
.accSettingcontainer .MuiFormHelperText-root {
  color: $color-error !important;
}

.helpSignInField .MuiInput-underline.Mui-error:after {
  border-bottom: 2px solid $color-error !important;
  border-bottom-color: $color-error !important;
  transform: none;
}

.helpSignInField .MuiFormHelperText-root {
  color: $color-success !important;
}

.errorMsgSignUp .MuiInputLabel-shrink {
  color: $color-error !important;
}

.errorMsgSignUp .MuiOutlinedInput-notchedOutline,
.errorMsgSignUp .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-error !important;
}

.signUpError .MuiInput-underline:after {
  border-bottom: 2px solid $color-error !important;
}

.helpSignInButtonDisabled:disabled {
  background-color: #e1e1e1 !important;
  color: #888888 !important;
}

.recoverTxt {
  font-weight: 700;
  padding-bottom: 10px;
}

.loaderResendBtn {
  color: $color-primary !important;
}

.pswResetText {
  font-weight: 700;
  padding-top: 10px;
  margin-bottom: 0px;
}

.HelpSignIn {
  margin-top: 20px;
}

.signUpForm {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  margin-top: 7vh;
}

.signUpLabel {
  font-weight: 700;
  vertical-align: middle;
  color: $color-secondary !important;
  display: inline-block;
  padding-left: 15px;
  text-decoration: none;
  cursor: pointer;
}

.goBackToSign {
  font-weight: 700;
  vertical-align: middle;
  color: $color-secondary !important;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.backArrowSignIn {
  font-size: 1rem !important;
}

.signUpLabel a:hover {
  text-decoration: underline !important;
}

.signInLink {
  font-weight: 700;
  vertical-align: middle;
  color: $color-secondary !important;
  display: inline-block;
  text-decoration: none;
}

.forgotPasslLink {
  text-decoration: underline;
  font-weight: bold !important;
}

.password-strength-meter {
  text-align: left;
  margin-top: 5px;
  width: 95%;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border-top: none;
}

.signUpForm .MuiInputBase-input:-webkit-autofill,
.signUpForm .MuiInputBase-input:-webkit-autofill:hover,
.signUpForm .MuiInputBase-input:-webkit-autofill:focus,
.signUpForm .MuiInputBase-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f0f1f4 inset !important;
  -webkit-text-fill-color: #707070 !important;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
  font-size: 12px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: #fa5b5b;
}

.strength-Medium::-webkit-progress-value {
  background-color: #ff6d2d;
}

.strength-Strong::-webkit-progress-value {
  background-color: #7cfc00;
}

.strength-Weak::-moz-progress-bar {
  background-color: #fa5b5b;
}

.strength-Medium::-moz-progress-bar {
  background-color: $color-secondary;
}

.strength-Strong::-moz-progress-bar {
  background-color: #7cfc00;
}

.passInstruction {
  font-size: 0.7rem;
  color: #707070;
}

.passExample {
  color: #000;
  font-weight: 700;
}

.rightsigninform .MuiInputBase-input,
.signupDropdown,
.MuiSelect-select,
.MuiSelect-select:focus {
  padding: 16px 15px !important;
  color: $color-primary;
  font-size: 0.9rem !important;
  font-weight: 500;
}

.signupDropdown:hover {
  background-color: #f0f1f4 !important;
}

.passwordIcon {
  color: #e1e1e1 !important;
  width: 0.8em !important;
  height: 0.8em !important;
}

.signUpNote {
  margin: 15px 0 4px 0;
  color: #1d1d1d;
}

.signUpPage #sign-up-profession,
.signUpPage #sign-up-specialty {
  padding: 6px 0 !important;
  text-transform: capitalize !important;
}

.signUpPage .MuiAutocomplete-listbox {
  padding: 0px;
  text-transform: capitalize;
}

.forgotpass {
  color: $color-primary;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 10px;
}

.resendTextfield {
  width: 96%;
  margin-top: 15px !important;
}

.resendTextfield .MuiInput-underline:after {
  border-bottom: 2px solid $color-success !important;
  border: none;
}

.resendTextfield .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border-bottom: 2px solid green;
}

.rightsigninform .MuiFormHelperText-root {
  color: $color-error;
  font-size: 0.8rem;
  font-weight: 500;
}

.resendEmailBtn {
  background: $color-primary !important;
  border-radius: 2px !important;
  padding: 6px 20px !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.pswSuccessImg {
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

.pwdUpdateback {
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000029;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.passUpdate h3 {
  color: $color-primary;
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 15px;
  text-align: center;
}

.passUpdate p {
  color: $color-primary;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 700;
  margin-top: 15px;
}

.loginForm .MuiInputBase-input:-webkit-autofill,
.loginForm .MuiInputBase-input:-webkit-autofill:hover,
.loginForm .MuiInputBase-input:-webkit-autofill:focus,
.loginForm .MuiInputBase-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f0f1f4 inset !important;
  -webkit-text-fill-color: #707070 !important;
}

.payrollNav {
  z-index: 99;
  background-color: #fff;
  width: 100%;
  box-shadow: 2px 3px 4px #00000020;
}

.payRowClick {
  cursor: pointer;
  margin-top: 10px;
  padding: 0px;
  color: $color-primary;
}

.payRowClick:hover {
  color: $color-secondary !important;
}

.payRowClick h6 {
  margin-top: 9rem;
  margin-left: 0px;
}

.payRowClick h6 a {
  cursor: pointer;
  margin-top: 10px;
  padding: 0;
  color: $color-primary !important;
}

.payRowClick h6 a:hover,
.payRowClick:hover {
  color: $color-secondary !important;
  text-decoration: none !important;
}

svg.paystubBackBtn:hover {
  fill: $color-secondary !important;
  color: $color-secondary !important;
}

.payrollNav .nav-item a {
  color: #1b356f !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  width: max-content;
}

.payRollNavMargin {
  margin-top: 2.3rem;
  background-color: white;
}

.mainPaystub {
  padding-top: 15px;
  background-color: #e9ecef;
}

.paystubSelectedPg {
  position: absolute;
  right: 5rem;
  bottom: 13px;
}

.paystubtable .pagination {
  padding: 25px 0 25px 0;
}

.titlepaystub {
  color: #1a2f64;
  font-weight: 700;
  font-size: 1.8rem;
  background-color: #fff;
  padding: 2.5rem 0rem 0.5rem 0rem !important;
  margin-top: 6rem;
}

.paystubDetailHead {
  color: #1a2f64;
  font-weight: 700;
  font-size: 1.8rem;
  background-color: #fff;
  padding: 0.5rem 0rem 0.5rem 0.5rem !important;
  margin-top: 0rem;
}

.mainPaystub .jumbotron {
  padding: 10px;
  margin-top: 14.5rem;
  margin-bottom: 0rem;
}

#payStubDetailMargin {
  margin-top: 3rem;
}

.paystubBackBtn {
  vertical-align: middle;
  margin-bottom: 2px;
  font-size: 1rem !important;
}

.payrollNav .nav-link {
  text-align: left;
  opacity: 1;
  cursor: pointer;
  margin: 0rem 2rem 0rem 0rem;
}

.payDetails .MuiTableCell-root {
  display: table-cell;
  padding: 12px 15px 12px 20px;
  font-size: 0.9rem;
  text-align: left;
}

#paymentDeductionPg .payDetails .MuiTableCell-root {
  display: table-cell;
  padding: 12px 15px 12px 20px;
  font-size: 0.9rem;
  text-align: left;
  vertical-align: top;
}

.payStubDetails .MuiTableCell-root:last-child {
  text-align: right !important;
  padding-right: 18px !important;
}

.directDeposit h5 {
  margin-top: 5px;
}

.payheading .MuiTableCell-head {
  color: #3a3b3f;
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 0.875rem;
  padding: 12px 15px 12px 20px;
  background-color: #f3f3f3;
}

.directDeposit {
  background-color: #fff;
  border: 1px solid #0000001f;
  border-radius: 4px;
  margin: 20px 15px 7px 0px;
  padding: 20px;
}

.latestPay {
  color: #3a3b3f;
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 1rem;
  padding: 10px 0px 10px 20px;
  background-color: #fff;
  margin-bottom: 0px;
}

.payDetailDate {
  display: block;
  font-weight: 400;
  font-size: 0.9rem;
}

.directDeposit span {
  letter-spacing: 1.18px;
  font-size: 0.9rem;
  font-weight: bold;
}

.dateDropDown {
  width: 100% !important;
  margin-bottom: 10px !important;
  font-family: $fontfamily-primary !important;
}

a.viewPaystub {
  color: #254d91;
  font-weight: 700;
  cursor: pointer;
}

a.viewPaystub:hover {
  color: $color-secondary;
  text-decoration: none !important;
}

.dateDropDown {
  & div {
    padding: 7px;
    font-family: $fontfamily-primary;
    font-weight: 500;
  }
}

.dateDropDown {
  & div:focus {
    background-color: #fff !important;
  }
}

.tableRowHover :hover {
  background: #fbfbfb;
}

.hospTimesheet {
  background-color: #fff;
  height: 100%;
  padding: 15px 10px 10px 20px;
}

.timeLabel {
  font-size: 0.8rem !important;
  padding-bottom: 7px;
  text-transform: uppercase;
  height: 60%;
}

.timeValue {
  font-size: 0.8rem !important;
  padding-bottom: 7px;
  font-weight: 700;
}

.lineSeperator {
  background-color: #f6f8f9 !important;
  margin: 15px 0px 15px 0px;
}

.noteText {
  font-size: 0.8rem;
  margin-top: 8%;
}

.payCheckDate {
  margin-top: 16%;
  text-align: right;
  margin-right: 10px;
  font-size: 0.875rem;
  font-weight: 700;
}

.selectDate {
  padding: 0.4rem;

  & div {
    background-color: #fff !important;
  }
}

.payPeriod {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.hospTimeValue {
  padding: 5px;
}

.hospTextMob {
  font-size: 0.8rem !important;
  padding-bottom: 7px;
  text-transform: uppercase;
  height: 60%;
  padding-left: 5px;
}

.paymentDeduct {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 10px;
}

.payDateText {
  padding-bottom: 10px;
}

.deductionTable {
  margin-top: 25px;
}

.payDeduction {
  background-color: #fff;
  height: 100%;
  padding: 15px 10px 10px 20px;
}

.deductionTblMrgin:first-child {
  margin: 0px 0px 0px 0px;
}

.deductionTblMrgin {
  margin: 20px 0px 0px 0px;
}

.payDeductionNoRec {
  background-color: #fff;
  height: 150px;
  padding: 65px 15px 50px 15px;
  text-align: center;
}

.payRateNoteDashB {
  margin-top: 7.5rem;
  color: #fff;
  padding: 12px;
  text-align: center;
}

.labelDetailsLink {
  color: $color-primary !important;
  padding-left: 20px;
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: underline !important;
}

.labelDetailsLink:hover,
a.labelDetailsLink:hover {
  color: $color-secondary !important;
  text-decoration: underline !important;
}

.readMoreBtn {
  padding: 0.75rem 1.2rem !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  background: $color-secondary !important;
  cursor: pointer !important;
  box-shadow: none !important;
  line-height: 1.75rem !important;
}

.readMoreBtn:hover {
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.accSettingBanner {
  background-image: url(../src/images/AccountsettingsBanner.png);
  background-repeat: no-repeat;
  height: 159px;
  background-size: 100%;
  margin-top: 9.5rem;
}

.userPhoto {
  margin-top: -11rem;
}

.userPhoto .MuiAvatar-root {
  width: 120px;
  height: 120px;
  margin: 6rem 0rem 1rem 0rem;
  border: 12px solid #e8e8e830;
  margin-left: 10px;
}

.accSettingContent {
  margin: auto;
  width: 100%;
}

.accSettingContent .MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}

.acsettingbtns {
  width: 100%;
}

.MuiButton-containedSizeLarge {
  width: max-content;
}

.accSettingActionBtns {
  width: 100%;
}

.accSettingContent .MuiFormControl-root {
  width: 100%;
  margin: auto !important;
}

.acSettingSaveChangediv {
  margin-top: 3rem;
  text-align: right;
}

.savechangedivbottom {
  text-align: right;
  margin: 10px 0px;
}

.accSettingcontainer .row {
  width: -webkit-fill-available;
}

.accSettingNewPwdSection .password-strength-meter {
  text-align: left;
}

.accSettingNewPwdSection .password-strength-meter-label {
  text-align: left;
  line-height: 0.5;
  margin-top: 10px;
  height: 20px;
}

.accSettingContent .MuiInputLabel-shrink {
  color: black !important;
}

.accSettingContent .MuiFormLabel-root.Mui-disabled {
  font-weight: 700;
}

.accSettingContent .MuiInput-underline:after {
  border: 1px solid #4270cb !important;
}

.accSettingConfPwd label + .MuiInput-formControl {
  width: 100%;
}

.accSettingPassStrMeter .password-strength-meter-progress {
  width: 100% !important;
  height: 8px !important;
}

.accSettingSubHead {
  color: $color-primary;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 10px;
}

.saveChangeBtn {
  float: right;
  border: none !important;
  border-radius: 10px !important;
  background-color: $color-secondary !important;
}

.userPhoto .MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover {
  color: #fff;
  background-color: $color-primary;
  border-radius: 5px;
  outline: none !important;
  padding: 0.4rem 1.2rem;
}

.saveChangebtnbottom {
  float: right;
  border: none !important;
  font-weight: 700 !important;
}

.saveChangebtnbottom:hover,
.saveChangeBtn:hover {
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.saveChangebtnbottom:disabled {
  font-weight: 700 !important;
}

.changeAvatarBtn,
.changeAvatarBtn:hover,
.changeAvatarBtn:active,
.changeAvatarBtn:focus {
  background-color: $color-primary !important;
  text-align: center;
  letter-spacing: 0;
  color: #ffffff !important;
  font-weight: 700 !important;
  padding: 0.4rem 1.2rem !important;
  border-radius: 5px;
  font-weight: 700 !important;
}

.ChangeAvatarpoper {
  cursor: pointer;
  position: absolute;
  background-color: white !important;
  border-radius: 4px;
  font-size: 0.7rem;
  padding: 20px;
  left: 6%;
  top: 28%;
  margin: auto;
  z-index: 988;
  box-shadow: 0px 1px 10px #00000029;
}

.userPhoto1 {
  width: max-content;
  height: max-content;
  min-height: 100px;
  margin: auto;
  position: relative;
}

.userPhoto1 .MuiAvatar-root {
  width: 110px;
  height: 110px;
  margin: 12px auto;
  border: 7px solid #e8e8e8;
}

.userPhoto1 .MuiAvatar-root:hover {
  border: 10px solid #e8e8e8;
}

.userPhoto .MuiAvatar-img {
  background: whitesmoke;
}

.successIcon {
  color: #2fcb65;
  margin-right: 10px;
  font-size: 2.3rem !important;
}

.successMsgFadeOut {
  color: #2fcb65;
  background: #ffffff repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 5px;
  padding: 13px 22px;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  width: max-content;
  margin: 0;
  opacity: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.successMsgFadeIn {
  color: #2fcb65;
  background: #ffffff repeat padding-box;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 5px;
  padding: 13px 22px;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
  width: max-content;
  margin: 0;
  opacity: 1;
}

.payPeriod {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.hospTimeValue {
  padding: 5px;
}

.hospTextMob {
  font-size: 0.8rem !important;
  padding-bottom: 7px;
  text-transform: uppercase;
  height: 60%;
  padding-left: 5px;
}

.paymentDeduct {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 10px;
}

.payDateText {
  padding-bottom: 10px;
}

.deductionTable {
  margin-top: 25px;
}

.payDeduction {
  background-color: #fff;
  height: 100%;
  padding: 15px 10px 10px 20px;
}

.deductionTblMrgin:first-child {
  margin: 0px 0px 0px 0px;
}

.deductionTblMrgin {
  margin: 20px 0px 0px 0px;
}

.payDeductionNoRec {
  background-color: #fff;
  height: 150px;
  padding: 65px 15px 50px 15px;
  text-align: center;
}

.benefitsBanner {
  background: $color-primary url(./images/BenefitsNew.jpg);
  height: 280px;
  margin-top: 12.8rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.benefitsfluidBanner {
  background-color: $color-primary;
  padding: 0px;
}

.benefitsBannerText {
  font-family: $fontfamily-primary;
  font-size: 3rem;
  color: #fff;
  text-align: center;
  padding: 3.5rem 0;
  font-weight: bold;
  line-height: unset;
}

.enrollBenefitHead {
  font-size: 1.4rem;
  font-weight: bold;
  color: $color-primary;
  margin: 30px 0px;
}

.enrollBenefitHead .MuiLink-root {
  color: $color-primary !important;
  width: max-content;
  cursor: pointer;
}

.enrollBenefitHead .MuiLink-root:hover {
  color: $color-secondary !important;
}

.benefitOffer {
  color: #888888;
  padding-left: 0px;
}

.benefitWrapper {
  font-family: $fontfamily-primary !important;
  margin: 40px 0px;
  font-size: 1rem;
}

.planOption {
  max-height: 4rem;
  margin-bottom: 20px;
}

.benefitHead {
  font-size: 2rem;
  font-weight: bold;
  color: $color-primary;
  margin: 40px 0;
  text-align: center;
  font-family: $fontfamily-primary !important;
}

.benefitsArrow {
  font-size: 1.4rem;
}

.benefitAppstore {
  width: 95%;
  max-width: 10rem;
  height: auto;
  cursor: pointer;
}

.benefitgplay {
  width: 95%;
  max-width: 10rem;
  height: auto;
  cursor: pointer;
}

.benefitAppstore:hover,
.benefitgplay:hover {
  opacity: 0.8 !important;
}

.benifitUrl {
  color: $color-primary !important;
  font-weight: bold;
}

a.benifitUrl:hover {
  color: $color-secondary !important;
}

.benefitLinkColor {
  color: $color-primary !important;
}

a.benefitLinkColor:hover {
  color: $color-secondary !important;
}

.beneOfferings {
  background-color: #f0f1f4;
  padding-bottom: 20px;
  padding-top: 1px;
}

.beneCards {
  display: flex;
  margin-bottom: 30px;
}

.offeringDetails {
  background-color: #ffffff;
  width: 20%;
  padding: 15px;
  margin: 0 15px;
  text-align: center;
  color: #888888;
  font-weight: 700;
  cursor: default;
}

.offeringDetails a:hover {
  text-decoration: none;
}

.retirementPlan {
  height: 4rem;
  margin-bottom: 3px;
}

.moreDetailsTextDiv {
  color: $color-primary;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  margin: 10px 0px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreDetailsText {
  cursor: pointer;
}

.arrowRightIcon {
  position: absolute;
  left: 71%;
  bottom: -4px;
}

.registerBtn {
  background: $color-secondary !important;
  cursor: pointer !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  border-radius: 10px 10px 10px 0 !important;
}

.registerBtn:hover {
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.beneInfoImage {
  height: 4rem;
  margin-top: 18px;
}

.workLifeText {
  padding: 5rem 0;
  line-height: 26px;
}

.retailSubText {
  width: 100% !important;
}

.retailDiscountImg {
  background: url("./images/Retail-Discounts.jpg") top center no-repeat !important;
  height: auto;
  padding: 40px 112px;
}

.retailHead {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  padding: 40px 40px 20px 0px;
  display: flex;
  font-family: $fontfamily-primary;
}

.retailPanel {
  background-color: $color-primary;
  padding: 0px;
}

.retailSubText {
  width: 50%;
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 25px;
  line-height: 26px;
}

.beneAboutText {
  margin: 18px 0;
  font-size: 1.3rem;
  color: $color-primary;
  padding-left: 20px;
  font-weight: bold;
}

.beneSubText ul li {
  color: $color-primary;
  list-style-type: disc;
  padding-bottom: 10px;
  font-weight: 500;
  line-height: 26px;

  & span {
    color: black;
    font-size: 0.9rem;
    line-height: 26px;
  }
}

.benefitLinks {
  color: $color-primary !important;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline !important;
}

a.benefitLinks:hover {
  color: $color-secondary !important;
  text-decoration: underline !important;
}

.benefitsPhone {
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
}

a.benefitsPhone:hover {
  color: $color-secondary !important;
  text-decoration: none !important;
}

.premiumsSection {
  background-color: #f0f1f4;
  padding-top: 12px;
  padding-bottom: 15px;
  cursor: default;
}

.planSection {
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: default;
}

.benefitSubHeads {
  margin: 18px 0;
  font-size: 1.1rem;
  color: $color-primary;
  font-weight: bold;
  cursor: default;
}

.medicalPlan {
  padding: 0 10% !important;
  display: flex;
  justify-content: center;
}

.dentalPlan {
  padding: 0 16% !important;
  display: flex !important;
  justify-content: center;
}

.benefitRowMar {
  margin-top: 2rem;
}

.taxPagePadding {
  padding-left: 22px;
}

.taxSavingBanner {
  background: url(./images/taxsavingsplus.jpg);
  height: 280px;
  margin-top: 12.8rem;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
}

.BannerSubTxt {
  font-family: $fontfamily-primary;
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  padding: 0 18%;
}

.taxBannerText {
  font-family: $fontfamily-primary;
  font-size: 3rem;
  color: #fff;
  text-align: center;
  padding-top: 3rem;
  font-weight: bold;
}

.taxsavingComingSoonHead {
  color: $color-primary;
  font-size: 2rem;
  font-weight: bold;
  font-family: $fontfamily-primary;
  margin: 4rem 0rem;
  text-align: center;
}

.errorPageBanner {
  background: url(./images/ErrorPageBG.jpg) center center fixed;
  height: auto !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.notLogPageBanner {
  background: url(./images/ErrorPageBG.jpg) center center fixed;
  min-height: 100vh !important;
  margin-top: -100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.errorPageBackbtn a,
.errorPageSubTxt a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
  font-weight: 700;
  font-family: $fontfamily-primary;
}

.errorPageSubTxt a:hover {
  color: $color-secondary !important;
}

.errorPageBackbtn .MuiSvgIcon-fontSizeSmall {
  margin-top: -3px;
}

.errorPageBackbtn a :hover {
  color: #fff;
  text-decoration: none;
}

.errorImgDiv {
  text-align: center;
  padding-top: 12rem;
}

.notLogErrImg {
  text-align: center;
  padding-top: 0rem;
}

.errorTxt {
  text-align: center;
  text-transform: UPPERCASE;
  color: #ffffffb0;
  letter-spacing: 30px;
  font-size: 1.1rem;
  margin: 15px 0px 55px 0px;
}

.erroPageMainTxt {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
  margin-top: 3rem;
  font-family: $fontfamily-primary;
}

.errPageBtn {
  color: #ffffff !important;
  background: $color-secondary !important;
  border-radius: 7px !important;
  box-shadow: none !important;
  padding: 0.5rem 1.2rem !important;
  text-transform: uppercase;
  font-size: 0.8rem !important;
}

.errPageBtn:hover {
  text-decoration: none !important;
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
  border-radius: 7px !important;
}

.errorPageBackbtn {
  padding-top: 1rem !important;
  text-align: center;
  margin-top: 1.5rem;
}

.errorPageSubTxt {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 3rem;
  padding-bottom: 3rem;
  font-family: $fontfamily-primary;
}

.errPageLogo {
  background: url($generic-logo) 50% 80% no-repeat, #ffffff;
  background-size: 60% !important;
  margin: 0 auto;
  margin-bottom: 65px;
  width: 205px;
  height: 135px;
  border-radius: 0 0 10px 10px;
}

.errorImgDiv img,
.notLogErrImg img {
  width: 21rem;
}

.enrollmentBanner {
  background: $color-primary url(./images/Enrollment.png) top center;
  height: 280px;
  margin-top: 11rem;
  background-size: 100%;
  background-repeat: no-repeat;
}

.enrollmentvideo {
  margin-top: 2.2rem;
}

.enrollmentlisttxt {
  padding: 0px 25px !important;
}

.enrollmentBannertxt {
  font-family: $fontfamily-primary;
  font-size: 3rem;
  color: #fff;
  text-align: center;
  padding-top: 6rem;
  font-weight: bold;
}

.enrollmentPlanTitle {
  font-size: 2rem;
  padding: 30px 75px;
  color: $color-primary;
  font-weight: bold;
}

.enrollmentPlanDesc {
  padding: 0px 75px;
  line-height: 26px;
}

.enrollmentReview {
  margin-top: 3rem;
  padding: 0px 60px;
}

.enrollmentContent {
  margin: 18px 0;
  font-size: 1.3rem;
  color: $color-primary;
  font-weight: bold;
  padding: 0px 75px;
}

.enrollmentList ul li {
  color: #a0a0a0;
  list-style-type: disc;
  padding-bottom: 10px;
  font-weight: 500;
}

.enrollMentBack {
  background-color: #f0f1f4;
  padding-bottom: 2rem;
  padding-top: 2rem;
  margin-top: 3.5rem;
}

.enrollmentTxtfont {
  font-size: 0.9rem;
  padding: 0px 50px;
}

.enrollmentTxtfontPara {
  font-size: 0.9rem;
  padding: 0px 75px;
  line-height: 26px;
}

.apiErrorMainTxt {
  text-align: center;
  padding: 4rem 0rem 0rem;
  color: $color-primary;
  font-size: 1.2rem;
  font-weight: bold;
}

.apiErrorSubTxt {
  text-align: center;
  width: 62%;
  margin: 0px auto;
  font-size: 0.9rem;
  padding: 1.5rem 5px 3rem 5px;
  color: $color-primary;
}

.apiErrorImgDiv {
  text-align: center;
  padding-top: 9rem;
}

.apiErrorImgDiv img {
  width: 10rem;
}

.apiErroremailLink a {
  color: $color-primary;
  text-decoration: underline;
}

.apiErroremailLink a:hover {
  color: $color-secondary;
}

.benefitsAppGpay {
  display: flex;
  justify-content: normal;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .beneCards {
    flex-direction: column;
  }

  .offeringDetails {
    min-width: 20%;
    width: auto;
    margin-bottom: 10px;
  }

  .workLifeText {
    padding: 5rem 15px;
  }

  .retailDiscountImg {
    background-size: cover !important;
    padding: 10px 20px;
  }

  .benefitsAppGpay {
    display: flex;
    justify-content: space-between;
  }

  .tabemailCard {
    width: 95%;

    .jobDesEmailCloseBtn {
      left: unset;
      right: 20px;
    }
  }

  .noPaddingMob {
    padding: 0px !important;
  }

  .loadingTxt {
    font-size: 1.3rem;
  }

  .loaderPage {
    padding: 8rem 4rem 4rem 4rem;
  }

  .loadHeaderLogo {
    width: 90%;
    padding: 5px 0px 0px 10px;
  }

  .payrollNav {
    padding: 0px;
  }

  #profileIframePage {
    margin-top: 9rem;
    height: 63vh;
  }

  #incompleteProfilePage {
    margin-top: 5rem;
    height: 63vh;
  }

  .MuiDrawer-paper {
    width: 100vw !important;
  }

  .navbar {
    display: flex;
    overflow-x: auto;
  }

  .hamNavAppBar {
    width: 4rem !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0px 0px 0px 0px;
    margin: 0px 13px 0px 0px;
    height: 62px;
  }

  .hamMenu {
    padding: 15px 0px 15px 22px !important;
  }

  .hamNavAppBar .MuiToolbar-gutters {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .hamburgerNavIcon {
    font-size: 2.2rem !important;
  }
  .MuiPaginationItem-root {
    min-width: 20px !important;
  }

  .otpSignIn .signInButton:disabled {
    width: 125px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .signinLogo {
    background-size: 80%;
  }

  .genericLogo {
    background-size: 80%;
  }

  .footercolMargin {
    margin-right: 3%;
  }

  .iconLabel {
    font-size: 0.65rem;
    padding-right: 5px;
    padding-left: 5px;
  }

  .nightIcon,
  .dayIcon,
  .eveningIcon,
  .rotationIcon,
  .timeIcon {
    font-size: 0.8rem !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .specialityJobdetails {
    font-size: 1.9rem;
  }

  .fixHeader .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 27.666667%;
  }

  .fixHeader .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 60.666667%;
  }

  #profileIframePage {
    margin-top: 11.5rem;
    height: 65vh;
  }

  #incompleteProfilePage {
    margin-top: 5.5rem;
    height: 70vh;
  }

  .titlepaystub,
  .paystubDetailHead {
    font-size: 1.5rem;
  }

  .mainPaystub .jumbotron {
    padding: 10px;
    margin-top: 14rem;
  }

  .payRollNavMargin {
    margin-top: 1.3rem;
    background-color: white;
  }

  .DefaultBannerJobsPg {
    margin-top: 8rem;
  }

  .userPhoto {
    margin-top: -14rem;
  }

  .accSettingBanner {
    margin-top: 8.5rem;
  }

  .ChangeAvatarpoper {
    left: 7%;
    top: 31%;
  }

  .payrollNav .nav-item a {
    font-size: 0.9rem !important;
  }

  .benefitsBanner {
    margin-top: 11rem;
  }

  .taxSavingBanner {
    margin-top: 11rem;
  }

  .globalNavbar .nav-link {
    margin: 0rem 0.6rem 0rem 0.6rem;
  }

  .globalNavbar .navbar-nav .active .myActivelink,
  .globalNavbar .nav-item a:hover .myActivelink {
    margin: 0px;
  }

  .globalNavbar {
    height: 60px;
    margin-bottom: 13px;
  }

  #profileIframePage {
    margin-top: 11rem;
  }

  .payRateNoteDashB {
    margin-top: 7rem;
  }

  .imInterestedBtnrow {
    margin-right: 32px;
  }

  .imInterestedBtn,
  .imInterestedBtn:disabled {
    width: 102px;
  }

  .payRowClick h6 {
    margin-top: 9rem;
    padding-left: 10px;
  }
}

@media only screen and (min-width: 1281px) {
  .headerIcon {
    margin-left: 0;
  }

  .emailCard .MuiCardContent-root:last-child {
    padding: 0;
  }

  .imInterestedBtn,
  .imInterestedBtn:disabled {
    width: 102px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .globalNavbar .navItemHeight {
    height: 3.5rem;
  }

  .DefaultBannerJobsPg {
    margin-top: 8rem;
  }

  .benefitsBanner {
    margin-top: 11.8rem;
  }

  .taxSavingBanner {
    margin-top: 10rem;
  }

  .accSettingBanner {
    margin-top: 8.5rem;
  }

  .emailCard .MuiCardContent-root:last-child {
    padding: 0 !important;
  }

  .payRateNoteDashB {
    margin-top: 7rem;
  }

  .payRowClick h6 {
    margin-top: 9rem;
    padding-left: 10px;
  }

  .mainPaystub .jumbotron {
    padding: 10px;
    margin-top: 14.5rem;
  }

  .payRollNavMargin {
    margin-top: 1.3rem;
    background-color: white;
  }
}

.MuiTooltip-popper[x-out-of-boundaries] {
  display: none;
}

.MuiAutocomplete-popper {
  z-index: 999999;
}

.certForm .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 93% !important;
}

.certForm .MuiAutocomplete-inputRoot .MuiAutocomplete-input:focus-visible {
  outline-style: none;
}

.certForm .onBMainTxtField .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-radius: 5px !important;
}
