@import "src/theme/variables.scss";

.quickApplyEligibility {
    background-color: #ECF1F8;
}

.quickApplyEligibilityContainer {
    background-color: #ECF1F8;
    padding: 35px 45px;
    max-width: 1635px;

    .header {
        font-family: "Nunito Sans";
        font-size: 20px;
        font-weight: 700;
        color: #003366;
        margin-bottom: 16px;

        .icon {
            margin-right: 8px;
        }
    }

    .quickApplyEligibilityGrid {
        margin-bottom: 8px;
        max-width: 1635px;

        .descriptionContainer {
            padding: 44px 32px;
            border-radius: 30px 30px 30px 0px;
            background-image: url(../../../../../imagesV2/quickApplyAdBackgroundWeb.png);
            background-color: #1D4D86;
            background-position: center;
            background-repeat: no-repeat;
            background-position-x: -50px;

            .title {
                font-family: "Nunito Sans";
                font-size: 34px;
                font-weight: 700;
                color: white;
                margin-bottom: 24px;
            }

            .text {
                font-family: "Nunito Sans";
                font-size: 16px;
                color: white;
                margin-bottom: 24px;
            }
        }
    }
}

.quickApplyCard {
    background-color: white;
    height: fit-content;
    width: fit-content;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 1281px) {
    .quickApplyEligibilityContainer {
        margin: auto;

        .quickApplyEligibilityGrid {
            display: flex;
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1280px) {
    .quickApplyEligibilityContainer {
        padding: 20px 10px;

        .header {
            margin: 0 30px 16px 30px;
        }

        .quickApplyEligibilityGrid {
            display: block;

            .descriptionContainer {
                margin: 0 30px 30px 30px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .quickApplyEligibilityContainer {
       max-width: 960px;
       margin: auto;
    }
}

@media only screen and (min-width: 769px) {
    .quickApplyEligibilityContainer {
        .quickApplyEligibilityGrid {
            .cardContainer {
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
                margin-left: 30px;
            }

            .cardContainer > div {
                flex: 0 0 calc(50% - 30px);
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .quickApplyCard {
        .quickApplyHospitalName {
            font-size: 1rem !important;
        }

        .quickApplyFacilityName {
            font-size: .8rem !important;
        }

        .jobCardSalary {
            font-size: 1rem !important;
        }

        .quickApplyHospitalName {
            width: 175px !important;
        }
    }
}

@media only screen and (max-width: 768px) {
    .quickApplyEligibilityContainer {
        max-width: 540px;
        margin: auto;
        padding: 20px 10px;

        .header {
            font-size: 18px;
            text-align: center;
        }

        .quickApplyEligibilityGrid {
            .descriptionContainer {
                padding: 20px;
                border-radius: 15px 15px 15px 0px;

                .title {
                    font-size: 16px;
                    text-align: center;
                    margin-bottom: 8px;
                }

                .text {
                    font-size: 14px;
                    text-align: center;
                    margin-bottom: 0;
                }
            }
        }
    }

    .quickApplyCarouselContainer  {
        .alice-carousel__stage-item > div:first-child {
            margin-right: 10px;
            width: 310px;
        }
    }
}

@media only screen and (max-width: 576px) {
    .quickApplyEligibilityContainer {
        max-width: 540px;
    }
}