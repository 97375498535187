@import "src/theme/variables.scss";

.main {
  background-color: #F9FAFC;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub {
  width: 100%;
}

.skeleton {
  span {
    height: 150px;
    width: 100%;
    border-radius: 25px;
  }
}

.nextDestinationHeading {
  margin-bottom: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: $color-primary;
  text-align: center;
}

.welcomeHeading {
  color: white;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.exploreSubHeading {
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

@include breakpoint("sm") {
  .main {
    padding: 24px;
  }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .completeProfile {
    padding: 24px 100px;
  }
}

@media screen and (max-width: 768px) {
  .skeleton {
    span {
      height: 75px;
      width: 100%;
      border-radius: 15px;
    }
  }
}

@media screen and (min-width: 769px) {
  .nextDestinationHeading {
    font-size: 24px;
  }

  .welcomeHeading {
    font-size: 34px;
    margin-bottom: 12px;
  }

  .exploreSubHeading {
    font-size: 16px;
    margin-bottom: 12px;
  }
}

@include breakpoint("md") {
  .main {
    padding: 30px;
  }

  .completeProfile {
    padding: 90px 50px 90px 50px;
  }

  .sub {
    max-width: 1080px;
  }
}

@include breakpoint("lg") {
  .main {
    padding: 30px 30px;
  }

  .completeProfile {
    padding: 90px;
  }
}

@include breakpoint("xl") {
  .main {
    padding: 30px 40px;
  }

  .completeProfile {
    padding: 90px;
  }
}