@import "src/theme/variables.scss";

.ReferFriend {
  background-color: transparent;
  flex-grow: 2;
  width: 332px;
  min-width: 332px;
  max-width: 332px;
  height: 154px;
}

.ReferFriendText {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.ImageContainer {
  position: relative;
  max-width: 332px;
  height: 114px;
}

.ReferFriendBackground {
  position: absolute;
  z-index: 0;
  border-radius: 5px;
  width: 100%;
  height: 114px;
}

.EarnBig {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 14px;
  padding-left: 14px;
  gap: 28px;
}

.EarnBigIcon {
  width: 86.28px;
  height: 91.55px;
}

.EarnBigText {
  color: $color-primary;
  font-weight: 600;
  font-size: 14px;
  width: 186px;
}

.EarnBigButton {
  border: 1px solid $color-primary;
  border-radius: 15px 15px 15px 0px;
  width: 163px;
  height: 42px;
  color: $color-primary;
  font-weight: 600;
  margin-top: 7px;

  &:hover {
    background-color: $color-primary;
    color: #fff;
  }
}

@media screen and (max-width: 576px) {
  .ReferFriend{
    margin: 0 auto;
  }
  .ReferFriendText {
    text-align: center;
  }
  
}
