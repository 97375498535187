@import "src/theme/variables.scss";

.housingHeading {
  width: 100%;
  margin-bottom: 20px;
  font-family: $fontfamily-primary;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: $color-primary;
  padding: 0 40px;
}

.housingSubHeading {
  font-family: $fontfamily-primary;
  text-align: left;
  color: $color-primary;
  padding: 0 40px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
}

.Icon {
  width: 28.1px;
  height: 25.8px;
  background-color: $color-primary;
}

.Frame {
  width: 100%;
  margin: 24px 0 0;
}

.Flex {
  display: flex;
  margin-bottom: 20px;
}

.ImgHousing {
  width: 46px;
  height: 47px;
  flex-grow: 0;
  object-fit: contain;
}

.FrameLeft {
  width: 20%;
  height: 125px;
  padding: 10px 15px 10px 15px;
}

.ProvidedHousing {
  width: 65px;
  height: 44px;
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: $color-primary;
}

.providedHousingText {
  width: 80%;
  margin: 20px;
  font-family: $fontfamily-primary;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  text-align: left;
  color: $color-primary;
}

.FrameHousingImg {
  width: 65px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .housingHeading {
    width: 100%;
    margin: 20px 0 0px 0;
    font-family: $fontfamily-primary;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
    padding: 0 5%;
  }

  .Flex {
    margin-bottom: 0px;
  }

  .housingSubHeading {
    font-size: 16px;
    line-height: 1.22;
    padding: 0;
  }

  .Frame {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    margin: 45px 5% 0 5%;
    padding: 0;
  }

  .Flex {
    display: block;
  }

  .providedHousingText {
    width: 300px;
    height: 252px;
    flex-grow: 0;
    font-family: $fontfamily-primary;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
  }
  
  .FrameLeft {
    width: 40%;
    height: 125px;
    flex-grow: 0;
    padding: 14px 0 0;
    margin-right: 30%;
    margin-left: 30%;
    margin-top: -5px;
    margin-bottom: -5px;
  }

  .FrameHousingImg {
    width: 65px;
    height: 97px;
    align-items: center;
    gap: 6px;
    margin: 0 auto;
    padding: 0;
  }
}
