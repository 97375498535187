@import "src/theme/variables.scss";

.main {
  background-color: white;
  padding: 20px;
  flex: 1;
}

.chips {
  margin-bottom: 16px;
  display: flex;
  gap: 10px;
}

.locationAndSecondaryInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.cityState {
  color: $color-primary;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;

  svg {
    font-size: 15px;
    margin-right: 5px;
  }
}

.facilityName {
  color: $color-primary;
  font-size: 12px;
  font-weight: 700;
  height: 2.4em;
  line-height: 1.2em;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.secondaryInfo {
  margin-left: 10px;
}

.salary {
  color: $color-primary;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 25px;
  display: flex;
  align-items: center;

  svg {
    color: #656E7E;
    font-size: 15px;
    margin-left: 5px;
  }
}

.professionAndSpecialty {
  color: #656E7E;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1;
  text-overflow: ellipsis;
  max-width: 300px;
}

.extraInfo {
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    color: #656E7E;
    font-size: 12px;
    font-weight: 600;
  }

  svg {
    font-size: 15px;
    margin-right: 2px;
  }
}

.viewsContainer {
  background-color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  border-radius: 10px;
  text-wrap: nowrap;
  white-space: nowrap;

  svg {
    color: white;
    font-size: 10px;
    margin-right: 2px;
  }
}

.compact {
  padding: 10px;

  .locationAndSecondaryInfo {
    margin-bottom: 0;
  }

  .cityState {
    font-size: 12px;

    svg {
      font-size: 12px;
      margin-right: 2px;
    }
  }

  .facilityName {
    font-size: 12px;
  }

  .salary {
    font-size: 12px;
    margin-bottom: 5px;

    svg {
      font-size: 12px;
      margin-left: 2px;
    }
  }

  .professionAndSpecialty {
    margin-bottom: 0;
    font-size: 10px;
  }

  .extraInfo {
    display: none;
  }
}

@include breakpoint("sm") {
  .cityState {
    font-size: 20px;

    svg {
      font-size: 20px;
      color: $color-primary;
    }
  }

  .facilityName {
    color:$color-primary;
    font-size: 16px;
  }

  .salary {
    font-weight: 700;

    svg {
      font-size: 20px;
      color: #D6D8DC;
    }
  }

  .professionAndSpecialty {
    font-size: 14px;
    max-width: 300px;
  }

  .compact {
    .professionAndSpecialty {
      max-width: 250px;
    }
  }

  .extraInfo {
    div {
      font-weight: 700;
    }
  }
}

@include breakpoint("md") {
  .professionAndSpecialty {
    max-width: 300px;
  }

  .compact {
    .professionAndSpecialty {
      max-width: 250px;
    }
  }
}

@include breakpoint("lg") {
  .professionAndSpecialty {
    max-width: 275px;
  }
}

@include breakpoint("xl") {
  .professionAndSpecialty {
    max-width: 300px;
    height: 21px;
  }

  .compact {
    .professionAndSpecialty {
      max-width: 300px;
    }
  }
}