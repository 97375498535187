@import "src/theme/variables.scss";

.main {
  padding: 20px;
  background-color: lightgray;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.compactProgressContainer {
  max-width: 350px;
  margin: 0 auto;
}

.progressContainer {
  display: none;
}

.greeting {
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.completeButton {
  button {
    background-color: white;
    color: $color-primary;
    border: none;
    padding: 12px 20px;
    width: 100%;
    border-radius: 15px 15px 15px 0px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.greetingAndCurrentProgress {
  width: 100%;
}

.stepper {
  display: flex;
  margin-bottom: 10px;
}

.step {
  position: relative;
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 25px;
  background-color: #ECF1F8;
}

.step:hover {
  background-color: #D4D9DF;
  text-decoration: none;
}

.skeletonStep:hover {
  background-color: #ECF1F8;
}

.stepComplete:hover {
  background-color: #39CD7E;
}

.step:not(:last-child) {
  margin-right: 10px;
}

.step:last-child:hover {
  background-color: #ECF1F8;
}

.step:not(:last-child)::after {
  background-color: white;
  content: "";
  position: absolute;
  right: -10px;
  top: 50%;
  width: 10px;
  height: 1px;
  transform: translateY(-50%);
}

.stepIcon {
  svg {
    color: $color-primary;
  }
}

.stepName {
  color: $color-primary;
  font-size: 12px;
  font-weight: 700;
  text-wrap: nowrap;
}

.stepEstimatedTime {
  color: #989898;
  font-size: 10px;
  font-style: italic;
  text-wrap: nowrap;
}

.stepComplete {
  background-color: #39CD7E;

  .stepIcon {
    svg {
      color: white;
    }
  }

  .stepName {
    color: white;
  }

  .stepEstimatedTime {
    display: none;
  }
}

.pickupWhereYouLeftOff {
  a {
    color: white;
    font-size: 12px;
    text-decoration-line: underline;
  }
}

.quickApplyContainer {
  margin-top: 30px;

  .regular {
    display: none;
  }
}

.quickApplyTitle {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;
}

.jobCardList {
  display: none;
}

@include breakpoint("sm") {
  .jobSlider {
    display: none;
  }

  .jobCardList {
    display: initial;
  }
}

@include breakpoint("md") {
  .main {
    padding: 30px;
  }
}

@include breakpoint("lg") {
  .main {
    padding: 50px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .compactProgressContainer {
    display: none;
  }

  .progressContainer {
    display: flex;
  }

  .progressBar {
    width: 150px;
    margin-right: 10px;
  }

  .greeting {
    text-align: left;
    margin-bottom: 10px;
  }

  .quickApplyContainer {
    .compact {
      display: none;
    }

    .regular {
      display: flex;
    }
  }
}

@include breakpoint("xl") {
  .main {
    padding: 50px 100px;
  }

  .sub {
    // max-width: 1188px;
  }

  .step {
    padding: 12px 24px;
  }

  .iconOnlyStep {
    padding: 12px;
  }
}