.NotificationBox {
  color: black;
  background-color: white;
  padding: 10px;
  background-color: #f5f5f5;
}

.MessageList {
  overflow-y: scroll;
  min-height: 95px;
  max-height: 400px;
}
