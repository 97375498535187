@import "src/theme/variables.scss";

.jobCardListSub {
  width: 100%;
}

@include breakpoint("sm") {
  .quickApplyContainer {
    .jobCardListSub {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include breakpoint("md") {
  .quickApplyContainer {
    .jobCardListSub {
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
  }
}

@include breakpoint("lg") {
  .quickApplyContainer {
    .jobCardListSub {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}