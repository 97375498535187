.payrollNavbarV2 {
  display: inline;
}

.payrollNav .navbar-expand .navbar-nav {
  flex-direction: column !important;
  margin-top: 1.2rem;
}
.payrollNavWrap {
  position: relative !important;
  background-color: #f0f1f4 !important;
  z-index: 99;
  width: 20% !important;
  display: inline-flex !important;
  height: 100% !important;
}
.payRollNavMargin .payrollNav {
  position: relative !important;
  background-color: #F9F9F9 !important;
  margin-bottom: -7px;
}
.noPaddingMob {
  padding: 3rem 1.5rem 1rem 2rem;
}
.mainPaystub {
  background-color: #fff !important;
  width: 80% !important;
  display: inline-flex !important;
  margin-bottom: 2rem;
  padding: 2rem 1rem !important;
  vertical-align: top;
}
.mainPaystub .titlepaystub {
  margin-top: 0px !important;
  padding: 0rem 0rem 0.5rem 0rem !important;
}

.mainPaystub .jumbotron {
  padding: 10px;
  margin-top: 0 !important;
}
.mainPaystub .payDeduction {
  height: auto;
}
.payRollNavMargin {
  margin-top: 0rem !important;
  background-color: white;
  min-height: 500px;
  margin-bottom: 2rem;
  width: 100%;
}
.payRowClick h6 {
  margin-top: 2rem !important;
}
.payrollNavbarV2 .nav-item a:active,
.payrollNavbarV2 .nav-item a.active {
  background-color: $color-primary !important;
  color: #fff !important;
  border-radius: 30px !important;
  justify-content: left !important;
  padding: 15px 25px !important;
  width: 100%;
  margin: 1.2rem 0rem 0rem 0rem;
}
.payrollNavbarV2 .nav-item a:active:hover,
.payrollNavbarV2 .nav-item a.active:hover {
  background-color: $color-primary !important;
  color: #fff !important;
}
.payrollNavbarV2 .nav-item a {
  background-color: transparent !important;
  color: $color-primary !important;
  border-radius: 50px !important;
  justify-content: left !important;
  padding: 15px 25px !important;
  width: 100% !important;
  margin: 1.2rem 0rem 0rem 0rem;
}
.payrollNavbarV2 .nav-item a:hover {
  background-color: #ecedf2 !important;
  box-shadow: none !important;
}
.directDeposit {
  width: 32% !important;
}
#payStubDetailMargin {
  margin-top: 0px !important;
}
.latestPay {
  padding: 10px 0px 10px 0px !important;
}
.deductionTblMrgin {
  margin: 20px 0px 0px 0px;
  box-shadow: 0px 1px 3px #3f3f4426 !important;
}
.hospTimesheet {
  min-height: 150px;
  height: auto !important;
  padding: 15px 10px 10px 10px !important;
}
.payrollNoticeWrap {
  padding: 10px 0px;
  display: flex;
}
.payDeductionNoRec {
  box-shadow: 0px 1px 3px #3f3f4426;
  margin-top: 1rem;
}
.timeValue {
  padding-top: 4px;
}
.payrollTab {
  display: flex;
  align-items: center;
  font-weight: 500 !important;
}
.payrollTab p {
  margin: 0 0 0 7px;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .mainPaystub {
    width: 100% !important;
    padding: 1rem 0rem !important;
  }
  .payrollNoticeWrap {
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
  }
  .directDeposit {
    width: 100% !important;
    margin: 10px 0px !important;
  }
}