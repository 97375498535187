@import "src/theme/variables.scss";

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
}
.content {
  width: 808px;
  min-height: 350px;
  height: 90%;
  background-color: #ffffff;
  border-radius: 20px;
}

.headeContent {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 20px 20px 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading {
  font-size: 1.25rem;
  color: $color-primary;
  font-weight: 700;
}
.closeIconContainer {
  position: absolute;
  right: 3%;
}

.mainContent {
  width: 100%;
  padding: 10px 20px 20px 20px;
  height: 90%;
  overflow-y: auto;
}

.videoContent {
  width: 100%;
  height: 40%;
}

.video {
  width: inherit;
  height: 100%;
  border-radius: 20px;
}

.facilityDetails {
  width: 100%;
  color: $color-primary;
  margin-top: 10px;
  height: 40%;
}

.facilityName {
  font-size: 1.25rem;
  font-weight: 700;
}
.facilityLocation p {
  line-height: 10px ;
}
.facilityLocation span {
  font-size: 1.125rem;
  font-weight: 600;
}

.facilityContent {
  font-size: 0.875rem;
  font-weight: 400;
}
.facilityContent p {
  line-height: 20px ;
}

.socailLinksContainer {
  width: 100%;
  height: 20%;
  border-radius: 20px;
  margin-top: 20px;
  padding: 10px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
}

.socialLinkBox {
  width: 31.33%;
  background-color: rgba(240, 241, 244, 0.5);
  border-radius: 10px;
  padding-left: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.socialLinkBox span {
  color: $color-primary;
  font-size: 0.875rem;
  font-weight: 600;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.socialLinkBox img {
  width: 25px;
  height: 25px;
}

//  Mobileview
@media screen and (max-width: 760px) and (min-width: 0px) {
  .container {
    position: relative;
  }
  .headeContent{
    padding: 5px;
  }
  .content {
    position: absolute;
    bottom: 0%;
    width: 100%;
    height: 90%;
    border-radius: 20px 20px 0px 0px;
  }
  .mainContent{
    padding: 0px 20px 0px 20px;
    height: 95%;
    position: relative;
  }
  .videoContent {
    height: 30%;
  }
  .facilityDetails{
    height: 20%;
  }
  .socailLinksContainer{
   position: absolute;
   bottom: 0%;
   height: 45%;
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 0px 0px 20px 0px;
  }
  .socialLinkBox{
    width: 90%;
    height: 31.33%;
    flex-grow: 1;
    padding-left: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .socialLinkBox img {
    margin-left: 5%;
  }
}
//  Mobileview with smaller height
@media screen and (max-width: 780px) and (max-height: 1000px) {

  .facilityName{
    font-size: 1.125rem;
    line-height: 18px ;
  }
  .facilityLocation p {
    line-height: 7px ;
  }
  .facilityLocation span {
    font-size: 0.9375rem;
    font-weight: 600;
  }
  
  .facilityContent {
    font-size: 0.75rem;
    font-weight: 400;
  }
  .facilityContent p {
    line-height: 15px ;
  }
  .socialLinkBox span {
    font-size: 0.75rem;
    font-weight: 600;
    margin-left: 10px;
  }
  .socialLinkBox:first-child > div > span {
    font-size: 0.6875rem;
  }
}

@media screen and (max-height: 600px) and (max-width: 700px) {
  .content {
    width: 100%;
  }
  .socailLinksContainer {
    height: 40%;
  }
  .heading {
    font-size: 1.125rem;
  }
  .headeContent {
    padding-top: 10px;
  }
  .facilityName{
    font-size: 0.875rem;
    line-height: 18px;
  }
  .facilityLocation p {
    line-height: 7px;
    margin-bottom: 0px;
  }
  .facilityLocation span {
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 7px;
    margin-bottom: 0px;
  }
  
  .facilityContent {
    font-size: 0.75rem;
    font-weight: 400;
  }
  .facilityContent p {
    line-height: 11px;
  }
  .socialLinkBox span {
    font-size: 0.6875rem;
    font-weight: 600;
    margin-left: 10px;
  }
  .socialLinkBox:first-child > div > span {
    font-size: 0.5625rem;
  }
}
// Borswer zoom-in view
@media screen and (min-width: 1200px) and (max-height: 780px) {

  .headeContent {
    padding: 2px;
  }
  .facilityName {
    font-size: 0.9375rem;
    line-height: 13px ;
  }
  .facilityLocation p {
    line-height: 5px ;
  }
  .facilityLocation span {
    font-size: 0.875rem;
    font-weight: 600;
  }
  .facilityContent {
    font-size: 0.8125rem;
    font-weight: 300;
  }
  .facilityContent p {
    line-height: 11px ;
  }
  .socialLinkBox span {
    font-size: 0.6875rem;
    font-weight: 600;
    margin-left: 10px;
  }
  .socialLinkBox:first-child > div > span {
    font-size: 0.6875rem;
  }
}

// Borswer zoom-in view
@media screen and (min-width: 1000px) and (max-height: 600px) {
  .headeContent {
    padding: 2px;
  }
  .heading {
    font-size: 0.875rem;
  }
  .mainContent {
    padding: 0px 20px 0px 20px;
  }
  .facilityName {
    font-size: 0.8125rem;
    line-height: 13px ;
  }
  .facilityLocation p {
    line-height: 5px ;
  }
  .facilityLocation span {
    font-size: 0.875rem;
    font-weight: 600;
  }
  .facilityContent {
    font-size: 0.8125rem;
    font-weight: 300;
  }
  .facilityContent p {
    line-height: 11px ;
  }
  .socialLinkBox span {
    font-size: 0.5rem;
    font-weight: 600;
    margin-left: 10px;
  }
  .socialLinkBox:first-child > div > span {
    font-size: 0.5rem;
  }
}
