@import "src/theme/variables.scss";

.LoginFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: $color-primary;
}

@media screen and (max-width: 768px) {
  .LoginFooter {
    position: relative;
  }
}
