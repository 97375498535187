@import "src/theme/variables.scss";

.benefitsV2 .beneInfoImage {
  height: 4rem;
  margin-top: 25px;
}
.benefitsV2 .benefitHead,
.benefitsV2 .benefitSubHeads,
.benefitsV2 .beneAboutText {
  color: $color-primary-dark;
}
.benefitsLearnMore {
  color: $color-primary;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.benefitsLearnMore:hover {
  color: $color-secondary;
}
.backToBenefits {
  color: $color-primary;
  text-decoration: underline;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
  padding: 20px 0px 0px 75px;
  width: max-content;
}
.backToBenefits:hover {
  color: $color-secondary;
}
.benefitsV2 .benefitsBanner {
  margin-top: 6rem;
}
.benefitsV2 .benefitWrapper {
  font-family: $fontfamily-primary !important;
}
.enrollmentBanner {
  margin-top: 2rem !important;
}
.marginEnrollment {
  margin: 15px 0;
  padding: 0px 75px;
}
.openEnrollmentBenefits {
  display: flex;
  padding: 26px 156px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--bg-Primary, #F0F1F4);
}

.openEnrollmentHeading {
  color: var(--primary-main, $color-primary);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 9px 20px 3px 0px;
}

.learnMoreButton {
  background: $color-secondary !important;
  display: inline-flex;
  padding: 12px 20px !important;
  justify-content: center;
  align-items: flex-end;
  gap: 9px;
  cursor: pointer;
  box-shadow: none;
  color: #FFF !important;
  text-align: center;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 100% !important;
  border-radius: 15px 15px 15px 0px !important;
}

.learnMoreButton:hover {
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.LearnMoreLink {
  margin-top: 0px;
}


@media screen and (min-width: 0px) and (max-width: 768px) {
  .benefitsV2 .benefitsBanner {
    margin-top: 4rem;
  }
  .benefitsV2 .benefitgplay {
    text-align: center !important;
  }
  .benefitsAppGpay {
    text-align: center !important;
  }
  .backToBenefits {
    padding: 20px 0px 10px 0px;
  }
  .enrollmentBanner {
    margin-top: 0rem !important;
  }
  .marginEnrollment {
    margin: 0;
    padding: 0;
  }
  .enrollmentvideo {
    margin-top: 0;
  }
  .openEnrollmentBenefits{
    padding: 15px 0px;
  }
  .openEnrollmentHeading {
    padding: 5px 10px 0px 5px;
    width: 205px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
  .LearnMoreLink{
    padding: 20px 5px 0px 5px;
  }

}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .benefitsV2 .benefitsBanner {
    margin-top: 6rem;
  }
  .benefitsV2 .benefitsBannerText {
    padding-top: 6rem;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
}
