@import "src/theme/variables.scss";

.fontStyle {
    font-family: "Nunito Sans";
}

.LoginFooter {
    position: absolute;
    width: 100%;
    z-index: 9;
    bottom: 0;
    background-color: $color-primary;
}

.heroText {
    color: var(--Primary-Primary-900-Main, #036);
    font-family: "Nunito Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 20px 0px;
    text-align: center;
}

.heroTextWidth {
    margin: 20px !important;
}

.resendCode {
    color: var(--Primary-Primary-900-Main, #036);
    font-weight: 700;
    font-size: 14px;
}

.infoText {
    color: var(--Grays-Gray-600-Primary, #656E7E);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.encodedEmail {
    color: var(--Grays-Gray-600-Primary, #656E7E);
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.userSpinner {
    height: 120px !important;
    width: 120px !important;
    margin: auto;
    content: url("~src/imagesV2/spinner.gif");    
    margin-top: 40px;
}

.otpText {
    display: flex;
    height: 56px;
    padding: 4px 0px 4px 16px;
    align-items: center;
    flex-shrink: 0;
}

.mt20px {
    margin-top: 20px;
}

.mb40px {
    margin-bottom: 40px;
}

.useMyPasswordInstead {
    color: var(--Tertiary-Main, #656E7E);
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.signInBtn {
    color: #ffffff;
    line-height: 20.8px;
    font-size: 16px;
    font-weight: 700;
}

.backButton {
    width: 85px;
    height: 45px;
    padding: 12px 20px 12px 20px;
    border-radius: 15px 15px 15px 0px;
    font-family: "Nunito Sans";
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20.8px;
    text-align: center;
    color: var(--Primary-Primary-900-Main, #036);
}

.fieldMessage {
    font-family: "Nunito Sans";
    font-size: 42px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.haveNAttemptsLeft {
    text-align: center;
    font-weight: 500;
    color: #656E7E;
}

@media screen and (max-width: 768px) {
    .LoginFooter {
        position: relative;
    }

    .heroTextAccountBlocked {
        text-align: center;
        width: 300px !important;
    }

    .haveNAttemptsLeft {
        text-align: center;
        margin: 0px 20px;
        color: #656E7E;
    }
}