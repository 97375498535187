@import "src/theme/variables.scss";

.loginbackgroundimageV2 {
  background-image: url("../images/GlobalBackground.jpg") !important;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.loginV2 {
  height: 100vh;
  margin-top: -100px;
  padding-top: 100px;
  background-image: url("../images/GlobalBackground.jpg") !important;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: scroll;
}

.fullCoverBackground {
  height: auto;
  min-height: 100vh;
  padding-top: 1px;
  background-image: url("../images/GlobalBackground.jpg") !important;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  .loginV2 {
    height: 103.5vh;
    padding-top: 70px;
  }
}

.loginV2 .signinLogo,
.forgotPassMain .signinLogo,
.signinLogoPassRecov {
  background-repeat: no-repeat;
  background-position: center;
  width: 140px;
  height: 45px;
  margin: 20px auto;
  background-size: 100%;
}
.loginV2 .genericLogo,
.forgotPassMain .genericLogo,
.genericLogoPassRecov {
  background-repeat: no-repeat;
  background-position: center;
  width: 140px;
  height: 45px;
  margin: 20px auto;
  background-size: 100%;
}
.loginV2 .signInButton,
.passwordRecovery .signInButton,
.forgotPassMain .signInButton {
  background-color: $color-primary !important;
  border-radius: 10px 10px 10px 0 !important;
  height: 45px;
  padding: 12px 20px !important;
  font-size: 16px;
  white-space: nowrap;
}

.backButton {
  width: 85px;
  height: 45px;
  padding: 12px 20px 12px 20px;
  border-radius: 15px 15px 15px 0px;
  font-family: "Nunito Sans";
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 20.8px;
  text-align: center;
  color: var(--Primary-Primary-900-Main, #036);
}

.continueToSignInWidth {
    width: 213px;
}

.continueToSignInWidth:disabled {
  width: 245px;
}

.signInButton .MuiButton-label {
  letter-spacing: 0.02em;
}
.loginV2 .signInButton:hover,
.passwordRecovery .signInButton:hover,
.forgotPassMain .signInButton:hover {
  background-color: $color-primary-500 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.loginV2 .signUpLabel,
.forgotPassMain .signUpLabel {
  color: #373c73 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-left: 0px !important;
}
.loginV2 .signUpLabel:hover,
.forgotPassMain .signUpLabel:hover {
  opacity: 0.6;
  text-decoration: underline !important;
}
.loginV2 .signInBtnAlign,
.forgotPassMain .signInBtnAlign,
.passwordRecovery .signInBtnAlign {
  display: block;
  text-align: center;
  color: #848484 !important;
  font-size: 0.65rem;
}
.passwordRecovery .saveBtn {
  display: block;
  text-align: center;
  color: #848484 !important;
  font-size: 0.65rem;
}
.loginV2 .loginHeight {
  height: 14vh;
}
.loginV2 .needHelpTxt {
  font-size: 0.7rem !important;
  text-decoration: none !important;
  text-align: center !important;
  width: max-content !important;
  margin: 15px 0px 10px 0px;
  color: #00000099 !important;
  font-weight: 500;
  margin: 7px auto 10px;
}
.loginV2 .needHelpTxt:hover {
  color: #00000099 !important;
  text-decoration: underline !important;
}
.loginV2 .rightsigninform,
.forgotPassMain .rightsigninform,
.passwordRecovery .rightsigninform {
  background: #fff !important;
  box-shadow: 0px 0px 6px #00000012;
  border: 1px solid #e1e1e1;
  border-radius: 40px 40px 40px 0;
  padding: 0px 30px;
  min-height: 300px;
}
.loginV2 .signintextfiled,
.errorMsgSignUp,
.helpSignInField {
  width: 100%;
  margin-top: 20px !important;
}
.loginV2 .signintextfiled .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
.errorMsgSignUp .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
.helpSignInField .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-primary;
}
.helpSignInField .Mui-disabled:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #00000038;
}
.helpSignInField .MuiInputBase-root.Mui-disabled:hover {
  pointer-events: none !important;
  border-color: #00000038;
}
.loginV2 .MuiFormLabel-root,
.forgotPassMain .MuiFormLabel-root,
.passwordRecovery .MuiFormLabel-root {
  font-size: 1rem;
}

.loginV2 .forgotpass,
.forgotPassMain .forgotpass {
  font-weight: normal;
  text-align: center;
}
.loginV2 .HelpSignIn {
  margin-top: 25px;
}
.loginV2 .forgotpass {
  margin-bottom: 20px;
}
.rightsigninform .HelpSignIn .recoverTxt {
  margin-bottom: 15px;
}
.loginV2 .recoverTxt,
.forgotPassMain .recoverTxt {
  font-weight: 500;
  color: #626c7d;
  font-size: 0.75rem;
  padding-bottom: 0px;
  margin: 0 auto;
}

.loginV2 .goBackToSign,
.loginV2 .backArrowSignIn,
.forgotPassMain .backArrowSignIn {
  color: #373c73 !important;
  margin-right: 4px;
  font-weight: 500;
}
.loginV2 .signUpLabel a:hover {
  color: #373c73 !important;
}
.loginFormV2 {
  position: relative;
  width: 40vw;
  right: -12%;
  padding: 20px;
}
.desktopQRBanner {
  position: fixed;
  left: 10%;
  padding: 20px;
}
.mobileAppImgWrap {
  width: 120px;
  height: 120px;
  background-color: #fff;
  margin-top: 25px;
  border-radius: 8px;
}
.mobileAppBannerLogo {
  width: 84%;
  margin: 10px 10px;
}
.QRBannerText {
  color: #ffffff;
  font-size: 1.3rem;
  padding-left: 30px;
}
.mobileAppImage {
  width: 185px;
}
.loginV2 .signUpNote {
  text-align: center;
  color: #888888;
  font-size: 0.7rem;
  margin-top: -10px;
}
.loginV2 .helpSignInField,
.forgotPassMain .helpSignInField {
  margin-bottom: 20px !important;
}
.signUpFormV2 {
  margin-top: 4rem;
}
.signUpFormV2 .signInBtnAlign {
  margin-top: 5px;
  margin-bottom: 15px;
}

.loginV2 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.forgotPassMain .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.passwordRecovery .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-primary;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline,
.passwordRecovery .Mui-error .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $color-secondary !important;
}
.loginV2 .MuiFormLabel-root.Mui-focused,
.forgotPassMain .MuiFormLabel-root.Mui-focused,
.passwordRecovery .MuiFormLabel-root.Mui-focused {
  color: $color-primary;
}
.signUpFormV2 .MuiInputBase-input:-webkit-autofill,
.signUpFormV2 .MuiInputBase-input:-webkit-autofill:active,
.signUpFormV2 .MuiInputBase-input:-webkit-autofill:focus,
.signUpFormV2 .MuiInputBase-input:-webkit-autofill:hover,
.loginFormV2 .MuiInputBase-input:-webkit-autofill,
.loginForm .MuiInputBase-input:-webkit-autofill:active,
.loginForm .MuiInputBase-input:-webkit-autofill:focus,
.loginForm .MuiInputBase-input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: $color-primary !important;
}
.loginFormV2 .attemptsLeft {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 5px;
}
.loginFormV2 .signintextfiled {
  width: 100% !important;
}
.loginFormV2 .signintextfiled .MuiInput-underline:after,
.loginFormV2 .helpSignInDefault .MuiInput-underline:after,
.loginFormV2 .signuptextfiled .MuiInput-underline:after {
  border-bottom: 2px solid $color-secondary !important;
}
.helpsignBtmTxt {
  text-align: left !important;
  margin-bottom: 2rem;
}
.loginFormV2 .secondaryBtn {
  background-color: $color-primary !important;
  border-radius: 10px 10px 10px 0 !important;
}
.loginFormV2 .secondaryBtn:hover {
  background-color: $color-primary-500 !important;
  border-radius: 10px 10px 10px 0 !important;
}
.loginFormV2 .helpSignInButtonDisabled:disabled {
  background-color: #e1e1e1 !important;
  color: #888888 !important;
}
.loginFormV2 .goBackLabel {
  padding-right: 0px !important;
}
.loginFormV2 .MuiFormLabel-root.Mui-error {
  color: $color-error !important;
}
.loginFormV2 .helpSignInEmailSent .MuiInput-underline:before {
  border-bottom: 2px solid $color-success !important;
}
.loginFormV2 a.loginHelpEmail,
a.loginHelpEmail:hover {
  color: $color-primary;
}
.loginFormV2 a.loginHelpEmail,
a.loginHelpEmail:hover {
  color: $color-primary !important;
}
.forgotPassTxt {
  font-size: 0.7rem;
  color: #848484;
}
.signUpFormV2 .MuiFormLabel-root.Mui-focused {
  color: $color-secondary;
}
.forgotPassMain .goBackToSign {
  vertical-align: middle;
  color: $color-primary !important;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.forgotPassMain {
  margin-top: 15rem;
}
.passwordRecovery .pswResetText {
  font-weight: 700;
  padding-top: 10px;
  margin-bottom: 0px;
  text-align: center;
  color: $color-primary !important;
}
.passwordRecovery {
  margin-top: 14rem;
}
.passwordRecoveryTop {
  margin-top: 10rem;
}
.duplicateLoginMsgV2 {
  color: $color-primary;
  font-weight: bold;
  text-align: center;
  font-size: 0.8rem;
  padding: 20px 0px;
}
.loginForm .MuiInputLabel-formControl,
.loginV2 .MuiInputLabel-formControl,
.loginFormV2 .MuiInputLabel-formControl {
  font-size: 1rem !important;
  background-color: #ffffff;
  padding: 0 7px 0 2px !important;
}

.otpSignInText {
  display: block;
  width: 100%;
  color: #656E7E;
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
  margin-top: 40px;
}

.continueSignIn {
  margin-top: -5px !important;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .loginbackgroundimageV2 {
    background-position: initial;
    background-image: url("../images/GlobalBackground.jpg") !important;
    background-repeat: no-repeat;
    background-color: #000;
  }
  .loginFormV2 {
    margin-right: 5px !important;
    margin-left: 5px !important;
    width: 100%;
    right: 2%;
    padding: 0px;
    top: -3rem;
    position: relative;
  }
  .loginFormMarginMobile {
    top: -6rem;
  }
  .signUpFormV2 {
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 0px;
  }
  .signUpFormV2 .rightsigninform {
    padding: 0px 5px;
  }
  .loginV2 .signinLogo {
    width: 140px;
    height: 70px;
    margin: 15px auto 0px;
    background-size: contain;
  }
  .loginV2 .genericLogo {
    width: 140px;
    height: 70px;
    margin: 15px auto 0px;
    background-size: contain;
  }
  .loginV2 .signintextfiled,
  .helpSignInField {
    margin-top: 40px !important;
  }
  .loginV2 .loginHeight {
    height: 9vh;
  }
  .loginV2 .needHelpTxt {
    margin: 0 auto 10px;
  }
  .loginV2 .rightsigninform {
    padding: 0px 20px;
  }
  .goBackLabel {
    padding-right: 0px !important;
  }
  .loginFormV2 .secondaryBtn {
    background-color: $color-primary !important;
    border-radius: 10px 10px 10px 0 !important;
    width: 150px;
    height: 46px;
  }
  .loginFormV2 .secondaryBtn:hover {
    width: 150px;
    height: 46px;
  }
  .loginFormV2 .helpSignInButtonDisabled:disabled {
    min-width: 115px;
    width: max-content;
    height: 46px;
  }
  .loginV2 .signInButton:disabled {
    background-color: #e1e1e1 !important;
    color: #888888 !important;
    font-weight: 700 !important;
  }
  .loginV2 .forgotpass {
    font-size: 1.3rem;
  }
  .loginV2 .recoverTxt {
    font-weight: 700;
  }
  .forgotPassMain {
    margin-top: 11rem;
  }
  .passwordRecovery {
    margin-top: 10rem;
  }
  .passwordRecoveryTop {
    margin-top: 10rem;
  }
  .signInButton:disabled {
    width: 140px !important;
  }
  .duplicateLoginMsgV2 {
    font-size: 0.8rem !important;
  }
  .signinLogoPassRecov {
    background-size: 100% !important;
    height: 45px !important;
  }
  .genericLogoPassRecov {
    background-size: 100% !important;
    height: 45px !important;
  }
  .desktopQRBanner {
    display: none;
  }
  .continueToSignInWidth:disabled {
    width: 245px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .loginV2 .loginHeight {
    height: 0vh;
  }
  .loginV2 .signinLogo {
    margin: 5px auto;
  }
  .loginV2 .genericLogo {
    margin: 5px auto;
  }
  .loginV2 .signintextfiled,
  .loginV2 .helpSignInField,
  .forgotPassMain .helpSignInField {
    margin-top: 20px !important;
  }
  .loginV2 .rightsigninform {
    padding: 0px 25px;
  }
  .loginV2 .recoverTxt,
  .forgotPassMain .recoverTxt {
    font-size: 0.65rem;
  }
  .loginFormV2 {
    width: 50vw;
  }

  .loginV2 .signUpNote {
    font-size: 0.7rem;
  }
  .signUpFormV2 {
    margin-top: -53px;
  }

  .passwordRecovery {
    margin-top: 1rem;
  }
  .passwordRecoveryTop {
    margin-top: 1rem;
  }
  .forgotPassMain {
    margin-top: 5rem;
  }
  .duplicateLoginMsgV2 {
    font-size: 0.7rem !important;
    margin-bottom: -30px !important;
  }
  .logoPassRecovMargin {
    margin: 10px auto 0 auto;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .loginV2 .loginHeight {
    height: 7vh;
  }
  .loginV2 .signintextfiled,
  .helpSignInField {
    width: 100% !important;
    margin-top: 40px !important;
  }
  .loginFormV2 {
    width: 46vw;
  }
  .signUpFormV2 {
    margin-top: -2px;
  }
  .loginV2 .signInBtnAlign {
    font-size: 0.69rem;
  }
  .passwordRecovery {
    margin-top: 9rem;
  }
  .passwordRecoveryTop {
    margin-top: 4rem;
  }
  .forgotPassMain {
    margin-top: 10rem;
  }
}
