@import "src/theme/variables.scss";

.jobCardInfoToolTip {
  min-height: initial !important;
  width: initial !important;
  display: initial !important;
  text-align: initial !important;
}

.payRateSvg {
  height: 20px !important;
  width: 20px !important;
}

.compact {
  .payRateSvg {
    height: 15px !important;
    width: 15px !important;
  }
}