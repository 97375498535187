@import "src/theme/variables.scss";

.card {
  margin: 0 10px;
}

.firstCard {
  margin: 0 10px 0 0;
}

.lastCard {
  margin: 0 0 0 10px;
}