@import "src/theme/variables.scss";

.main {
  padding: 30px 20px;
}

.sub {
  width: 100%;
}

.heading {
  margin-bottom: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: $color-primary;
  text-align: center;

  span {
    margin: auto;
  }
}

@include breakpoint("md") {
  .main {
    padding: 30px;
  }

  .sub {
    min-width: 710px;
    max-width: 1080px;
  }
}

@include breakpoint("lg") {
  .main {
    padding: 30px 30px;
  }

  .sub {
    max-width: 1080px;
  }

  .heading {
    text-align: left;

    span {
      margin: initial;
    }
  }
}

@include breakpoint("xl") {
  .main {
    padding: 30px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub {
    max-width: 1590px;
  }
}