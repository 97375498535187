.skeletonMain .MuiCard-root {
  margin: 16px 0px !important;
  max-width: 100% !important;
  border-radius: 30px 30px 30px 0;
}
.imgSkeleton {
  height: 145px !important;
}
.skeletonTxtFormat {
  margin-bottom: 6px !important;
  height: 20px !important;
  border-radius: 8px !important;
}
.skeletonSubTxtFormat {
  height: 20px !important;
  width: 75% !important;
  border-radius: 8px !important;
}
.skeletonBtn {
  height: 75px !important;
  margin: 0;
  top: 12px;
}
.skeletonMain .MuiCardContent-root:last-child {
  padding-bottom: 10px;
}
.savedSearchSkeleton{
  width: 336px !important;
  height: 350px !important;
  border-radius: 15px 15px 15px 0 !important;
}
.sortSkeleton {
  width: 100px;
  height: 20px !important;
  margin-left: 5rem !important;
  border-radius: 8px !important;
}
.filterSkeleton {
  width: 100px;
  height: 20px !important;
  border-radius: 8px !important;
  margin-bottom: 20px !important;
}
.myJobSekeleton {
  width: 170px;
  height: 20px !important;
  border-radius: 9px !important;
  border-radius: 8px !important;
}
.paginationSkeleton {
  width: 100px !important;
  height: 20px !important;
  border-radius: 8px !important;
}
.mapSkeleton {
  width: 100px !important;
  height: 20px !important;
  border-radius: 8px !important;
}
.paginaSkeletonBtm {
  width: 80px !important;
  border-radius: 8px !important;
  margin-left: 23rem !important;
  margin-top: 15px;
}
.paginationSkeletonBtm {
  width: 150px !important;
  border-radius: 8px !important;
  margin-left: 16rem !important;
  margin-top: 15px;
}
.pageWrapper .MuiSkeleton-root {
  background-color: rgba(0, 0, 0, 0.11) !important;
}
.pageWrapper .MuiSkeleton-wave::after {
  animation: MuiSkeleton-keyframes-wave 1.6s linear 3s infinite !important;
}
.cardSkeleton {
  width: 280px;
  margin: 10px;
}
.mainCarousel .cardSkeleton {
  width: 375px;
}
.mainFilter .cardSkeleton {
  width: 375px;
}
.skeletonMain .jobCardsFlexWrapSkeleton .cardSkeleton {
  margin-right: 14px !important;
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  .skeletonTxtFormat {
    width: 85% !important;
  }
  .skeletonSubTxtFormat {
    width: 65% !important;
  }
  .skeletonBtn {
    margin-bottom: 0px !important;
  }
  .paginationSkeletonBtm {
    margin-left: -7.7rem !important;
  }
  .paginaSkeletonBtm {
    margin-left: 1rem !important;
  }
  .filterSkeleton {
    width: 130px !important;
    height: 20px !important;
    margin-left: 3rem !important;
    border-radius: 8px !important;
    margin-top: 16px;
  }
  .jobCardsFlexWrapSkeleton div {
    width: 100%;
  }
  .jobCardsFlexWrapSkeleton .cardSkeleton,
  .skeletonMain .cardSkeleton {
    width: 100% !important;
    margin-right: 10px;
    margin-left: 10px;
  }
  .mainFilter .jobCardsFlexWrapSkeleton .cardSkeleton {
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .mainFilter .jobCardsFlexWrapSkeleton {
    justify-content: center;
    margin: 0px 14px;
  }
  .myJobsWrap .skeletonMain {
    padding-top: 2rem;
  }
  .skeletonMain div {
    display: flex;
  }
  .skeletonMain .jobCardsFlexWrapSkeleton div {
    display: block;
    .row {
      width: 100%;
      margin: 0;
      .col-lg-12 {
        padding: 0;
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .imgSkeleton {
    height: 110px !important;
  }
  .filterSkeleton {
    margin-left: 17rem !important;
  }
  .sortSkeleton {
    width: 100px !important;
    margin-left: 20rem !important;
  }
  .paginaSkeletonBtm {
    width: 50px !important;
    margin-left: 19rem !important;
    margin-top: 15px !important;
  }
  .paginationSkeletonBtm {
    width: 50px !important;
    margin-left: 7rem !important;
    margin-top: 15px !important;
  }
  .mapSkeleton {
    width: 50px !important;
    margin-left: 12rem !important;
  }
  .paginationSkeleton {
    width: 50px !important;
    margin-left: 7rem !important;
  }
  .skeletonTxtFormat,
  .skeletonSubTxtFormat {
    height: 15px !important;
  }
  .mainFilter .cardSkeleton {
    width: 280px;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .paginaSkeletonBtm {
    margin-left: 25rem !important;
  }
  .mapSkeleton,
  .paginationSkeleton {
    margin-left: 5rem !important;
  }
  .sortSkeleton {
    margin-left: 12rem !important;
  }
  .mainFilter .cardSkeleton {
    width: 350px;
    margin: 10px;
  }
}
