@import "src/theme/variables.scss";

.NoNotification {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .NoNotificationHeader {
    color: $color-primary;
    font-size: 16px;
    line-height: 21px;
  }
  .NoNotificationText {
    color: #707070;
    font-size: 14px;
    line-height: 19px;
  }
}
