.myJobMain {
  margin-top: 0rem !important;
  height: auto;
  padding-top: 0;
  padding-bottom: 20px;
}
.resultsSubWrap .myJobMain {
  padding-top: 24px;
}
.JobBannerSection {
  background: url(../imagesV2/Banner2x.webp);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0 90px 0;
  background-position-x: 70%;
  display: flex;
  justify-content: center;
  .jobSearchHeading {
    color: #fff;
  }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .JobBannerSection {
    background: url(../imagesV2/Banner-Mobile.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
  }
}

.myJobsWrap {
  background-color: #f0f1f4;
  padding-top: 2.75rem;
  padding-bottom: 3rem;
}
.mapViewV2 {
  background-color: #e9ecef;
  height: 60vh;
  width: 100%;
  margin: 12px -15px 25px -15px;
  padding: 0px;
  display: block;
}
.filterMapViewV2 {
  height: 60vh;
  width: 100%;
  margin: 12px -15px 25px -15px;
  padding: 0px;
  display: block;
}
.hidemapViewV2 {
  background-color: #e9ecef;
  height: 60vh;
  width: 100%;
  margin: -10px -15px 15px -15px;
  padding: 0px;
  display: none;
}

.filtersRowV2 {
  padding: 20px 0px 0px 0px;
  margin-bottom: 4px;
  margin: 0px;
  > div {
    width: 100%;
  }
}
.myJobsTitle {
  color: $color-primary;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.myJobChip,
.myJobChipSelected {
  margin-right: 22px !important;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  line-height: 22px;
  font-size: 1.125rem;
  padding: 21.5px 0;
}

.myJobsTxt {
  color: #626c7d !important;
  font-size: 0.7rem;
}

.myJobChip {
  color: $color-primary;
  &[tabindex="0"] {
    transform: translateY(2px);
    &::before {
      content: "";
      height: 15px;
      width: 16px;
      background: url("~src/imagesV2/heartIconOutlined.svg") center/contain no-repeat;
      margin-right: 10px;
    }
  }
}
.myJobChipSelected {
  color: $color-secondary;
  position: relative;
  &::after {
    content: "";
    height: 6px;
    width: 100%;
    border-radius: 10px;
    position: absolute;
    background: $color-primary;
    transform: translateY(30px);
  }
  &[tabindex="0"] {
    transform: translateY(2px);
    &::before {
      content: "";
      height: 16px;
      width: 15px;
      background: url("~src/imagesV2/heartIcon.svg") center/contain no-repeat;
      margin-right: 10px;
    }
  }
}

.mjchkboxAlign {
  margin-right: 10px !important;
  color: #626c7d !important;
  font-size: 0.9rem;
  display: inline-block;
  margin-top: -1px;
}
.myJobSortWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  width: 100%;
}
.mostRecentText,
.mostRelevantText {
  color: $color-primary !important;
  font-size: 0.7rem !important;
  font-weight: bold !important;
}
.myJobsSort .MuiSelect-select,
.myJobsSort .MuiSelect-select:focus,
.myJobsSort .MuiMenuItem-root {
  color: $color-primary !important;
  font-size: 0.7rem !important;
  font-weight: bold !important;
  border-radius: 4px;
  padding: 5px 30px 5px 15px !important;
  text-align: left;
}
.myJobsPagination .MuiSelect-select,
.myJobsPagination .MuiSelect-select:focus,
.myJobsPagination .MuiMenuItem-root {
  color: $color-primary !important;
  font-size: 0.7rem !important;
  font-weight: bold !important;
  border-radius: 4px;
  padding: 5px 42px 5px 17px !important;
  text-align: left;
}
.myJobsSort .MuiSelect-select.Mui-disabled,
.myJobsSort .MuiSelect-select.Mui-disabled:focus,
.myJobsSort .MuiMenuItem-root.Mui-disabled,
.myJobsPagination .MuiSelect-select.Mui-disabled,
.myJobsPagination .MuiSelect-select.Mui-disabled:focus,
.myJobsPagination .MuiMenuItem-root.Mui-disabled {
  color: #c4c4c4 !important;
}
.myJobsSort .MuiInput-underline:before,
.myJobsSort .MuiInput-underline:after {
  border-bottom: none !important;
}
.myJobsSort fieldset.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline,
.myJobsPagination fieldset.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline {
  border-color: #c4c4c4 !important;
}

.myJobsSort {
  margin-top: 5px;
}
.myJobMain .listMapViewButtons {
  background-color: inherit !important;
  margin-left: 0rem;
}

.myJobsShowingPagin {
  color: #626c7d;
  font-size: 0.9rem;
}
.myJobsPagination {
  margin-top: 12px !important;
  margin-left: 1.7rem;
}
.paginationV2 {
  position: absolute;
  right: 10%;
  margin-top: -3rem;
}
.paginationMyJobs {
  width: 100%;
  text-align: center;
}
.paginationResults .pagination {
  width: 80%;
}
.pagiResultsNoFilter .pagination {
  width: 100%;
  padding: 10px 20% 20px 35%;
}
.showingResV2 .myJobsPagination {
  width: 85px;
}
.showingResV2 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.myJobMain .jobCardHolder .emailCard .MuiCardContent-root:last-child {
  padding-bottom: 16px;
}
.mapView,
.gridView {
  margin: 0px 10px 0px 0px;
  cursor: pointer !important;
}
.mapView img,
.gridView img {
  height: 30px;
  width: 30px;
}
.myJobMain .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #c4c4c4 !important;
}
.selectedPgValue {
  padding-left: 30px !important;
  font-size: 0.8rem !important;
  color: $color-primary !important;
  font-weight: 700 !important;
  height: 22px !important;
}
.myJobsTxtAlign {
  text-align: right !important;
  margin-top: -2rem;
}
.myJobsWrap .myJobsTxtAlign {
  margin-top: -3rem;
}
.myJobsPaginSelectOpt {
  margin: -2px 0px 0px 8px !important;
  width: 60px !important;
}
.myJobPaginationMain {
  width: 50px !important;
  margin: -2px 0px 0px 8px;
}
.myJobsPagination .MuiFormControl-root {
  margin: -4px 0px 0px 8px;
}
.MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.jobCardMainWrapV2 {
  width: 375px;
  position: relative;
}
.hotJobsCarousel .jobCardMainWrapV2 {
  width: 375px;
  position: relative;
}
.jobCardsFlexWrap > .jobCardMain {
  width: 375px;
  margin: 10px;
  &:hover {
    box-shadow: 0px 10px 20px #00000030 !important;
  }
  &.jobCardDisabled:hover {
    background-color: #d9d9d9;
    opacity: 0.5;
    box-shadow: none !important;
  }
}

.jobCardsFlexWrap {
  display: flex;
  flex-wrap: wrap;
}
.jobCardsFlexWrapSkeleton {
  display: flex;
  flex-wrap: wrap;
}
.filterResultTxt {
  font-size: 1rem !important;
  font-weight: bold !important;
  color: $color-primary !important;
  margin-left: 0px !important;
  padding-left: 31%;
}
.filterResultTxtPos {
  padding-left: 0 !important;
}
.filterClearAllMyJobs {
  font-size: 0.7rem;
  margin-left: 5.2rem !important;
  color: $color-secondary;
}
.filterTxtMyJobs {
  margin-top: 0px;
  color: #626c7d;
  font-weight: 700;
  font-size: 0.8rem;
  position: absolute;
}
.recommendedJobText {
  padding: 15px 30px !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  color: $color-primary !important;
  margin-left: 0px !important;
}
.sortBtnMRBorder {
  width: 22%;
}
.sortWrapWidth {
  width: 100%;
  padding-right: 15px;
}
.saveSearchWidth {
  width: 100%;
  padding-right: 15px;
}
.sortPadding {
  margin-right: -30px;
}
.filterMapView {
  height: 60vh;
}
.listMapViewButtons {
  width: 80px !important;
  padding: 4px 0px !important;
}

.myJobsFilterChipMob {
  margin: 0 20px 0 0;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 45px;
  align-items: center;
}
.resultsSubWrap .myJobsFilterChipMob {
  border-bottom: none;
  align-items: flex-start;
  margin-bottom: 0;
}

.myJobs-noResults {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  color: $color-primary;
  font-size: 1.125rem;
  line-height: 1.575rem;
  width: 275px;
  padding: 85px 0 62px;

  &::before {
    content: "";
    margin: auto;
    width: 105px;
    height: 105px;
    margin-bottom: 23px;
  }
  &.favoriteTab::before {
    background: url("~src/imagesV2/favoriteNoResults.svg") center no-repeat;
  }
  &.emailTab::before {
    background: url("~src/imagesV2/emailNoResults.svg") center no-repeat;
  }
  &.applyTab::before {
    background: url("~src/imagesV2/applyNoResults.svg") center no-repeat;
  }
  &.quickApplyTab::before {
    background: url("~src/imagesV2/quickApplyNoResults.svg") center no-repeat;
  }
  &.assignmentsTab::before {
    background: url("~src/imagesV2/assignmentsNoResults.svg") center no-repeat;
  }
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  .mainFilter .jobCardsFlexWrap > .jobCardMain {
    width: 100% !important;
  }
  .jobCardsFlexWrap > .jobCardMain {
    width: 100%;
  }

  .myJobsWrap {
    padding-top: 0;
  }
  .myJobsWrap .myJobsTxtAlign {
    margin-top: 0;
  }
  .myJobMain {
    padding-top: 0px;
  }
  .myJobMain .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .myJobMain .iconLabel {
    font-size: 0.75rem !important;
  }
  .myJobsTxtAlign {
    text-align: left !important;
    margin: 16px 12px 0;
    padding: 0 3px !important;
    border-bottom: 1px solid #c4c4c4;
    height: 66px;
    display: flex;
    align-items: center;
    div {
      padding-top: 16px;
    }
    button {
      padding: 16px 0 0 0;
    }
  }

  .myJobSortWrapper {
    display: block;
    margin-bottom: 0px !important;
  }
  .myJobsChipMob {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 20px 10px 0px;
  }
  .mjchkboxAlign {
    color: #626c7d !important;
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    margin-left: 0px !important;
  }

  .myJobsSort .sortSelectListMob {
    min-width: 330px !important;
    min-width: 84vw !important;
  }
  .myJobPaginationMain {
    width: 92% !important;
  }

  .mapView,
  .gridView {
    margin: 0px 15px 0px 0px !important;
  }
  .mapView img,
  .gridView img {
    height: 40px;
    width: 40px;
  }
  .myJobsShowingPagin {
    display: block !important;
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    margin-top: 5px;
  }

  .showingResV2 .myJobsPagination {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: -10px !important;
    margin-left: 55px;
  }

  .paginationResults .paginationV2 {
    position: initial;
  }
  .paginationMyJobs,
  .paginationResults {
    display: inline-flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .myJobsPagination .MuiFormControl-root {
    margin: 0px 0px 0px 8px;
  }
  .myJobsPaginSelectOpt {
    margin: -2px 0px 0px 8px;
    min-width: 330px;
  }
  .myJobsSort {
    margin-top: 0px !important;
  }
  .myJobsSort .MuiSelect-select,
  .MuiSelect-select:focus,
  .myJobsPagination .MuiSelect-select,
  .myJobsPagination .MuiSelect-select:focus {
    font-size: 0.8rem !important;
    color: $color-primary !important;
    font-weight: bold !important;
    width: 70vw;
    border: none !important;
    padding: 5px 15px 10px 0px !important;
  }

  .myJobsSort .MuiSelect-icon,
  .myJobsPagination .MuiSelect-icon {
    color: $color-primary !important;
  }
  .mostRecentText,
  .mostRelevantText {
    font-size: 1rem !important;
  }
  .jobsTabFilterBtn {
    color: $color-primary !important;
    font-size: 0.8rem !important;
    font-weight: bold !important;
  }
  .myJobsSort {
    margin-left: 25px !important;
    text-align: left;
  }
  .mapViewV2 > div {
    padding: 0px 0px 0px 0px !important;
  }
  .filtersRowV2 {
    padding: 0px !important;
    margin-bottom: 0px;
    margin: 0px -15px;
    background-color: #f0f1f4;
  }
  .resultsSubWrap .filtersRowV2 {
    border-top: 1px solid #c4c4c4 !important;
    border-bottom: 1px solid #c4c4c4 !important;
  }

  .sortPadding {
    padding: 10px 4px;
    text-align: center;
    margin-right: 0px;
  }
  .sortBtnMRBorder {
    border-right: 1px solid #c4c4c4;
    width: 50%;
    display: inline-block;
  }
  /* Hide filters Mobile */
  .sortWrapWidth {
    width: 50%;
    border-right: 1px solid #c4c4c4;
  }
  .saveSearchWidth {
    width: 100% !important;
    padding-right: 0px;
  }
  .saveSearchMobMain {
    display: flex;
  }
  .searchFilterMob {
    width: 50% !important;
    border-right: 1px solid #c4c4c4 !important;
    text-align: center !important;
    padding: 10px 5px !important;
  }
  .searchSortMob {
    width: 50%;
    text-align: center;
    padding: 10px 5px;
    border-right: 1px solid #c4c4c4;
  }
  .sortFilterstickyMob {
    top: 75px !important;
    z-index: 99 !important;
  }
  .mobPaddingV2 .MuiDialogContent-dividers {
    padding: 16px 0px !important;
  }
  .myJobsWrap .pagination {
    justify-content: left !important;
    padding-left: 10px;
  }

  .paginationResults .pagination {
    width: unset;
  }
  .filterPopupMob .myJobsPagination {
    display: block !important;
  }
  .showingResV2 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .jobSortMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
    background: #f9f9f9;
  }
  .myJobsTitle {
    font-size: 1rem;
    font-weight: normal;
    margin-top: 0px;
    text-align: center;
    border-right: 1px solid #7070701a;
  }
  .myJobsTitle,
  .jobsTabFilterBtn {
    flex: 1;
    padding: 11px 0;
  }
  .myJobMain .MuiSelect-select,
  .myJobMain .MuiSelect-select:focus,
  .myJobMain .MuiMenuItem-root {
    padding: 5px 10px 5px 12px !important;
  }

  .myJobsPagination .MuiSelect-select,
  .myJobMain .MuiSelect-select:focus {
    padding: 6px 0 12px 12px !important;
  }
  .myJobsPagination .MuiFormControl-root {
    display: flex !important;
    width: 55px !important;

    .MuiOutlinedInput-root {
      height: 100% !important;
    }
    label {
      position: initial !important;
      transform: translate(-150%, 25px);
      margin: 0;
      font-size: 0.875rem !important;
      &::after {
        content: ":";
      }
    }
    fieldset legend {
      display: none;
    }
    svg {
      fill: #c4c4c4;
      transform: translateY(-3px);
    }
  }

  .jobCardsFlexWrap {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .myJobsMobMT {
    margin-top: 7px;
  }
  .myJobsMobile {
    display: none;
  }
  .mapViewV2 {
    margin: 0px;
  }
  .myJobsFilterChipMob {
    margin: 10px 0 0 !important ;
    border-bottom: none;
  }
  .filterResultTxt {
    padding-left: 0px;
  }
  .myJobChip,
  .myJobChipSelected {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-right: 18px !important;
    padding: 0;
    white-space: nowrap;
    padding-top: 10px;
    justify-content: center;
    &[tabindex="0"] {
      transform: translateY(-1px);
    }
  }
  .myJobChipSelected::after {
    transform: translateY(22px);
  }
  .recommendedJobText {
    padding: 15px 30px 15px 15px !important;
  }
  .filterResultTxt {
    font-size: 1.2rem !important;
  }
  .filtersRowV2 .myJobsPagination {
    margin-left: 0rem !important;
  }
  .filterResultTxt {
    font-size: 1.2rem !important;
  }
  .myJobs-noResults {
    padding: 30px 0;
  }
  .myJobsTxt {
    margin-top: 3px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .jobCardMainWrapV2 .hospitalName {
    padding-top: 3.5rem !important;
    padding-left: 10px !important;
  }
  .myJobsPagination {
    margin-left: -0.9rem !important;
    margin-top: 11px !important;
  }
  .myJobMain .listMapViewButtons {
    margin-left: 0.7rem !important;
  }
  .myJobsShowingPagin,
  .mjchkboxAlign {
    font-size: 0.85rem !important;
  }
  .myJobsSort .MuiSelect-select,
  .myJobsSort .MuiSelect-select:focus,
  .myJobsSort .MuiMenuItem-root {
    padding: 5px 35px 5px 14px !important;
    font-size: 0.65rem !important;
  }
  .myJobsPagination .MuiSelect-select,
  .myJobsPagination .MuiSelect-select:focus,
  .myJobsPagination .MuiMenuItem-root {
    padding: 5px 30px 5px 25px !important;
  }
  .paginationV2 {
    right: 6%;
  }
  .showingResV2 .myJobsPagination {
    margin-left: 0rem !important;
    width: 65px;
  }
  .myJobsTxt {
    top: 12px;
    left: 55%;
  }
  .jobCardsFlexWrap > .jobCardMain {
    width: 275px;
  }
  .jobCardMainWrapV2 {
    width: 275px;
  }
  .filterResultTxt {
    font-size: 0.8rem !important;
  }
  .sortPadding {
    margin-right: -10px;
  }
  .listMapViewButtons {
    padding: 6px 0px !important;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .myJobMain .emailCard .MuiCardContent-root:last-child {
    padding: 0 !important;
  }
  .myJobsPagination {
    margin-top: 12px !important;
    margin-left: 1rem;
  }
  .myJobMain .listMapViewButtons {
    margin-left: 0.2rem;
    margin-top: 3px;
  }
  .myJobsTxt {
    top: 12px;
    left: 45%;
  }
  .paginationV2 {
    right: 7%;
  }

  .jobCardMainWrapV2 {
    width: 350px;
    position: relative;
  }
  .jobCardsFlexWrap > .jobCardMain {
    width: 340px;
    margin: 10px;
  }
  .sortPadding {
    margin-right: -10px;
  }
}
@media only screen and (max-device-width: 390px) {
  .myJobsFilterChipMob .showingResV2 {
    padding-left: 0px !important;
  }
  .showingResV2 {
    padding-left: 20px !important;
  }
  .myJobsChipMob {
    padding: 10px 0px 10px 0px;
  }
  .myJobChipSelected .MuiChip-label,
  .myJobChip .MuiChip-label {
    font-size: 0.75rem;
  }
}

@media only screen and (max-device-width: 375px) {
  .myJobChip,
  .myJobChipSelected {
    margin-right: 14px !important;
  }
}
