.createPasswordSuccess {
  .toasterWrapper {
    .MuiSnackbar-root {
      top: 120px;

      .MuiSnackbarContent-root {
        background-color: #ffffff;
        padding: 7px 30px;
        color: #39cd7e;
        font-size: 15px;
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-around;
        border-radius: 10px !important;
        box-shadow: 0px 6px 25px #00000033;

        .MuiSnackbarContent-root {
          color: #39cd7e !important;
        }

        .MuiSnackbarContent-action {
          padding: 0;
        }
      }
    }

    .passwordSuccessIcon {
      color: #39cd7e;
      margin-right: 20px;
      font-size: 2rem !important;
    }
  }
}

span.createPasswordSuccess.certLic {
  position: absolute;
  top: 120px;
  right: 0;
}

span.createPasswordSuccess.certLic .toasterWrapper .MuiSnackbar-root .MuiSnackbarContent-root .MuiSnackbarContent-action {
  padding-right: 15px;
}


@media screen and (min-width: 0px) and (max-width: 768px) {
  .createPasswordSuccess {
    .toasterWrapper {
      .MuiSnackbar-root {
        top: 90px;
      }

      .MuiSnackbarContent-root {
        flex-direction: column-reverse !important;
        justify-content: center;
      }

      .MuiSnackbarContent-action {
        width: 58%;
      }
    }

    .passwordSuccessIcon {
      margin: 6px 0 4px;
    }
  }

  .createPasswordSuccess .certLic {
    position: absolute;
    top: 90px;
    right: 0;
  }

}