@import "src/theme/variables.scss";

.paystubsEarningTable {
  margin-bottom: 50px;
  .tableClientName {
    background-color: #fafafa;
  }
  table {
    border-collapse: collapse;
    padding: 5px;
    width: 100%;
    font-size: 16px;
    box-shadow: 0px 1px 3px #3f3f4426;
    border-radius: 5px;
    table-layout: fixed;
    th {
      border-bottom: 1px solid #e8e8e8;
      padding: 16px 20px;
      background: #f3f3f3;
      color: #3a3b3f;
    }
    td {
      border-bottom: 1px solid #e8e8e8;
      padding: 14px 20px;
      background: #ffffff;
      color: #313030;
      word-break: break-word;
      &:last-child {
        font-weight: bold;
      }
    }
  }
}

.amountCol {
  text-align: right;
}

.tableHeading {
  font-weight: bold;
  border-top: 1px solid #9ba0c1;
  letter-spacing: -0.09px;
  color: $color-primary;
  font-size: 24px;
  margin: 0;
  padding: 20px 0;
}

@media only screen and (max-width: 1024px) {
  .tableHeading {
    font-size: 18px;
    padding: 18px 0;
  }

  .paystubsEarningTable {
    margin-bottom: 40px;
    table {
      box-shadow: 0px 3px 10px #3f3f4433;
      td[colspan="2"],
      th[colspan="3"] {
        width: 0;
        padding: 0;
      }
      th {
        font-size: 14px;
        padding: 8px 10px;
      }
      td {
        font-size: 12px;
        padding: 8px 10px;
      }
    }
  }
}
