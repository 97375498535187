@import "src/theme/variables.scss";

.quickApplyCardWrapper {
  border-radius: 16px 16px 16px 0;
  padding: 10px 20px 20px 20px;
  background: #edf1ffb0;
  margin-bottom: 24px;
  .quickApplyCardHead {
    h2 {
      margin-bottom: 0;
      color: $color-primary;
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
    }
    padding-bottom: 18px;
    .applicationTip {
      background: #fff;
      padding: 12px 20px;
      border-radius: 8px;
      margin-top: 20px;
    }
    .applicationTipHead {
      font-size: 18px;
      color: $color-primary;
      padding-bottom: 6px;
      line-height: 22px;
      font-weight: 700;
      .applicationTipIcon {
        padding-right: 4px;
      }
    }
    .applicationTipContent {
      margin: 0;
      color: $color-primary;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .quickApplyCardBody {
    .quickApplyCardItem {
      padding: 10px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background: #f8f8f8;
        border-radius: 4px;
      }
      .quickApplyCardItemRow {
        display: flex;
        align-items: center;
        width: 90%;
      }
      .itemHeading {
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        color: #626c7d;
      }
      .itemSubHeading {
        color: #93979d;
        font-size: 16px;
        line-height: 25px;
        padding-bottom: 4px;
        svg {
          font-size: 18px;
          padding-right: 4px;
        }
      }
      .itemSubHeadingLabel {
        font-style: italic;
        font-weight: 400;
        display: block;
        font-size: 12px;
        padding-left: 18px;
      }
      .incompleteOutlinedIcon,
      .incompleteIcon,
      .quickApplyCardUpdateBtn {
        color: $color-secondary;
      }
      .completeOutlinedIcon,
      .completeIcon {
        color: $color-success;
      }
      .quickApplyCardUpdateBtn {
        text-decoration: underline;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        width: 120px;
        text-align: right;
      }
      .pendingIcon {
        color: #ed6c02;
      }
      .itemIcon {
        padding-right: 12px;
        svg {
          font-size: 24px;
        }
      }
    }

    button.quickApplyCardUpdateBtn {
      background-color: transparent;
      border: none;
      text-align: left;
    }
  }
  .quickApplyCardFooter {
    margin-top: 20px;
    .quickApplyButton {
      width: 100%;
      padding: 12px 0;
      border-radius: 20px 20px 20px 0;
      font-size: 16px;
      font-weight: 700;
      box-shadow: none;
      background: $color-primary;
      color: #fff;
      border: 0;
      .quickApplyButtonIcon {
        margin-right: 5px;
      }
      &:disabled {
        color: #656e7e;
        background-color: #efefef;
        &.quickApplied {
          color: #329a4e;
          background-color: #e5ffec;
        }
      }
    }
  }
  .quickApplyButton>span{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.quickApplyHeadingWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .quickApplyCardWrapper {
    margin: 0;
    background-color: transparent;
    padding: 18px;
    .quickApplyCardBody {
      .quickApplyCardItem {
        margin-bottom: 4px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        .quickApplyCardUpdateBtn {
          padding-left: 36px;
          width: auto;
          text-align: left;
          font-size: 14px;
        }
        .itemHeading {
          font-size: 14px;
        }
        .itemSubHeading {
          font-size: 12px;
        }
      }
    }
    .quickApplyCardFooter {
      position: fixed;
      bottom: 0;
      left: 0px;
      right: 0px;
      z-index: 988;
      background-color: #fff;
      padding: 20px;
      .quickApplyButton {
        font-size: 12px;
        &:disabled {
          &.quickApplied {
            color: #329a4e;
            background-color: #e5ffec;
          }
        }
      }
      &.notApplied {
        bottom: 70px;
      }
    }
    .quickApplyCardHead {
      h2 {
        font-size: 14px;
      }
      .applicationTipHead {
        font-size: 14px;
      }
      .applicationTipContent {
        font-size: 12px;
      }
      .applicationTip {
        background-color: $color-primary-50;
        padding: 12px 18px;
      }
    }
  }
}
