@import "src/theme/variables.scss";
.textLeft {
  text-align: left;
}
.createPasswordTextWrap .MuiInputBase-input:-webkit-autofill,
.createPasswordTextWrap .MuiInputBase-input:-webkit-autofill:hover,
.createPasswordTextWrap .MuiInputBase-input:-webkit-autofill:focus,
.createPasswordTextWrap .MuiInputBase-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-text-fill-color: #707070 !important;
}
.createPasswordTextWrap .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-primary;
}
.createPasswordMargin {
  height: 14vh;
}
.createPasswordTextWrap {
  background: #fff !important;
  box-shadow: 0px 0px 6px #00000012;
  border: 1px solid #e1e1e1;
  border-radius: 40px 40px 40px 0;
  padding: 50px 40px 30px 40px;
  min-height: 300px;
  text-align: center;
  margin: 0 40px;
}
.createPasswordIcon {
  height: 100px;
  margin: 30px 0;
}
.createPasswordHead {
  font-size: 1.1rem;
  color: $color-primary;
  font-weight: 700;
  margin-top: 18px;
  margin-bottom: 18px;
}
.createPasswordNote {
  font-size: 1.3rem;
  color: $color-primary;
}
.passwordInput .MuiOutlinedInput-input {
  color: #888;
  font-weight: 400 !important;
  line-height: 1rem;
  padding: 16px 15px !important;
  color: $color-primary;
  font-size: 0.9rem !important;
  font-weight: 700;
}
.formValidationWrap .passwordInput {
  margin-top: 30px;
  width: 100%;
}
.saveButtonWrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.saveButtonWrap .saveButton {
  background-color: $color-primary;
  border-radius: 20px 20px 20px 0;
  box-shadow: none;
  padding: 10px 20px;
  color: #fff;
  font-weight: 700;
  width: 100%;
  letter-spacing: 0.03em;
}
.saveButtonWrap .saveButton:hover {
  background-color: $color-primary-500 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
}
.saveButtonWrap .saveButton:disabled {
  background-color: #e1e1e1 !important;
  color: #888888 !important;
  font-weight: 700 !important;
  letter-spacing: 0.03em;
}

.formValidationWrap {
  text-align: center;
  display: block;
}
.passwordFieldIcon {
  color: #888888;
  width: 0.8em;
  height: 0.8em;
}
.passwordErrors {
  text-align: left;
  color: #000000;

  .noErrorIcon {
    font-size: 18px;
    color: $color-success;
    margin: 5px 8px 5px 0;
  }
  .errorIcon {
    font-size: 18px;
    color: $color-error;
    margin: 5px 8px 5px 0;
  }
  .errorText {
    font-size: 0.8rem;
  }
}
.changePassword {
  margin: 0 auto;
  text-align: left;
  width: 70%;
  color: #000000;
  .noErrorIcon {
    font-size: 18px;
    color: $color-success;
    margin: 5px 8px 5px 0;
  }
  .errorIcon {
    font-size: 18px;
    color: $color-error;
    margin: 5px 8px 5px 0;
  }
  .errorText {
    font-size: 0.8rem;
  }
}
.createPasswordLogo {
  height: 37px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .createPasswordMargin {
    height: 9vh;
    margin-top: 20px;
  }
  .createPasswordTextWrap {
    margin: 0;
    padding: 20px;
  }
  .createPasswordIcon {
    height: 80px;
    margin: 10px 0;
  }
  .createPasswordHead {
    font-weight: 500;
  }
  .createPasswordNote {
    font-weight: bold;
  }
  .formValidationWrap {
    fieldset {
      legend {
        font-size: 0.75rem !important;
        visibility: hidden !important;
      }
    }
  }
  .errorTextMobileMobile {
    padding-top: 3px;
    line-height: 20px;
  }
  .flexDisplayMobile {
    display: flex;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .createPasswordMargin {
    height: 0vh;
  }
  .createPasswordTextWrap {
    margin: 0;
    padding: 30px 20px 30px;
  }
  .createPasswordIcon {
    height: 80px;
    margin: 10px 0;
  }
  .createPasswordNote {
    font-size: 1.1rem;
  }
  .createPasswordHead {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .createPasswordMargin {
    height: 7vh;
  }
  .createPasswordTextWrap {
    margin: 0 30px;
    padding: 30px 25px 30px 25px;
  }
  .createPasswordIcon {
    height: 80px;
    margin: 10px 0;
  }
}
