@import "src/theme/variables.scss";

.smartbanner-show.smartbanner-margin-top {
  margin-top: 80px;
}

.smartbanner-show.smartbanner-margin-bottom {
  margin-bottom: 80px;
}

.smartbanner-show .smartbanner {
  display: block;
}

.smartbanner {
  left: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: $fontfamily-primary;
  background: #f4f4f4;
  z-index: 9998;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}

.smartbanner-top {
  position: absolute;
  top: 0;
}

.smartbanner-bottom {
  position: fixed;
  bottom: 0;
}

.smartbanner-container {
  margin: 0 auto;
  padding: 0 5px;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
  font-family: ArialRoundedMTBold, Arial;
  font-size: 20px;
  text-align: center;
  color: #888;
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  padding: 0 0 1px;
  background-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
  color: #aaa;
}

.smartbanner-icon {
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-icon,
.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
}

.smartbanner-info {
  white-space: normal;
  width: calc(99% - 201px);
  font-size: 11px;
  line-height: 1.2em;
  font-weight: 700;
}

.smartbanner-wrapper {
  max-width: 110px;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-description {
  max-height: 40px;
  overflow: hidden;
}

.smartbanner-author {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.smartbanner-author:empty + .smartbanner-description {
  max-height: 50px;
}

.smartbanner-button {
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
  color: #6a6a6a;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
}

.smartbanner-button:active,
.smartbanner-button:hover {
  color: #aaa;
}

.smartbanner-ios,
.smartbanner-android {
  background: #f2f2f2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 80px;
}

.smartbanner-ios .smartbanner-close,
.smartbanner-android .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-family: Arial;
  color: #888;
  text-shadow: 0 1px 0 #fff;
  -webkit-font-smoothing: none;
}

.smartbanner-ios .smartbanner-close:active,
.smartbanner-ios .smartbanner-close:hover,
.smartbanner-android .smartbanner-close:active,
.smartbanner-android .smartbanner-close:hover {
  color: #888;
}

.smartbanner-ios .smartbanner-icon,
.smartbanner-android .smartbanner-icon {
  background-size: cover;
}

.smartbanner-ios .smartbanner-info,
.smartbanner-android .smartbanner-info {
  color: #6a6a6a;
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.8);
  font-weight: 300;
}

.smartbanner-ios .smartbanner-title,
.smartbanner-android .smartbanner-title {
  color: #4d4d4d;
  font-weight: 500;
}

.smartbanner-ios .smartbanner-button,
.smartbanner-android .smartbanner-button {
  padding: 0 10px;
  font-size: 15px;
  min-width: 10%;
  font-weight: 600;
  color: $color-primary;
}

.smartbanner-ios .smartbanner-button:active,
.smartbanner-ios .smartbanner-button:hover,
.smartbanner-android .smartbanner-button:active,
.smartbanner-android .smartbanner-button:hover {
  color: #888;
}
