@import "src/theme/variables.scss";

.main {
  height: 140px;
  border-radius: 30px 30px 0px 0px;
  background-color: $color-primary;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.noBackground {
  background-color: transparent;
}

.top {
  width: 100%;
  position: absolute;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.chips {
  display: flex;
  gap: 10px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
  }
}

.contact {
  margin-right: 20px;
}

.favorite {
  button {
    padding: 0;
  }
}

.favorite:hover {
  svg {
    color: $color-secondary;
  }
}

.isFavorite {
  svg {
    color: $color-secondary;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}