.Loading {
  color: black;
  text-align: center;
}

.Header {
  color: black;
  text-align: left;
  padding: 10px;
}

.BellIcon {
  height: 30px;
  width: 30px;
}
