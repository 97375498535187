@import "src/theme/variables.scss";

.notMandatory {
  height: 44px;
  flex-grow: 1;
  font-family: $fontfamily-primary;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #414f8f;
  padding: 0 24px;
}
.learnMoreText {
  height: 49px;
  font-size: 16px;
  font-family: $fontfamily-primary;
  color: #545b67;
  text-decoration: underline;
  display: inline;
}
.onHoverIcon {
  width: 20px;
  height: 20px;
  color: #414f8f;
  padding: 10px 10px 10px 0px;
  display: inline;
}
.selfReporting {
  height: 44px;
  flex-grow: 1;
  font-family: $fontfamily-primary;
  font-size: 16px;
  font-weight: 600;
  padding: 0 24px;
  padding-top: 20px;
}
.selfReporting :hover {
  cursor: pointer;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-grow: 0;
  flex-direction: column;
  gap: 16px;
  padding: 32px 24px;
}
.accSettingSubHeadV2 {
  font-size: 1.4rem;
  color: $color-primary;
  font-weight: bold;
  margin-bottom: 1rem;
}
.modalBox {
  width: 651px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 13px;
  padding: 32px 24px;
}
.modalHeadings {
  width: 604px;
  height: 21px;
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #656e7e;
}
.modalLayout {
  background-color: #fff;
  width: auto;
  height: auto;
  overflow-y: auto;
  border-radius: 10px;
}
.modalContent {
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #656e7e;
}
.close {
  width: 109.8px;
  height: 45px;
  flex-grow: 0;
  padding: 11.5px 21.6px 11.5px 20.9px;
  border: solid 1px #707070 !important;
  color: #707070;
  border-radius: 10px 10px 10px 0 !important;
  box-shadow: none !important;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
  height: 40px;
}
.closeText {
  width: 67.3px;
  height: 22px;
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.selfIdentificationContainer {
  border-radius: 8px;
  background-color: #f9fafc;
  padding: 20px;
}
@media screen and (min-width: 0px) and (max-width: 768px) {
  .notMandatory {
    width: 400px;
    font-family: $fontfamily-primary;
    height: 95px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    color: #414f8f;
  }
  .OnBOnHoverIcon {
    padding-bottom: 5px;
    color: #414f8f;
    width: 24px;
    height: 24px;
    flex-grow: 0;
    padding: 2px;
    display: inline;
  }
  .learnMoreText {
    height: 26px;
    flex-grow: 1;
    font-family: $fontfamily-primary;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #545b67;
    display: inline;
  }
  .modalBox {
    width: 350px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 13px;
    padding: 32px 24px;
  }
  .modalHeadings {
    width: 300px;
    height: 42px;
    flex-grow: 0;
    font-family: $fontfamily-primary;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #656e7e;
  }
  .modalHeadingsSingleLine {
    width: 300px;
    height: 21px;
    flex-grow: 0;
    font-family: $fontfamily-primary;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #656e7e;
  }
  .selfReporting {
    height: 44px;
    flex-grow: 1;
    font-family: $fontfamily-primary;
    font-size: 16px;
    font-weight: 600;
    padding: 0 10px;
    padding-top: 10px;
  }
}
