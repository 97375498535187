@import "src/theme/variables.scss";

.jobCardHolder {
    padding: 10px;
    border-radius: 10px;
}
.incompleteProfileTitle{
font-size: 16px;
font-weight: 700;
line-height: 20px;
color: white;
width: 80%;

}

.jobCardViewMain {
    border-radius: 10px !important;
    padding: 10px;
    margin-top: 4px;
    --card-width: 340px;
    width: var(--card-width)


}

.jobCardViewsHeader {
    display: flex;
}

.jobCardViewsCityName {
    width: 200px;
    height: 22px;
    color: #656E7E;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    & img {
        width: 9.32px;
        color: #656E7E;
        margin-bottom: 1px;
    }
}

.jobFacilityName {
    height: 20px;
    width: 325px;
    color: #656E7E;
    font-size: 10px;
    font-weight: 700;


}

.jobCardSalary {
    font-size: 12px;
    font-weight: 700;
    line-height: 16.8px;
}

.jobCardTitle {
    width: 325px;
    color: #656E7E;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.jobViewCard {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: left;
    max-width: 100%;
}

@media screen and (max-width: 599px) {
    .jobViewCard {
        display: block;
        flex-wrap: nowrap;
        gap: 15px;
        justify-content: initial;

    }
}



.jobViewCard,
.jobViewCard .mainCarouselsection {
    height: auto;
    background-color: none !important;
    padding-top: 0px;
    padding-bottom: 0px;

    & h2 {
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        margin-left: 15px;
        line-height: 20px;
        font-family: $fontfamily-primary;
    }
}

.jobViewsChip,
.jobCardViewMain .mainCarouselsection .jobViewsChip {
    height: 20px !important;
    background-color: $color-primary !important;
    border: 1px solid $color-primary !important;

    & span {
        color: #fff !important;
        font-size: 9.6px;
    }

    & img {
        width: 12.34px !important;
    }
}

.jobViewsAdvChip{
    width: 135px;
    text-align: end;
}

.jobViewSkeleton{
    width: 150px;
    margin-bottom: 8px;
    border-radius: 10px;
}
.jobViewSkeletonSecond{
    width: 200px;
    margin-bottom: 8px;
    border-radius: 10px;
}
.jobViewSkeletonThird{
    width: 130px;
    margin-bottom: 8px;
    border-radius: 10px;
}
.jobViewSkeletonForth{
    width: 250px;
    border-radius: 10px;
}
.jobViewSkeletonFifth{
    width: 375px;
    border-radius: 10px;
    margin-top: 75px;
}
.jobsCitySkeleton{
    width: 170px;
}

.jobViewChipSkeleton{
    width: 100px;
    height: 20px;
    border-radius: 10px;
    margin-left: 20%;
}
.jobsSkeletonHeader{
    display: flex;
}


@media screen and (min-width: 0px) and (max-width: 768px) {

    .jobViewCard{
        max-width: 100%;
    }
    .jobCardViewMain {
        width: 260px;
        margin-right: 15px;
        margin-left: 15px;
    }

    .jobViewCard{
        max-width: 100%;
    }

    .jobViewCard,
    .jobViewCard .mainCarouselsection {
        & h2 {
            font-size: 14px;
            text-align: center;
        }
    }

    .jobCardHolder {
        padding: 10px;
    }

    .jobCardViewsCityName {
        width: 115px;
        height: 22px;
        color: #656E7E;
        font-size: 12px;
        font-weight: 700;
        line-height: 16.8px;

        & img {
            width: 9.32px;
            color: #656E7E;
            margin-bottom: 1px;
        }
    }

    .jobViewsChip,
    .jobCardViewMain .mainCarouselsection .jobViewsChip {
        height: 20px !important;
        background-color: $color-primary !important;
        border: 0.72px solid $color-primary !important;

        & span {
            color: #fff !important;
            font-size: 9px;
        }

        & img {
            width: 11.34px !important;
        }
    }
        .jobViewsAdvChip {
            width: 115px;
            text-align: end;
        }

        .jobFacilityName {
            width: 245px;
        }

        .jobCardTitle {
            width: 245px;
        }
}
@media screen and (min-width: 1200px) {
    .jobViewCard {
        max-width: 90%!important;
    }
}