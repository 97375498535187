@import "src/theme/variables.scss";

.filterComp {
  width: 22% !important;
  padding: 0rem 1rem 0rem 1rem;
  border-right: 1px solid #e9e7e7;
  margin-top: -3rem;
  position: relative;
}
.filterCompwidth {
  width: 22% !important;
  border-right: 1px solid #e9e7e7;
}
.jobCardComp {
  width: 78% !important;
  padding: 0rem 0rem 0rem 0rem;
}

svg.savedSearchIcon {
  width: 1rem;
  height: 11px;
  padding-right: 4px;
  margin-top: -4px;
  height: 20px;
  color: inherit;
}

.Savedsearchescontainer {
  margin-left: -26px;
  width: auto;
  height: auto;
  display: inline;
  vertical-align: top;
  border-radius: 15px 15px 15px 0 !important;
  padding: 20px;
  border-width: 1px;
  background-color: #ffffff;
  position: relative;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-left: 8px;
    margin-top: 20px;
    width: 100%;
  }
}
.savedsearchsubheading {
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 16px;
  margin-left: 32px;
  display: inline-flex;
  align-items: center;
  line-height: 21.82px;
  color: $color-primary;
}
.hidefilterComp {
  display: none;
}
.jobCardCompNoFilter {
  width: 100% !important;
}

.filterMaster {
  margin-top: 32px;
}
.filterDivider {
  margin: 15px 0px !important;
}
.savesearchdivider{
  width: 296px;
  justify-content: center;
  margin-bottom: 20px !important;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: 100% !important;
  }
}

.filterSubHeads {
  color: #626c7d;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: 24.55px;
  text-align: left;
}
.weeklyDistanceTxt {
  color: #626c7d;
  font-size: 0.8rem;
  font-weight: 500;
}
.filterTxt {
  color: #000000;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: 24.55px;
  text-align: left;
}
.filterSlider .MuiSlider-rail {
  height: 4px;
  border-radius: 5px;
  background-color: $color-primary !important;
  width: 81% !important;
  opacity: 1 !important;
}
.filterSlider .MuiSlider-track {
  height: 4px;
  display: block;
  position: absolute;
  border-radius: 5px;
  background-color: #c4c4c4 !important;
}
.filterSlider .MuiSlider-thumb {
  background-color: $color-primary !important;
}
.filterBoxWrap {
  position: relative;
  width: 21%;
}
.filterPreferance {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  font-size: 0.8rem;
  color: #626c7d;
  padding: 12px 64px 12px 12px;
  background-image: url(./../imagesV2/Filter-notification.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-color: #fff;
  position: absolute;
  width: 98%;
  left: 10px;
  z-index: 99;
  border: 1px solid #c4c4c4 !important;
}
.filterUpdatePref {
  color: $color-primary;
  text-decoration: underline !important;
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 700;
}

.filterOptionsClearAll {
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 700;
  color: #656e7e;
  font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-decoration: underline;
  border: none;
  @media (max-width: 768px) {
    background-color: white;
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #444f8b !important;
  border-color: #babec6;
}

.facilitiesStyle {
  height: 50px;
  width: 255px;
  padding: 15px 0px;
}

.facilitiesTextStyle {
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 20px;
  width: 224px;
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
  line-height: 20px;
  color: #434851;
}

.mt {
  margin-top: 12px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
}

.facilitiesListContainer {
  width: 325px;
  height: 266px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
  @media (max-width: 768px) {
    width: 345px;
    height: 266px;
  }
}

.facilityrowsection {
  height: 50px;
  width: 300px;
}

a.filterUpdatePref:hover {
  color: #d7282f !important;
  text-decoration: underline !important;
}
.filterCloseIcon {
  font-size: 0.9rem !important;
  cursor: pointer;
  position: absolute;
  right: 3%;
  top: 10%;
}
.filterClearAll {
  color: $color-secondary;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  font-family: Nunito Sans;
  font-size: 12px;
  text-align: right;
}
.filterClear {
  color: #626c7d;
  font-size: 12px;
  text-decoration: underline;
  text-transform: uppercase;
  text-align: right;
  margin-top: -30px;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  right: 6%;
}
.filterSubHeads {
  padding: 15px 0px;
}
.filterDayIcon,
.filterIcon .dayIcon {
  font-size: 1rem !important;
  color: $color-primary;
}
.filterSubTxt {
  width: 70%;
  font-size: 0.8rem;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .jobCardComp {
    width: 100% !important;
    padding-left: 10px;
  }
  .filterMaster {
    margin-top: 20px;
    display: block;
  }
  .filterComp,
  .filterCompwidth {
    width: 50% !important;
    padding: 0rem 0rem 0rem 0rem;
    border-right: none;
    margin-top: 0rem;
  }

  .filterClearAll {
    font-size: 12px;
    // margin-left: 4rem;
    font-weight: 700;
    float: left;
    margin-top: 2px;
  }
  .filterClear {
    margin-right: 15px !important;
    position: static;
    padding: 15px 0px;
    margin-top: -45px;
  }
  .filterSlider .MuiSlider-rail {
    width: 85vw !important;
  }
  .filterSubHeads {
    font-size: 14px;
    font-weight: 700;
    display: inline-flex;
    line-height: 19.1px;
  }
  .filterDateMain {
    border: 1px solid $color-primary;
    padding: 10px 10px;
    margin-top: 12px;
    width: 97% !important;
  }
  .filterBoxWrap {
    position: relative;
    width: 94%;
    margin-left: 3%;
  }
  .filterPreferance {
    height: auto;
    padding: 0px 142px 0px 12px;
    font-size: 0.9rem;
    top: 0px;
    position: relative;
    margin: 20px 0px;
    background-size: contain;
    width: 95%;
    z-index: 0;
  }
  .filterCloseIcon {
    font-size: 0.8rem;
  }
  .filterDateMain .MuiInputBase-input {
    margin-right: 9rem;
  }
  .filterSubTxt {
    width: 100%;
    font-size: 0.8rem;
    padding: 15px 0px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .filterPreferance,
  .filterUpdatePref {
    font-size: 0.6rem;
  }

  .filterSlider .MuiSlider-rail {
    width: 57% !important;
  }
  .filterDateMain .MuiInputBase-input {
    margin-right: 0rem;
  }
  .filterPreferance {
    padding: 12px 10px 0px 10px;
    height: 85px;
  }
  .filterSubHeads {
    padding: 10px 0px;
    margin-top: -10px;
  }
  .filterSubTxt {
    width: 55%;
    font-size: 0.6rem;
  }
  .filterClear {
    margin-top: -25px;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .filterSlider .MuiSlider-rail {
    width: 75% !important;
  }
  .filterClear {
    margin-top: -30px;
  }
}
