@import "src/theme/variables.scss";

.main {
  background-color: #FFF4F7;
  padding: 30px 20px;
}

.heading {
  color: #7D1F23;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  svg {
    color: #7D1F23;
    margin-right: 2px;
  }
}

.jobCardList {
  display: none;
}

@include breakpoint("sm") {
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }

  .sub {
    width: 100%;
  }

  .heading {
    font-size: 20px;
  }

  .jobSlider {
    display: none;
  }

  .jobCardList {
    display: initial;
  }
}

@include breakpoint("md") {
  .main {
    padding: 30px 100px 45px 100px;
  }

  .sub {
    min-width: 710px;
    // max-width: 1080px;
  }

  .jobSlider {
    display: initial;
  }

  .jobCardList {
    display: none;
  }
}

@include breakpoint("lg") {
  .main {
    padding: 30px 80px 45px 80px;
  }

  .heading {
    text-align: left;
    justify-content: start;

    span {
      margin: initial;
    }
  }
}

@include breakpoint("xl") {
  .main {
    padding: 30px 40px 45px 40px;
  }

  .sub {
    max-width: 1590px;
  }
}