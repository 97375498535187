.footerV2 .footercolMargin {
  margin-top: 2rem;
  margin-right: 0;
}
.footerV2,
.footerLinkHead {
  font-size: 0.9rem !important;
  font-weight: 700;
}
.footerV2 .footerLinks:hover,
.footerV2 .footerSubLinks:hover {
  opacity: 0.6 !important;
  color: #fff !important;
}
.footerV2 .footerLinks,
.footerV2 .footerLinkHead {
  font-size: 1.2rem;
  font-weight: 700;
}
.footerV2 .footerLabel {
  font-weight: 700;
  font-size: 1.2rem;
  padding: 6px 0px;
}
.footerV2 .footerLinkHead:hover {
  color: #fff !important;
}
.footerV2 .MuiLink-root:hover {
  color: #fff !important;
  opacity: 0.6;
  text-decoration: underline !important;
}
.footerV2 .footerSocialIcon svg:hover path {
  fill: #fff !important;
  opacity: 0.6;
}
.footerSubLinks {
  font-weight: normal !important;
  font-size: 0.9rem !important;
}
.referFriend {
  background-image: url("./../imagesV2/referafriendbackground.png");
  background-size: 100%;
  height: 102px;
  width: 295px;
  background-repeat: no-repeat;
  border-radius: 5px;
  cursor: pointer;
}
.referNowBtn {
  border: 1px solid $color-primary !important;
  border-radius: 10px 10px 10px 0 !important;
  margin-left: 0rem !important;
  margin-top: 0.5rem !important;
  padding: 2px 12px !important;
  color: $color-primary !important;
  font-size: 0.9rem !important;
}
.referNowBtn:hover {
  background-color: $color-primary-light !important;
  color: #fff !important;
}
.referFriendMain {
  width: 50%;
  margin-left: 6rem;
  top: 30%;
  position: absolute;
}
.referenceTabReferFrnd {
  width: 50%;
  margin-left: 6rem;
  top: 25%;
  position: absolute;
}
.referFriendTxt {
  color: $color-primary !important;
  font-size: 0.9rem;
}
.earnBigIcon {
  margin-left: 10px;
  width: 75px;
  height: 104px;
}
.referFrdFooterMargin {
  margin-top: 2rem;
  padding-left: 5rem;
}
.referNowBtn .MuiSvgIcon-fontSizeSmall {
  font-size: 1rem;
}
.footerV2 .footerBorder {
  border-bottom: 1px solid #5e7fb159 !important;
}
.footerV2 .MuiLink-root {
  color: #fff !important;
  width: max-content;
  font-size: 0.9rem !important;
  text-decoration: underline;
}
.footerV2 .footerAddress {
  font-size: 0.9rem !important;
  font-weight: normal !important;
  line-height: 26px;
}
.footerColPadding {
  padding-left: 4rem;
}
.downloadCC {
  padding-left: 7.5rem;
}
.referFrdMob {
  margin-bottom: 10px !important;
}
.footerV2 .footerFollowUs {
  padding: 6px 0px;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 2rem;
}
.footerIcon img:hover {
  opacity: 0.6;
}
@media screen and (min-width: 0px) and (max-width: 768px) {
  .referFrdMob {
    margin: 0 auto;
  }
  .referFriend {
    height: 105px;
    width: 100%;
  }
  .footerV2,
  .footerLinkHead,
  .footerV2 .footerFollowUs {
    font-size: 1.1rem;
  }
  .referNowBtn {
    margin-left: 0rem;
  }
  .earnBigIcon {
    width: 26%;
    height: 115px;
  }
  .referFriendMain {
    width: 56%;
    margin-left: 7rem;
    top: 37%;
  }
  .referenceTabReferFrnd {
    width: 56%;
    margin-left: 7rem;
    top: 38%;
  }
  .referFrdFooterMargin {
    padding-left: 1rem;
  }
  .downloadCC {
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .footerV2 .footerAddress,
  .footerV2 .MuiLink-root {
    font-size: 0.75rem !important;
    line-height: 20px;
  }
  .footerV2 .footercolMargin {
    padding-left: 27vw;
  }
  .footerV2 .footerLinks,
  .footerV2 .footerLinkHead {
    font-size: 1.1rem;
  }
  .footerLinks,
  .footerLinkHead {
    padding: 8px 0px !important;
  }
  .footerV2 .footerLabel {
    font-size: 1.1rem;
    margin-top: 2rem;
  }
  .footerLabel {
    padding: 8px 0px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .referFriend {
    height: 73px;
    width: 230px;
  }
  .earnBigIcon {
    width: 55px;
    height: 72px;
  }
  .referFriendMain {
    margin-left: 5rem;
    top: 25%;
  }
  .referenceTabReferFrnd {
    margin-left: 5rem;
    top: 21%;
  }
  .referFriendTxt {
    font-size: 0.65rem;
  }
  .referNowBtn {
    font-size: 0.6rem !important;
    margin-top: 0.3rem !important;
  }
  .referNowBtn .MuiSvgIcon-fontSizeSmall {
    font-size: 0.9rem;
  }
  .downloadCC {
    padding-left: 4rem;
  }
  .footerV2 .footerLinks,
  .footerV2 .footerLinkHead {
    font-size: 0.9rem;
  }
  .footerSubLinks {
    font-size: 0.7rem !important;
  }
  .footerV2 .footerLabel {
    font-size: 0.875rem;
  }
  .footerV2 .footerAddress {
    font-size: 0.75rem !important;
  }
  .footerV2 .MuiLink-root {
    font-size: 0.75rem !important;
  }
  .referFrdFooterMargin {
    padding-left: 4rem;
  }
  .footerColPadding {
    padding-left: 3.5rem;
  }
  .footerV2 .footerFollowUs {
    font-size: 0.9rem;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .referFriendMain {
    width: 55%;
    margin-left: 6.5rem;
    top: 32%;
  }
  .referenceTabReferFrnd {
    top: 25%;
  }
  .referFriendTxt {
    font-size: 0.8rem;
  }
  .footerV2 .footerLabel {
    font-size: 1.1rem;
    font-weight: 700;
  }
  .earnBigIcon {
    margin-left: 10px;
    width: 75px;
    height: 110px;
  }
  .downloadCC {
    padding-left: 7.5rem;
  }
  .referFrdFooterMargin {
    padding-left: 5rem;
  }
  .footerColPadding {
    padding-left: 3rem;
  }
}
@media only screen and (max-device-width: 360px) {
  .referFriendMain {
    margin-left: 6rem;
    top: 38%;
  }
  .referenceTabReferFrnd {
    top: 37%;
    margin-left: 6rem;
  }
  .referFriend {
    height: 88px;
  }
  .referFriendTxt {
    font-size: 0.8rem;
  }
  .referNowBtn {
    margin-top: 0.2rem !important;
    font-size: 0.8rem !important;
  }
}
