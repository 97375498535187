@import "src/theme/variables.scss";

.myRecruiterMain {
  height: auto !important;
  background-color: #ecedf2 !important;
  padding-top: 40px !important;
  padding-bottom: 70px !important;
  border-top: 1px solid #ecedf2;
}

.customerService {
  color: $color-primary;
  font-weight: 700;
  font-size: 1.1rem;
  padding-bottom: 10px;
}
.myRecruiterDetails {
  display: flex;
}
.myRectContactDetail {
  font-size: 1rem;
  color: $color-primary !important;
  font-weight: 500;
  cursor: pointer;
  margin-top: 5px;
}
.myRecruiterNameLabel {
  font-size: 1rem;
  color: $color-primary !important;
  font-weight: 700;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
  font-weight: 500 !important;
}

.myRectContactDetail:hover,
.myRectContactDetail a:hover {
  color: $color-secondary !important;
}
.myRecruiterMain .myRecruiterLabel {
  color: #000000de;
  font-size: 0.8rem;
  letter-spacing: 1.18px;
  font-weight: 700;
  font-family: $fontfamily-primary;
}
.details {
  margin: 1rem 0rem 0rem 1rem;
}
.custServiceImg {
  background-image: url("./../imagesV2/CustomerService.svg");
  width: 256px;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.recruiterImg {
  background-image: url("./../imagesV2/Recruiter.svg");
  width: 256px;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
}
.myRecruiterMain .MuiPaper-elevation1 {
  box-shadow: 0px 0px 0px 1px #0000001f !important;
  border-radius: 30px 30px 30px 0;
}
.myRecruiterMain .labelDetailsLink {
  color: $color-primary !important;
  padding-left: 0px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none !important;
}
.myRecruiterMain .labelDetailsLink:hover,
a.labelDetailsLink:hover {
  color: $color-secondary !important;
  text-decoration: none !important;
}
@media screen and (min-width: 0px) and (max-width: 768px) {
  .myRecruiterDetails {
    display: block;
    border-radius: 7px;
  }
  .details {
    margin: 0rem;
  }

  .custServiceImg {
    background-image: url("./../imagesV2/Customer-mobile.svg");
    width: 100%;
    height: 110px;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .recruiterImg {
    background-image: url("./../imagesV2/Recruiter-mobile.svg");
    width: 100%;
    height: 110px;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .myRecruiterMain .container {
    padding: 0px 0px;
  }
  .myRecruiterLabel {
    font-size: 0.9rem;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .myRectContactDetail,
  .myRecruiterNameLabel {
    font-size: 1rem !important;
    width: 100%;
  }
  .contactDetailsMob {
    margin-top: -1.2rem;
  }
  .customerService {
    font-size: 1.3rem !important;
  }
  .recruiterLblMob {
    margin-top: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .customerService {
    font-size: 1rem !important;
  }
  .myRecruiterLabel {
    font-size: 0.7rem;
  }
  .myRecruiterMain .labelDetailsLink {
    font-size: 0.8rem !important;
  }
  .myRectContactDetail {
    font-size: 0.8rem !important;
    font-weight: normal;
    margin-top: 0px;
  }
  .myRectContactDetail,
  .myRecruiterNameLabel {
    font-size: 0.8rem !important;
  }
  .details {
    margin: 0;
  }

  .custServiceImg,
  .recruiterImg {
    width: 231px;
    height: auto;
  }
}
