@import "src/theme/variables.scss";

:export {
  colorPrimary: $color-primary;
}

.Widget {
  padding-left: 40px;
}

.WidgetTitle {
  font-family: $fontfamily-primary;
  font-size: 20px;
  color: $color-primary;
  font-weight: 700;
  margin-bottom: 10px;
}

.WidgetContainer {
  background: linear-gradient(#1f70c2, #90d7ff);
  border-radius: 16px;
  padding: 12px 24px;
  max-height: 240px;
  height: 240px;
  max-width: 512px;
  width: 512px;
  font-family: $fontfamily-primary;
}

.WidgetHeader {
  display: flex;
  justify-content: space-between;
}

.MonthlyData {
  height: 60px;
  width: 343px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.Month {
  font-size: 10px;
  padding: 5px 12px 0px 12px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
}

.TempPrecipContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  font-size: 14px;
  line-height: initial;
}

.TempPrecipLabel {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}

.HighLowTempContainer {
  display: flex;
  justify-content: space-between;
}

.TempPrecipData {
  font-size: 12px;
  padding-right: 10px;
  width: 80px;
}

.ChartLegend {
  content: " ";
  height: 36px;
  width: 80px;
}

.ChartContainer {
  position: relative;
  margin-top: 10px;
}

.OverlaySlider {
  left: 58px;
  top: 9px;
  height: 112px;
  width: 356px;
  position: absolute;
  z-index: 1;
  background-color: transparent;
}

@media (max-width: 768px) {
  .Widget {
    padding-left: 0;
  }

  .WidgetTitle {
   margin-left: 10px;
  }
  
  .WidgetContainer {
    max-width: 355px;
    width: 355px;
    max-height: 252px;
    height: 252px;
    padding: 12px;
  }

  .WidgetHeader {
    display: flex;
    flex-direction: column;
  }

  .MonthlyData {
    width: 100%;
  }

  .HighLowTemp {
    height: 60px;
    width: 332px;
  }

  .ChartLegend {
    content: " ";
    height: 14px;
    width: 100%;
  }

  .OverlaySlider {
    width: 222px;
  }
}
