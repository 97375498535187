@import "src/theme/variables.scss";

.main {
  border-radius: 24px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
}

.compact {
  border-radius: 12px;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: 700;
}

.exclusive {
  background-color: $color-primary;
  color: white;
}

.hotJobLabel {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 5px;
    font-size: 15px;
  }
}

.hotJob {
  background-color: $color-secondary;
  color: white;
}