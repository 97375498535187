@import "src/theme/variables.scss";

.hotJobsContainer {
    height: 419px;
    padding: 30px 0 35px 15px !important;
    background-color: #FFF4F7;
}
.jobSearchContainerWithHotJobs {
    padding: 40px 10px 40px 10px !important;
}
.jobSearchContainer {
    margin-bottom: 6rem !important;
}
.jobSearchContainerWithCompleteProfile{
    height:180px !important;
    padding-left:0;
}
.hotJobsTitle {
    height: 29px;
    width: 100%;
    gap: 8px;
    margin-bottom: 8px;
}
.hotJobsIcon {
    width: 14.72px;
    height: 18.93px;
    margin: 0 3px 7px 0;
}
.hotJobsTitleText {
    display: inline;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: #7D1F23;
    margin-left: 8px;
}
.hotJobsCarousel {
    height: auto;
    background-color: #FFF4F7;
    padding-top: 0px;
    padding-bottom: 35px;
}
.hotJobCardWrap {
    height: 290px !important;
}
.hotJobsCarousel .alice-carousel .jobCardMain {
    height: 290px !important;
    border-radius: 30px 30px 20px 0px !important;
}
.hotJobsCarousel .alice-carousel {
    margin-left: 7px !important;
}
.quickOfferLabel,
.hotJobCardWrap .mainCarouselsection .quickOfferLabel {
    height: 22px !important;
    width: 83px !important;
}
.hotJobCardWrap .quickOfferLabel .MuiChip-label {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    position: relative !important;
    left: 4px !important;
}
.hotJobsCarousel .jobCardMain {
    margin: 10px;
}
.hotJobCardWrap .MuiChip-root {
    border: none !important;
}
.hotJobCardWrap .iconLabel {
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 700 !important;
    color: #656E7E !important;
}
.hotJobsCarousel .jobCardIcons {
    margin-left: 4px !important;
}
.hotJobsCarousel .jobCardMainWrapV2 .hospitalNameV2 {
    width: 265px;
    margin-right: 12px;
    font-size: 20px !important;
    font-weight: 800;
    color: #626c7d;
    min-height: 75px;
}
.hotJobChip {
    padding: 20px 0px 0px 20px;
}
.hotJobCardWrap .quickOfferLabel .MuiChip-icon {
    width: 9.34px !important;
    height: 12px !important;
    top: 2px !important;
    left: 3.32px !important;
    position: relative !important;
    margin-bottom: 5px !important;
}
.hotJobCardWrap .jobCardHeader {
    padding: 0px 0px 0px 20px !important;
    margin-top: 16px;
}
.hotJobCardWrap .jobFacilityName{
    font-size: 16px !important;
    min-height: 20px;
	line-height: 20px;
    font-weight: 700;
}
.hotJobsRightArrowContainer {
    position: "absolute";
    right: "-5%";
    top: "30%";
    border-radius: "100px";
    cursor: 'pointer';
}
.alice-carousel .hotJobCardWrap .specialtyJobCardWrap {
    padding: 0px 0px 0px 20px !important;
    margin-top: 12px !important;
}
.hotJobCardWrap .payRateSvg {
    height: 15px;
    width: 15px;
    margin-top: -5px;
}
.hotJobsCarousel .specialtyNameJobCardV2 {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;

}
.hotJobCardWrap .jobTitleV2 {
    color: #626C7D;
    font-size: 14px !important;
    font-weight: 700;
    padding-left: 20px;
    line-height: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
}
.hotJobCardWrap .imInterestedBtn,
.hotJobCardWrap .imInterestedBtn:disabled {
  height: 50px !important;
  width: 100% !important;
  font-size: 16px !important;
  border-radius: 0 0 20px 0 !important;
  padding: 12px 15px !important;
  font-family: $fontfamily-primary;
  font-weight: 700 !important;
  line-height: 20.8px !important;
}
@media screen and (min-width: 0px) and (max-width: 768px) {
    .hotJobsCarousel .alice-carousel {
        margin-left: 0;
    }
    .hotJobsContainer {
        height: 348px;
        padding: 15px 0px !important;
        background-color: #FFF4F7;
    }
    .hotJobCardWrap {
        height: 267.31px !important;
        width: 325px !important;
    }
    .hotJobsCarousel .alice-carousel .jobCardMain {
        height: 267.31px !important;
        width: 325px !important;
    }
    .hotJobsTitle {
        height: 20px !important;
        margin-bottom: 16px !important;
    }
    .hotJobsTitleText {
        font-size: 16px !important;
        line-height: 20px !important;
    }
    .hotJobChip {
        padding: 13.93px 0px 0px 18.57px !important;
    }
    .quickOfferLabel,
    .hotJobCardWrap .mainCarouselsection .quickOfferLabel {
        height: 20.43px !important;
        width: 78.14px !important;
    }
    .hotJobCardWrap .quickOfferLabel .MuiChip-icon {
        width: 8.67px !important;
        height: 11.14px !important;
        top: 1.39px !important;
        left: 3.09px !important;
    }
    .hotJobCardWrap .jobCardHeader {
        padding: 9.29px 18.57px 4.64px 18.57px !important;
        margin-top: 0px !important;
    }
    .hotJobsCarousel .jobCardMainWrapV2 .hospitalNameV2 {
        width: 287.86px;
        margin-right: 12px;
        font-weight: 700;
        font-size: 18px !important;
        min-height: 60px;
    }
    .hotJobCardWrap .jobFacilityName {
        font-size: 12px !important;
        line-height: 16.71px;
    }
    .hotJobsCarousel .specialtyNameJobCardV2 {
        font-size: 16px !important;
        font-weight: 800 !important;
        line-height: 21.82px !important;
    }
    .alice-carousel .hotJobCardWrap .specialtyJobCardWrap {
        padding: 13.93px 18.57px 13.93px 18.57px !important;
        margin-top: 0px !important;
    }
    .hotJobCardWrap .payRateSvg {
        height: 16.71px !important;
        width: 16.71px !important;
    }
    .hotJobCardWrap .jobTitleV2 {
        width: 287.86px !important;
        color: #626C7D;
        font-size: 12px !important;
        padding-left: 18.57px !important;
        margin-bottom: 9.29px !important;
        margin-top: 0px !important;
    }
    .hotJobCardWrap .iconLabel {
        font-size: 12px !important;
        padding-right: 4.64px !important;
        padding-left: 6px !important;
        font-weight: 600 !important;
        line-height: 15.79px !important;
        color: #656E7E !important;
    }
    .hotJobCardWrap .imInterestedBtn {
        height: 46.14px !important;
    }
    .hotJobsCarousel .jobCardIcons {
        margin-left: 2px !important;
        padding-bottom: 13.93px !important;
    }
    .hotJobCardWrap .imInterestedBtn,
    .hotJobCardWrap .imInterestedBtn:disabled {
        line-height: 21.82px !important;
    }
    .hotJobCardSingleWrap {
        height: 291.03px !important;
        width: 100% !important;
    }
    .hotJobSingleCarousel .alice-carousel .jobCardMain {
        height: 291.03px !important;
    }
    .hotJobSingleCarousel .alice-carousel__stage-item.__active {
        width: 100% !important;
    }
    .hotJobCardSingleWrap .hotJobChip {
        padding: 20px 0px 0px 20px !important;
    }
    .quickOfferLabel,
    .hotJobCardSingleWrap .mainCarouselsection .quickOfferLabel {
        height: 22px !important;
        width: 83px !important;
    }
    .hotJobCardSingleWrap .quickOfferLabel .MuiChip-label {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        position: relative !important;
        left: 4px !important;
    }
    .hotJobCardSingleWrap .quickOfferLabel .MuiChip-icon {
        width: 9.34px !important;
        height: 12px !important;
        top: 1.5px !important;
        left: 3.32px !important;
        position: relative !important;
        margin-bottom: 5px !important;
    }
    .hotJobCardSingleWrap .jobCardHeader {
        padding: 10px 0px 10px 20px !important;
    }
    .hotJobCardSingleWrap .jobFacilityName {
        font-size: 14px !important;
        min-height: 20.03px;
        line-height: 18px;
        font-weight: 700;
        margin-top: 10px;
    }
    .alice-carousel .hotJobCardSingleWrap .specialtyJobCardWrap {
        padding: 0px 0px 0px 20px !important;
    }
    .hotJobSingleCarousel .jobCardMainWrapV2 .hospitalNameV2 {
        width: 310px;
        margin-right: 12px;
        font-size: 20px;
        font-weight: 700;
        color: #626c7d;
        line-height: 24px;
        min-height: 75px;
    }
    .hotJobSingleCarousel .specialtyNameJobCardV2 {
        font-size: 18px !important;
        font-weight: 800 !important;
        line-height: 24.55px !important;

    }
    .hotJobCardWrap .payRateSvg {
        height: 18px !important;
        width: 18px !important;
    }
    .hotJobCardSingleWrap .jobTitleV2 {
        color: #626C7D;
        font-size: 14px !important;
        font-weight: 700;
        padding-left: 20px;
        line-height: 21px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .hotJobCardSingleWrap .iconLabel {
        font-size: 12px !important;
        line-height: 17px !important;
        font-weight: 600 !important;
        color: #656E7E !important;
    }
    .hotJobSingleCarousel .jobCardIcons {
        margin-left: 4px !important;
        padding-bottom: 10px !important;
    }
    .hotJobCardSingleWrap .imInterestedBtn,
    .hotJobCardSingleWrap .imInterestedBtn:disabled {
      height: 51px !important;
      width: 100% !important;
      font-size: 18px !important;
      border-radius: 0 0 20px 0 !important;
      padding: 12px 15px !important;
      font-family: $fontfamily-primary;
      font-weight: 700 !important;
      line-height: 24.55px !important;
    }
    .jobSearchContainerWithCompleteProfile{
        height:160px !important;
        padding-left:0;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .hotJobsCarousel .alice-carousel {
        margin-left: 0;
    }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
    .hotJobsCarousel .alice-carousel {
        margin-left: 0;
    }
}

@media only screen and (min-width: 901px) {
    .jobSearchContainerWithHotJobs,.jobSearchContainer {
        width: 75%;
    }
}

@media only screen and (max-width: 900px) {
    .jobSearchContainerWithHotJobs,.jobSearchContainer {
        width: 100%;
    }
}