@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500&display=swap");

$color-primary: var(--primary-900-main);
$color-primary-shade-light: var(--primary-900-light);
$color-primary-dark: var(--primary-900-dark);
$color-primary-800: var(--primary-800);
$color-primary-700: var(--primary-700);
$color-primary-500: var(--primary-500);
$color-primary-400: var(--primary-400);
$color-primary-300: var(--primary-300);
$color-primary-200: var(--primary-200);
$color-primary-100: var(--primary-100);
$color-primary-50: var(--primary-50);
$color-secondary: #d7282f;
$color-secondary-light: #f83d34;
$color-secondary-light-50: #ffebee;
$color-tertiary: #4270c9;
$color-success: #008122;
$color-error: #ce0036;
$color-gray: #7b7b7b;

$generic-logo: "imagesV2/xperience_rebrand_logo.svg";
$color-background-light: #000;
$color-background-dark: rgba(0, 0, 0, 0.8);

$fontfamily-primary: "Nunito Sans", Helvetica, Arial, sans-serif;

:export {
  colorPrimary: $color-primary;
  colorSecondary: $color-secondary;
  colorTertiary: $color-tertiary;
  colorBackgroundLight: $color-background-light;
  colorBackgroundDark: $color-background-dark;
  colorSuccess: $color-success;
  colorError: $color-error;
  colorGray: $color-gray;
  fontFamilyPrimary: $fontfamily-primary;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1635px;
  }
}

@mixin breakpoint($breakpoint) {
  @if $breakpoint == "xs" {
    @media (min-width: 0px) { @content; }
  } @else if $breakpoint == "sm" {
    @media (min-width: 600px) { @content; }
  } @else if $breakpoint == "md" {
    @media (min-width: 900px) { @content; }
  } @else if $breakpoint == "lg" {
    @media (min-width: 1200px) { @content; }
  } @else if $breakpoint == "xl" {
    @media (min-width: 1536px) { @content; }
  }
}

@mixin breakpoint-only($breakpoint) {
  @if $breakpoint == "xs" {
    @media (max-width: 599px) { @content; }
  } @else if $breakpoint == "sm" {
    @media (min-width: 600px) and (max-width: 899px) { @content; }
  } @else if $breakpoint == "md" {
    @media (min-width: 900px) and (max-width: 1199px) { @content; }
  } @else if $breakpoint == "lg" {
    @media (min-width: 1200px) and (max-width: 1535px) { @content; }
  } @else if $breakpoint == "xl" {
    @media (min-width: 1536px) { @content; }
  }
}