.DoormatLinks {
  background-color: transparent;
  max-width: 300px;
}

.DoormatTerms {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 30px;
  width: fit-content;
}

.DoormatSubLink {
  color: #fff;
  width: fit-content;
  font-size: 0.875rem;
  &:hover {
    opacity: 0.6;
    color: #fff;
  }
}

@media screen and (max-width: 576px) {
  .DoormatLinks {
    padding-left: 70px;
  }

  .DoormatTerms{
    margin-top: 50px;
  }
}
