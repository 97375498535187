@import "src/theme/variables.scss";

.menuItemInner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .iconDateWrapper {
    display: flex;
    align-items: center;

    .date {
      margin: 0;
      font-size: 14px;
      color: $color-primary;
      padding-left: 15px;
      line-height: initial;
    }
  }
  .dollar {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: $color-primary;
  }
}

.paystubsPageWrapper {
  .pageHeading {
    color: #373c73;
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 32px;
    margin: 0;
  }
  .grossPay {
    font-size: 24px;
    color: $color-primary;
    background: #dadbdf;
    border-radius: 10px;
    padding: 12px 20px;
    display: flex;
    margin-bottom: 50px;
    font-weight: bold;
    justify-content: space-between;
    h4 {
      margin: 0;
      font-weight: bold;
    }
  }
  .paystubsPageHeader {
    .paystubsPageHeaderInner {
      padding-bottom: 45px;
      .paystubsPageHeaderRow {
        margin-right: 0;
      }
    }
    .payCheckDateDropdownWrapper {
      display: flex;
      flex-direction: row;
      padding-right: 23px;
      .payCheckDateDropdown {
        width: 100%;
        margin-left: 15px;
        label {
          background: #fff;
          padding: 0 5px;
          font-size: 14px;
          letter-spacing: 0.47px;
        }
      }
    }
    .payPeriod {
      margin: 0;
      color: #4b4b4b;
      border-left: 1px solid #d6d6d6;
      border-right: 1px solid #d6d6d6;
      padding-right: 15px;
      padding-left: 15px;
      span {
        font-size: 14px;
        text-transform: capitalize;
        letter-spacing: 0.47px;
      }
      .payPeriodDate {
        font-size: 16px;
        margin: 0;
        letter-spacing: -0.05px;
        text-transform: uppercase;
        padding-top: 5px;
      }
    }
    .patRate {
      border-right: 1px solid #d6d6d6;
      padding-right: 15px;
      padding-left: 15px;
      h4 {
        font-size: 30px;
        font-weight: bold;
        margin: 0;
        color: $color-primary;
        letter-spacing: -0.09px;
      }
      span {
        font-size: 12px;
        text-align: left;
        color: #000000;
        b {
          margin-left: 7px;
        }
      }
      label {
        color: #4b4b4b;
        margin: 0;
        font-size: 14px;
        letter-spacing: 0.5px;
        b {
          color: $color-primary;
        }
      }
    }
    .viewPaystubButtonWrapper {
      padding: 9px 15px;
      text-align: center;
      .viewPaystubButton {
        font-size: 14px;
        font-family: $fontfamily-primary;
        text-transform: none;
        align-self: center;
        font-weight: bold;
        border-radius: 21px 21px 21px 0px;
        background-color: $color-primary;
        white-space: nowrap;
        letter-spacing: 0.91px;
        color: #FFFFFF;
        padding: 6px 12px;
        border: 1px solid #707070;
      }
    }
  }
  .table-heading {
    font-weight: bold;
    border-top: 1px solid #9ba0c1;
    letter-spacing: -0.09px;
    color: $color-primary;
    font-size: 24px;
    margin: 0;
    padding: 20px 0;
  }
  .tableClientName {
    background-color: #fafafa !important;
  }
  .paystubsTable {
    table {
      border-collapse: collapse;
      padding: 5px;
      width: 100%;
      font-size: 16px;
      box-shadow: 0px 1px 3px #3f3f4426;
      border-radius: 5px;
      margin-bottom: 50px;
      table-layout: fixed;
      letter-spacing: -0.06px;
      th {
        border-bottom: 1px solid #e8e8e8;
        padding: 16px 20px;
        background: #f3f3f3;
        color: #3a3b3f;
        &:last-child {
          text-align: right;
        }
        &:nth-child(2) {
          padding-left: 120px;
        }
      }
      td {
        border-bottom: 1px solid #e8e8e8;
        padding: 14px 20px;
        background: #ffffff;
        color: #3a3b3f;
        &:last-child {
          text-align: right;
          font-weight: bold;
        }
        &:nth-child(2) {
          padding-left: 120px;
        }
      }
    }
  }
  .paystubsEarningTable {
    margin-bottom: 50px;
    .amountCol {
      text-align: right !important;
    }
    table {
      border-collapse: collapse;
      padding: 5px;
      width: 100%;
      font-size: 16px;
      box-shadow: 0px 1px 3px #3f3f4426;
      border-radius: 5px;
      table-layout: fixed;
      th {
        border-bottom: 1px solid #e8e8e8;
        padding: 16px 20px;
        background: #f3f3f3;
        color: #3a3b3f;
      }
      td {
        border-bottom: 1px solid #e8e8e8;
        padding: 14px 20px;
        background: #ffffff;
        color: #313030;
        word-break: break-all;
        &:last-child {
          font-weight: bold;
        }
      }
    }
  }

  .paystubsPageFooter {
    font-size: 20px;
    margin: 0;
    color: $color-primary;
    display: flex;
    justify-content: space-between;
    padding: 18px;
    box-shadow: 0px 1px 3px #3f3f4426;
    border: 1px solid #e5e5e5;
    border-radius: 10px;

    h4 {
      font-size: 18px;
      margin: 0;
    }
  }

  .datePaycheckIcon {
    margin-left: 12px;
    height: 58px;
    width: 58px;
  }
}

.myPaystubWrap {
  margin-top: 0rem !important;
  background-color: #fff;
}

.payCheckDate {
  margin: 0;
  font-size: 16px;
  color: $color-primary;
  line-height: initial;
}

.payCheckDateDropdownWrapper.MuiSelect-selectMenu .MuiInputBase-input,
.signupDropdown,
.MuiSelect-select,
.MuiSelect-select:focus {
  color: $color-primary;
  font-size: 16px !important;
}

.payCheckDateDropdownWrapper .payCheckDateDropdown label.MuiInputLabel-root.Mui-focused {
  color: $color-primary;
}

.payCheckDateDropdownWrapper .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-primary;
}

.payCheckDateDropdown {
  .MuiInputBase-formControl {
    &:hover {
      fieldset {
        border-color: $color-primary;
      }
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .mainPaystub {
    width: 100vw !important;
    padding: 1rem 0rem !important;
    margin-top: 50px;
  }

  .hospTimesheet {
    height: auto !important;
  }

  .directDeposit {
    width: 100vw !important;
    margin: 20px 0px 7px 0px !important;
  }

  .myProfileSubNav {
    width: 100%;
    padding: 0rem 0rem 0rem 0rem;
    background-color: $color-primary;
    color: #fff;
    box-shadow: inset 0px 1px 4px 2px rgba(0, 0, 0, 0.0705882353);
    position: fixed;
    z-index: 99;
  }

  .payrollNavMobActive {
    background-color: $color-primary;
    font-size: 1rem;
    padding: 20px 10px;
    color: #fff;
    font-weight: bold;
  }

  .payrollNavMobActive .dropDownIcon {
    right: 14px;
    margin-top: 0px;
  }

  .payrollPaystubNavWrap {
    position: relative !important;
    background-color: #f0f1f4;
    z-index: 99;
    height: auto !important;
  }

  .mainPaystub .titlepaystub {
    padding: 2rem 0rem 0.5rem 0rem !important;
    font-size: 1.3rem !important;
  }

  .paystubDetailHead {
    font-size: 1.3rem !important;
  }

  .paystubsPageWrapper {
    .table-heading {
      font-size: 18px;
      padding: 18px 0;
    }

    .pageHeading {
      font-size: 24px;
      color: #373c73;
      letter-spacing: 0px;
      text-align: left;
      padding-bottom: 12.28px;
    }

    .paystubsPageHeader {
      box-shadow: 3px 3px 10px #00000029;
      border-radius: 10px;
      padding: 20px 14px 14px 14px;
      margin-bottom: 30px;
      border: 1px solid #d6d6d6;

      .payCheckDateDropdownWrapper {
        padding-right: 0;
      }
      .patRate {
        text-align: center;
        border: 0;
        padding: 0 12px 30px 12px;
        h4 {
          font-size: 41px;
          font-weight: bold;
          margin: 0;
          color: $color-primary;
          letter-spacing: -0.13px;
        }
        span {
          font-size: 12px;
          text-align: left;
          color: #000000;
        }
      }

      .payPeriod {
        border: 0;
        text-align: center;
        padding: 31px 12px;
        letter-spacing: 0.4px;
        color: #4b4b4b;
        font-size: 12px;
        span {
          font-size: 12px;
          text-transform: capitalize;
          letter-spacing: 0.4px;
        }

        .payPeriodDate {
          font-size: 12px;
          margin: 0;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          padding-top: 5px;
        }
      }
      .viewPaystubButtonWrapper {
        padding-left: 35px;
        text-align: center;
        .viewPaystubButton {
          margin: 0 16px;
          font-family: $fontfamily-primary;
          font-size: 15px;
          text-transform: none;
          align-self: center;
          font-weight: bold;
          border-radius: 21px 21px 21px 0px;
          background-color: $color-primary;
          white-space: nowrap;
          letter-spacing: 0.91px;
          color: #ffffff;
          padding: 10px 20px;
          border: 1px solid #707070;
        }
      }

      .grossPay {
        color: $color-primary;
        background: #dadbdf;
        border-radius: 10px;
        padding: 12px 20px;
        display: flex;
        margin-bottom: 0px;
        font-weight: bold;
        justify-content: space-between;
        h4 {
          margin: 0;
          font-weight: bold;
          font-size: 18px;
        }
      }

      .paystubsPageHeaderInner {
        padding-bottom: 30px;
      }
    }

    .paystubsTable {
      table {
        box-shadow: 0px 3px 10px #3f3f4433;
        th {
          font-size: 14px;
          &:nth-child(2) {
            padding-left: 20px;
          }
        }
        td {
          font-size: 12px;
          &:nth-child(2) {
            padding-left: 20px;
          }
        }
      }
    }

    .datePaycheckIcon {
      height: 58px;
      width: 58px;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
  .mainPaystub {
      width: 100vw;
      padding: 1rem 0rem;
    }

  .payrollNavbarV2 .nav-item a {
    padding: 10px 12px !important;
  }

  .payrollNavbarV2 .nav-item a:active,
  .payrollNavbarV2 .nav-item a.active {
    padding: 10px 12px !important;
  }

  .noPaddingMob {
    padding: 2rem 1.5rem 1rem 2rem;
  }

  .payRollNavMargin {
    margin-bottom: 24px !important;
  }

  .payRollNavMargin .payrollNav {
    margin-bottom: 0px !important;
  }

  .directDeposit {
    margin: 20px 9px 7px 0px !important;
  }
  .paystubsPageHeaderRow .col-xl-3 {
    flex: 0 0 25%;
    max-width: 24% !important;
}

  .paystubsPageWrapper {
    .paystubsPageHeader {
      .paystubsPageHeaderInner {
        .paystubsPageHeaderRow {
          .payCheckDateDropdownWrapper {
            padding: 0 15px;
          }

          .payPeriod {
            padding: 0 10px;

            .payPeriodDate {
              font-size: 15px;
              padding-top: 5px;
            }
          }

          .viewPaystubButtonWrapper {
            padding: 9px 9px;
            .viewPaystubButton {
              padding: 5px 10px;
              font-size: 11px;
              letter-spacing: 0.25px;
            }
          }
          .patRate {
            padding: 0 10px;
            h4 {
              font-size: 24px;
            }
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1281px) And (max-width: 1440px) {
  .paystubsPageWrapper {
    .paystubsPageHeader {
      .viewPaystubButtonWrapper {
        padding: 9px 9px;

        .viewPaystubButton {
          font-size: 12px;
          letter-spacing: 0.45px;
        }
      }
      .payPeriod {
        padding: 0 10px;
      }
      .col-xl-3 {
        flex: 0 0 25%;
        max-width: 24% !important;
      }

      .patRate {
        padding: 0 10px;
        h4 {
          font-size: 24px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .paystubsPageWrapper {
    .paystubsTable {
      table {
        margin-bottom: 40px;
        th {
          padding: 8px 10px;
          &:nth-child(2) {
            padding-left: 60px;
          }
        }
        td {
          padding: 8px 10px;
          &:nth-child(2) {
            padding-left: 60px;
          }
        }
      }
    }

    .paystubsEarningTable {
      margin-bottom: 40px;
      table {
        box-shadow: 0px 3px 10px #3f3f4433;
        td[colspan="2"],
        th[colspan="3"] {
          width: 0;
          padding: 0;
        }
        th {
          font-size: 14px;
          padding: 8px 10px;
        }
        td {
          font-size: 12px;
          padding: 8px 10px;
        }
      }
    }
    .paystubsPageFooter {
      font-size: 16px;
      flex-direction: column;
    }
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1900px) {
  .paystubsPageHeaderRow .col-xl-3 {
    flex: 0 0 25%;
    max-width: 24% !important;
}
  .paystubsPageWrapper {
    .paystubsPageHeader {
      .viewPaystubButtonWrapper {
        padding: 9px 15px;

        .viewPaystubButton {
          font-size: 13px;
        }
      }
    }
  }
}
