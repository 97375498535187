@import "src/theme/variables.scss";

.onBboxShadow {
  box-shadow: 0px 3px 10px #0000000d;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  padding: 0 20px;
}
.onBFormSaveBtn {
  text-align: center;
  margin: 2rem 0 1rem;
}
.onBaddOneRecord {
  color: $color-primary;
  font-size: 1rem;
  padding: 20px;
}

@media all and (max-width: $breakpoint) {
  .onBboxShadow,
  .onBtableBGMobile {
    background-color: #ffffff;
  }
  .onBFormBGMobile,
  .onBaddOneRecord {
    background-color: #f0f1f4;
  }
  .onBFullWidthMob {
    width: 100% !important;
    padding: 10px 0 !important;
  }
}
