@import "src/theme/variables.scss";

.customBox {
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    padding: 10px 26px 0px 26px;
    margin-top: 0px;

    legend {
        color: #707070;
        width: auto;
        line-height: 1.36;
        letter-spacing: normal;
        font-weight: normal;
    }

    div[disabled],
    legend[disabled] {
        color: #888;
    }

    .mobileView {
        display: none;
    }

    .SsnAndDobLabel {
        background-color: #fff;
        padding: 0 7px;
        color: #707070;
        font-size: 14px;
        line-height: 1.36;
        width: auto;
    }
}

.customBox+legend {
    background: #fff;
}

.noBorder {
    border: none;
}

.inputText {
    input {
        color: $color-primary;
        font-size: 1rem;
        font-weight: 500;
        padding: 16px 15px;
        text-transform: capitalize;

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px #ffffff inset;
            -webkit-text-fill-color: $color-primary-shade-light;
        }
    }

    .MuiInputLabel-root.MuiInputLabel-shrink.Mui-error {
        color: #ce0036;
    }

    .MuiFormLabel-asterisk.Mui-error {
        color: inherit;
    }

    label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 1rem;
    }

    label.Mui-focused {
        color: $color-primary;

        & .MuiInputBase-multiline {
            & fieldset {
                border: 2px solid $color-primary-shade-light;
            }
        }
    }

    .Mui-error {
        .MuiOutlinedInput-notchedOutline {
            border-color: #ce0036;
        }
    }

    div {
        &.Mui-focused fieldset {
            border: 1px solid $color-primary;
            color: $color-primary;
            border-color: $color-primary;
        }
    }

    div[class*="Mui-error"] {
        fieldset.MuiOutlinedInput-notchedOutline {
            border-color: #ce0036;
        }
    }
}

.lockIcon {
    margin-left: 5px;
    width: 14.7px;
    height: 17.4px;
}

.ssnBox {
    margin-bottom: 0;

    & span[class*="Mui-checked"] {
        color: #d7282f;
        padding: 0px 9px;
    }
}

.withoutCheckbox {
    margin-bottom: 26px;
}

.removeCheckboxOnModal {
    margin-bottom: 15px !important;
}

.ssnContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.ssnContainer label{
    padding-left: 10px;
}

.MuiIconButton-root:hover {
    background-color: unset;
}

.checkBoxLabel {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #707070;
}

.ssnTitle {
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
}

.readOnlyBox {
    border: none;
    border-radius: 0;
    padding: 0;
}

.noDisplay {
    display: none;
}

.ssnSubtitle {
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1b1b1b;
}

.dobReadonly{
    padding-left: 12px;
}

@media screen and (max-width: 768px) {
    .customBox:not(.readOnlyBox) {
        margin-top: 40.5px;
        padding: 15px;

        legend {
            display: none;
        }

        .mobileView {
            display: block;
            margin-bottom: 23px;
            padding: 0px;
        }

        .ssnTextfieldLabel {
            top: -5px !important;
        }
    }
    .ssnContainer label{
        padding-left: 10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
    .dobReadOnly {
        margin-top: 3rem;
    }
}