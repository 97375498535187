.skeletonWrapper {
    border-radius: 10px;
    width: 150px;
    min-height: 5vh;
    margin-bottom: 10px;
}

.iconSkeleton {
    border-radius: 10px;
    width: 20%;
    min-height: 5vh;
}

.dropdownSkeleton {
    border-radius: 10px;
    margin-left: 20px;
    width: 100%;
    min-height: 5vh;
}

.payPeriodSkeleton {
    border-radius: 10px;
    width: 100%;
    min-height: 5vh;
}

.payRateSkeleton {
    border-radius: 10px;
    width: 100%;
    min-height: 5vh;
}

.viewButtonSkeleton {
    border-radius: 10px;
    width: 100%;
    min-height: 5vh;
}

.grossPaySkeleton {
    border-radius: 10px;
    min-height: 5vh;
    margin-bottom: 40px;
    width: 100%;
    margin-top: 3rem;
}

.tableSkeleton {
    border-radius: 10px;
    min-height: 40vh;
    margin-bottom: 40px;
    width: 100%;
}

.payStatementsSkeleton {
    display: flex;
    flex-direction: row;
}

.payStstementSkeleton {
    border-radius: 10px;
    width: 80%;
    min-height: 5vh;
    margin-left: 20px;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {

    .mainPaystub {
        width: 100vw !important;
        padding: 1rem 0rem !important;
        margin-top: 70px;
    }

    .grossPaySkeleton {
        min-height: 4vh;
        margin-bottom: 20px;
        width: 100%;
        margin-top: 2rem;
    }

    .skeletonWrapper {
        width: 100%;
        min-height: 4vh;
    }

    .payStatementsSkeleton {
        margin-top: 0rem !important;
    }

    .iconSkeleton {
        min-height: 4vh;
    }

    .dropdownSkeleton {
        width: 72%;
        min-height: 4vh;
    }

    .payPeriodSkeleton {
        margin-left: 3rem;
        width: 80%;
        min-height: 4vh;
        margin-top: 3rem;
    }

    .payRateSkeleton {
        margin-left: 3rem;
        width: 80%;
        min-height: 5vh;
        margin-top: 1.0rem;
    }

    .viewButtonSkeleton {
        margin-left: 3rem;
        width: 80%;
        min-height: 4vh;
        margin-top: 3rem;
    }

    .tableSkeleton {
        min-height: 20vh;
        margin-bottom: 20px;
    }

}