.MuiInputBase-root {
  font-family: $fontfamily-primary !important;
}
.MuiPaper-root .MuiMenuItem-root {
  padding: 14px 12px;
  font-size: 0.9rem;
  color: #707070;
  font-family: $fontfamily-primary !important;
  text-transform: capitalize;
}
.onBtextfiled {
  :disabled {
    color: #888888;
    -webkit-text-fill-color: #888888;
  }
}
.onBBasicInfoBanner {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}
.legacyPageHead {
  height: 100%;
  color: #373c73;
  padding: 11%;
  text-align: center;
}

.onBSkillListWrap {
  margin-top: -2rem !important;
  margin-left: -1rem;
  background-color: #fff !important;
}
.emptySkillLabel td {
  text-align: center;
  color: $color-primary;
  font-size: 0.85rem;
  padding: 20px 0px;
}
.starIconWrapCompleted,
.starIconWrap {
  margin-top: 7px !important;
}
.googlePlaces .MuiPaper-root {
  width: 94% !important;
}
.googlePlaces .MuiMenuItem-root {
  white-space: break-spaces !important;
}

#ableIframeId {
  margin-top: 1rem;
  height: 79vh;
  margin-bottom: 18px;
}
.onBAbtTravChkHead {
  padding-top: 0rem !important;
}
.onBMainTxtField .MuiOutlinedInput-input {
  padding: 16px 15px !important;
  color: $color-primary;
  font-size: 0.9rem !important;
  text-transform: capitalize;
  font-weight: 700;
}
.onBMainTxtField .MuiSelect-outlined.MuiSelect-outlined {
  padding: 16px 15px !important;
  font-weight: 700;
  div {
    display: flex;
    flex-wrap: wrap;
  }
}

.googlePlaces {
  .MuiFormControl-root.MuiTextField-root + div + div {
    z-index: 99 !important;
  }
}
.onBMainTxtField .MuiInputBase-root {
  font-weight: 700 !important;
}
.onBMainTxtField fieldset.MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid $color-primary !important;
  border-color: $color-primary !important;
}
.onBMainTxtField .MuiOutlinedInput-notchedOutline {
  border: 1px solid #0000001f;
}
.onBMainTxtField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-primary;
}
.onBMainTxtField .MuiInputLabel-root.MuiInputLabel-shrink.Mui-error {
  color: $color-error !important;
}
.onBMainTxtField .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-error;
}
.onBAutoContInfo .MuiAutocomplete-inputRoot,
.onBtextfiled .MuiAutocomplete-inputRoot {
  padding: 0px 5px !important;
}
.onBTextFieldReq.Mui-error .MuiAutocomplete-inputRoot .MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-error;
}
.onBTextFieldReq.Mui-error .MuiInputLabel-root.MuiInputLabel-shrink {
  color: $color-error;
}
.Mui-error .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  color: $color-error;
  background-color: #ffffff;
  padding: 0px 8px 0px 1px;
}
.Mui-error .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-error;
}
.onBMainTxtField .Mui-error .MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-error;
}
.onBBackBtnPopUpPosition {
  position: absolute;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 2px 45px #00000024;
  border-radius: 4px;
  z-index: 1;
  width: 30%;
  margin-top: -14rem;
  left: 42%;
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .onBPopUpCloseBtn {
    left: 92%;
    bottom: 75%;
  }
}
.onBReadOnlyLabel {
  color: $color-gray;
  font-weight: 500;
}
.onBReadOnlyValue {
  color: $color-primary;
  font-weight: 600;
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .myProfilePageWrap .onBYesNoBtn {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .onBSkillGroups .MuiFormControlLabel-root {
    margin-right: 24px !important;
  }
  #ableIframeId {
    margin-top: 0rem;
  }
  .onBBackBtnPopUpPosition {
    position: absolute;
    width: 35%;
  }
}
@media screen and (min-width: 0px) and (max-width: 768px) {
  .onBSkillListWrap {
    margin-top: 3rem !important;
    margin-left: 0rem !important;
    background-color: #fff !important;
  }
  .onBTimeToComplete,
  .onBStepperActiveStep .onBTimeToComplete {
    color: #707070 !important;
    position: relative !important;
    font-size: 0.75rem;
    display: inline;
    margin-top: 15px;
    margin-left: 10px;
  }
  .myProfilePageWrap .onBServErrWrap {
    padding: 10px;
    margin-top: 3.5rem;
    margin-bottom: 0rem;
  }
  .onBMainTxtField .MuiOutlinedInput-input,
  .onBMainTxtField .MuiSelect-select:focus {
    padding: 17px 15px !important;
    color: $color-primary;
  }
  .onBAutoContInfo .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    height: 1.1rem;
  }
  .txtFldMBMob {
    margin-bottom: 1rem;
  }
  fieldset {
    legend {
      font-size: 0 !important;
      visibility: visible !important;
    }
  }
  .onBBackBtnPopUpPosition {
    position: absolute;
    width: 96%;
    margin-top: -14rem;
    left: 2%;
  }
  .onBBackBtnPopUpPosition .onBPopUpCloseBtn {
    left: 89%;
    bottom: 57%;
  }
}
