@import "theme/variables.scss";

$helpdesk-desktop: "./images/HELP-DESK-Desktop-cca.png";
$helpdesk-mobile: "./images/HELP-DESK- Mobile-cca.png";

@import "index.scss";
@import "onboarding.scss";

.alliedPayroll {
  min-height: 270px;
  margin-top: 9.5rem;
}

.duplicateLoginMsg {
  width: 100%;
}

@media screen and (max-width: 768px) and (min-width: 0px) {
  .alliedPayroll {
    margin-top: 6rem;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .alliedPayroll {
    margin-top: 8.5rem !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .alliedPayroll {
    margin-top: 8.5rem !important;
  }
}
