$breakpoint: 812px;
@import "DataTable.scss";
@import "OnBoardingCommon.scss";

* {
  box-sizing: border-box;
}

/* Table column sizing
================================== */
.statusColumn {
  width: 12%;
}
.actionColumn {
  width: 8%;
  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
    width: 12%;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1600px) {
    width: 10%;
  }
}
.degreeColumn {
  width: 30%;
}
.schoolNameColumn {
  width: 30%;
}
.cityColumn {
  width: 15%;
}
.stateColumn {
  width: 15%;
}
@media all and (max-width: $breakpoint) {
  .onBSpecialtyPopupMob {
    position: fixed !important;
  }
  .onBboxShadow {
    background-color: #ffffff;
  }
}
