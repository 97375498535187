@import "src/theme/variables.scss";

$color-primary: $color-primary;
$color-white: #fff;
$color-lighten-1: #e1e1e1;

body {
  padding-right: 0px;
}
.normalText {
  text-transform: none !important;
}
.MuiPaper-root .MuiMenuItem-root {
  padding: 14px 12px;
  font-size: 0.9rem;
  color: #707070;
  text-transform: capitalize;
}
.MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.textCenter {
  text-align: center;
}
.MuiFormLabel-asterisk {
  color: $color-primary;
}
.MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #ce0036;
}
.formContainer {
  font-family: $fontfamily-primary;
  color: #3a3b3f;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;

  .disableGrid {
    opacity: 0.5;
    pointer-events: none;
  }

  .MuiSelect-select,
  .MuiSelect-select:focus {
    color: $color-primary !important;
    font-size: 1rem !important;
    padding: 8px 15px !important;
    font-weight: 500;
  }
  .professionLabel {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    margin-top: -9px;
  }
  .professionText {
    color: $color-primary;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 7px;
  }
  .professionLabel[disabled] {
    color: #888;
  }
  .professionText[disabled] {
    color: #888;
  }
  .noReferenceAdded {
    text-align: center;
    margin: 4rem auto 2rem;
    p {
      font-weight: bold;
    }
  }
  .formContent {
    max-width: 1200px;
    width: 100%;
    padding: 20px;
    text-align: left;
    .titleContainer {
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 30px;
    }
    .empNameTitle {
      font-size: 1rem;
      line-height: 16px;
      color: #626c7d;
      font-weight: bold;
    }
    .empName {
      font-size: 1rem;
      line-height: 16px;
      color: #626c7d;
      text-transform: capitalize;
    }
    .empCode {
      font-size: 1rem;
      line-height: 16px;
      color: #626c7d;
    }
    .formFields {
      margin-bottom: 60px;

      .input-label-select {
        color: rgba(0, 0, 0, 0.6);
        font-size: 1rem;
      }
      .input-select.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $color-primary;
        }
      }
      .Mui-checked {
        color: $color-primary;
      }
      .MuiCheckbox-root {
        &:hover {
          background-color: transparent;
        }
      }
      .MuiFormHelperText-root,
      .errorText {
        color: #ce0036;
        font-size: 0.8rem;
        margin-bottom: 0;
      }
      .MuiFormGroup-row {
        align-items: center;
        .errorText {
          margin-left: 20px;
        }
      }
      .input-select {
        color: $color-primary !important;
        font-size: 1rem;
        font-weight: bold;
        padding: 8px 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid rgba(0, 0, 0, 0.2);
        }

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid $color-primary;
          }
        }
      }
      .input-select.Mui-disabled:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
      .input-textarea {
        textarea {
          padding-left: 0 !important;
          font-weight: 500;
          color: $color-primary;
        }
      }

      .input-text {
        input {
          color: $color-primary;
          font-size: 1rem;
          font-weight: 500;
          padding: 16px 15px !important;
          text-transform: capitalize;
          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px #ffffff inset;
            -webkit-text-fill-color: $color-primary-shade-light;
          }
        }
        .MuiInputLabel-root.MuiInputLabel-shrink.Mui-error {
          color: #ce0036;
        }
        .MuiFormLabel-asterisk.Mui-error {
          color: inherit;
        }
        label {
          color: rgba(0, 0, 0, 0.6);
          font-size: 1rem;
        }
        label.Mui-focused {
          color: $color-primary-shade-light;
          & .MuiInputBase-multiline {
            & fieldset {
              border: 2px solid $color-primary-shade-light;
            }
          }
        }
        .Mui-error {
          .MuiOutlinedInput-notchedOutline {
            border-color: #ce0036;
          }
        }
        div {
          &.Mui-focused fieldset {
            border: 1px solid $color-primary;
            color: $color-primary;
            border-color: $color-primary;
          }
        }
        div[class*="Mui-error"] {
          fieldset.MuiOutlinedInput-notchedOutline {
            border-color: #ce0036;
          }
        }
      }

      .formSubHead {
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        color: $color-primary;
        margin-bottom: 30px;
        font-family: $fontfamily-primary;
      }
      .formSubHeadDisabled {
        color: #888 !important;
      }

      .customDropDown {
        position: relative;
        .MuiFormHelperText-root {
          position: absolute;
          top: 56px;
          & + .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              border-color: #ce0036;
            }
          }
        }
      }

      .selectLabel {
        color: #00000099;
      }
      .Mui-focused.selectLabel {
        color: $color-primary-shade-light;
        & .MuiInputBase-multiline {
          & fieldset {
            border: 2px solid $color-primary-shade-light;
          }
        }
      }

      .customBox {
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        padding: 26px;
        margin-top: 15px;
        margin-bottom: 8px;
        legend {
          color: #707070;
          font-size: 13px;
          line-height: 16px;
          width: auto;
        }
        div[disabled],
        legend[disabled] {
          color: #888;
        }
        .addOrLabel {
          padding-left: 32px;
          padding-top: 47px;
        }
        .emailAndPhoneLabel {
          background-color: #fff;
          padding: 0 7px;
          color: #707070;
          font-size: 14px;
          line-height: 16px;
          width: auto;
        }
      }
      .customBox + legend {
        background: #fff;
      }

      .optionBox {
        padding: 14px 20px;
        margin: 0 0 30px 0;
        box-shadow: 0px 3px 6px #0000000d;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        .options {
          align-items: center;
          .MuiRadio-root {
            &:hover {
              background-color: transparent;
            }
          }
        }
        div[disabled] {
          color: #888;
        }
        & label {
          margin: 0;
          & span[class*="Mui-checked"] {
            color: #d7282f;
            & + .MuiFormControlLabel-label {
              color: $color-primary;
              font-weight: 500;
            }
          }
        }
      }
      .Mui-disabled {
        color: #888 !important;
        -webkit-text-fill-color: #888;
        fill: #888;
      }
    }
  }

  .MuiButton-contained {
    background-color: $color-primary;
    font-family: $fontfamily-primary;
    box-shadow: none;
    font-weight: bold;
    font-size: 1rem;
    color: #ffffff;
    padding: 7px 15px !important;
    min-width: 106px;
    &:hover {
      background-color: $color-primary !important;
    }
  }

  .MuiButton-outlined {
    color: #707070;
    border: 1px solid #707070 !important;
    font-weight: bold;
    background-color: #ffffff;
    font-family: $fontfamily-primary;
    box-shadow: none;
    font-weight: bold;
    font-size: 1rem;
    padding: 6px 15px;
    min-width: 106px;
    &:hover {
      background: #f5f5f5;
      box-shadow: 0 4px 6px 0 #00000020;
    }
  }
  .addIconMargin {
    margin-bottom: 1px;
    margin-right: 2px;
  }
}

.syncDataWrapper {
  padding: 7px 13px;
  background-color: #e8f5fc;
  color: #264c67;
  font-size: 0.9rem;
  border-radius: 10px;
  display: flex;
  margin: 30px 0;
  align-items: center;
  min-height: 50px;
}
.syncInfoIcon {
  color: #68b4f1;
}
.loadingBtnIcon {
  color: #bac4ca !important;
}
.syncDataButton {
  border: 1px solid $color-primary;
  border-radius: 5px;
  padding: 5px 18px;
  font-size: 0.9rem;
  background: transparent;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $color-primary;
  &:hover {
    background: $color-primary;
  }
}
.syncDataButton:disabled {
  border: 1px solid #bac4ca;
  background: transparent;
  color: #bac4ca;
  pointer-events: none;
}

.referTableWrap {
  .tableHead .MuiTableCell-head {
    color: #3a3b3f;
    font-weight: 700;
    line-height: 1.5rem;
    font-size: 0.875rem;
    padding: 15px;
    background-color: #f3f3f3;
  }
  .tableBody .MuiTableCell-root {
    display: table-cell;
    padding: 20px 15px 20px;
    font-size: 0.9rem;
    text-align: left;
    text-transform: capitalize;
    font-size: 0.8rem;
    color: #3a3b3f;
  }
  .MuiChip-label {
    font-weight: 700;
    font-size: 0.8rem;
    text-align: center;
  }
  .MuiChip-root {
    font-weight: bold !important;
    font-size: 0.7rem !important;
    border-radius: 25px !important;
    height: 25px !important;
    width: max-content;
    text-transform: capitalize;
  }
  .completeStatus {
    background-color: #e5ffec;
    border: 1px solid #329a4e29;
  }
  .completeStatus .MuiChip-label {
    color: #329a4e !important;
  }
  .completeStatus:hover,
  .completeStatus .MuiChip-label:hover {
    background-color: #e5ffec;
  }
  .inCompleteStatus {
    background-color: #fff4f7;
    border: 1px solid #ffe0e8;
  }
  .inCompleteStatus .MuiChip-label {
    color: #ce0036 !important;
  }
  .inCompleteStatus:hover,
  .inCompleteStatus .MuiChip-label:hover {
    background-color: #fff4f7;
    border-radius: 25px;
  }
  .expiredStatus {
    background-color: #ffe9e0;
    border: 1px solid #f83d34;
  }
  .expiredStatus .MuiChip-label {
    color: #f83d34 !important;
  }
  .expiredStatus:hover,
  .expiredStatus .MuiChip-label:hover {
    background-color: #ffe9e0;
  }
  .pendingStatus {
    background-color: #fff9f4;
    border: 1px solid #ffa150;
  }
  .pendingStatus .MuiChip-label {
    color: #ffa150 !important;
  }
  .pendingStatus:hover,
  .pendingStatus .MuiChip-label:hover {
    background-color: #fff9f4;
    border-radius: 25px;
  }
  .progressStatus {
    background-color: #f2f4ff;
    border: 1px solid #8698fc;
  }
  .progressStatus .MuiChip-label {
    color: #8698fc !important;
    border-radius: 25px;
  }
  .progressStatus:hover,
  .progressStatus .MuiChip-label:hover {
    background-color: #f2f4ff;
  }
  .declineStatus {
    background-color: #fff2f0;
    border: 1px solid #e54e40;
  }
  .declineStatus .MuiChip-label {
    color: #e54e40 !important;
    border-radius: 25px;
  }
  .declineStatus:hover,
  .declineStatus .MuiChip-label:hover {
    background-color: #fff2f0 !important;
  }
  .expiredStatus:hover .expiredStatus .MuiChip-label:hover {
    background-color: #ffe9e0 !important;
  }
  .editIcon,
  .deleteIcon,
  .viewIcon {
    color: $color-primary;
    cursor: pointer;
    min-width: 24px;
    padding: 0px 20px 0px 0px;
    font-size: 0.9rem;
  }
  .iconBtnDisabled {
    pointer-events: none;
  }
  .viewIconDisabled {
    color: rgba(0, 0, 0, 0.2);
    min-width: 24px;
    padding: 0px 20px 0px 0px;
    font-size: 0.9rem;
  }
  .editIcon:hover,
  .deleteIcon:hover,
  .viewIcon:hover {
    fill: #d7282f;
    color: #d7282f;
    background-color: transparent !important;
  }
  .tableIcons {
    padding: 0px;
  }
}

@media screen and (max-width: 480px) {
  .formContainer .formContent {
    padding: 2px;
  }
}
