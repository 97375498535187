@import "src/theme/variables.scss";

.certAndLic {
  position: relative;
  left: 0;
  right: 0;
  background: #fff;
  margin: 0 auto;
  top: 5%;
  padding: 60px;
  border: 1px solid #ddd;
  border-radius: 25px 25px 25px 0;
  z-index: 99999;
  width: 100vw;
  max-width: 448px;
  max-height: calc(100% - 72px);
  overflow-y: auto;
  @media screen and (min-height: 1000px) {
    top: 15%;
  }
}

.certAndLic::-webkit-scrollbar-track {
  margin: 20px 0;
}

@media screen and (max-width: 768px) and (min-width: 0px) {
  .certAndLic {
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 15px 15px 15px 0;
  }
}

.confirmationBox {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .confirmTitle {
    height: 26px;
    flex-grow: 0;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
    margin-bottom: 24px;
  }

  .confirmSubTitle {
    width: 288px;
    height: 38px;
    margin-bottom: 24px;
    flex-grow: 0;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
  }

  .stayOnPage {
    width: 328px;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;
    padding: 12px 32px;
    margin-bottom: 16px;
    border: none;
  }

  .discard {
    width: 328px;
    height: 46px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;
    padding: 12px 32px;
    background-color: $color-primary;
    margin-bottom: 24px;
    border: none;
  }
}
