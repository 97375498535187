@import "src/theme/variables.scss";

:export {
  colorPrimary: $color-primary;
}


.modal {
  width: 100vw;
  max-width: 800px;
  background-color: white;
  justify-content: center;
  position: relative;
  top: 15vh;
  margin: 0 auto;
  border-radius: 30px 30px 30px 0;
}

.close {
  width: 30px;
  height: 30px;
  margin-left: 94% !important;
  margin-top: 20px !important;
}

.heading {
  font-family: $fontfamily-primary;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $color-primary;
}

.divider {
  padding: 0 40px 0 0;
}

.rtoDivider {
  padding: 10px 0 0 40px;
  border-left: 1px solid #d6d8dc;
}

.submissionModal {
  padding: 0 60px 60px 60px;
}

.preferredContainer {
  padding-top: 20px;
  padding-bottom: 20px;
}

.datePickerText {
  font-family: $fontfamily-primary;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #626c7d;
  padding-top: 4.5px;
}

.tentativeEndDate {
  margin: 0 0 4px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  padding: 16px 32px 0 0;
  color: #30333a;
}

.dateText {
  margin: 4px 28px 20px 0;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal ;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $color-primary;
}

.ackTextFirst {
  text-indent: 30px;
}

.needToRequestText {
  font-family: $fontfamily-primary;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #30333a;
  padding: 0 0 12px 0;
}

.requestTimeOffContainer {
  margin-top: 20px;
}

.chipCardUnselected {
  width: 67px;
  border-radius: 20px;
  background-color: $color-primary-50 !important;
  margin-left: 10px !important;
}

.chipCardSelected {
  width: 67px;
  border-radius: 20px;
  margin-left: 10px !important;
  background-color: $color-primary !important;
}

.ackFrame {
  width: 100vw;
  max-width: 830px;
  border-radius: 30px 30px 30px 0;
  padding: 60px;
  justify-content: center;
  position: relative;
  top: 5vh;
  margin: 0 auto;
  background-color: white;
}

.acknowledgement {
  width: 377px;
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #30333a;
}

.ackText {
  align-self: stretch;
  font-family: $fontfamily-primary;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #656e7e;
}
.closeButton {
  padding: 12px 32px;
  background-color: $color-primary;
  font-family: $fontfamily-primary;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  border: solid 1px $color-primary;
  text-align: center;
  color: white; 
  border-radius: 30px 30px 30px 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
  height: 40px;
}

.text {
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 0;
 
}

.checkBox {
  width: 18px;
  height: 18px;
  border: solid 1px #888492;
  margin-right: 8px !important;
}
.textBody{
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.acceptAndAgreeText{
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  align-items: center;
  display: contents;
  color: #707070;
  padding-left: 8px;
}
.acceptAndAgreeText span {
  margin-left: 3px;
}
.quickApplyAcknowledgment {
  font-weight: 600;
  line-height: 1.14;
  color: #626c7d;
  text-decoration: underline;
}

.submitText {
  width: 67px;
  height: 22px;
  flex-grow: 0;
  font-family: $fontfamily-primary;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
}

.submitButton {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 14px;
  gap: 14px;
  margin: 20px 30% 0 30%;
  padding: 12px 32px;
  background-color: #d7282f;
  border-radius: 15px 15px 15px 0;
  color: #fff;
  border: none;

  &:disabled {
    color: #989898;
    background-color: #efefef;
  }
}

.Acknowledgement {
  width: 138px;
  height: 22px;
  flex-grow: 0;
  font-family: NunitoSans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #30333a;
  padding: 60px;
}
.confirmationBox {
  width: 100vh;
  max-width: 448px;
  justify-content: center;
  position: relative;
  padding: 60px;
  background-color: white;
  top: 20vh;
  margin: 0 auto;
  border-radius: 30px 30px 30px 0;

  .confirmTitle {
    height: 26px;
    flex-grow: 0;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary;
    margin-bottom: 24px;
  }

  .confirmSubTitle {
    width: 288px;
    height: 38px;
    margin-bottom: 24px;
    flex-grow: 0;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin-left: 10px;
  }

  .stayOnPage {
    width: 328px;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;
    padding: 12px 32px;
    margin-bottom: 16px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
  }

  .discard {
    width: 328px;
    height: 46px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;
    padding: 12px 32px;
    background-color: $color-primary;
    border: none;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
  }
}


@media screen and (max-width: 768px) {

  .modalMobile {
    width: 100%;
    height: 580px;
    padding: 20px;
    margin-left: 0;
    border-radius: 15px 15px 0 0;
    position: fixed;
    bottom: 0 !important;
    justify-content: center;
    background-color: #ffffff;
  }

  .modalMobileWithoutRtoDates {
    height: 480px;
  }

  div.modal {
    padding: 20px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    text-align: justify;
  }

  .submissionModal {
    padding: 0 0 0 0 !important;

  }

  .upperSection {
    object-fit: contain;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .closeMobile {
    width: 30px;
    height: 30px;
    margin-left: -10px !important;
    color: $color-primary !important;
  }
  
  .divider {
    padding: 0 0 0 0 !important;
  }

  .heading {
    width: 90%;
    height: 30px;
    margin: 2.5px 0 0;
    font-family: $fontfamily-primary;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: $color-primary-shade-light;
  }

  .prfStartDate {
    width: 100%;
    height: 30px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 8px 14px 12px;
    border-radius: 15px;
    border: solid 1px #d6d8dc;
    background-color: #fff;
  }

  .prfStartDate.MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  .tentativeEndDate {
    padding: 15px 32px 0 0;
  }

  .preferredContainer {
    padding-bottom: 0;
    padding-top: 0 !important;
  }

  .preferredContainerWithoutRtoDates {
    margin-bottom: 10px;
  }

  .ButtonsPrimary {
    width: 326px;
    height: 45px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 13.5px 116px;
    background-color: #efefef;
  }

  .rtoDivider {
    border-left: 0px solid #d6d8dc;
    padding: 20px 0 0 0 !important;
  }

  .quickApplyButton {
    width: 100%;
    padding: 12px 0;
    border-radius: 20px 20px 20px 0;
    font-size: 14px;
    font-weight: bold;
    box-shadow: none;
    background: #ffff;
    color: $color-primary;
    margin-top: 5px;
    border: none;
    border: 2px solid $color-primary;

    svg {
      margin-right: 5px;
    }

    &:disabled {
      color: #656e7e;
      background-color: #efefef;

      &.agreed {
        color: #329a4e;
        background-color: #e5ffec;
      }
    }
  }

  .viewAcknowledgement {
    width: 100%;
    padding: 12px 0;
    border-radius: 20px 20px 20px 0;
    font-size: 14px;
    font-weight: 700;
    background: $color-primary;
    color: #fff;
    margin-top: 20px;
    border: none;
  }

  .submitButtonM {
    width: 100%;
    padding: 13.5px 116px;
    border-radius: 20px 20px 20px 0;
    font-size: 14px;
    font-weight: 700;
    box-shadow: none;
    color: #989898;
    background-color: #efefef;
    margin-top: 20px;
    border: none;
  }

  .submitActive {
    width: 100%;
    padding: 12px 0;
    border-radius: 20px 20px 20px 0;
    font-size: 14px;
    font-weight: 700;
    box-shadow: none;
    color: #ffffff;
    background-color: #d7282f;
    margin-top: 20px;
    border: none;
  }

  .agreed {
    width: 100%;
    padding: 12px 0;
    border-radius: 20px 20px 20px 0;
    font-size: 14px;
    font-weight: 700;
    box-shadow: none;
    background: #e5ffec;
    color: #329a4e;
    border: 0;

    svg {
      margin-right: 5px;
    }
  }

  .ackModal {
    width: 100%;
    height: 90%;
    padding: 16px;
    margin-top: 10%;
    margin-left: 0;
    border-radius: 15px 15px 0 0;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
  }

  .ackUpper {
    width: 100%;
    object-fit: contain;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ackHeading {
    width: 100%;
    height: 21.3px;
    font-family: $fontfamily-primary;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $color-primary;
    justify-content: space-between;
    margin-left: 23%;
    margin-bottom: 15px;
  }

  .ackTextMain {
    height: 80%;
    align-self: stretch;
    flex-grow: 0;
    font-family: $fontfamily-primary;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #656e7e;
    padding-top: 30px;
    overflow-y: scroll;
    border: solid 1px #f8f8f9;
    padding: 8px;
  }
  .textBody{
    justify-content: flex-start;
    margin: 32px 6px 0 7px;
  }

  .confirmationBox {
    bottom: 0 !important;
    top: unset;
    left: unset;
    width: 100%;
    padding: 20px 20px 30px 20px;
    border-radius: 15px 15px 0 0;
    position: fixed;
    display: grid;
    .stayOnPage {
      font-size: 14px;
    }
    .discard {
      font-size: 14px;
    }
  }
  .acceptAndAgreeText{
    line-height: 0;
    white-space: pre-line;
    span{
    margin-left: 25px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1800px) {

  .ackFrame {
    .ackText {
      height: 67vh;
      overflow-y: scroll;

    }
  }

}

@media only screen and (max-width: 1440px) {
  .modal, .ackFrame {
    max-width: 700px;
  }

  .ackFrame {
    .ackText {
      height: 60vh;
      overflow-y: scroll;
    }
  }
}

@media only screen and (max-width: 1280px) {

  .modal, .ackFrame {
    max-width: 650px;
  }

  .submissionModal {
    padding: 0px 40px 40px 40px;
  }
  .preferredContainer {
    padding-top: 8px;
  }

  .ackFrame {
    .ackText {
      height: 58vh;
      overflow-y: scroll;
    }
  }

}