@import "src/theme/variables.scss";

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: $color-primary;
}

.rc-checkbox-inner {
  border-color: $color-primary;
  background-color: #ffffff;
}

/* Checked state */
.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: $color-primary;
}
.rc-checkbox-checked .rc-checkbox-inner {
  border-color: $color-primary;
  background-color: $color-primary;
}

.rc-checkbox-inner {
  color: $color-primary;
  margin: 0.5rem;
}

/* Hides the native input box */
input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
}

.clearAll {
  font-size: 12px;
  cursor: pointer;
  color: $color-primary-400;
  text-align: right;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background: none;
  text-decoration: underline;
}

.specialtiesTextColorDark{
  color: $color-primary-dark;
}

.specialtiesTagsChipCount {
  background-color: $color-primary !important;
  margin: 3px !important;
}

.specialtiesTagsChipCountLabel {
  color: #fff !important;
}
