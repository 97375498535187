@import "src/theme/variables.scss";

/* Onboarding Css Code starts*/
$color-secondary: #d7282f;
$color-secondary-light: #f83d34;
/* Onboarding Generic Classes */
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
}
.MuiAutocomplete-option {
  text-transform: capitalize;
}
button:focus {
  outline: none !important;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left !important;
}
.MuiAutocomplete-option {
  text-transform: capitalize;
}
div {
  outline: 0 !important;
}
hr {
  border-top: 1px solid #e1e1e1;
  width: 100%;
}
/* added class to make emailtext field as normal text as all other fields are capitalised*/
.normalText input,
.normalText {
  text-transform: none !important;
}
.onBtextfiled {
  width: 95%;
  margin-top: 25px !important;
}
.onBMainTxtField .MuiInputBase-input:-webkit-autofill,
.onBMainTxtField .MuiInputBase-input:-webkit-autofill:hover,
.onBMainTxtField .MuiInputBase-input:-webkit-autofill:focus,
.onBMainTxtField .MuiInputBase-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  -webkit-text-fill-color: $color-primary !important;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
}
.datePick .MuiInputBase-input {
  color: $color-primary;
  font-weight: 700;
  cursor: pointer;
  padding-top: 6px;
}
.MuiPickersToolbar-toolbar .MuiButton-root:hover {
  background-color: inherit;
}
.MuiPickersToolbar-toolbar .MuiButton-root h4:hover {
  cursor: default;
  background-color: inherit;
}
.onBerrorMarginChkBox {
  margin-top: 14px !important;
  color: $color-error !important;
  font-weight: 500 !important;
}
.onBerrorMarginChkBoxLegalQn {
  @extend .onBerrorMarginChkBox;
  margin-top: 8px !important;
  font-size: 0.8rem !important;
}
/*OnBoarding Css Start*/
/* Onboarding logo css */
/* Onboarding Header */
.onBfixHeader {
  position: fixed;
  background: #fff;
  width: 100vw;
  top: 0;
  z-index: 999;
  box-shadow: 0px 3px 6px #00000029;
}
.iframeFooter {
  box-shadow: inset 0px 3px 6px #00000029;
  z-index: 9;
}
.iframePhNo a:hover {
  color: $color-secondary !important;
}
.iframePhNo a {
  text-decoration: none !important;
  color: $color-primary !important;
  margin: 1rem 0;
  font-weight: 500 !important;
}
.onBGetStartEmail {
  text-decoration: underline;
}
.onBgetStartFooterLogout {
  float: right;
  text-decoration: underline;
}
.getStartFooter {
  color: $color-primary !important;
}
/* My Profile Status Progress Bar*/
.myProfileCircProgress {
  width: 110px !important;
  height: 110px !important;
}
.myProfileCircProgress .CircularProgressbar-trail {
  stroke: #cecfdb !important;
  stroke-width: 8;
}
.myProfileCircProgress .CircularProgressbar-path {
  transform: rotate(1turn) !important;
}
/* Onboarding Stepper Start */
.myProfStatus {
  margin-top: 1.9rem;
  text-align: right;
}
.myProfileNote {
  color: $color-primary;
  font-weight: 700;
  padding: 0px 0px 10px 0px;
  font-size: 1rem;
  display: block;
}
.myProfileText {
  color: #707070;
  font-size: 0.9rem;
  display: inline;
}
.myProSearchLink {
  color: $color-primary;
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  padding-left: 7px;
}
.myProSearchLink:hover {
  color: $color-secondary;
}
.myProfileWrap {
  background-color: #f0f1f4;
  margin-top: 10rem;
  margin-bottom: 1.5rem;
}
.onBStepperStep {
  display: flex !important;
  padding: 11px 25px !important;
  align-items: center !important;
  background-color: #fff !important;
  border: 1px solid $color-primary !important;
  border-radius: 50px !important;
  min-width: 170px;
}
.onBStepperStep:hover {
  background-color: #c7cbdf !important;
  box-shadow: 1px 3px 9px 1px #00000030 !important;
}
.MuiStepLabel-label.MuiStepLabel-active {
  color: #fff;
  font-weight: 500;
}
.onBStepperActiveStep {
  display: flex !important;
  padding: 11px 25px !important;
  align-items: center;
  background-color: #d7282f !important;
  border: 1px solid #d7282f !important;
  color: #fff !important;
  border-radius: 50px !important;
  min-width: 170px;
}
.onBStepperCompleted {
  display: flex !important;
  padding: 11px 25px !important;
  align-items: center;
  background-color: #39cd7e !important;
  border: 2px solid #39cd7e !important;
  border-radius: 50px !important;
  color: #fff !important;
  min-width: 170px;
}

.onBStepperActiveStep .onBStepLabel,
.onBActiveCompleteStep .onBStepLabel {
  font-weight: 900;
}

.dashboardBannerV2 .onBStepperCompleted,
.dashboardBannerV2 .onBStepperActiveStep,
.dashboardBannerV2 .onBStepperStep {
  padding: 11px 5px !important;
  border-radius: 50px !important;
}
.dashProfileNavReference .onBStepper .onBStepperCompleted,
.dashProfileNavReference .onBStepper .onBStepperStep,
.dashProfileNavReference .onBStepper .onBStepperActiveStep {
  padding: 8px 25px !important;
}
.MuiStepLabel-label.MuiStepLabel-completed {
  color: #fff;
}
.onBActiveCompleteStep {
  border: 2px solid #26b569 !important;
  box-shadow: 0px 6px 4px -3px #00000030 !important;
}
.onBStepperCompleted .MuiTypography-body2 {
  color: #fff !important;
}
.onBStepper .MuiTypography-body2 {
  font-size: 1rem;
  font-family: $fontfamily-primary;
  font-weight: normal;
  color: $color-primary;
  white-space: nowrap;
  text-align: left;
}
.onBStepper {
  margin-top: 20px;
}
.onBStepper .MuiStepper-horizontal {
  margin-top: 25px;
}
.onBStepper .MuiStep-horizontal {
  margin-top: 11px;
  min-width: 19% !important;
}
.onBStepper .MuiStepper-root {
  display: flex;
  padding: 0px !important;
  background-color: transparent;
}
.MuiStepIcon-root.MuiStepIcon-completed {
  color: $color-success !important;
}
.starIconWrap {
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: 50px;
  width: 29px;
  height: 29px;
  right: -15px;
  position: absolute;
  margin-top: 4px;
}
.starIconWrapCompleted .starIcon svg {
  fill: #fff;
  position: absolute;
  margin: 3px 0px 0px 4px;
  height: 20px;
  width: 20px;
}
.starIcon svg {
  fill: #fff;
  position: absolute;
  margin: 3px 0px 0px 4px;
  height: 20px;
  width: 20px;
}
.starIconWrapCompleted {
  background-color: #39cd7e;
  border: 1px solid #39cd7e;
  border-radius: 50px;
  width: 29px;
  height: 29px;
  right: -11px;
  position: absolute;
  margin-top: 4px;
}
.onBStepper .MuiStepLabel-iconContainer {
  display: none;
}
.onBStepper .onBStepIcons {
  margin-right: 7px;
}
.onBStepper .onBStepperStep::nth-child(5) {
  border: none;
}
.onBStepper .MuiStepLabel-label.MuiStepLabel-active {
  color: #fff;
  font-weight: normal;
}
.onBStepper .MuiStepButton-touchRipple {
  color: #fff;
}
.onBStepper .MuiStepIcon-text {
  color: #fff !important;
  font-size: 0.75rem;
  fill: #fff !important;
  font-weight: normal;
  font-family: $fontfamily-primary;
}
.onBStepper .MuiStepIcon-root {
  color: $color-primary;
  display: block;
}
.onBStepper .MuiStepIcon-active {
  color: #fff !important;
}
.onBStepper .MuiStepIcon-root.MuiStepIcon-completed {
  color: #ffffff !important;
}
.onBStepper .MuiStepIcon-active .MuiStepIcon-text {
  color: $color-secondary !important;
  fill: $color-secondary !important;
  font-weight: normal;
}
.MuiStepConnector-lineHorizontal {
  border-top-style: solid;
  border-top-width: 1.5px;
  border-color: #e1e1e1;
  display: block;
  margin-left: -14px !important;
}
.onBActiveStepper .MuiStepConnector-active .MuiStepConnector-lineHorizontal,
.MuiStep-completed .MuiStepConnector-lineHorizontal {
  border-color: $color-success !important;
}
.onBfixHeader [type="button"]:not(:disabled),
.onBfixHeader [type="reset"]:not(:disabled),
.onBfixHeader [type="submit"]:not(:disabled),
.onBfixHeader button:not(:disabled) {
  cursor: default;
}
.onBTimeToComplete {
  color: #707070;
  display: inline;
  font-style: italic;
  font-size: 0.7rem;
  font-weight: normal;
}
.onBStepperActiveStep .onBTimeToComplete {
  color: #ffffff;
  display: inline;
}
.onBStepperCompleted .onBTimeToComplete {
  display: none;
}
.onBStepper .MuiStepConnector-root {
  margin-top: 7px;
}
/* Onboarding Stepper Ends */

/* Welcome Onboarding Start*/
.onbWelcometxt {
  padding-top: 3rem;
}
.onBAlmostDoneWrap .onbWelcometxt {
  padding-top: 0rem;
}
.onBMainLetsGo {
  padding: 12rem 0 0 1rem;
}
.onBAlmostDoneWrap {
  padding: 14rem 0 0 0;
}
.onBLetsGo {
  margin-top: 5rem;
}
.onBBlueBackgLetsGo {
  background-color: #ecedf4;
  height: 97.6vh;
}
.onBBlueBgAlmostDone {
  background-color: #ecedf4;
  height: 90vh;
}
.onBLetsGoTitle {
  color: $color-primary;
  font-size: 1.8rem;
  font-family: $fontfamily-primary;
  font-weight: 700;
  text-align: center;
  padding: 20px 0px;
}
.onBSkillCheckList {
  padding: 20px 100px;
}
.onBLetsGoDesc {
  color: $color-primary;
  font-size: 1.2rem;
  font-family: $fontfamily-primary;
  padding: 0rem 9rem 1rem 9rem;
  text-align: center;
}
.onBAlmostDoneText {
  color: $color-primary;
  font-size: 1.4rem;
  font-family: $fontfamily-primary;
  padding: 0rem 1rem 2rem 1rem !important;
  text-align: left;
}
.onBAlmostDoneList li {
  color: $color-primary;
  font-size: 1.2rem;
  font-family: $fontfamily-primary;
  padding: 0.4rem 0rem;
}
.onBAlmostDoneList ::marker {
  color: $color-secondary;
}
.onBLetsGoBtn {
  text-align: center;
}
.onBcompleteSkillsBtn {
  text-align: left;
  display: inline-block;
}
.onBLetsGoBtn button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  background-color: $color-secondary !important ;
  color: #ffffffde;
  padding: 5px 20px;
}
.onBLetsGoBtn button.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.onBLetsgoImg {
  background-image: url(./images/onboarding/OnBWelcome.svg);
  height: 600px;
  background-repeat: no-repeat;
  background-size: 95% !important;
}

.onBMainTxtField .MuiFormControl-root {
  width: 100%;
}
.onBMainTxtField .MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent;
}
.onBMainTxtField .MuiSelect-select,
.MuiSelect-select:focus {
  font-size: 0.9rem !important;
}
.onBPrimaryBtn {
  background-color: $color-secondary !important;
  font-weight: 700 !important;
  color: #fff !important;
  padding: 5px 40px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}
.onBPrimaryBtn:disabled {
  background-color: #e1e1e1 !important;
  color: #888888 !important;
  font-weight: 700 !important;
}
.onBPrimaryBtn:hover {
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.onBNextBtn {
  text-align: center;
  margin: 1rem 0rem 4rem 0rem;
}
.kentuckyLaw {
  color: $color-primary;
}
.onBWorkExpSaveBtn {
  text-align: center;
  margin: 2rem 0;
}
.onBGrayButton {
  padding: 5px 20px !important;
  background-color: #e1e1e1 !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  color: #707070 !important;
  border-radius: 5px !important;
}
#defaultValue {
  color: $color-primary-dark !important;
  font-size: 0.8rem;
  font-weight: 700;
}
.onBMainTxtField .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #888888 !important;
}
.onBMainTxtField .MuiInput-underline:after {
  border-bottom: 1px solid $color-secondary !important;
}
.onBMainTxtField .MuiFormLabel-root {
  color: #888888;
  font-weight: normal !important;
  line-height: 1rem;
  background-color: #ffffff;
  padding: 0 7px 0 2px;
}
.onBMainTxtField .MuiFormLabel-root.Mui-focused {
  color: $color-primary;
}
.onBMainTxtField .MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}
.onBMainTxtField .MuiInput-input {
  color: $color-primary;
  font-weight: 500 !important;
  text-transform: capitalize;
}
.onBMainTxtField .Mui-disabled {
  fill: #888888;
  color: #888888;
}
.onBMainTxtField .MuiSelect-selectMenu {
  color: $color-primary !important;
}
.onBFNSelected .MuiFormLabel-root {
  color: $color-primary-dark;
  font-weight: 700;
}
.onBFNSelected .MuiInput-underline:before {
  border-bottom: 1px solid $color-primary-dark;
}
.onBFNSelected .MuiFormLabel-root.Mui-focused {
  color: $color-primary-dark !important;
}
.onBFNSelected .MuiInput-underline:after {
  border-bottom: 1px solid $color-primary-dark !important;
}
.onBFNSelected .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid $color-primary-dark !important;
}

.onBWorkExpRadio {
  color: #888888;
  font-size: 0.9rem;
  margin-right: 16px;
}
.onBAbtTravDrop .MuiFormControl-root {
  width: 100% !important;
}
.onBWorkExpRadio .MuiTypography-body1 {
  font-size: 0.9rem;
  color: #888888;
}
.onBWorkExpRadio .onBchkBoxLabel {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 0px;
}
.onBWorkExpRadio .MuiFormGroup-root {
  display: inline-block;
  margin-right: 15px;
}
.onBWorkExpRadio .onBWorkExpErrorChkBox {
  position: absolute;
  margin-top: 7px !important;
  display: inline-block !important;
}
.onBChipSelected {
  background-color: $color-secondary !important;
  padding: 20px 25px !important;
  border-radius: 50px !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}
.onBChipSelected .MuiChip-label {
  color: #fff !important;
}
.onBOtherChip .MuiChip-label {
  color: #888888 !important;
  font-weight: 700;
}
.onBOtherChip {
  padding: 20px 25px !important;
  border-radius: 50px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  cursor: pointer !important;
  background-color: #e8ecf3 !important;
  box-shadow: none !important;
}
.onBMainTxtField .MuiFormControl-marginNormal,
.onBAbtTravDrop .MuiFormControl-marginNormal {
  margin-top: 0px;
  margin-bottom: 8px;
}

input[aria-describedby] {
  & + .MuiInputAdornment-root {
    & + fieldset {
      border-color: $color-secondary !important;
      border-width: 1px !important;
    }
  }
}

.onBAutoContInfo
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
  .MuiAutocomplete-input:first-child {
  padding: 6px 0px 6px 0px !important;
}
.onBAutoContInfo .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-weight: 700 !important;
  color: $color-primary;
}
.onBSubHeading {
  color: $color-primary;
  font-weight: bold;
  font-size: 1.3rem;
  padding-top: 30px;
  font-family: $fontfamily-primary;
}
.onBselectOpt {
  color: #c9c9c9;
  font-size: 0.7rem;
}
.errorNote {
  color: $color-error !important;
  padding: 0.75rem 0rem 0.5rem !important;
  font-weight: 500 !important;
  font-size: 0.8rem !important;
  text-align: center !important;
}
.onBServErrWrap {
  background-color: #fffcfc !important;
  border: 1px solid #ffd5d5;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 1.5rem;
  color: $color-error;
  box-shadow: 1px 2px 5px 0px #00000030;
}
.onBServErrWrap svg {
  color: $color-error;
  fill: $color-error;
  margin-top: -2px;
}
.onBServErrTxt {
  font-weight: 700;
  font-size: 1.1rem;
  padding-left: 8px;
}
.onBServErrSubTxt {
  font-size: 0.9rem;
  color: #707070;
  padding: 4px 0px 14px 33px;
}
.onBServErrList {
  margin-left: 7px;
  margin-bottom: 0px !important;
}
.onBServErrList li {
  font-size: 0.9rem;
}
.onBModalPopupWrap {
  background-color: #fff;
  padding: 0px 22px 22px 22px;
  width: 30%;
  box-shadow: 0px 3px 25px #00000029;
  border-radius: 4px;
  text-align: center;
  font-family: $fontfamily-primary;
}
.onBModalPopupIcon svg {
  color: $color-error;
  fill: $color-error;
  margin: 25px 0px 15px 25px;
  height: 30px;
  width: 30px;
}
.onBInvalidTxt {
  font-size: 1.1rem;
  color: $color-error !important;
  font-weight: 700;
}
.onBInvalidSubTxt {
  font-size: 0.8rem;
  color: #707070;
  padding: 7px 0px 22px 0px;
}
.textNormalise .MuiInput-input {
  text-transform: none !important;
}
.onBSecondaryBtnNew {
  border: 1px solid $color-primary !important;
  padding: 4px 40px !important;
  color: $color-primary !important;
  background-color: transparent !important;
  box-shadow: none;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}
.onBSecondaryBtnNew:hover {
  background-color: #f2f2f7 !important;
}
.textNormalise .MuiInput-input {
  text-transform: none !important;
}

.onBBasicInfoBanner {
  background: url(./images/onboarding/Basic-info.svg) top center;
  height: 112px;
  margin-top: 0rem;
  margin-bottom: 1rem;
  background-size: 100%;
  background-repeat: no-repeat;
}
.onBBasicInfoChangeBanner {
  margin-bottom: 0rem;
}
.onBTextFieldReq .MuiInput-underline:before {
  border-bottom: 1px solid $color-error !important;
}
.onBTextFieldReq .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid $color-error !important;
}
.onBTextFieldReq .MuiFormHelperText-root,
.datePick .MuiFormHelperText-root,
.onBAbtTravDrop .MuiFormHelperText-root.Mui-error,
.onBSkillsGroupErr {
  color: $color-error !important;
  font-weight: 500;
  font-size: 0.8rem;
  font-family: $fontfamily-primary;
}
.onBSkillsGroupErr p {
  margin: 5px 0 1rem 0;
  font-size: 0.75rem;
}

.onBChipMargin {
  margin-top: 2.2rem;
}
.onBAboutTravBanner {
  background: url(./images/onboarding/YourPreferences.svg) top center;
  height: 121px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 4rem;
}
.onBSelfIdentificationBanner {
  background: url(./images/onboarding/SelfIdentificationBanner.svg) top center;
  height: 100px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 4rem;
  margin-left: 10px;
}
.onBBlueBackgAbtTravl {
  background: $color-primary;
  background-size: 669px 305px !important;
  background-size: cover;
  padding-top: 30px;
  height: auto !important;
  margin-bottom: 15px;
}
.onBAbtTravChkHead {
  color: $color-primary;
  font-size: 1.3rem;
  font-weight: bold;
  padding-top: 2rem;
  font-family: $fontfamily-primary;
  margin-right: 16px;
}
.onBPermanentAddMain {
  background: #ffffff;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #e0e0e0;
  padding: 8px 20px;
  margin: 2rem 0rem 1rem;
  border-radius: 5px;
}
.onBPermanentAddMain .MuiSvgIcon-root {
  font-size: 1rem !important;
}
.onBAutoContInfo .MuiSvgIcon-root {
  font-size: 1.5rem !important;
}
.onBPermanentAddMain label {
  margin-bottom: 0rem !important;
}
.onBPermanentAdd {
  margin-right: 16px;
  font-size: 0.9rem;
  color: #707070;
}
.onBAddRadioBtn {
  color: #888888 !important;
}
.onBTravelRadioBtn {
  color: $color-primary !important;
  font-weight: bold !important;
  font-family: $fontfamily-primary;
}
.onBAbtTravChkMainHead {
  color: $color-primary;
  font-size: 2rem;
  font-weight: bold;
  padding-top: 2rem;
  font-family: $fontfamily-primary;
  margin-right: 16px;
}
.onBAbtTravChk {
  font-size: 0.9rem;
}
.onBAbtTravChk .MuiTypography-body1 {
  font-size: 0.9rem;
  font-weight: 700;
}
.onBAbtTravChk .MuiIconButton-colorSecondary:hover,
.onBAbtTravChk .MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.onBLbonBAbtTravChklCheck .MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: $color-primary;
}
.onBAbtTravDrop .MuiFormLabel-root {
  font-weight: 500;
}
.onBAbtTravDrop .MuiInput-underline:after {
  border-bottom: 1px solid $color-secondary !important;
}
.onBShiftTypeTxt {
  font-size: 0.75rem;
  color: #00000099;
}
.onBAbtTravDrop .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-size: 0.8rem !important;
  font-weight: 500 !important;
}
.onBCalendarIcon {
  position: absolute;
  right: 26px;
  top: 22px;
  color: #888888;
  fill: #888888;
  height: 21px;
}

.onBClosePopupBtn {
  float: right !important;
  margin-right: -20px !important;
}
.onBClosePopupBtn .MuiSvgIcon-root {
  font-size: 1.1rem;
  color: #777777;
}
.onBEditIcon {
  font-size: 1.2rem !important;
  color: $color-primary-dark;
  fill: $color-primary-dark;
  cursor: pointer;
  min-width: 24px !important;
  padding: 0px 3px !important;
}
.onBDeleteIcon {
  color: $color-primary-dark;
  fill: $color-primary-dark;
  cursor: pointer;
  min-width: 24px !important;
  padding: 0px 3px !important;
}
.onBDeleteIcon:hover,
.onBEditIcon:hover {
  fill: $color-secondary !important;
  color: $color-secondary !important;
  background-color: transparent !important;
}
.onBSpecialtyBanner {
  background: url(./images/onboarding/SpecialtiesBanner.svg) top center;
  height: 100px;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
}
.onBEducationBanner {
  background: url(./images/onboarding/EducationBanner.svg) top center;
  height: 100px;
  margin-top: 1.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
}
.onBTableContain .MuiTableCell-root {
  display: table-cell;
  padding: 15px 15px 15px 20px;
  font-size: 0.9rem;
  text-align: left;
  text-transform: capitalize;
}
.onBTableHead .MuiTableCell-head {
  color: #3a3b3f;
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 0.875rem;
  padding: 12px 15px 12px 20px;
  background-color: #f3f3f3;
}
.onBTableIcons a {
  display: inline-block;
}
.onBBackBtn {
  padding: 5px 40px !important;
  color: #fff !important;
  background-color: $color-primary !important;
  box-shadow: none !important;
  border-radius: 10px 10px 10px 0 !important;
}
.onBBackBtn:hover,
.onBCancelBtn:hover {
  background-color: #4e5794 !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.onBCancelBtn {
  padding: 5px 40px !important;
  color: #fff !important;
  background-color: $color-primary !important;
  box-shadow: none !important;
}
.onBTableCellSkill .onBBackBtn {
  padding: 5px 25px !important;
  color: #fff !important;
  background-color: $color-primary !important;
  width: 80px;
}
.onBTableCellSkill .onBBackBtn:hover {
  background-color: #4e5794 !important;
  box-shadow: 0px 4px 2px -2px #00000070;
}
.onBBackBtn:disabled {
  background-color: #e1e1e1 !important;
  color: #888888 !important;
  border-radius: 10px 10px 10px 0 !important;
  font-weight: 700 !important;
}
.onBLegalCertifi {
  color: $color-primary;
  font-weight: bold;
  font-size: 1.3rem;
  margin: 3rem 0rem 0rem 0rem;
  font-family: $fontfamily-primary;
}
.onBDeletePopupTxt {
  color: $color-primary;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: $fontfamily-primary;
  margin-bottom: 7px;
}
.onBDeletePopupWrap {
  position: absolute;
  padding: 35px 20px;
  background-color: #fff;
  left: 33%;
  width: 34%;
  bottom: 40%;
  box-shadow: 0px 3px 30px #00000029;
  z-index: 999;
  text-align: center;
}
.onBdeletePopoverClose {
  font-family: $fontfamily-primary;
  position: absolute !important;
  left: 88%;
  top: 2%;
  color: #888;
  font-weight: 700;
  cursor: pointer;
}
.onBdeletePopoverClose:hover {
  text-decoration: none;
  background-color: transparent !important;
}
.onBLegalCheckDesc {
  padding: 0px !important;
}
/* Popup */
.onBBackBtnPopUp {
  margin-top: -5rem;
  margin-left: 33%;
  background-color: #fff;
  padding: 15px;
  width: 37%;
  box-shadow: 0px 2px 45px #00000024;
  border-radius: 4px;
  left: 34%;
  z-index: 1;
}
.onBBackBtnPopUpTxt {
  color: $color-primary;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 10px 0px 5px 0px;
}
.onBBackBtnPopUpNote {
  color: #707070;
  font-size: 0.75rem;
  font-weight: normal;
  display: block;
  margin: 16px 0px;
  border-radius: 10px 10px 10px 0;
}
.onBPopupBtn {
  padding: 5px 20px !important;
  color: #fff !important;
  background-color: $color-primary !important;
  box-shadow: none !important;
  border-radius: 10px 10px 10px 0 !important;
}
.onBPopupBtn:hover {
  background-color: #4e5794 !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.onBPopupPrimaryBtn {
  background-color: $color-secondary !important;
  font-weight: 500 !important;
  color: #fff !important;
  padding: 5px 20px !important;
  border-radius: 10px 10px 10px 0 !important;
  box-shadow: none !important;
}
.onBPopupPrimaryBtn:hover {
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.onBSecondaryBtn {
  border: 1px solid $color-primary !important;
  padding: 5px 20px !important;
  color: $color-primary !important;
  background-color: transparent !important;
  box-shadow: none;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}
.onBSecondaryBtn:hover {
  background-color: #f2f2f7 !important;
}
.onBPopUpCloseBtn {
  position: absolute !important;
  left: 67%;
  bottom: 99%;
}
.onBPopUpCloseBtn .MuiSvgIcon-fontSizeSmall {
  font-size: 0.9rem;
}
/* Work Experience Starts */
.onBWorkAuto .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  padding: 6px 0 !important;
}
.onBWorkExpBanner {
  background: url(./images/onboarding/WorkExpBanner.svg) top center;
  height: 105px;
  margin-top: 0rem;
  margin-bottom: 1rem;
  background-size: 100%;
  background-repeat: no-repeat;
}
.onBWarningText {
  color: $color-secondary;
  line-height: 26px;
}
.onBWorkExpText {
  color: $color-primary;
  line-height: 26px;
}
.onLastRowMargin {
  margin-bottom: 1rem;
}
/* Onboarding Almost Done Ends */
.onBAlmostDoneImg {
  background-image: url(./images/onboarding/OnBAlmostDone.png);
  height: 400px;
  background-repeat: no-repeat;
  background-size: 80% !important;
}
.onBPhoneNo {
  text-decoration: underline !important;
  color: $color-primary !important;
  font-size: 1.2rem;
}
.onBPhoneNo:hover {
  text-decoration: underline !important;
  color: $color-secondary !important;
}
.onBCallCareTeamBtn {
  color: #ffffffde !important;
  padding: 9px 18px;
  border-radius: 4px;
  background: $color-secondary !important;
  cursor: pointer !important;
  box-shadow: none !important;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 15px !important;
}
.onBCallCareTeamBtn:hover {
  color: #ffffffde;
  background-color: $color-secondary-light !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.onBNoThanksBtn {
  color: $color-primary !important;
  border: 1px solid $color-primary !important;
  border-radius: 3px !important;
  padding: 4px 18px !important;
}
.onbWelcometxt .onBNoThanksBtn {
  margin-top: 0rem !important;
}
.onbWelcometxt .onBLetsGoDesc {
  padding: 0rem 0rem 3rem 0rem !important;
  text-align: left;
}
.onBSkillAlmostBtn {
  text-align: left;
  margin-left: 18px;
}
.onBSkillNoThanksBtn {
  display: inline-block;
  margin-left: 1rem;
}
/* Onboarding Almost Done Ends */

/* NO Applicant ID */
.noApplicantIDWrap {
  margin-top: 14rem !important;
  margin-bottom: 8rem !important;
}
.onBnoAppIdError {
  text-align: center;
  padding: 4rem 0rem 1rem 0rem;
  color: $color-primary;
  font-size: 1.4rem;
  font-weight: bold;
}
.onBNoAppID {
  text-align: center;
  color: $color-primary;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 4rem;
}
.MuiToolbar-gutters {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.onBoardingTable {
  margin-top: 2rem !important;
}
.onBTableMsg {
  text-align: center;
  color: #888888;
  font-size: 0.8rem;
}
.onBSpeCompleteStatus {
  background-color: #e5ffec !important;
  border: 1px solid #329a4e29 !important;
  font-weight: bold;
  font-size: 0.7rem !important;
  border-radius: 25px !important;
  height: 25px !important;
  width: max-content !important;
}
.onBSpeCompleteStatus .MuiChip-label {
  color: #329a4e !important;
}
.onBSpeCompleteStatus:hover,
.onBSpeCompleteStatus .MuiChip-label:hover {
  background-color: #e5ffec;
}
.onBSpeInCompleteStatus {
  background-color: #fff4f7 !important;
  border: 1px solid #ffe0e8 !important;
  font-weight: bold;
  font-size: 0.7rem !important;
  border-radius: 25px !important;
  height: 25px !important;
  width: max-content !important;
}
.onBSpeInCompleteStatus .MuiChip-label {
  color: #ce0036 !important;
}
.onBSpeInCompleteStatus:hover,
.onBSpeInCompleteStatus .MuiChip-label:hover {
  background-color: #fff4f7;
  border-radius: 25px;
}
.onBSpeExpiredStatus {
  background-color: #ffe9e0;
  border: 1px solid #ffd6c7;
  font-weight: bold;
  font-size: 0.7rem !important;
  border-radius: 25px;
  height: 25px;
  width: max-content;
}
.onBSpeExpiredStatus .MuiChip-label {
  color: #e27d58 !important;
}
.onBSpeExpiredStatus:hover,
.onBSpeExpiredStatus .MuiChip-label:hover {
  background-color: #ffe9e0 !important;
}
.onBPendingStatus {
  background-color: #fff9f4 !important;
  border: 1px solid #ffa150 !important;
  font-weight: bold;
  font-size: 0.7rem !important;
  border-radius: 25px;
  height: 25px !important;
  width: max-content;
  text-transform: capitalize;
}
.onBPendingStatus .MuiChip-label {
  color: #ffa150 !important;
}
.onBPendingStatus:hover,
.onBPendingStatus .MuiChip-label:hover {
  background-color: #fff9f4 !important;
  border-radius: 25px;
}
.onBInProgressStatus {
  background-color: #f2f4ff !important;
  border: 1px solid #8698fc !important;
  font-weight: bold;
  font-size: 0.7rem !important;
  border-radius: 25px;
  height: 25px !important;
  width: max-content;
  text-transform: capitalize;
}
.onBInProgressStatus .MuiChip-label {
  color: #8698fc !important;
  border-radius: 25px;
}
.onBInProgressStatus:hover,
.onBInProgressStatus .MuiChip-label:hover {
  background-color: #f2f4ff !important;
}
.onBDeclinedStatus,
.onBExpiredStatus {
  background-color: #fff2f0 !important;
  border: 1px solid #f83d34 !important;
  font-weight: bold;
  font-size: 0.7rem !important;
  border-radius: 25px;
  height: 25px !important;
  width: max-content;
  text-transform: capitalize;
}
.onBDeclinedStatus .MuiChip-label,
.onBExpiredStatus .MuiChip-label {
  color: #f83d34 !important;
  border-radius: 25px;
}
.onBDeclinedStatus:hover,
.onBDeclinedStatus .MuiChip-label:hover,
.onBExpiredStatus:hover,
.onBExpiredStatus .MuiChip-label:hover {
  background-color: #fff2f0 !important;
}
/* Skills Checklist CSS Start*/
.onBYourSkillWrap {
  margin-bottom: 0rem;
}
.tableRowHover .MuiButton-label:hover {
  background: transparent !important;
}
.onBSkillDeletePopupTxt {
  color: $color-primary;
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 26px;
}
.onBSkillCheckError {
  background-color: #ecedf3;
}
.onBSkillChecklistName {
  font-weight: bold;
}
.onBSkillDeleteModal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.onBSkillDeletePopupWrap {
  background-color: #fff;
  padding: 22px;
  width: 24%;
  box-shadow: 0px 3px 25px #00000029;
  border-radius: 4px;
}
.onBTableCellSkill td {
  padding: 15px 15px 15px 20px;
  font-size: 0.9rem;
  text-align: left;
  text-transform: capitalize;
}
.onBSkillsTable tr {
  border-bottom: 1px solid #d6d6d6;
}
.onBSkillsTable tr:last-child {
  border-bottom: none;
}
.onBSkillsTable td:last-child {
  width: 10%;
}
.onBSkillsTable td:first-child {
  width: 15%;
}
.onBTableHeadSkill th {
  color: #3a3b3f;
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 0.875rem;
  padding: 12px 15px 12px 20px;
  background-color: #f3f3f3;
}
.onBViewIcon {
  color: $color-primary-dark;
  fill: $color-primary-dark;
  cursor: pointer;
  min-width: 24px !important;
  padding: 0px 15px 0px 0px !important;
}
.onBViewIcon:hover {
  fill: $color-secondary !important;
  color: $color-secondary !important;
  background-color: transparent !important;
}
.MuiTooltip-tooltip {
  margin-bottom: 10px !important;
}
.onBSkillCheckBanner {
  background: url(./images/onboarding/SkillChecklist.svg) top center;
  height: 105px;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
}
.onBSkillsTable {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16) !important;
}
.onBIncompleteStatus,
.onBIncompleteStatus:hover {
  background-color: #fff4f7 !important;
  border: 1px solid #ffe0e8;
  font-weight: bold;
  font-size: 0.7rem !important;
  text-align: center;
  border-radius: 25px;
  color: $color-error;
  width: max-content;
  padding: 3px 13px;
}
.onBCompleteStatus,
.onBCompleteStatus:hover {
  background-color: #e5ffec !important;
  border: 1px solid #329a4e29 !important;
  font-weight: bold;
  font-size: 0.7rem !important;
  text-align: center;
  border-radius: 25px;
  color: $color-success;
  width: max-content;
  height: max-content;
  padding: 3px 13px;
}
.onBAddIcon {
  color: #fff;
  fill: #fff;
}
.onBBackIcon {
  height: 18px;
  width: 18px;
  margin-bottom: 2px;
}
.onBAddBtn {
  margin-right: 30px !important;
  float: right;
  margin-top: 30px !important;
  padding: 5px 15px !important;
}
.onBSkillBtn {
  padding: 5px 25px !important;
}
.onBOptskillChip {
  margin-right: 10px;
  background-color: #e8ecf3;
  height: 35px;
  margin-bottom: 5px;
}
.onBOptskillChip .MuiChip-label {
  color: #888 !important;
  font-weight: 700;
}
.onBSkillsTable .onBMainTxtField .Mui-disabled {
  fill: #c3c3c3;
  color: #c3c3c3;
}
.onBMainTxtField .Mui-disabled .onBAddIcon {
  fill: #888888;
  color: #888888;
  width: 20px;
  height: 20px;
}
// Skill Checklist Slider Start
/* Signature Css*/
.onBSignatureWrap {
  text-align: center;
  margin-top: 25px;
}
.OnBSignaturePopup ~ div {
  color: #888;
}
.OnBSignaturePopup-content {
  background: #fff !important;
  width: 25% !important;
  margin: auto !important;
  border: 1px solid #888 !important;
  padding: 25px !important;
  border-radius: 4px !important;
  color: $color-primary;
}
.signatureCanvas {
  border: 1px solid #bebebe;
  width: 100%;
  height: 9rem;
  border-radius: 4px;
  background: #efefef;
}
.onBSignatureRow {
  padding: 15px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16) !important;
  height: 8.7rem;
  background-color: #fff;
  margin-bottom: 1rem;
}
.onBSinatureBox {
  background-color: #f0f1f4;
  padding: 15px;
  float: left;
  width: 20rem;
  height: 7rem;
}
.onBSinatureBox img {
  max-height: 90px;
  max-width: 300px;
}
.onBUpdtSignBtn {
  margin-top: 2.5rem !important;
}

.onBSkillRatingHead {
  color: $color-primary;
  font-weight: bold;
  font-size: 1.3rem;
  font-family: $fontfamily-primary;
}
.onBSkillListWrap {
  margin-top: 6rem;
  background-color: #f0f1f4;
  padding-bottom: 7rem;
}
.onBSkillCheckAccordMain {
  margin-bottom: -9px;
}
.onBSkillCheckAccordMain .MuiAccordionSummary-root {
  min-height: 60px;
}
.onBSkillAccordSeperate {
  margin-top: 0.8rem;
}
.onBSecondaryBtn .MuiSvgIcon-fontSizeSmall {
  font-size: 0.9rem;
}
.onBQuickSetFirstColumn {
  background-color: #f3f3f3;
  position: sticky;
  top: -2px;
  z-index: 1;
}
.onBSkillCheckAccorIcon svg {
  color: #3a3b3f;
}
.onBMainSkill {
  color: #3a3b3f;
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-family: $fontfamily-primary;
}
.onBSubSkill {
  color: #3a3b3f !important;
  font-size: 0.9rem;
  font-weight: bold;
  font-family: $fontfamily-primary;
  padding: 0px 0px 8px 0px;
}
.onBSkillCheckAccordMain .MuiPaper-root {
  box-shadow: 0px 0px 4px 0px #3f3f4426 !important;
  border: 1px solid #3f3f4426;
  opacity: 1;
  border-radius: 5px !important;
  margin-top: 0.8rem;
}
.onBSkillCheckAccordMain .MuiAccordion-root:before {
  background-color: #fff4f7 !important;
}
.onBSkillCheckAccordMain .MuiAccordionDetails-root {
  border-top: 1px solid #3f3f4426;
  padding: 0px 0px;
}
.onBSkillCheckBtmBorder {
  border-bottom: 1px solid #3f3f4426;
  padding: 1.7rem 0.5rem 1rem;
}
.MuiAccordionDetails-root .row:last-child {
  border-bottom: none !important;
}
.onBSkillRatingTxt {
  margin-top: -30px;
  float: right;
  font-size: 0.7rem;
  font-family: $fontfamily-primary;
  color: #0000007f;
}
.onBSkillCheckApplyToAllBtn {
  background-color: #00000029 !important;
  color: #707070 !important;
  font-size: 0.8rem !important;
  font-weight: 700 !important;
  padding: 0.5rem 1rem !important;
  margin-top: -20px !important;
}
.onBSkillCheckTitle {
  color: $color-primary;
  font-weight: 700;
  font-size: 1.8rem;
  padding: 2rem 0rem 0rem;
  font-family: $fontfamily-primary;
}
.onBSkillsCheckPopUp {
  background-color: #fff;
  padding: 25px;
  width: 30%;
  box-shadow: 0px 3px 45px #00000029;
  border-radius: 4px;
}
.onBSkillCheckPopUpTxt {
  color: $color-primary;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 26px;
}
.onBSkillsCheckPopUpBtns {
  margin-right: 10px;
}
.onBSaveandUpload {
  margin-top: 3rem;
}
.onBGoBackBtnMt {
  margin-top: 4rem;
}
.onBGoBackBtn {
  position: absolute;
  bottom: 0;
}
.onBSignCompeleteBtn {
  padding: 5px 40px !important;
  color: #fff !important;
  background-color: $color-primary !important;
  box-shadow: none !important;
  margin-top: 2rem !important;
}
.onBSignCompeleteBtn:hover {
  background-color: #4e5794 !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
//*Skill Checklist Radio Group*//
.onBSkillGroups {
  display: inline !important;
  margin-right: 10px;
}
.onBSkillGroups .MuiSvgIcon-root {
  width: 0.9em;
  height: 0.9em;
  font-size: 1.3rem;
}
.onBSkillGroups .MuiTypography-body1 {
  font-size: 0.8rem;
}
.onBSkillGroups .MuiFormControlLabel-root {
  margin-right: 35px;
}
.onBSkillGroups .Mui-checked + .MuiFormControlLabel-label {
  color: $color-primary;
  font-weight: 700;
}
.onBSkillRatingKeys {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 10px #00000012;
  border-radius: 5px;
}
.onBSkillRatingKeysList {
  padding: 2px 0px;
  color: #3a3b3f;
}
.onBSkillGroups .Mui-checked {
  color: $color-secondary;
}
/*Slider Css start*/
@mixin bullet($right) {
  position: absolute;
  top: -1px;
  right: $right;
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 50%;
}
.range {
  position: relative;
  .range-slide {
    position: absolute;
    width: 100%;
    background: transparent;
    appearance: none;
    left: 0;
    top: -3px;
    z-index: 9;
    outline: none;
    opacity: 0;

    &::-ms-expand {
      display: none;
    }
    &::-ms-clear {
      display: none;
    }
    &::-webkit-slider-thumb {
      width: 35px;
      height: 35px;
      margin: -3px 0 0 -3px;
      cursor: pointer;
      appearance: none;
    }
  }
  .circle {
    position: absolute;
    top: -4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
    border: solid 2px $color-primary;
    background: $color-primary;
    display: inline-block;
    transition: 0.2s;
  }
  .range-value {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20%;
    height: 3px;
    background: $color-primary;
    border-radius: 10px 0 0 10px;
    transition: 0.2s;
  }
  &::after {
    content: "";
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background: #f2f4f4;
    display: block;
    margin-top: 5px;
  }
  &::before {
    @include bullet(0px);
    content: "";
  }
  .bullet {
    @include bullet(20%);
    &-1 {
      @include bullet(40%);
    }
    &-2 {
      @include bullet(60%);
    }
    &-3 {
      @include bullet(80%);
    }
  }
}
/*End Slider Css*/
/*Progress Bar css start*/
.onBProgressBarText {
  margin-top: 10px;
  color: #fff;
  margin-right: 15px;
}
.onBProgressBar {
  left: 38%;
  margin-top: 10px;
  color: #fff;
  position: absolute;
}
.onBProgressBarMain {
  height: 100px;
  width: 100%;
}
.onBProgressBarMain .MuiCircularProgress-colorPrimary,
.onBProgressBarMain .MuiTypography-colorTextSecondary {
  color: #fff !important;
}
.onBSkillYesBtn {
  background-color: $color-secondary !important;
  color: #fff !important;
}
.onBSkillYesBtn:hover {
  background-color: $color-secondary-light !important;
  color: #fff !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.onBNoBtn {
  padding: 5px 25px !important;
  color: #fff !important;
  background-color: $color-primary !important;
  box-shadow: none;
}
.onBNoBtn:hover {
  background-color: #4e5794 !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
.onBYesNoBtn {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.onBSkillsCheckPopUpDesk {
  background-color: #fff;
  padding: 22px;
  width: 20%;
  box-shadow: 0px 3px 25px #00000029;
  border-radius: 4px;
  line-height: 26px;
}
.onBSkillRatingDesk {
  color: #373c73;
}
.onBSpecSaveandContinueBtn {
  border: 1px solid $color-primary !important;
  color: $color-primary !important;
  background-color: transparent !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  padding: 5px 10px !important;
  font-weight: bold !important;
}
.onBSpecSaveandContinueBtn:hover {
  background-color: #f2f2f7 !important;
}
.onBSpecProvideSkillBtn:hover {
  background-color: #f2f2f7 !important;
}
.onBSkillsCheckPopUpSkillRate {
  background-color: #fff;
  padding: 30px 40px;
  width: 36%;
  box-shadow: 0px 3px 45px #00000029;
  border-radius: 4px;
}
.onBSpecProvideSkillBtn {
  padding: 5px 25px !important;
  color: #fff !important;
  background-color: $color-primary !important;
  box-shadow: none !important;
}
.onBSpecProvideSkillBtn:hover {
  background-color: #4e5794 !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}
// Skill Checklist Slider End
// Profile Page Skill Checklist Start
.profileSkillBanner {
  background: url(./images/onboarding/SkillChecklist.svg) top center;
  height: 80px;
  margin-top: 14rem;
  margin-bottom: 1rem;
  background-repeat: no-repeat;
}
.profileSkillWrap {
  margin-top: 13rem !important;
}
.profileYourSkillWrap {
  margin-bottom: 5rem;
}
.onBGoBackProfileBtn {
  margin-bottom: 2rem !important;
}

/* Modal For confirm box */
.onBconfirmPopupWrap {
  font-family: $fontfamily-primary;
  padding: 20px;
}
.unSavedhangesChangesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .StayPageButton {
    margin-right: 15px;
  }
}
.onBconfirmPopupWrapTxt {
  padding: 0px !important;
  color: $color-primary;
  font-size: 1.4rem;
  text-align: center;
  font-family: $fontfamily-primary;
  font-weight: bold;
  margin-bottom: 20px;
}
.onBconfirmTxt {
  font-size: 1rem;
  text-align: center;
  line-height: 26px;
  font-family: $fontfamily-primary;
  margin-bottom: 27px;
  color: #444;
}
.onBconfirmModalYesBtn {
  background-color: $color-secondary !important;
  color: #fff !important;
  padding: 10px 20px !important;
  border-radius: 5px;
  border: none !important;
  font-size: 1rem;
  box-shadow: none;
  margin-right: 10px;
  font-weight: 500;
  font-family: $fontfamily-primary;
}
.onBconfirmModalYesBtn:hover {
  background-color: $color-secondary-light !important;
  color: #fff !important;
  box-shadow: 0px 4px 2px -2px #00000070 !important;
}

.onBoardingBasicInfoChangeWrapper {
  margin-bottom: 1rem;
  background-color: #f9fafc;
  padding: 1.5rem;
}

.changeBasicInfo {
  font-size: 14px;
  font-weight: 400;
  color: $color-primary;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .emailRecruiter {
    font-size: 14px;
    font-weight: 600;
    color: $color-primary;
    text-decoration: underline;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
/* Media Queries Starts */
@media screen and (min-width: 0px) and (max-width: 1350px) {
  .onBNextBtn {
    flex-direction: column;

    p {
      margin-bottom: 24px;
    }
  }
  .kentuckyLaw {
    text-align: left;
  }
}
@media screen and (min-width: 0px) and (max-width: 768px) {
  .fullWidth {
    width: 100%;
  }
  /* Confirm Popup Mobile */
  .onBconfirmPopupBtn {
    margin: -20px;
    margin-bottom: 20px;
  }
  .onBconfirmTxt {
    margin-bottom: 20px;
  }
  .onBconfirmModalYesBtn {
    margin-right: 0px;
    display: block;
    width: 100%;
  }
  /*Mobile common classes*/
  .onBMainTxtField .MuiSelect-select,
  .MuiSelect-select:focus {
    font-size: 0.9rem !important;
  }
  .onBAutoContInfo .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    font-size: 0.9rem !important;
  }
  .onBMainTxtField .MuiInput-input {
    font-size: 0.9rem;
  }
  /* Welcome Onboarding Mobile*/
  .onBStepper {
    margin-top: 0px;
  }
  .onBStepperStep,
  .onBStepperActiveStep,
  .onBStepperCompleted {
    padding: 0px !important;
    height: 45px;
    border-radius: 50px !important;
    width: 45px;
    min-width: 46px;
    margin-left: -29px !important;
  }
  .dashProfileNavReference .onBStepper .onBStepperCompleted,
  .dashProfileNavReference .onBStepper .onBStepperStep,
  .dashProfileNavReference .onBStepper .onBStepperActiveStep {
    padding: 0 5% 0 16% !important;
  }
  .myProfStatus {
    display: none;
  }
  .myProfileWrap {
    margin-top: 6.5rem;
  }
  .onBStepper .onBStepIcons {
    margin-right: 0px;
  }
  .starIconWrapCompleted {
    position: absolute;
    margin: 8px 0px 0px 0px;
    right: -9px;
    height: 46px;
    width: 46px;
  }
  .starIconWrap {
    background-color: #cfd1df;
    border: 1px solid #cfd1df;
    border-radius: 50px;
    width: 46px;
    height: 46px;
    right: -5px;
    margin: 6px 0px 0px 7px;
    position: absolute;
  }
  .starIconWrapCompleted .starIcon svg,
  .starIconWrap .starIcon svg {
    fill: #fff;
    position: absolute;
    margin: 9px 0px 0px 10px;
    height: 25px;
    width: 25px;
  }
  .onBStepper .MuiStepConnector-root {
    margin-top: 9px;
    margin-left: -32px;
    margin-right: 19px;
  }
  .onBLetsGoDesc {
    padding: 10px;
    font-size: 1.4rem;
  }
  .onBSteps,
  .onBStepIcons {
    display: block;
  }
  .onBStepper .MuiStep-horizontal {
    min-width: 16% !important;
  }
  .onBTimeToComplete,
  .onBStepperActiveStep .onBTimeToComplete {
    color: #707070;
    position: absolute;
    font-size: 0.75rem;
    display: inline;
    margin-top: 15px;
    left: -12px;
    width: 70px;
  }
  .onBStepper .onBStepLabel {
    display: none;
  }
  .myProfileNote {
    color: $color-primary-shade-light;
    font-weight: 700;
  }
  .myProfileTextMob {
    margin: 0px -15px 0px -15px;
  }
  .onBStepper .MuiStepper-horizontal {
    margin-top: 25px;
  }
  .onbWelcometxt .onBLetsGoDesc,
  .onBAlmostDoneText {
    text-align: center !important;
  }
  .onBLetsGoTitle {
    font-size: 1.7rem;
    padding: 10px 12px;
    text-align: center !important;
  }
  .onBBlueBackgLetsGo {
    height: 100%;
  }
  .onBBlueBgAlmostDone {
    height: 100% !important;
  }
  .onBLetsgoImg {
    background-image: url(./images/onboarding/OnBWelcome-Mobile.svg);
    height: 300px;
    background-repeat: no-repeat;
    background-size: 100% !important;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .getStartFooter {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  .iframePhNo a {
    text-decoration: underline !important;
    font-weight: bold !important;
  }
  .onBMainLetsGo {
    padding: 5rem 0 0 0rem;
  }
  .onBAlmostDoneWrap {
    padding: 5rem 0 0 0;
  }
  .onbWelcometxt {
    padding-top: 2rem;
  }
  .onBAlmostDoneWrap .onbWelcometxt {
    padding: 2rem 1rem 1rem 1rem;
  }
  .onbWelcometxt .onBNoThanksBtn {
    width: 100%;
  }
  .onBNoThanksMob {
    margin-top: 1rem;
  }
  .onBCallCareTeamBtn {
    margin-right: 0px !important;
    margin-bottom: 1rem;
    display: block;
  }
  .onBSkillAlmostBtn {
    text-align: center;
    margin-left: 0px;
  }
  .onBSkillNoThanksBtn {
    display: block;
    margin-left: 0rem;
    margin-top: 1rem;
  }
  .onBcompleteSkillsBtn {
    text-align: center;
    margin-left: 0rem;
    display: block;
  }
  .onBLetsGoBtn button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
    width: 100%;
  }
  /*OnBoarding Basic Info Page Mobile Css */
  .onBServErrList {
    margin-left: 0px;
    margin-bottom: 0px !important;
  }
  .onBServErrTxt {
    padding-left: 24px;
    display: block;
  }
  .onBServErrWrap {
    padding: 10px;
    margin-bottom: 1.5rem;
  }
  .onBServErrWrap svg {
    margin-top: 2px;
    position: absolute;
  }
  .onBServErrSubTxt {
    padding: 5px 0px 10px 24px;
  }
  .onBBasicInfoBanner {
    background: url(./images/onboarding/BasicInfo-Mobile.svg) top center;
    height: 75px;
    margin-top: 0rem;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .onBBasicInfoChangeBanner {
    margin-bottom: 0rem;
  }
  .onBoardingBasicInfoChangeWrapper {
    margin-bottom: 0.4rem;
    padding: 0.7rem;
    padding-left: 1.5rem;
  }
  .onBNextBtn {
    margin: 1rem 0 4rem;
  }
  .onBAutoContInfo
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    padding: 5px 0px 0px 0px !important;
  }
  /*OnBoarding About Travel Page Mobile Css */
  .onBChipMargin {
    margin-top: 1rem;
  }
  .onBAboutTravBanner {
    background: url(./images/onboarding/YourPreferences-Mobile.svg) top center;
    height: 75px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .onBSelfIdentificationBanner {
    background: url(./images/onboarding/SelfIdentification-Mobile.svg) top center;
    height: 70px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 0px;
  }
  .onBButtonColor {
    padding: 5px 30px !important;
  }
  .onBFNSelected {
    width: 100% !important;
  }
  .onBAutoContInfo .MuiAutocomplete-root {
    width: 100% !important;
  }
  .onBAbtTravChkHead {
    padding-top: 1rem;
  }
  .onBPermanentAddMain {
    padding: 15px;
    margin: 1.5rem 0rem 0rem;
  }
  .onBPermanentAddMain .onBAutoContInfo .MuiSvgIcon-root {
    margin-left: -30px;
    z-index: 9;
    font-size: 1.5rem !important;
  }
  /* Specialties Mobile*/
  .onBSpecialtyBanner {
    background: url(./images/onboarding/SpecialtiesBanner-Mobile.svg) top center;
    height: 70px;
    margin-top: 0rem;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .onBEducationBanner {
    background: url(./images/onboarding/EducationBanner-Mobile.svg) top center;
    height: 70px;
    margin-top: 1.5rem;
    margin-bottom: 0rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .onBLegalCertifi {
    margin: 1rem 0rem 0rem 0rem;
  }
  .onBoardingTable table {
    min-width: 950px;
  }
  .onBoardingTable {
    margin-top: 2rem;
  }
  .onBDeletePopupWrap {
    left: 2%;
    width: 96%;
    bottom: 40%;
  }
  .onBdeletePopoverClose {
    left: 82%;
  }
  .onBDeletePopupWrap {
    left: 1%;
    width: 100%;
    bottom: 40%;
  }
  .onBbuttonMob,
  .onBCancelBtn {
    padding: 5px 20px !important;
  }
  .onBBackBtnPopUp {
    width: 100%;
    margin-left: 0%;
  }
  .onBPopupBtn,
  .onBPopupPrimaryBtn {
    font-size: 0.7rem !important;
    font-weight: 700 !important;
  }
  .onBBackBtnPopUpTxt {
    font-size: 0.8rem;
  }
  .onBSecondaryBtn {
    font-size: 0.7rem !important;
    font-weight: 700 !important;
  }
  .onBSecondaryBtnNew {
    padding: 4px 20px !important;
  }
  .onBClosePopupBtn {
    margin-right: -15px !important;
  }
  .onBTableMsg {
    text-align: left;
  }
  .onBPopUpCloseBtn {
    position: absolute !important;
    left: 87%;
    top: -14%;
  }
  /* Work Experience Mobile*/
  .onBWorkExpBanner {
    background: url(./images/onboarding/WorkExpBanner-Mobile.svg) top center;
    height: 72px;
    margin-top: 0rem;
    margin-bottom: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .onBWorkExpRadio .MuiFormGroup-root {
    display: block;
  }
  .onBChipSelected {
    background-color: $color-secondary !important;
    padding: 20px 25px !important;
    border-radius: 50px !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;
  }
  /* Onboarding Almost Done Mobile */
  .onBAlmostDoneImg {
    background-image: url(./images/onboarding/OnBAlmostDone-Mobile.png);
    height: 280px;
    background-repeat: no-repeat;
    background-size: 100% !important;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .onBPhoneNo {
    font-size: 1.4rem;
  }
  .onBPhoneDivMob {
    display: block;
  }
  /* No applicant ID Mobile*/
  .onBnoAppIdError {
    font-size: 1.6rem;
    padding: 2.5rem 0rem 2rem 0rem;
  }
  .onBnoAppIdErrorMob {
    display: block;
  }
  .noApplicantIDWrap {
    margin-top: 10rem !important;
    margin-bottom: 1rem !important;
  }
  /* complete Skils Button Mobile */
  .onBcompleteSkillsBtn {
    text-align: center;
  }
  /* Skill Checklist Mobile*/
  .onBSkillDeletePopupWrap {
    left: 5%;
    width: 90%;
    margin-top: -12rem;
  }
  .onBModalPopupWrap {
    left: 5%;
    width: 90%;
    margin-top: -12rem;
    padding: 0px 15px 22px 15px;
  }
  .onBModalPopupIcon svg {
    margin: 25px 0px 15px 24px;
  }
  .onBInvalidSubTxt {
    padding: 4px 0px 14px 0px;
  }
  .onBCompleteStatus,
  .onBCompleteStatus:hover,
  .onBIncompleteStatus,
  .onBIncompleteStatus:hover {
    margin: auto 0;
  }
  .onBSkillsCheckPopUpSkillRate {
    width: 95%;
  }
  .onBSpecProvideSkillBtn {
    width: 100%;
  }
  .onBSpecSaveandContinueBtn {
    width: 100%;
    margin-bottom: -10px !important;
  }
  .onBSkillListWrap {
    margin-top: 5rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .onBSkillsCheckPopUpDesk {
    width: 80%;
  }
  .onBSkillCheckBanner {
    background: url(./images/onboarding/SkillChecklist-Mobile.svg) top center;
    height: 73px;
    margin-top: 0rem;
    margin-bottom: 0rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .OnBSignaturePopup-content {
    width: 100% !important;
  }
  .responsiveTable td.pivoted {
    border-bottom: 1px solid #e1e1e1 !important;
    width: 100% !important;
  }
  .responsiveTable tbody tr {
    border: none !important;
    margin-bottom: 10px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.16) !important;
    background-color: #ffffff;
  }
  .onBSkillsTable td:last-child,
  .onBSkillsTable td:first-child {
    width: 100% !important;
  }
  .onBSkillsTable td:last-child {
    border-bottom: none !important;
    height: 60px;
  }
  .onBStartBtnMob {
    position: absolute !important;
    top: 13px;
    right: 38%;
  }
  .onBSkillsTable {
    width: 100%;
    box-shadow: none !important;
  }
  .onBTableCellSkill td {
    padding: 15px;
  }
  .onBAddBtn {
    margin-right: 0px !important;
    float: none;
    padding: 5px 20px !important;
  }
  .onBAddBtnMob {
    text-align: center;
  }
  .onBAddBtnMob .onBAddBtn {
    margin-right: 0px !important;
    padding: 5px 22px !important;
  }
  .onBAddBtnMob .onBAddBtn .onBAddIcon {
    width: 20px;
    height: 20px;
  }
  .onBOptskillChip {
    display: flex !important;
    margin-top: 7px;
  }
  .onBViewIcon,
  .onBDeleteIcon {
    font-size: 0.8rem !important;
    height: 24px !important;
    padding: 0px 20px 0px 0px !important;
  }
  .range {
    margin-top: 3rem;
  }
  .onBMainSkill {
    font-size: 1rem !important;
    margin-top: 2px;
  }
  .onBSkillRatingTxt {
    margin-top: -30px;
    float: left;
  }
  .onBSinatureBox {
    width: 8rem;
    height: 6.8rem;
  }
  .onBGoBackBtn {
    position: relative;
    margin-top: 2rem;
  }
  .onBSkillCheckApplyToAllBtnMt {
    margin: 35px 0px 10px !important;
  }
  .onBSkillCheckGoBackBtn {
    margin-top: 2rem;
    display: inline-block;
    margin-right: 1rem;
  }
  .onBBackBtn {
    padding: 5px 30px !important;
  }
  .onBPrimaryBtn {
    padding: 5px 30px !important;
  }
  .onBSignCompeleteBtn {
    padding: 5px 40px !important;
    width: 100%;
  }
  .onBSkillsCheckPopUp {
    width: 96%;
    padding: 3px 25px 5px 25px;
  }
  .onBSkillGroups span.MuiFormControlLabel-label {
    margin-top: 4px;
  }
  .onBSinatureBox img {
    max-width: 100px;
    max-height: 90px;
    margin-top: 2vh;
  }
  .onBProgressBarMain {
    margin-bottom: 20%;
  }
  .onBProgressBar {
    left: 17%;
  }
  .onBSkillsRadioPopUp {
    padding: 6px 25px;
  }
  .onBSkillsCheckPopUpBtns {
    width: 100%;
    margin-bottom: 10px;
  }
  .onBQuickSetFirstColumn {
    position: sticky;
  }
  .onBSkillCheckBtmBorder {
    padding: 5px 0px 10px 0px;
  }
  .onBSubSkill {
    padding: 10px 0px 10px 0px;
    color: #373c73 !important;
  }
  .onBSkillAddRatingMobile {
    color: #707070;
    font-size: 0.9rem;
  }
  .onBSkillRatingMobColor {
    color: $color-secondary;
    font-weight: 700;
  }
  .onBSkillRatingDropDownIcon {
    fill: $color-primary !important;
    margin-left: 12px;
  }
  .onBSkillGroups {
    margin-right: 0px;
  }
  .onBSkillGroups div {
    border-bottom: 1px solid #3f3f4426;
    padding: 6px 0px 0px 0px;
  }
  .onBSkillGroups div:last-child {
    border-bottom: none;
  }
  .onBSkillGroups div:last-child {
    padding-top: 6px;
  }
  .onBSkillGroups div {
    border-bottom: 1px solid #3f3f4426;
    padding: 6px 0px 0px 0px;
  }
  .onBSkillGroups div:last-child {
    border-bottom: none;
  }
  .onBSkillGroups div:last-child {
    padding-top: 6px;
  }
  .onBSkillRadioMob {
    width: 100%;
  }
  .onBSkillsGroupErr {
    display: block;
  }
  .profileSkillBanner {
    background: url(./images/onboarding/SkillChecklist-Mobile.svg) top center;
    height: 60px;
    margin-top: 11rem;
    margin-bottom: 0.5rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .profileSkillWrap {
    margin-top: 10rem !important;
  }
  .datePick .MuiInputBase-input {
    min-width: 200px !important;
  }
  .unSavedhangesChangesWrapper {
    display: flex;
    flex-direction: column;
    .StayPageButton {
      margin: 0 0 10px 0;
      width: 100%;
      padding: 8px 16px;
    }
    .discardButton {
      width: 100%;
      padding: 8px 16px;
    }
  }
  .changeBasicInfo {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .onBfixHeader .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .onBfixHeader .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .onBStepper {
    margin-top: 7px;
  }
  .onBStepper .MuiTypography-body2 {
    font-size: 0.8rem;
  }
  .onBStepperStep,
  .onBStepperActiveStep,
  .onBStepperCompleted,
  .dashProfileNavReference .onBStepper .onBStepperCompleted,
  .dashProfileNavReference .onBStepper .onBStepperStep,
  .dashProfileNavReference .onBStepper .onBStepperActiveStep {
    padding: 11px 16px !important;
  }
  .onBLetsgoImg {
    background-size: 95% !important;
  }
  .onbWelcometxt {
    padding-top: 0rem;
  }
  .onBMainLetsGo {
    padding: 7rem 0 0 1rem;
  }
  .onBAlmostDoneWrap {
    padding: 6rem 0 0 0;
  }
  .onBAlmostDoneWrap .onbWelcometxt {
    padding-top: 0rem;
    margin-top: -1.5rem;
  }
  .onBLetsGoDesc {
    padding: 0rem 3rem 1rem 3rem;
  }
  .onBAlmostDoneText {
    padding: 0rem 0rem 1rem 0rem;
  }
  .onBBasicInfoBanner {
    margin-bottom: 0.5rem;
  }
  .onBBasicInfoChangeBanner {
    margin-bottom: 0rem;
  }
  .onBoardingBasicInfoChangeWrapper {
    margin-bottom: 0.5rem;
  }
  .myProfileWrap {
    margin-top: 8.7rem;
  }
  .onBStepper .MuiTypography-body2 {
    font-size: 0.65rem;
  }
  .myProfileText {
    font-size: 0.8rem;
  }
  .myProSearchLink {
    font-size: 0.8rem;
  }
  .onBAboutTravBanner {
    margin-top: 1.5rem;
  }
  .onBSelfIdentificationBanner {
    margin-top: 1.5rem;
  }
  .onBEducationBanner {
    margin-top: 1.5rem;
  }
  .onBWorkExpBanner {
    margin-bottom: 0.5rem;
  }
  .onBdeletePopoverClose {
    left: 85%;
  }
  .onBAlmostDoneImg {
    padding: 9rem 0 0 0;
    background-size: 86% !important;
  }
  .onBBlueBgAlmostDone {
    height: 100vh;
  }
  .onBSkillListWrap {
    margin-top: 4rem;
  }
  .onBAddBtn {
    margin-right: 12px !important;
  }
  .onBPopUpCloseBtn {
    position: absolute !important;
    left: 66%;
    bottom: 99%;
  }
  .profileSkillBanner {
    margin-top: 13rem;
    height: 80px;
  }
  .onBBlueBackgLetsGo {
    height: 96.5vh;
  }
  .onBLetsgoImg {
    height: 412px;
  }
  .onBTimeToComplete {
    font-size: 0.6rem;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .onBStepper {
    margin-top: 15px;
  }
  .onBStepper .MuiTypography-body2 {
    font-size: 0.8rem;
  }
  .onBStepperStep,
  .onBStepperActiveStep,
  .onBStepperCompleted,
  .dashProfileNavReference .onBStepper .onBStepperCompleted,
  .dashProfileNavReference .onBStepper .onBStepperStep,
  .dashProfileNavReference .onBStepper .onBStepperActiveStep {
    padding: 11px 18px !important;
  }
  .myProfileWrap {
    margin-top: 8.5rem;
  }
  .onBMainLetsGo {
    padding: 10rem 0 0 1rem;
  }
  .onBAlmostDoneWrap {
    padding: 10rem 0 0 0;
  }
  .onBLetsGoDesc {
    padding: 0rem 6rem 1rem 6rem;
  }
  .onBSkillListWrap {
    margin-top: 5rem;
  }
  .onBAddBtn {
    margin-right: 20px !important;
    border-radius: 10px 10px 10px 0 !important;
    padding: 5px 15px !important;
  }
  .onBLetsgoImg {
    background-size: 90% !important;
    height: 490px;
  }
  .onBBlueBackgLetsGo {
    height: 97.2vh;
  }
}
