@import "src/theme/variables.scss";

:export {
  primaryColor: $color-primary;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 448px;
  height: 426px;
  background-color: #FFF;
  border-radius: 25px 25px 25px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content>div>div>div>div {
  margin: auto;
}

.header {
  margin-bottom: 8px;
  color: $color-primary;
  font-family: $fontfamily-primary;
  font-weight: bold;
  font-size: 24px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: fit-content;
}

.closeButton>button>span>svg {
  color: $color-primary;
}

.ssnDobConatiner{
  padding-bottom: 0px;
}

.ssnDobConatinerError {
  padding-bottom: 0px;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    height: 338px;
    background-color: #FFF;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .closeButton {
    position: absolute;
    top: 0px;
    left: 10px;
    cursor: pointer;
    font-size: 28px;
    width: fit-content;
  }
  .ssnDobConatiner{
    padding-bottom: 35px;
  }
  .ssnDobConatinerError {
    padding-bottom: 15px !important;
  }
}