@import "src/theme/variables.scss";

.dashboardBannerV2 {
  height: 386px !important;
  padding-top: 5.5rem;
  margin-top: 0rem;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 32%;
}
.dashboardBannerEnableV2 {
  height: 400px !important;
  padding-top: 2.5rem;
}
.dashboardBannerWithLocationSearch{
  padding-top: 0 !important;
}
.dashboardBannerBkgrdAM1 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url("https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/am/bg-am-lg-1.jpg");
}
.dashboardBannerBkgrdAM2 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/am/bg-am-lg-2.jpg);
}
.dashboardBannerBkgrdAM3 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/am/bg-am-lg-3.jpg);
}
.dashboardBannerBkgrdAM4 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url("https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/am/bg-am-lg-4.jpg");
}
.dashboardBannerBkgrdAM5 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/am/bg-am-lg-5.jpg);
}
.dashboardBannerBkgrdAM6 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/am/bg-am-lg-6.jpg);
}
.dashboardBannerBkgrdPM1 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/pm/bg-pm-lg-1.jpg);
}
.dashboardBannerBkgrdPM2 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/pm/bg-pm-lg-2.jpg);
}
.dashboardBannerBkgrdPM3 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/pm/bg-pm-lg-3.jpg);
}
.dashboardBannerBkgrdPM4 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/pm/bg-pm-lg-4.jpg);
}
.dashboardBannerBkgrdPM5 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/pm/bg-pm-lg-5.jpg);
}
.dashboardBannerBkgrdPM6 {
  background: radial-gradient(rgba(68, 79, 139, 0.5), rgba(68, 79, 139, 0.01)),
    url(https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard/pm/bg-pm-lg-6.jpg);
}
.dashboardCards {
  margin-top: 0px !important;
}
.payRateNoteDashB {
  margin-top: 0rem !important;
  padding: 0px !important;
}
.dashProfileMain .dashmyProfStatus {
  margin-top: 1.9rem;
  text-align: right;
}
.dashProfileMain .onBMyProfileNav {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.dashBannerTxtHead {
  color: #ffffff;
  font-size: 2.3rem;
  font-weight: 500;
  padding-top: 1.5rem;
}
.dashBannerTxtHeadEnable{
  color: #ffffff;
  font-size: 1.7rem;
  font-weight: 700;
  padding-top: 1.5rem;
}
.dashBannerSubHead {
  display: block;
  color: #fff;
  padding: 10px 0px;
  font-size: 1.2rem;
}

.dashBannerSubHeadEnable {
  display: none;
}
.dashProfileMain .onBMyProfileNav {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.dashProfileMain .dashProfileNav .myProfileText {
  display: none;
}
.dashProfileMain .onBStepperActiveStep {
  color: $color-primary !important;
  background-color: #fff !important;
  border: none !important;
}
.dashProfileMain .onBStepperStep {
  border: none !important;
  box-shadow: 0px 3px 20px #00000029;
  padding: 13px 5px !important;
}
.dashProfileMain .onBStepperStep:hover,
.dashProfileMain .onBStepperActiveStep:hover {
  background-color: #ecedf2 !important;
  box-shadow: 1px 3px 9px 1px #00000030 !important;
}
.dashProfileNav .onBStepper .MuiStepLabel-label.MuiStepLabel-active {
  color: $color-primary;
  font-weight: bold;
}
.dashProfileNav .myProfileNote {
  color: #fff;
  font-weight: 500;
  margin-top: -7px;
  padding: 0;
}
.dashProfileNav .onBStepper .MuiStepper-horizontal {
  margin-top: -10px;
  margin-bottom: -25px;
}
.dashProfileStatus .myProfPercent {
  color: #fff;
  font-size: 2rem;
  font-family: $fontfamily-primary;
}
.dashProfileStatus .myProfileCircProgress {
  width: 140px !important;
  height: 140px !important;
}
.dashProfileSubTxt {
  color: #ffffff !important;
  position: absolute;
  cursor: pointer;
  margin-top: 3.5rem;
}
.dashProfileSubTxt:hover {
  color: #ffffff70 !important;
}
.dashProfileSubTxt .MuiSvgIcon-fontSizeSmall {
  font-size: 0.75rem;
  margin-left: 6px !important;
}
.dashProfileNav .onBStepper {
  margin-top: 0px;
}
.dashProfileNav .onBStepper .MuiStepConnector-root {
  margin-top: 10px;
}
.dashProfileNav .starIcon svg {
  fill: #003366;
}
.dashProfileNav .starIconWrap {
  background-color: #fff;
  border: 1px solid $color-primary;
}
.dashProfileNav .onBStepperActiveStep .onBTimeToComplete {
  color: #707070 !important;
  display: inline;
}
.dashProfileNav .onBTimeToComplete {
  font-style: normal !important;
}
.dashProfileNav .starIconWrapCompleted .starIcon svg {
  fill: #003366;
}
.dashProfileTxt,
.myProSearchLink {
  display: none;
}
.myProfileStatus {
  text-align: center !important;
  margin-top: 2.9rem;
}
.myProfileStatusEnable {
  text-align: center !important;
  margin-top: 1.9rem;
}
.dashProfileNav .onBStepperCompleted {
  border: 1px solid #39cd7e !important;
}
.myProfileTextMobComplete {
  margin: 4rem 0rem 4rem 0rem;
  text-align: center;
}
.dashBMargTop {
  margin: 2rem 0rem 4rem 0rem !important;
}
.dashboardLocSearchMarginTop {
  margin: 4.5rem 0 2rem 0rem !important;
}
.dashProfileStatus {
  position: absolute;
  left: 13%;
  top: -28%;
}
.dashProfileNav {
  margin-left: 25px;
}
.dashProfileNav .onBStepper .MuiStep-horizontal {
  margin-left: 9px;
}
.letsGoBtnDashB {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  padding: 6px 30px !important;
  color: $color-primary !important;
  font-weight: bold !important;
}
.letsGoBtnDashB:hover {
  background-color: #ecedf2 !important;
  box-shadow: 1px 3px 9px 1px rgba(0, 0, 0, 0.18823529411764706) !important;
}
.workExpAutomation {
  background-color: #f8fff5;
  color: #138e4d;
  padding: 20px;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}
.JobViewsDashboardAdvertisement{
  margin-top: 4rem;
  margin-left: 3%;
}
@media screen and (min-width: 0px) and (max-width: 768px) {

  .dashboardLocSearchMarginTop{
    top:0rem !important;
    margin : 40px 0 0 0 !important
  }
  .text-centerMob {
    text-align: center;
  }
  .payRateNoteDashB {
    margin-top: 0rem !important;
    padding: 0px !important;
  }
  .dashboardBannerBkgrdAM1,
  .dashboardBannerBkgrdAM2,
  .dashboardBannerBkgrdAM3,
  .dashboardBannerBkgrdAM4,
  .dashboardBannerBkgrdAM5,
  .dashboardBannerBkgrdAM6,
  .dashboardBannerBkgrdPM1,
  .dashboardBannerBkgrdPM2,
  .dashboardBannerBkgrdPM3,
  .dashboardBannerBkgrdPM4,
  .dashboardBannerBkgrdPM5,
  .dashboardBannerBkgrdPM6 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 567px !important;
    padding-top: 1rem;
  }
  .dashboardBannerV2 {
    height: 567px !important;
    padding-top: 1rem;
  }
  .dashboardBannerV2AddRefer {
    height: auto !important;
    padding-top: 1rem;
    padding-bottom: 30px;
  }
  .dashboardBannerWithSearch{
    height: 504px !important;
  }
  .dashmyProfStatus {
    text-align: left;
  }
  .dashProfileStatus .myProfPercent {
    font-size: 1.5rem;
  }
  .dashProfileStatus .myProfileCircProgress {
    width: 110px !important;
    height: 110px !important;
  }
 .dashProfileStatusEnable .myProfPercent {
    font-size: 1.5rem;
    color: #fff;
    font-family: $fontfamily-primary;
  }
  .dashProfileTxt {
    display: block !important;
  }
  .dashProfileTxtMob {
    display: none;
  }
  .myProfileStatus {
    text-align: center !important;
    margin-top: 1rem;
  }
  .dashProfileNav .onBStepper .MuiStepper-root {
    display: block;
  }
  .dashProfileNav .myProfileNote {
    margin-top: 0px;
  }
  .dashProfileNav .onBMyProfileNav {
    padding: 0rem !important;
    margin-top: 25px;
  }
  .dashProfileML {
    margin-left: 0px;
  }
  .dashProfileNav .onBStepper .MuiStep-horizontal {
    margin-top: 11px;
    display: block !important;
  }
  .dashProfileMain .onBStepperActiveStep {
    width: 100%;
    height: 45px;
    margin-top: 2.5rem;
    justify-content: left;
    padding: 0% 5% 0% 16% !important;
    align-items: center !important;
    background-color: #fff !important;
    border: 1px solid $color-primary-shade-light !important;
    border-radius: 50px !important;
    width: 97%;
    justify-content: left !important;
  }
  .dashProfileNav .onBStepperStep,
  .dashProfileNavReference .onBStepper .onBStepperStep {
    padding: 0% 5% 0% 16% !important;
    align-items: center !important;
    background-color: #fff !important;
    border: 1px solid $color-primary-shade-light !important;
    border-radius: 50px !important;
    width: 96%;
    margin-top: 3rem;
    justify-content: left !important;
  }
  .dashProfileNav .onBStepperCompleted,
  .dashProfileNavReference .onBStepper .onBStepperCompleted {
    padding: 0% 5% 0% 16% !important;
    height: 45px;
    border-radius: 50px !important;
    width: 96%;
    margin-top: 3rem;
    justify-content: left !important;
  }
  .dashProfileSubTxt {
    font-size: 0.9rem !important;
    position: relative;
    top: 29rem;
  }
  .dashProfileSubTxtAddReference {
    top: 33rem;
  }
  .dashProfileSubTxtAddReferenceEnable {
    display: none;
  }
  .myProfileTextMobComp {
    margin: 0% 10% !important;
  }
  .dashProfileNav .onBSteps,
  .dashProfileNav .onBStepIcons {
    display: inline;
    margin-right: 10px !important;
  }

  .dashProfileNav .onBStepper .MuiTypography-body2 {
    font-size: 0.875rem;
    font-family: $fontfamily-primary;
    font-weight: 700;
    color: $color-primary;
  }
  .dashProfileNav .onBStepper .onBStepLabel {
    display: inline;
  }
  .dashProfileNav .starIconWrap,
  .dashProfileNav .MuiStepConnector-lineHorizontal {
    display: none;
  }
  .dashProfileTxtCentMob {
    text-align: center;
  }
  .dashProfileBannerTxtMob {
    display: block;
  }
  .starIconWrapCompleted {
    margin-top: 9px !important;
  }
  .dashBannerTxtHead {
    font-size: 1.4rem;
  }
  .dashBannerCompleteProdTxtHead{
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    color: #ffffff;
  }
  .dashBannerSubHead,
  .starIconWrapCompleted {
    display: none;
  }
  .dashBannerSubHeadMob {
    display: block;
  }
  .dashProfileNav .myProfileTextMob {
    margin: 0% 3% 0% 6%;
    text-align: center;
  }
  .dashProfileNav .myProfileTextMobEnable {
    margin: 0% 3% 0% 34%;
    text-align: center;
  }
  .myProfileTextMob .displayBlockMob {
    display: block;
    margin-bottom: -3rem;
  }
  .myProfileTextMob .displayBlockMobEnable {
    display: none;
  }
  .myProfileTextMob {
    position: absolute;
    top: -2rem;
    margin: 0% 19% 0% 23%;
    text-align: center;
  }
  .myProfileTextMobComplete {
    position: absolute;
    top: 6rem;
    margin: 0 auto;
    text-align: center;
  }
  .dashBMargTop {
    margin: 0rem !important;
  }
  .myProfileTextMobComplete .dashBannerTxtHead {
    font-size: 2rem;
  }
  .myProfileTextMobComplete .dashBannerSubHeadMob {
    padding-top: 1rem;
    font-size: 1.4rem;
  }
  .dashboardBannerDesc{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    align-items: center;
    color: white
  }
  .complSubHeadMob {
    display: block;
  }
  .dashProfileStatus {
    position: absolute;
    top: 4rem;
    left: 34%;
  }

  .dashProfileStatusEnable{
    position: absolute;
    top: 2.2rem;
    left: 34%;
  }
  .dashProfileNav .onBStepper {
    margin-top: 12rem;
  }
  .dashProfileNav .onBStepperEnable {
    margin-top: 10rem;
  }
  .dashProfileNav {
    margin-left: 0px;
  }
  .JobViewsDashboardAdvertisement{
    margin-left: 0;
    margin-top: 0;
  }
}
.completeMyProfile {
  background-color: #FFFFFF;
  border: none;
  color: $color-primary;
  font-family: $fontfamily-primary;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 20px;
  border-radius: 15px 15px 15px 0px;
}
@media (min-width: 769px){
  .completeMyProfile {
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .dashboardBannerV2 {
    background-position: center 35%;
    padding-top: 3.5rem;
  }
  .dashProfileStatus .myProfileCircProgress {
    width: 112px !important;
    height: 112px !important;
  }
  .dashProfileMain .onBStepperActiveStep {
    padding: 11px 18px !important;
  }
  .dashProfileMain .onBStepperStep {
    padding: 11px 5px !important;
  }
  .dashBMargTop {
    margin: 3rem 0rem 4rem 0rem !important;
  }
  .dashProfileNav .myProfileNote {
    margin-top: -11px;
    font-size: 0.9rem !important;
  }
  .dashProfileSubTxt {
    position: absolute;
    cursor: pointer;
    margin-top: 2.8rem;
  }
  .dashProfileNav .onBStepIcons svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .dashProfileNav .starIconWrap {
    margin-top: -4px !important;
  }
  .dashProfileNav .onBStepper .MuiStepConnector-root {
    margin-top: -3px;
  }
  .dashProfileNav .onBStepperCompleted {
    padding: 8px 5px !important;
  }
  .dashBannerTxtHead {
    font-size: 2rem;
  }
  .dashBannerSubHead {
    font-size: 1rem;
    padding: 5px 0px 4px 0px;
  }
  .starIconWrapCompleted {
    margin-top: -4px !important;
  }
  .dashProfileStatus {
    left: -7%;
  }
  .dashProfileNav .onBStepper .MuiStep-horizontal {
    margin-left: 6px;
  }
  .starIconWrap {
    right: -7px !important;
  }
  .dashProfileNav {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .dashProfileNav .onBStepperStep,
  .dashProfileNav .onBStepperActiveStep,
  .dashProfileNav .onBStepperCompleted {
    padding: 11px 25px !important;
  }
  .dashProfileStatus .myProfileCircProgress {
    width: 135px !important;
    height: 135px !important;
  }
  .dashProfileNav .onBStepper .MuiStepConnector-root {
    margin-top: 11px;
  }
  .dashProfileStatus {
    left: -3%;
  }
  .dashProfileNav {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 1900px) {
  .dashboardBannerV2 {
    background-position: center 20%;
    background-size: cover;
  }
}
@media only screen and (max-device-width: 360px) {
  .dashProfileNav .myProfileTextMob {
    margin: 0;
    text-align: center;
    left: 2rem;
  }
  .dashProfileNav .myProfileTextMobEnable {
    margin: 0% 3% 0% 30%;
    text-align: center;
  }
  .dashProfileNav .onBStepper .MuiTypography-body2 {
    font-size: 0.9rem !important;
  }
  .dashBannerTxtHead {
    font-size: 1.3rem;
  }

}