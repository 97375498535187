@import "src/theme/variables.scss";

.LowerFooter {
  display: flex;
  font-size: 0.9rem;
  font-weight: normal;
  line-height: 26px;
  background-color: $color-primary;
  color:#fff;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 1920px;
  margin: 0 auto;
}

.FooterLink {
  color: #fff;
  text-decoration: underline;
  line-height: 26px;

  &:hover {
    color: #fff;
    opacity: 0.6;
    text-decoration: underline;
  }

  &.visited {
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .LowerFooter {
    flex-direction: column;
    text-align: center;
    font-size: 0.75rem;
    line-height: 20px;
    justify-content: center;
  }

  .FooterDivider {
    display: none;
  }
}
