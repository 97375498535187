@import "src/theme/variables.scss";

.onBSkillCheckBtmBorder {
  border-bottom: 1px solid #3f3f4426;
  padding: 1.7rem 0.5rem 1rem;
}

.onBSkillCheckError {
  background-color: #ecedf3;
}

.onBSubSkill {
  color: #3a3b3f;
  font-size: 0.9rem;
  font-weight: bold;
  font-family: $fontfamily-primary;
  padding: 0px 0px 8px 0px;
}

.MuiAutocomplete-option {
  text-transform: capitalize;
}

div {
  outline: 0 !important;
}

hr {
  border-top: 1px solid #e1e1e1;
  width: 100%;
}

.textCenter {
  text-align: center;
}

.onBTextFieldReq .MuiFormHelperText-root,
.datePick .MuiFormHelperText-root,
.onBAbtTravDrop .MuiFormHelperText-root.Mui-error,
.onBSkillsGroupErr {
  color: $color-error;
  font-weight: 500;
  font-size: 0.8rem;
  font-family: $fontfamily-primary;
}

.onBSkillsGroupErr p {
  margin: 5px 0 1rem 0;
  font-size: 0.75rem;
}

.onBSkillCheckAccorIcon svg {
  color: #3a3b3f;
}

.onBMainSkill {
  color: #3a3b3f;
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-family: $fontfamily-primary;
}

.onBCompleteStatus,
.onBCompleteStatus:hover {
  background-color: #e5ffec;
  border: 1px solid #329a4e29;
  font-weight: bold;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 25px;
  color: $color-success;
  width: max-content;
  height: max-content;
  padding: 3px 13px;
}

.onBIncompleteStatus,
.onBIncompleteStatus:hover {
  background-color: #fff4f7;
  border: 1px solid #ffe0e8;
  font-weight: bold;
  font-size: 0.7rem;
  text-align: center;
  border-radius: 25px;
  color: $color-error;
  width: max-content;
  padding: 3px 13px;
}

.onBQuickSetFirstColumn {
  background-color: #f3f3f3;
  position: sticky;
  top: -2px;
  z-index: 1;
}

.onBSkillListWrap {
  margin-top: -2rem !important;
  margin-left: -1rem;
  background-color: #fff;
  padding-bottom: 7rem;
}

.onBBackIcon {
  height: 18px;
  width: 18px;
  margin-bottom: 2px;
}

.onBSkillsCheckPopUpSkillRate {
  background-color: #fff;
  padding: 30px 40px;
  width: 36%;
  box-shadow: 0px 3px 45px #00000029;
  border-radius: 4px;
}
.onBSkillCheckPopUpTxt {
  color: $color-primary;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 26px;
}

.onBSkillsCheckPopUp {
  background-color: #fff;
  padding: 25px;
  width: 30%;
  box-shadow: 0px 3px 45px #00000029;
  border-radius: 4px;
}

.onBNoBtn {
  padding: 5px 25px;
  color: #fff;
  background-color: $color-primary;
  box-shadow: none;
}

.onBNoBtn:hover {
  background-color: #4e5794;
  box-shadow: 0px 4px 2px -2px #00000070;
}

.onBSkillCheckTitle {
  color: $color-primary;
  font-weight: 700;
  font-size: 1.8rem;
  padding: 2rem 0rem 0rem;
  font-family: $fontfamily-primary;
}

.onBWorkExpText {
  color: $color-primary;
  line-height: 26px;
}

.onBSkillRatingKeys {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 0px 10px #00000012;
  border-radius: 5px;
}

.provideSkillBorder {
  border: 1px solid $color-primary-shade-light;
}

.onBSkillRatingHead {
  color: $color-primary;
  font-weight: 700;
  font-size: 1.3rem;
  font-family: $fontfamily-primary;
}

.onBSkillRatingKeysList {
  padding: 2px 0px;
  color: #3a3b3f;
}

.onBSkillCheckAccordMain {
  margin-bottom: -9px;
}

.MuiAccordionSummary-root {
  min-height: 60px;
}

.MuiPaper-root {
  box-shadow: 0px 0px 4px 0px #3f3f4426;
  border: 1px solid #3f3f4426;
  opacity: 1;
  border-radius: 5px;
  margin-top: 0.8rem;
}

.MuiAccordion-root:before {
  background-color: #fff4f7;
}

.MuiAccordionDetails-root {
  border-top: 1px solid #3f3f4426;
  padding: 0px 0px;
}

.saveandUpload {
  border: none;
}

.onBSaveandUpload {
  margin-top: 3rem;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .fullWidth {
    width: 100%;
  }

  .onBSkillCheckBtmBorder {
    padding: 5px 0px 10px 0px;
  }

  .onBSubSkill {
    padding: 10px 0px 10px 0px;
    color: #373c73;
  }

  .onBSkillsGroupErr {
    display: block;
  }

  .onBSkillAddRatingMobile {
    color: #707070;
    font-size: 0.9rem;
  }

  .onBSkillRatingMobColor {
    color: $color-secondary;
    font-weight: 700;
  }

  .onBSkillRatingDropDownIcon {
    fill: $color-primary;
    margin-left: 12px;
  }

  .onBMainSkill {
    font-size: 1rem;
    margin-top: 2px;
  }

  .onBCompleteStatus,
  .onBCompleteStatus:hover,
  .onBIncompleteStatus,
  .onBIncompleteStatus:hover {
    margin: auto 0;
  }

  .onBQuickSetFirstColumn {
    position: sticky;
  }

  .onBSkillListWrap {
    margin-top: 3rem !important;
    margin-left: 0rem;
    background-color: #fff;
    padding-left: 0;
    padding-right: 0;
  }

  .onBSkillsCheckPopUpSkillRate {
    width: 95%;
  }

  .textcenter {
    text-align: center;
  }

  .onBSkillsCheckPopUpDesk {
    background-color: #fff;
    padding: 22px;
    width: 20%;
    box-shadow: 0px 3px 25px #00000029;
    border-radius: 4px;
    line-height: 26px;
  }

  .onBSkillsCheckPopUpDesk {
    width: 80%;
  }

  .onBSkillRatingDesk {
    color: #373c73;
  }

  .onBSkillYesBtn {
    background-color: $color-secondary;
    color: #fff;
  }

  .onBSkillYesBtn:hover {
    background-color: $color-secondary-light;
    color: #fff;
    box-shadow: 0px 4px 2px -2px #00000070;
  }

  .onBSkillsCheckPopUp {
    width: 96%;
    padding: 3px 25px 5px 25px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .onBSkillListWrap {
    margin-top: 4rem;
  }

  .onBCertificationAndLicensesBannerText .bannerHeading {
    font-size: 16px;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .onBSkillListWrap {
    margin-top: 5rem;
  }

  .onBCertificationAndLicensesBannerText .bannerHeading {
    font-size: 16px;
    padding-top: 10px;
  }
}
