@import "src/theme/variables.scss";


#jobSearchMainV2 {
  position: relative;

  .jobSearchGridContainer {
    width: 100%;
  }

  .jobSearchOverlay {
    top: 40px;
    left: -15px;
  }
}

@include breakpoint("md") {
  #jobSearchMainV2 {
    .jobSearchOverlay {
      top: 35px;
      left: -30px;
    }
  }
}