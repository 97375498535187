@import "src/theme/variables.scss";

* {
  box-sizing: border-box;
}

.certLicenStatusColumn {
  width: 12%;
}
.certLicenNameColumn {
  width: 28%;
}
.certLicenUploadDateColumn {
  width: 15%;
}
.certLicenExpirationDateColumn {
  width: 15%;
}
.certLicenActionColumn {
  background-color: #f3f3f3;
  flex-grow: 1;
  padding-left: 2rem;
  padding-right: 8rem;
}

.certLicenActionButton {
  fill: $color-primary;
  text-align: left;

  width: 18px;
  height: 18px;
}
.certLicenActionButton:hover {
  color: #d7282f !important;
  fill: #d7282f !important;
}

.certificationLicensesItem {
  font-family: $fontfamily-primary;
  color: $color-primary;
}

.certLicenRow {
  padding: 0px 14px;
}

.certLicenNoRecords {
  color: #d6d8dc;
  font-weight: 700;
  background-color: #f3f3f3;
}
