@import "src/theme/variables.scss";

.main {
  button {
    border-radius: 0px 0px 15px 0px;
    width: 100%;
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: $color-secondary;
    color: white;
  }

  button:hover {
    background-color: #f83d34;
  }

  span {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.skeleton {
  border-radius: 0px 0px 15px 0px;
}

.isInterestedClicked:disabled {
  background-color: #d5f8e7;

  span {
    color: #248953;
  }

  img {
    margin-right: 5px;
  }
}

.hasStatus:disabled {
  background-color: #e1e4f5;

  span {
    color: $color-primary;
  }

  img {
    margin-right: 5px;
  }
}

.completed,
.endedAssignment,
.cancelledAssignment,
.applicationClosed {
  &.hasStatus:disabled {
    background-color: #88909f;

    span {
      color: white;
    }
  }
}