.tableWrapper {
  width: 100%;
}

/* Apply styles
================================== */
.table {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  color: #3a3b3f;

  .tableRow {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    &:hover {
      background: #fbfbfb;
    }
    .tableCell {
      box-sizing: border-box;
      flex-grow: 1;
      padding: 15px 15px 15px 20px;
      overflow: hidden; // Or flex might break
      list-style: none;
      font-size: 0.9rem;
      text-transform: capitalize;
      line-height: 2.1;

      &.columnHeads {
        color: #3a3b3f;
        font-weight: 700;
        line-height: 1.5rem;
        font-size: 0.875rem;
        padding: 12px 15px 12px 20px;
        background-color: #f3f3f3;
      }

      .tableHeads {
        display: none;
      }
    }
  }
}

/* Responsive
  ==================================== */
@media all and (max-width: 812px) {
  .tableCollapse {
    display: block;
    width: 100%;
    box-shadow: none;

    .tableRow {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
      box-shadow: 02px 2px 5px 2px rgb(0 0 0 / 16%);
      background-color: #ffffff;

      .tableCell {
        width: 100% !important;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid rgb(224, 224, 224);
        padding: 15px;

        &:last-child {
          border-bottom: none;
        }
        .tableHeads {
          display: inline-block;
          flex: 2 1;
          color: #3a3b3f;
          font-weight: 700;
        }
        .editIconMobile {
          padding: 0px 20px 0px 0px !important;
          margin-top: -8px;
          margin-bottom: -5px;
        }
        .table-content {
          flex: 2;
          padding-left: 1em;
        }
      }
    }

    .tableRowHead {
      display: none;
    }
  }
}

// Non-Flex modernizer fallback
.no-flexbox .table {
  display: block;

  &.tableCell {
    width: 100%;
  }
}
