@import "src/theme/variables.scss";

.main {
  background-color: #ECF1F8;
  padding: 30px 20px;
}

.heading {
  color: $color-primary;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  svg {
    color: $color-primary;
    margin-right: 2px;
  }
}

.description {
  background-color: #1D4D86;
  border-radius: 15px 15px 15px 0px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin-bottom: 20px;
  background-image: url(../../../../../../imagesV2/quickApplyAdBackgroundWeb.png);
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-position-x: -75px;
}

.descriptionHeading {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

.descriptionText {
  color: white;
  text-align: center;
  font-size: 14px;
}

.jobCardListContainer {
  display: none;
}

.descriptionRegular {
  display: none;
}

@include breakpoint("sm") {
  .main {
    padding: 30px 20px 10px 20px;
  }

  .heading {
    font-size: 20px;
  }

  .description {
    max-width: 340px;
    margin: auto;
    margin-bottom: 20px;
  }

  .jobSlider {
    display: none;
  }

  .jobCardListContainer {
    display: initial;
  }

  .card {
    max-width: 340px;
    margin: 0 auto 20px auto;
  }
}

@include breakpoint-only("md") {
  .main {
    padding: 30px 20px;
  }

  .jobCardListContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .jobCardList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: stretch;
  }

  .description {
    grid-column: span 1;
    grid-row: span 2;
    margin: initial;
    max-width: initial;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
  }

  .descriptionRegular {
    display: initial;
  }

  .descriptionCompact {
    display: none;
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    max-width: initial;
  }
}

@include breakpoint("md") {
  .main {
    padding: 30px;
  }

  .sub {
    min-width: 710px;
    max-width: 1080px;
  }

  .description {
    max-width: 340px;
    padding: 44px 32px;
  }

  .descriptionHeading {
    text-align: left;
    font-size: 34px;
    font-weight: 700;
    line-height: 38px;
  }

  .descriptionText {
    text-align: left;
    font-size: 16px;
    margin: 10px 0;
  }

  .card {
    margin: 0;
    max-width: 340px;
  }
}

@include breakpoint-only("lg") {
  .heading {
    display: block;
    width: fit-content;
  }
}

@include breakpoint("lg") {
  .main {
    padding: 30px 30px;
  }

  .sub {
    max-width: 1080px;
  }

  .jobCardListContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .jobCardList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    width: 100%;
    align-items: stretch;
  }

  .description {
    grid-column: span 1;
    grid-row: span 2;
    margin: initial;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
  }

  .card {
    // max-width: 340px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
  }
}

@include breakpoint("xl") {
  .main {
    padding: 32px 60px;
  }

  .sub {
    margin: auto;
  }
}