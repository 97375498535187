@import "src/theme/variables.scss";

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.myTasksWrapper {
  @include flex-center;
  padding: 60px 50px 130px;
}

.myTasksBanner {
  @include flex-center;
  padding: 55px 0;
  background: url("~src/imagesV2/myTasksBanner.jpg") center/cover;
  color: white;
  height: 280px;

  &:before {
    background: rgba(74, 93, 177, 0.6);
    content: "";
    height: 280px;
    position: absolute;
    width: 100%;
  }
}

.myTasksBanner-sum {
  @include flex-center;
  flex-direction: column;
  height: 170px;
  width: 170px;
  border: solid 5px white;
  border-radius: 50%;
  font-size: 1.575rem;
  line-height: 31px;
  font-weight: bold;
  z-index: 1;

  span {
    font-size: 2.763rem;
    line-height: 54px;
  }
}

.myTasksBanner-placement {
  margin-left: 150px;
  z-index: 1;

  h4 {
    line-height: 29px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  p {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 0.93;
    margin-bottom: 14px;
  }

  .myTasksBanner-hospital::before {
    content: "";
    background: url("~src/imagesV2/hospital.svg");
    width: 20px;
    height: 17.8px;
    margin-right: 8px;
    display: inline-block;
    transform: translateY(1px);
  }

  .myTasksBanner-date::before {
    content: "";
    background: url("~src/imagesV2/dateIcon.svg");
    width: 19px;
    height: 20px;
    margin-right: 8px;
    display: inline-block;
    background-size: contain;
    transform: translateY(2px);
  }
}

.myTasksContent {
  width: 100%;
  max-width: 1130px;

  h4 {
    font-weight: bold;
    line-height: 26px;
    margin-bottom: 25px;
  }

  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.25;
  }
}

.myTasksTable,
.myTasksTable-empty {
  width: 100%;
  margin-top: 28px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%);
}

.myTasksTableHead {
  display: flex;
  background-color: #f3f3f3;
  color: #3a3b3f;
  padding: 16px 0 14px 0;
  font-weight: bold;

  .myTasksTable-action {
    padding-left: 20px;
  }
}

.myTasksTable-empty > .myTasksTableHead p {
  color: #b5b5b5;
  font-size: 0.875rem;
  line-height: 17px;
}

.myTasksTable-status {
  width: 270px;
  padding-left: 20px;

  .myTasksCompletedChip {
    border: solid 1px rgba(50, 154, 78, 0.16);
    background-color: #e5ffec;

    span {
      color: #329a4e !important;
    }
  }

  .myTasksIncompleteChip {
    border: solid 1px #ffadc2;
    background-color: #fff4f7;

    span {
      color: #ce0136 !important;
    }
  }

  .myTasksProcessingChip {
    border: solid 1px #f2a561;
    background-color: #fefaf5;

    span {
      color: #f2a561 !important;
    }
  }

  span {
    line-height: 1.25;
    font-weight: bold !important;
  }
}

.myTasksTable-action {
  width: 240px;

  .myTasksBtn {
    border-radius: 15px 15px 15px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.22;
    padding: 10px 18px;
    align-items: flex-start;
  }

  .myTasksDownload {
    padding-right: 16px;

    span {
      margin: 0;
    }

    svg {
      height: 18px;
      width: 18px;
      margin-left: 4px;
    }

    .loader {
      margin-left: 4px;

      svg {
        margin: 0;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

.myTasksTable-name {
  flex-grow: 1;
}

.myTasks-empty {
  height: 160px;
  background: #f3f3f3;
  text-align: center;
  padding-top: 26px;

  span {
    display: inline-block;
    width: 313px;
    font-size: 1rem;
    line-height: 21px;
  }

  span::before {
    content: "";
    width: 100%;
    height: 32.5px;
    background: url("~src/imagesV2/tasksIcon.svg") center/contain no-repeat;
    display: inline-block;
    margin-bottom: 2px;
  }
}

.myTasksTable {
  .MuiAccordion-root,
  .MuiAccordionSummary-content,
  .MuiAccordionDetails-root {
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
  }

  .MuiAccordion-root::before {
    background: none !important;
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
  }
}

.myTasksContent .MuiAccordionSummary-root {
  min-height: unset !important;
  display: flex;
  justify-content: flex-start;
  padding: 11px 0 11px 20px;
  font-size: 1rem;
  line-height: 26px;
  background-color: $color-primary-50;
  border-bottom: solid 1px #d6d8dc;
  color: #3a3b3f;
  margin: 0;
}

.myTasksContent .MuiAccordionSummary-content {
  flex-grow: 0;
}

.myTasksContent .MuiAccordionSummary-expandIcon {
  padding: 0;
  color: #3a3b3f;

  span {
    width: 25px;
    height: 25px;
  }

  svg {
    transform: scale(0.8, 0.95);
  }
}

.myTasksTable-task {
  display: flex;
  height: 87px;
  align-items: center;
  border-bottom: solid 1px #d6d8dc;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25rem;
}

.myTasksTable-error {
  color: #bb0f1a;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px 0 28px;

  &::before {
    content: "";
    width: 36px;
    color: #3a3b3f;
    height: 36px;
    background: url("~src/imagesV2/tasksError.svg") center/contain no-repeat;
    margin-right: 14px;
  }
}

.myTasksToggle {
  display: flex;
  border-radius: 14px;
  border: solid 1px #d6d8dc;
  background-color: #efefef;
}

.myTasksToggleOption {
  padding: 11px 0;
  font-size: 1rem;
  line-height: 1.25;
  font-weight: bold;
  color: $color-primary;
  text-align: center;
  flex: 1;
  cursor: pointer;
}

.myTasksToggleOption-active {
  background-color: $color-primary;
  border-radius: 14px;
  color: #fff;
  transition: background-color 0.2s ease;
}

@media screen and (max-width: 768px) {
  .myTasksTable-action {
    width: 160px;
  }

  .myTasksTable-status {
    width: 200px;
  }

  .myTasksTable-task {
    .myTasksTable-action {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;

      .myTasksBtn {
        min-width: unset;
      }

      .myTasksDownload {
        padding: 7px 16px;

        svg {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .myTasksBanner {
    flex-direction: column;
    padding: 0;
  }

  .myTasksBanner-sum {
    width: 143px;
    height: 143px;
    font-size: 1.25rem;
    line-height: 1.2rem;
    margin-bottom: 14px;
  }

  .myTasksBanner-placement {
    margin: 14px 0 0;

    h4 {
      font-size: 1.125rem;
      line-height: 18px;
      margin-bottom: 4px;
    }

    p {
      margin-bottom: 4px;
      font-size: 1rem;
    }
  }

  .myTasksWrapper {
    padding: 36px 16px 120px 16px;
  }

  .myTasksContent {
    h4 {
      margin-bottom: 13px;
    }

    .myTasksTable {
      margin-top: 24px;
    }

    .myTasksTableHead {
      padding: 11px 0;

      p {
        display: flex;
        align-items: center;
      }

      .myTasksTable-status {
        visibility: hidden;
        width: 0;
      }

      .myTasksTable-action {
        padding-left: 0;
      }
    }

    .myTasksTable-task {
      .myTasksTable-status {
        width: unset;
      }
    }

    .myTasksCompletedChip {
      display: none;
    }

    .myTasksIncompleteChip {
      span {
        display: none;
      }

      width: 12px;
      height: 12px;
      background-color: $color-secondary;
      border: none;
      margin-right: 20px;
    }

    .myTasksTable-action {
      width: 115px;
    }
  }
}
