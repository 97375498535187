@import "src/theme/variables.scss";

.SocialLinks {
  background-color: transparent;
  flex-grow: 2;
  max-width: 332px;
}

.FollowUsText {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.SocialLinkButton {
  background: transparent;
  border: none;
}

@media screen and (max-width: 576px) {
  .FollowUsText {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .SocialLinks {
    background-color: transparent;
    flex-grow: 2;
    max-width: 332px;
    margin: 0 auto;
    min-width: 332px;
  }
}
