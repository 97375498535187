.deletebutton {
  border-color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  border: none;
}

.confirmationBox {
  border-radius: 30px 30px 30px 0;
  width: 35%;
  background-color: #fff;
  padding: 22px;
  box-shadow: 0px 3px 25px rgb(0 0 0 / 16%);
  @media only screen and (min-width: 0px) and (max-width: 768px) {
    width: 60%;
  }
}

.spinnerBox {
  border-radius: 30px 30px 30px 0;
  width: 30%;
  background-color: #fff;
  padding: 22px;
  box-shadow: 0px 3px 25px rgb(0 0 0 / 16%);
  @media only screen and (min-width: 0px) and (max-width: 768px) {
    width: 50%;
  }
}

.loaderSpinner {
  margin-left: 41%;
  @media only screen and (min-width: 0px) and (max-width: 768px) {
    margin-left: 27%;
  }
  @media only screen and (min-width: 769px) and (max-width: 1280px) {
    margin-left: 37%;
  }
}
