@import "src/theme/variables.scss";

.marginEnrollment {
  padding: 20px 20px 20px 65px;
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.enrollmentDesc {
  padding: 0px 20px 0px 65px;
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}

.enrollmentBanner {
  background: $color-primary url(../../../../images/Enrollment.png) top center;
  height: 280px;
  background-size: 100%;
  background-repeat: no-repeat;
}

.enrollmentBannerText {
  font-family: $fontfamily-primary;
  font-size: 48px;
  color: #fff;
  text-align: center;
  padding-top: 8rem;
  font-weight: bold;
  letter-spacing: 0.15px;
}

.backToBenefits {
  color: $color-primary;
  text-decoration: underline;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  padding: 15px 0px 0px 65px;
  width: max-content;
}

.backToBenefits:hover {
  color: $color-secondary;
}

.enrollmentTitle {
  padding: 20px 20px 20px 65px;
  color: #373C73;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.openEnrollmentVideoContainer {
  padding: 60px 65px 0px 0px;
}

.openEnrollmentVideo{
  cursor: pointer;
}

.openEnrollSection {
  align-items: center;
  padding: 0px 65px 0px 0px;
}

.openEnrollmentWithYear {
  color: #373C73;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.openEnrollmentNovText {
  color: #373C73;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.enrollButton {
  display: flex;
  width: 100%;
  padding: 12px 20px !important;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex: 1 0 0;
  border-radius: 15px 15px 15px 0px !important;
  background: var(--secondary-main, #D7282F) !important;
}

.enrollText {
  color: #FFF;
  text-align: center;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 100% !important;
}

.enrollmentTextFont {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 15px 20px 15px 65px;
}

.beneSubText ul {
  margin-top: 0;
  margin-bottom: 0rem;
  padding-left: 30px;
}

.beneSubText ul li {
  color: $color-primary;
  list-style-type: disc;
  padding-bottom: 15px;
  font-weight: 400;
  font-style: normal;
  line-height: 24px;

  & span {
    color: #000;
    font-size: 14px;
    line-height: 24px;
  }
}

.enrollmentListText {
  padding: 0px 65px 0px 65px;
}

.enrollmentListTextHeading {
  padding: 0px 20px 0px 65px;
}

.enrollmentContent {
  font-size: 34px;
  color: #373C73;
  font-weight: 700;
  padding: 35px 65px 10px 65px;
  font-style: normal;
  line-height: 38px;
}

.enrollmentContentSecond {
  font-size: 34px;
  color: #373C73;
  font-weight: 700;
  padding: 50px 65px 10px 65px;
  font-style: normal;
  line-height: 38px;
}

.enrollmentTextFontPara {
  font-size: 14px;
  padding: 0px 65px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

a.benefitLinks {
  color: $color-primary !important;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline !important;
  line-height: 24px !important;
  font-style: normal;
  text-align: center;
}

a.benefitLinks:hover {
  color: $color-secondary !important;
  text-decoration: underline !important;
}

.benefitPlan {
  margin-bottom: 50px;
}

.openEnrollmentTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.openEnrollSectionNov2024 {
  display: flex;
  width: 100%;
  align-items: center;
}



.enrollButtonContainer {
  padding: 0px 0px;
}

.openEnrollmentNov2024 {
  padding-right: 0px;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .enrollmentBanner {
    background: $color-primary url(../../../../images/benefitsBanner.png);
  }

  .marginEnrollment {
    padding: 20px 0px;
  }

  .backToBenefits {
    padding: 0px 5px;
  }

  .openEnrollmentVideoContainer {
    padding: 10px 5px;
  }

  .enrollmentTitle {
    padding: 20px 5px;
  }

  .enrollmentDesc {
    padding: 0px 5px;
  }
  .enrollmentListTextHeading {
    padding: 0px 5px ;
  }

  .enrollmentListText {
    padding: 0px 5px;
  }

  .enrollmentContent {
    padding: 5px 5px 10px 5px;
  }

  .enrollmentContentSecond {
    padding: 20px 5px 10px 5px;
  }

  .enrollmentTextFontPara {
    padding: 0px 5px;
  }

  .openEnrollSection {
    padding: 0px 0px;
  }

  .enrollmentTextFont {
    padding: 15px 5px 15px 5px;
  }

  .openEnrollmentWithYear {
    text-align: left;
    padding-left: 5px;
  }

  .openEnrollmentNovText {
    text-align: right;
    padding-right: 5px;
  }

  .EnrollBtn {
    padding: 20px 20px;
  }

  .enrollmentBannerText {
    font-size: 37px;
  }
  .beneSubText ul {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {

  .enrollmentTitle {
    padding: 20px 20px 20px 65px;
  }

  .marginEnrollment {
    padding: 10px 20px 10px 65px;
  }

  .openEnrollmentWithYear {
    font-size: 12px;
  }

}