@import "src/theme/variables.scss";

.paystubsTable {
  table {
    border-collapse: collapse;
    padding: 5px;
    width: 100%;
    font-size: 16px;
    box-shadow: 0px 1px 3px #3f3f4426;
    border-radius: 5px;
    margin-bottom: 50px;
    table-layout: fixed;
    letter-spacing: -0.06px;
    th {
      border-bottom: 1px solid #e8e8e8;
      padding: 16px 20px;
      background: #f3f3f3;
      color: #3a3b3f;
      &:last-child {
        text-align: right;
      }
      &:nth-child(2) {
        padding-left: 120px;
      }
    }
    td {
      border-bottom: 1px solid #e8e8e8;
      padding: 14px 20px;
      background: #ffffff;
      color: #3a3b3f;
      &:last-child {
        text-align: right;
        font-weight: bold;
      }
      &:nth-child(2) {
        padding-left: 120px;
      }
    }
  }
}

.tableHeading {
  font-weight: bold;
  border-top: 1px solid #9ba0c1;
  letter-spacing: -0.09px;
  color: $color-primary;
  font-size: 24px;
  margin: 0;
  padding: 20px 0;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
    .tableHeading {
        font-size: 18px;
        padding: 18px 0;
      }

  .paystubsTable {
    table {
      box-shadow: 0px 3px 10px #3f3f4433;
      th {
        font-size: 14px;
        &:nth-child(2) {
          padding-left: 20px;
        }
      }
      td {
        font-size: 12px;
        &:nth-child(2) {
          padding-left: 20px;
        }
      }
    }
  }
}
