@import "src/theme/variables.scss";

.myProfileWrap {
  margin-top: 0rem !important;
  background-color: #fff !important;
}

.myProfileSubNav {
  width: 20% !important;
  padding: 3rem 0rem 0rem 2rem;
  background-color: #f0f1f4 !important;
  box-shadow: inset 0px 1px 4px 2px #00000012;
}

.myProfilePageWrap {
  width: 80% !important;
  padding: 3rem 4rem 0rem 4rem;
  background-color: #fff;
}

.iframeBGV2 {
  background-color: #f6f6f6 !important;
}

.onBCertificationAndLicensesBanner {
  background: url(../images/onboarding/OnB-certificationsAndLicensesBG.svg) top center;
  height: 100px;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 0;
}

.onBCertificationAndLicensesBannerText {
  text-align: left;
}

.onBCertificationAndLicensesBannerText .bannerHeading {
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  color: $color-primary;
}

.onBCertificationAndLicensesBannerText .bannerSubHeading {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $color-primary;
}

.certificationsAndLicensesBG {
  background-color: #f3f3f3;
  margin: 30px 0;
  color: $color-primary;
  padding: 1rem;
}

.noRecordFoundMessage {
  text-align: center;
  padding-top: 1rem;
  min-height: 300px;
  display: flex;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: $color-primary;
}

.colHeader {
  color: #3a3b3f;
}

.noRecords .colHeader {
  color: #d6d8dc;
}

.myProfileSubNav .onBStepper {
  width: 80% !important;
}

.iconPaddingBottom {
  padding-bottom: 2px;
}

.addCredentialsButtonBorder {
  border: none;
}

.addCredentialsButtonText {
  margin-left: 10px;
  display: inline-block;
}

.myProfilePgTitle {
  font-family: $fontfamily-primary;
  color: $color-primary;
  font-weight: 700;
  font-size: 1.4rem;
}

.myProfileSubNav .myProfileTextMob,
.myProfileSubNav .starIconWrap {
  display: none !important;
}

.myProfileSubNav .onBBasicInfoBanner {
  margin-top: 2rem !important;
}

.myProfileSubNav .onBStepper .MuiStep-horizontal {
  margin-top: 3rem;
}

.myProfileSubNav .onBStepper .MuiStepper-root {
  display: block !important;
}

.myProfileSubNav .onBStepperStep {
  background-color: transparent !important;
  border: none !important;
  justify-content: left !important;
}

.myProfileSubNav .onBStepperCompleted {
  justify-content: left !important;
  border-radius: 20px 20px 20px 0 !important;
}

.myProfileSubNav .onBStepperStep:hover {
  background-color: #ecedf2 !important;
  box-shadow: none !important;
  border-radius: 20px 20px 20px 0 !important;
}

.myProfileSubNav .onBStepperActiveStep {
  background-color: $color-primary !important;
  border: 1px solid $color-primary !important;
  color: #fff !important;
  border-radius: 20px 20px 20px 0 !important;
  justify-content: left !important;
}

.myProfileSubNav .MuiStepConnector-line {
  display: none !important;
}

.onBActiveCompleteStep {
  border: 1px solid #26b569 !important;
}

.myProfileSubNav .starIconWrapCompleted {
  display: none !important;
}

.myProfileSubNav .onBStepper p.myProfileTextMobComplete,
.dashBannerSubHeadMob {
  display: none;
}

.certLicencesBtnIcon {
  background: url(../imagesV2/CertificationIcon.svg) center center no-repeat;
  height: 22px;
  width: 22px;
  position: absolute;
  margin-top: -2px;
}

.certLicencesBtnTxt {
  padding-left: 34px;
  font-size: 1rem;
}

.certLicencesBtn {
  font-family: $fontfamily-primary;
  font-weight: 500;
  margin-top: -15px;
  margin-bottom: 20px;
  background-color: transparent !important;
  border: none !important;
  font-size: 1rem !important;
  color: $color-primary;
  padding: 11px 25px 11px 26px;
  margin-left: -9px;
  border-radius: 20px 20px 20px 0 !important;
  cursor: pointer;
}

.certLicencesBtn:hover {
  background-color: #ecedf2 !important;
  box-shadow: none !important;
  width: 90%;
  text-align: left;
  padding: 11px 25px 11px 26px;
  margin-left: -9px;
}

.certLicencesBtn svg {
  height: 22px;
  width: 22px;
}

.certLicencesBtnActive {
  background-color: $color-primary !important;
  border: none !important;
  font-weight: 400 !important;
  color: #fff !important;
  padding: 13px 25px 13px 26px !important;
  margin-left: -9px !important;
  border-radius: 20px 20px 20px 0 !important;
  margin-top: -15px !important;
  margin-bottom: 20px !important;
  width: 90% !important;
  text-align: left;
}

.certLicencesBtnActive .certLicencesBtnIcon {
  background: url(../imagesV2/CertificationWhiteIcon.svg) center center no-repeat;
  height: 22px;
  width: 22px;
  position: absolute;
  margin-left: 1px;
}

.certLicencesBtnActive .certLicencesBtnTxt {
  color: #fff;
}

.myProfilePageWrap #profileIframePage {
  margin-top: 0rem !important;
}

#profileIframePage,
#incompleteProfilePage {
  margin-top: 0rem !important;
}

.myProfilePageWrap #incompleteProfilePage,
.myProfilePageWrap #profileIframePage {
  margin: -3rem -4rem 0rem -4rem !important;
}

@media only screen and (min-width: 1281px) and (max-width: 1680px) {
  .myProfilePgTitle {
    font-size: 1.3rem;
  }

  .myProfileSubNav .onBStepper {
    width: 90% !important;
  }

  .certLicencesBtn {
    font-size: 0.9rem !important;
    padding: 11px 25px 11px 23px;
    margin-left: -9px;
    margin-top: -14px;
  }

  .certLicencesBtn:hover {
    width: 97%;
    text-align: left;
    padding: 11px 25px 11px 23px;
    margin-left: -9px;
    margin-top: -14px;
  }

  .certLicencesBtnActive {
    width: 97% !important;
    margin-top: -14px;
  }

  .certLicencesBtnTxt {
    padding-left: 27px;
    font-size: 0.9rem;
  }

  .certLicencesBtnActive .certLicencesBtnIcon {
    height: 20px;
    width: 15px;
    margin-left: -1px;
  }

  .certLicencesBtnIcon {
    height: 20px;
    width: 15px;
    margin-left: -1px;
  }

  .onBCertificationAndLicensesBannerText .bannerHeading {
    font-size: 16px;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .myProfileSubNav .onBStepperStep,
  .myProfileSubNav .onBStepperActiveStep,
  .myProfileSubNav .onBStepperCompleted {
    padding: 7px !important;
  }

  .myProfilePageWrap {
    padding: 2rem 1rem 0rem 1rem;
    background-color: #fff;
  }

  .myProfileSubNav {
    width: 20% !important;
    padding: 2rem 0rem 0rem 1rem;
  }

  .myProfilePageWrap #incompleteProfilePage,
  .myProfilePageWrap #profileIframePage {
    margin: -2rem 0rem 0rem -1rem !important;
  }

  #profileIframePage {
    margin-top: 0rem !important;
  }

  .myProfilePgTitle {
    font-size: 1.1rem;
  }

  .myProfileSubNav .onBStepper {
    width: 95% !important;
  }

  .certLicencesBtn {
    font-size: 0.75rem !important;
    padding: 9px 25px 9px 11px;
    margin-left: -9px;
    border-radius: 50px !important;
    margin-top: -15px;
  }

  .certLicencesBtn:hover {
    width: 95%;
    text-align: left;
    padding: 9px 25px 9px 11px;
    margin-left: -9px;
  }

  .certLicencesBtnActive .certLicencesBtnIcon {
    height: 21px;
    width: 15px;
    margin-left: -11px;
    margin-top: 1px;
  }

  .certLicencesBtnActive .certLicencesBtnTxt {
    padding-left: 13px;
    font-size: 0.65rem;
  }

  .certLicencesBtnTxt {
    padding-left: 28px;
    font-size: 0.65rem;
  }

  .certLicencesBtnActive {
    padding: 9px 25px 9px 26px !important;
    width: 97% !important;
  }

  .onBCertificationAndLicensesBannerText .bannerHeading {
    font-size: 16px;
    padding-top: 10px;
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .myProfileSubNav .starIconWrapCompleted {
    display: none !important;
  }

  .myprofileIcon {
    color: $color-primary;
  }

  .dropDownIcon {
    position: absolute;
    right: 14px;
    margin-top: -11px;
  }

  .customNavWrap {
    background-color: $color-primary !important;
    height: auto;
  }

  .myProfilePageWrap {
    width: 100% !important;
    padding: 1rem 0rem 0rem 0rem !important;
  }

  .myProfileSubNav {
    width: 100% !important;
    padding: 0rem 0rem 0rem 0rem;
    background-color: $color-primary !important;
    color: #fff;
    box-shadow: inset 0px 1px 4px 2px #00000012;
    position: fixed;
    z-index: 99;
  }

  .myProfileWrap .onBStepperCompleted {
    border: none;
    font-size: 1rem;
    padding: 15px 10px !important;
    color: #fff !important;
    justify-content: left !important;
    height: auto;
    width: auto;
    margin-left: 0px !important;
    border-radius: 0px !important;
    background-color: #39cd7e !important;
    font-weight: bold;
  }

  .myProfileWrap .customNavDropDown {
    width: 100vw;
    margin: 0 auto;
  }

  .custDropdown-ul {
    margin: 0;
    padding: 0px;
    background: #ffffff;
    border: 1px solid #c3c3c3;
    box-sizing: border-box;
    color: $color-primary;
    font-size: 1rem;
    box-shadow: 0px 3px 6px #00000040;
  }

  .customNavList {
    list-style: none !important;
    padding: 15px 10px;
    font-weight: 700;
    border-bottom: 1px solid #c3c3c3;

    &:last-child {
      border-bottom: none;
    }
  }

  .myProfileWrap .onBSteps,
  .onBStepIcons {
    display: inline-block !important;
    padding-right: 7px;
  }

  .myProfileSubNav .onBStepperActiveStep {
    background-color: $color-primary !important;
    border: none;
    font-size: 1rem;
    padding: 15px 10px !important;
    color: #fff !important;
    justify-content: left !important;
    height: auto;
    width: auto;
    margin-left: 0px !important;
    border-radius: 0px;
    font-weight: bold;
  }

  .onBStepMins {
    color: #707070;
    font-weight: normal !important;
    font-size: 0.8rem;
  }

  .onBStepperActiveStep .onBStepMins {
    color: #fff;
    font-weight: normal !important;
    font-size: 0.8rem;
  }

  .onBBasicInfoBanner,
  .onBSpecialtyBanner,
  .onBWorkExpBanner,
  .onBSkillCheckBanner {
    margin-top: 60px !important;
  }

  .myProfilePageWrap #incompleteProfilePage,
  .myProfilePageWrap #profileIframePage {
    margin-top: 2rem !important;
    height: 63vh;
  }

  .onBCertificationAndLicensesBanner {
    background: url(../images/onboarding/OnB-certificationsAndLicensesBGMob.svg) top center;
    height: 80px;
    margin-top: 70px;
    margin-bottom: 0rem;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .table-content .certLicenActionButton {
    min-width: 50px;
  }

  .onBCertificationAndLicensesBannerText {
    padding-top: 0px;
  }

  .onBCertificationAndLicensesBannerText .bannerHeading {
    font-size: 16px;
    padding-top: 10px;
  }

  .onBCertificationAndLicensesBannerText .bannerSubHeading {
    font-size: 12px;
    padding-top: 10px;
  }

  .noRecordFoundMessage {
    width: 88%;
  }

  .certLicencesBtnHamIcon {
    background: url(../imagesV2/CertificationIcon.svg) center center no-repeat;
    height: 22px;
    width: 22px;
    position: absolute;
  }

  .certLicencesBtnHamTxt {
    padding-left: 20px;
  }

  .hamMenuV2 .hamMenu.active .myprofileIcon .certLicencesBtnHamIcon {
    background: url(../imagesV2/Hamburger-Nav-IconV2/CertificationHamActiveIcon.svg) center center
      no-repeat;
    height: 22px;
    width: 22px;
    position: absolute;
  }

  .myProfilePageWrap #incompleteProfilePage,
  .myProfilePageWrap #profileIframePage {
    margin: 2rem 0.2rem 2rem -0.5rem !important;
    height: 63vh;
  }

  .certLicenRow {
    .addCredentialsButton {
      justify-content: center;
      width: 100%;
    }
  }
}

@media only screen and (max-device-width: 360px) {
  .onBBasicInfoBanner,
  .onBAboutTravBanner,
  .onBSpecialtyBanner,
  .onBWorkExpBanner,
  .onBSkillCheckBanner {
    background-size: initial !important;
  }
}
