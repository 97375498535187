@import "src/theme/variables.scss";

.recentResults {
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #989898;
  margin-left: -5px;
  margin-top: 3px;

  @media (max-width: 768px) {
    position: relative;
    margin-left: 60px;
    top: -19px;
  }
}

.fetchList {
  padding-left: 2px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding-left: 15px;
  }
}

.listDivider {
  height: 0.5px;
  background-color: rgb(0 0 0 / 6%) !important;
}

.textSelectedList {
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: $color-primary;
  position: relative;
  margin-top: -22px;
  line-height: "25px";
  left: 25px;
  @media (max-width: 768px) {
    position: relative;
    left: 30px;
  }
}

.filterContainer::-webkit-scrollbar {
  width: 4px;
}

.resultsClassCCA {
  &[data-condition="false"] {
    position: relative;
    left: 3px;
    top: 6px;
    bottom: 22px;
  }
}

.filterContainer {
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 2px;
  &[data-condition="false"] {
    max-height: 284px;
    margin-top: 8px;
  }
  &[data-condition="true"] {
    max-height: 250px;
  }
  @media (max-width: 768px) {
    &[data-condition="false"] {
      max-height: 210px;
    }
    &[data-condition="true"] {
      max-height: 175px;
    }
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0px;
  }
}

.locationIconColor {
  margin-right: 20px;
  margin-left: 10px;
}

.filterListPaper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.containerStyle {
  top: "8px";
  padding-left: "5px";
}

.compactStateStyle {
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -18px;
  color: $color-primary;
  top: -5px;
  &[data-condition="true"] {
    height: 45px;
    margin-top: -4px;
  }

  @media (max-width: 1286px) {
    font-size: 12px;
  }
}

.compactStateCSStyle {
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: $color-primary;
  position: relative;
  top: 2px;
}

.resultsText {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -7px;
  color: $color-primary-400;
}

.filterListNoData {
  @media (max-width: 768px) {
    &[data-condition="true"] {
      height: 135px;
    }
    &[data-condition="false"] {
      height: 178px;
    }
  }
}

.filterListWithData {
  @media (max-width: 768px) {
    &[data-condition="true"] {
      height: 182px;
    }
    &[data-condition="false"] {
      height: 225px;
    }
  }
}

.filterListNoOptionsData {
  @media (max-width: 768px) {
    &[data-condition="true"] {
      height: 182px;
    }
    &[data-condition="false"] {
      height: 225px;
    }
  }
}

.listItemClass.MuiListItemIcon-root {
  position: absolute;
  color: $color-primary;
  top: 5px;
}

.pinDropIconContainer {
  margin-left: -15px;
}

.noOptionText {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  color: #989898 !important;
  margin-top: 81px !important;
  overflow-y: hidden;
  @media (max-width: 768px) {
    margin-top: 25px !important;
    overflow-y: hidden;
  }
}

.typeToSelect {
  width: 214px;
  padding: 20px 0px 8px 0px;
  color: #656e7e;
  font-size: 16px;
  border-bottom: 0.5px solid #efefef;
  @media (max-width: 768px) {
    width: 340px;
  }
}

.typeToShowResultsDivide {
  background-color: rgba(0, 0, 0, 0.06) !important;
  margin-top: 8px !important;
  width: 244px;
  @media (max-width: 768px) {
    width: 319px;
  }
}

.addCompactStateText {
  margin-left: -10px;
  position: relative;
  top: 2px;
  @media (max-width:768px){
    margin-left: -5px;
  }
}

.checkboxColor {
  & span[class*="Mui-checked"] {
    color: $color-primary-400;
  }
  @media (min-width:1200px){ 
  white-space: nowrap;
  }
  @media (min-width:769px){ 
    width: 180px
  }
  @media (max-width:769px){ 
    white-space: nowrap;
  }
}

.checkboxIcon.MuiIconButton-root {
  height: 18.33px;
  width: 18.33px;
  margin-left: 12px;
  margin-top: 14px;
  margin-bottom: 12px;
  margin-right: 16px;
  @media (max-width: 768px) {
    height: initial;
    width: initial;
    margin-left: initial;
    margin-top: initial;
    margin-bottom: initial;
    margin-right: initial;
  }
}
