@import "src/theme/variables.scss";

.outerContainerStyle{
  padding: 8px;
  @media (max-width: 768px) {
  position: relative;
  left: 0px;
  top: 2px;
  }
}
.selectedTextStyleText {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: $color-primary-400
}

.clearAllClass {
  color: $color-primary-400;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  background: none;
  position: relative;
  left:-13px;
  top:-2px;
  text-decoration: underline;
}

.descriptionRowStyle {
    left: 12px;
    gap: 8px;
    margin-top: 10px;
}

.descriptionFontStyle{
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #989898 !important;
}

.selectedPaper {
  overflow-y: auto;
  overflow-x: hidden;
  &[data-condition="false"] {
    max-height: 282px;
    margin-top: -7px;
    margin-right: 5px;
  }
  &[data-condition="true"] {
    max-height: 275px;
    margin-right:5px;
  }
  @media (max-width: 768px) {
    margin-right: 0px;
    &[data-condition="false"] {
      max-height: 112px;
    }
    &[data-condition="true"] {
      max-height: 106px;
    }
  }
}

.selectedPaper::-webkit-scrollbar {
    width: 4px;
  }

.cityStateClass{
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  letter-spacing: 0 !important;
  text-align: left !important;
  color: #989898 !important;
  margin-left: 5px !important;
  margin-top: 5px !important;
}

.cityFontStyle{
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  color: #989898;
  margin-left: 5px;
  margin-top: 5px;
}

.delecteIconColour .MuiChip-deleteIcon {
  color: #30333A !important;
}

