@import "src/theme/variables.scss";

.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  align-items: stretch;
  min-width: 340px;
  width: initial !important;
}

.card {
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-self: center;
}

@include breakpoint("sm") {
  .main {
    // padding: 0 200px;
  }

  .card {
    max-width: 340px;
  }
}

@include breakpoint("md") {
  .sub {
    min-width: 710px;
    max-width: 1080px;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
}

@include breakpoint("lg") {
  .main {
    display: block;
    padding: 0;
  }

  .sub {
    max-width: 1080px;
    grid-template-columns: repeat(3, 1fr);
  }
}

@include breakpoint("xl") {
  .sub {
    max-width: 1590px;
    grid-template-columns: repeat(4, 1fr);
  }

  .card {
    max-width: initial;
  }
}