@import "src/theme/variables.scss";
.sentSuccessfully {
  color: #008122 !important;
  margin-bottom: 0;
  font-weight: 700;
  display: inline-block;
  padding: 10px 0 0;
}
.loaderResendBtn {
  color: $color-primary !important;
}
